import styled from "styled-components";

export const CardContainer = styled.div`
    padding: 0;
    margin-bottom: 0;
    background-color: #fff;
    border-bottom: 0;
    border-radius: 0;
`

export const CardHeader = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: auto;
    color: #121212;
    padding: 2rem 3rem ;
    transition: all 0.1s ease-in-out;

    .left{
        width: calc(100% - 35rem);
    }
    .rg{
        width: 35rem;
        padding-left: 5rem;
        text-align: center;
    }
    .timeout{font-size: 1.4rem;margin-bottom: 1rem;text-align: right;padding-right: 3rem;}
    .timeout span{font-size: 1.3rem;color: #888;margin-right: 0.8rem;} 
`

export const DetailBtn = styled.button`
    border-radius: 3rem;
    padding: 1.2rem 3rem;
    border: 1px solid #08B7A6;
    display: block;
    margin-right: 2rem;
    position: relative; 
    transition: all 0.2s ease;
    background-color:#fff;
    font-size: 1.8rem;
    font-weight: 700;
    color: #08B7A6;
`

export const ReqBtn = styled.div`
    font-size: 1.8rem;
    font-weight: 700;
    width: 15rem;
    height: 4.5rem;
    border-radius: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    align-items: center;
    border:none;
`
