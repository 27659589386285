import classNames from 'classnames';
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import CustomInput from '../../components/form/CustomInput';
import { usePostMutation } from '../../inc/axiosQuery';
import { LandReqListType, TravleReqListType } from '../../inc/type/reqType';
import { useAppDispatch, useAppSelector } from '../../store/config';
import { setLoading } from '../../store/slices/LoadingSlice';
import { setReload } from '../../store/slices/ReloadSlice';
import AlertModal from '../AlertModal';

interface ModalType {
    show : boolean;
    setShow : (hide:boolean) => void;
    reqItem:TravleReqListType | LandReqListType,
}

function LandReqEstModal({ // 랜드사 -> 여행사 견적내기
    show,
    setShow,
    reqItem,
}:ModalType) {

    const dispatch = useAppDispatch();
    const {jwt_token} = useAppSelector(state => state.userInfo);

    const estWriteMutation = usePostMutation('estWrite','doc/est_write.php');

    const [reqEstInfo, setReqEstInfo] = React.useState({
        req_idx : reqItem.req_idx,
        price_adult:'',
        price_child:'',
        price_infant:'',
        est_file:{},
        est_file_name:'',
        price_type:'원화',
        jwt_token:jwt_token,
    })

    const [alertModal, setAlertModal] = React.useState({
        show:false,
        msg:'',
        type:'',
    })

    const alertModalOn=(msg:string,type:string)=>{setAlertModal({show:true,msg:msg,type:type})}
    const alertModalOff=()=>{setAlertModal({show:false,msg:'',type:''})}
    const alertAction = () =>{
        if(alertModal.type === 'write_success'){
            setShow(false);
        }
    }



    const inputHandler = (text:string,key?:string) => {
        if(key){
            setReqEstInfo({
                ...reqEstInfo,
                [key]:text
            });
        }
    }

    const reqFileHandler = (file : FileList | null) => {
        if(file){
            setReqEstInfo({
                ...reqEstInfo,
                est_file : file[0],
                est_file_name : file[0].name,
            })
        }
    };

    const writeEst = async () => {
        if(reqEstInfo.price_adult === ''){
            alertModalOn('성인 1인 기준 금액을 입력해주세요.','');
        }
        // else if(reqEstInfo.price_child === ''){
        //     alertModalOn('유아 1인 기준 금액을 입력해주세요.','')
        // }
        // else if(reqEstInfo.price_infant === ''){
        //     alertModalOn('소아 1인 기준 금액을 입력해주세요.','');
        // }
        else{
            dispatch(setLoading(true));

            const params = {
                ...reqEstInfo,
                price_child : reqEstInfo.price_child === '' ? 0 : reqEstInfo.price_child,
                price_infant : reqEstInfo.price_infant === '' ? 0 : reqEstInfo.price_infant,
            }

            const {result,msg} = await estWriteMutation.mutateAsync(params);

            if(result){
                alertModalOn(msg,'write_success');
                dispatch(setReload(true));
            }
            else{
                alertModalOn(msg,'');
            }
            dispatch(setLoading(false));
        }
    }

    return (
        <Modal show={show} onHide={()=>{setShow(false);}} className='modal' centered>
            <div className="modal-header">
                <h5 className="modal-title">견적내기</h5>
                <button type="button" className="btn_close btn" onClick={()=>{setShow(false)}}>
                    <img src="/assets/img/ic_colse.png" alt="닫기 아이콘" />
                </button>
            </div>
            <div className="modal-body">
                <div className="info_box">
                    <p className="fs_18 fw_700 mb-3 pb-3">{reqItem.req_name}</p>
                    <p className="info_p">
                        <span>지역</span>
                        <span className="fs_14">{reqItem.loc_txt}{reqItem.loc_1_txt === '' && ' / '+reqItem.loc_1_txt}</span>
                    </p>
                    <div className="d-flex">
                        <p className="info_p mr-5">
                            <span>출발</span>
                            <span className="fs_14">{reqItem.start_date_txt}</span>
                        </p>
                        <p className="info_p">
                            <span>도착</span>
                            <span className="fs_14">{reqItem.end_date_txt}</span>
                        </p>
                    </div>
                    <div className="d-flex">
                        <p className="info_p mr-5">
                            <span>담당자</span>
                            <span className="fs_14">{reqItem.req_mb_name}</span>
                        </p>
                        <p className="info_p">
                            <span>여행 인원수</span>
                            <span className="fs_14">성인 {reqItem.cnt_adult} / 유아 {reqItem.cnt_child} /소아 {reqItem.cnt_infant}</span>
                        </p>
                    </div>
                    <p className="info_p"><span>요청사항</span></p>
                    <div className="info_txt">
                        {reqItem.req_memo === '' ? '요청사항이 등록되어있지 않습니다.' : reqItem.req_memo}
                    </div>
                </div>
                <div className="d-flex justify-content-between align-items-center mt-4 pt-2">
                    <p className="text_gray">통화 선택</p>
                    <div className="d-flex justify-content-end">
                        {reqEstInfo.price_type === '원화' ?
                            <button type="button" className="btn btn-dark btn-sm w_100 h_40 mr-3" onClick={()=>{setReqEstInfo({...reqEstInfo,price_type : '원화'})}}>원화</button>
                        :
                            <button type="button" className="btn btn-outline-dark btn-sm w_100 h_40 mr-3" onClick={()=>{setReqEstInfo({...reqEstInfo,price_type : '원화'})}}>원화</button>
                        }
                        {reqEstInfo.price_type === '달러' ? 
                            <button type="button" className="btn btn-dark btn-sm w_100 h_40" onClick={()=>{setReqEstInfo({...reqEstInfo, price_type : '달러'})}}>달러</button>
                        :
                            <button type="button" className="btn btn-outline-dark btn-sm w_100 h_40" onClick={()=>{setReqEstInfo({...reqEstInfo, price_type : '달러'})}}>달러</button>
                        }
                    </div>
                </div>
                <div className="ip_wr mt-4 pt-2">
                    <CustomInput 
                        title="성인 1인 기준"
                        placeHolder='금액을 입력해주세요.'
                        input={reqEstInfo.price_adult}
                        setInput={inputHandler}
                        keyName={'price_adult'}
                        isValidShow={false}
                        type={'number'}
                    />
                </div>
                <div className="ip_wr mt-4 pt-2">
                    <CustomInput 
                        title="유아 1인 기준"
                        placeHolder='금액을 입력해주세요.'
                        input={reqEstInfo.price_child}
                        setInput={inputHandler}
                        keyName={'price_child'}
                        isValidShow={false}
                        type={'number'}
                    />
                </div>
                <div className="ip_wr mt-4 pt-2">
                    <CustomInput 
                        title="소아 1인 기준"
                        placeHolder='금액을 입력해주세요.'
                        input={reqEstInfo.price_infant}
                        setInput={inputHandler}
                        keyName={'price_infant'}
                        isValidShow={false}
                        type={'number'}
                    />
                </div>
                {/* <button type="button" className="btn btn-dark text-white file_btn mt-4">파일첨부</button> */}
                <input id="reqFile" type="file" className={'d-none'} onChange={(e)=>{reqFileHandler(e.target.files)}}/>
                <label htmlFor='reqFile'>
                    <div className="btn btn-dark text-white file_btn d-flex align-items-center justify-content-center mt-4 attached" >파일첨부</div>
                </label>
                {reqEstInfo.est_file_name !== '' &&
                    <p className="mt-4">* {reqEstInfo.est_file_name}</p>
                }                   
            </div>
            <div className="modal-footer">
                <div className="d-flex w-100">
                    <button type="button" className="btn btn-lg btn-primary btn-block ml-4 mt-0" onClick={()=>{writeEst();}}>신청하기</button>
                </div>
            </div>
            <AlertModal 
                show={alertModal.show}
                msg={alertModal.msg}
                type={alertModal.type}
                alertAction={alertAction}
                btnLabel={'확인'}
                modalOff={alertModalOff}
            />
        </Modal>
    )
}

export default LandReqEstModal;
