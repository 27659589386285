import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import RecommandProductModal from '../../modal/recommandProduct/RecommandProductModal';
import ReqEstIndex from '../../modal/reqEstimate/ReqEstIndex';
import LoginModal from '../../modal/user/LoginModal';
import { useAppDispatch, useAppSelector } from '../../store/config';
import { setAutoLogin, setLogOut } from '../../store/slices/UserInfoSlice';

function Header() {

  const {mb_id,mb_type,mb_type_1,company_name,mb_name} = useAppSelector(state => state.userInfo);
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const [isLoginModal,setIsLoginModal] = React.useState(false);

  const [isReqEstModal, setIsReqEstModal] = React.useState(false);//견적신청 modal
  const [isRecommandProModal, setIsRecommandProModal] = React.useState(false);

  const logout = () => {
    navigate('/',{replace:true});
    dispatch(setAutoLogin(false));
    dispatch(setLogOut(''));
  }

  return (
    <div className="h_menu">
        <div className="container-lg">
            <div><Link to={mb_id === '' ? '/' : (mb_id !== '' && mb_type === '1') ? '/travleMain' : '/landMain'} className="logo"><img src="/assets/img/logo.svg" alt="홈으로 이동"/></Link></div>
            <div className="pc_nav">
              {mb_id === '' &&
                <nav className="nav_wr">
                  <ul className="nav_ul">
                    <li className="nav_li">
                      <a className="nav_a" onClick={()=>{setIsLoginModal(true)}}>로그인</a>
                    </li>
                    <li className="nav_li">
                      <Link to="/selJoinType" className="nav_a">여행사&일반회원가입 </Link>
                    </li>
                    <li className="nav_li">
                      <Link to="/landSelJoinType" className="nav_a">랜드사회원가입</Link>
                    </li>
                  </ul>
                </nav>
              }
              {/* ///////로그인 안했을때////// */}



              {(mb_id !== '' && mb_type === '1') &&
                <nav className="nav_wr">
                  <ul className="nav_ul">
                    <li className="nav_li">
                      <a href="#1" className="nav_a">안녕하십니까? <span className="ml-1">{mb_name}</span> 님 ! <img src="/assets/img/ic_lg_plate.png" alt="비행기" className="hd_plate" /></a>
                      <ul className="nav_ul2">
                        <li><Link to="editInfo" ><p>내정보</p></Link></li>
                        <li><a onClick={()=>{setIsReqEstModal(true)}}><p>견적 요청하기</p></a></li>
                        <li><Link to='/serviceCenter/1'><p>고객센터</p></Link></li>
                        <li><Link to="/myReview"><p>내 별점보기</p></Link></li>
                        <li><a onClick={()=>{logout();}}><p>로그아웃</p></a></li>
                      </ul>
                    </li>
                        <li className="nav_li">
                      <a className="nav_a req_btn"type="button" onClick={()=>{setIsReqEstModal(true)}}>견적 요청하기</a>
                    </li>
                  </ul>
                </nav>
              }
              {/*<!-- //////여행사&일반로그인시//// */}


            {(mb_id !== '' && mb_type === '2') && 
              <nav className="nav_wr">
                <ul className="nav_ul">
                  <li className="nav_li">
                    <a href="#1" className="nav_a">안녕하십니까? <span className="ml-1">{company_name}</span> 님 ! <img src="/assets/img/ic_lg_plate.png" alt="비행기" className="hd_plate" /></a>
                    <ul className="nav_ul2">
                      <li><Link to="editInfo"><p>내정보</p></Link></li>
                      <li><Link to='/serviceCenter/1'><p>고객센터</p></Link></li>
                      <li><Link to="/myReview"><p>내 별점보기</p></Link></li>
                      <li><a onClick={()=>{logout();}}><p>로그아웃</p></a></li>
                    </ul>
                  </li>
                      <li className="nav_li mr-4">
                    <a className="nav_a req_btn" type="button" onClick={()=>{setIsRecommandProModal(true)}}>상품 추천하기</a>
                  </li>
                      <li className="nav_li ml-4">
                    <Link to="/" className="nav_a req_btn">실시간 견적보기</Link>
                  </li>
                </ul>
              </nav>
            }
            {/* //////여행사&일반로그인시//// */}

            </div>
        </div>
        <LoginModal 
            show={isLoginModal}
            setShow={setIsLoginModal}
        />
        <ReqEstIndex //견적신청 
            show={isReqEstModal}
            setShow={setIsReqEstModal}
        />
        <RecommandProductModal 
            show={isRecommandProModal}
            setShow={setIsRecommandProModal}
            editType={'w'}
        />
    </div>
  );
}

export default Header;
