import React, { useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Link, useNavigate } from 'react-router-dom';
import CustomInput from '../../components/form/CustomInput';
import {useMutation} from 'react-query';
import { usePostMutation } from '../../inc/axiosQuery';
import { MarginY } from '../../inc/FlexStyle';
import { useAppDispatch, useAppSelector } from '../../store/config';
import { setAutoLogin, setUserInfo } from '../../store/slices/UserInfoSlice';
import { pwCheck, pwCheckRe } from '../../inc/func';
import AlertModal from '../AlertModal';
import { setLoading } from '../../store/slices/LoadingSlice';

interface ChgPwdModalType {
    show : boolean;
    setShow : (isLogin:boolean) => void;
    mb_id : string;
    type : 'edit' | 'find';
}

function ChangePwdModal({
    show,
    setShow,
    mb_id,
    type,
}:ChgPwdModalType) {

    const dispatch = useAppDispatch();
    const navigator = useNavigate();

    const {jwt_token} = useAppSelector(state => state.userInfo);

    const findPwMutation = usePostMutation('noneLoginPwChg','user/mb_pwd_chg_01.php');
    const changePwMutation = usePostMutation('loginPwChg','user/mb_pwd_chg.php.php');

    const initialInput = {
        mb_pw : '',
        mb_pw_re : '',
    }
    const initialCheck = {
        pwd : false,
        pwd_msg : '',
        pwd_re : false,
        pwd_re_msg :'',
    }
    const [inputInfo, setInputInfo] = React.useState(()=>initialInput);

    const [inputCheck, setInputCheck] = React.useState(()=>initialCheck);

    const [alertModal, setAlertModal] = React.useState({
        show:false,
        msg:'',
        type:'',
    })

    const alertModalOn = (msg:string, type:string) => {
        setAlertModal({
            show:true,
            msg:msg,
            type:type,
        })
    }

    const alertModalOff = () => {
        setAlertModal({
            show:false,
            msg:'',
            type:'',
        })
    }

    const alertAction = () => {
        if(alertModal.type === 'chg_success'){
            setShow(false);
            if(type === 'find'){
                navigator('/');
            }
        }
        alertModalOff();
        
    }

    const inputHandler = (text : string, key?:string) => {
        if(key){
            setInputInfo({
                ...inputInfo,
                [key] : text,
            })
        }
    }

    const chgPwHandler = async () => {
        

        if(inputInfo.mb_pw === ''){
            alertModalOn('변경할 비밀번호를 입력해주세요.','');
        }
        else if(inputInfo.mb_pw_re === ''){
            alertModalOn('변경할 비밀번호를 한번 더 입력해주세요.','');
        }
        else if(!inputCheck.pwd){
            alertModalOn(inputCheck.pwd_msg,'');
        }
        else if(!inputCheck.pwd_re){
            alertModalOn(inputCheck.pwd_re_msg,'');
        }
        else{
            dispatch(setLoading(true));
            if(type === 'find'){
                const params = {
                    mb_id : mb_id,
                    mb_pw_chg : inputInfo.mb_pw,
                }
                const {result, msg} = await findPwMutation.mutateAsync(params);

                if(result){
                    alertModalOn('비밀번호변경이 완료되었습니다.','chg_success');
                }
                else{
                    alertModalOn(msg,'');
                }
            }
            else{
                const params = {
                    jwt_token : jwt_token,
                    mb_pw_chg : inputInfo.mb_pw,
                }
                const {result, msg} = await changePwMutation.mutateAsync(params);

                if(result){
                    alertModalOn('비밀번호변경이 완료되었습니다.','chg_success');
                }
                else{
                    alertModalOn(msg,'');
                }
                setInputInfo(initialInput)
                setInputCheck(initialCheck);
            }
            dispatch(setLoading(false));
        }


    }

    React.useEffect(()=>{
        setInputCheck({
            pwd : pwCheck(inputInfo.mb_pw).result,
            pwd_msg : pwCheck(inputInfo.mb_pw).msg,
            pwd_re : pwCheckRe(inputInfo.mb_pw,inputInfo.mb_pw_re).result,
            pwd_re_msg : pwCheckRe(inputInfo.mb_pw,inputInfo.mb_pw_re).msg,
        })
    },[inputInfo.mb_pw,inputInfo.mb_pw_re])


    return (
        <Modal show={show} onHide={()=>{setShow(false)}} className='modal' centered>
            <div className="modal-header">
                <h5 className="modal-title">비밀번호 변경</h5>
                <button type="button" className="btn_close btn" onClick={()=>{setShow(false)}}>
                    <img src="/assets/img/ic_colse.png" alt="닫기 아이콘" />
                </button>
            </div>
            <form className="modal-body">
                <CustomInput 
                    input={inputInfo.mb_pw}
                    setInput={inputHandler}
                    title={'새 비밀번호'}
                    placeHolder={"변경할 비밀번호를 적어주세요."}
                    isValidShow={!inputCheck.pwd && inputInfo.mb_pw !== ''}
                    isValid={inputCheck.pwd}
                    validText={inputCheck.pwd_msg}
                    keyName={'mb_pw'}
                    type={'password'}
                />
                <MarginY mt={1} />
                <CustomInput 
                    input={inputInfo.mb_pw_re}
                    setInput={inputHandler}
                    placeHolder={"변경할 비밀번호를 다시 한번 적어주세요."}
                    type={'password'}
                    isValidShow={!inputCheck.pwd_re && inputInfo.mb_pw_re !== ''}
                    isValid={inputCheck.pwd_re}
                    validText={inputCheck.pwd_re_msg}
                    keyName={'mb_pw_re'}
                />
            </form>
            <div className="modal-footer mt-0">
                <div className="d-flex w-100">
                    <button type="button" className="btn btn-lg btn-gray btn-block mr-4" onClick={()=>{setShow(false);}}>취소</button>
                    <button type="button" className="btn btn-lg btn-primary btn-block mt-0" onClick={chgPwHandler}>확인</button>
                </div>
            </div>
            <AlertModal 
                show={alertModal.show}
                modalOff={alertModalOff}
                alertAction={alertAction}
                type={alertModal.type}
                msg={alertModal.msg}
                btnLabel={'확인'}
            />
        </Modal>
    )
}

export default ChangePwdModal;
