import React from 'react';
import classNames from 'classnames';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { KAKAO_AUTH_URL } from './KakaoAuth';
import FacebookLogin from '@greatsumini/react-facebook-login';
import { useAppDispatch, useAppSelector } from '../../../store/config';
import { NAVER_AUTH_URL } from './NaverAuth';
import { usePostMutation } from '../../../inc/axiosQuery';
import { setAutoLogin, setUserInfo } from '../../../store/slices/UserInfoSlice';

function NomalJoinSelect() {

    const dispatch = useAppDispatch();
    const {mb_type} = useAppSelector(state => state.userInfo);
    const navigator = useNavigate();
    const facebookLoginMutation = usePostMutation('accessSnsLogin','user/sns_login.php');

   

    const faceBookLoginSccuess = async (res:any) => {
        
        const {data,result, msg} = await facebookLoginMutation.mutateAsync({
            mt_id:res.id,
            mb_reg_type:'F',
        })

        if(result){
            await dispatch(setAutoLogin(true));
            await dispatch(setUserInfo(data));
        }
        else{
            navigator(`/normalJoin?type=facebook&id=${res.id}&email=${res.email}&name=${res.name}`,{replace:true});
        }

    }

    React.useEffect(()=>{
        if(mb_type === '1'){
            navigator('/travleMain',{replace:true});
        }
        else if(mb_type === '2'){
            navigator('/landMain',{replace:true});
        }
    },[])

    return (
        <div className="container-fluid px-0">
            <div className="sub_pg">
                <section className="container-lg mx-auto">
                    <div className="lg_bx">
                        <div className="tit_wr">
                            <h3 className=""><img src="/assets/img/logo.svg" alt="tobyfrom" /></h3>
                            <p className="fs_18 mt-2">내 입맛에 맞는 여행을 골라 보세요!</p>
                        </div>
                        <div className="join_btn_wr">
                            <div className="top">
                                <Link to="/normalJoin?type=normal">
                                    <button type="button" className="btn btn-primary btn-lg btn-block fw_700">일반 회원가입</button>
                                </Link>
                            </div>
                            <div className="or"><p>또는</p></div>
                            <div className="sns_btn_wr">
                            <button onClick={()=>{window.location.href=KAKAO_AUTH_URL}} type="button" className="btn  btn-lg btn-block kakao fw_700"><img src="/assets/img/ic_kako.png" alt="카카오로 회원가입" /> 카카오로 바로시작</button>
                            {/* <button type="button" className="btn  btn-lg btn-block facebook fw_700"><img src="/assets/img/ic_face.png" alt="페이스북으로 회원가입" /> 카카오로 바로시작</button> */}
                            
                            <FacebookLogin
                                appId={process.env.REACT_APP_FACEBOOK_APP_ID ? process.env.REACT_APP_FACEBOOK_APP_ID : ''}
                                initParams={{
                                    version:'v16.0',
                                    xfbml:true,
                                }}
                                onFail={(error) => {
                                    console.log('Login Failed!');
                                    console.log('status: ', error.status);
                                }}
                                onProfileSuccess={faceBookLoginSccuess}
                                render={({onClick})=>{
                                    return(
                                        <button onClick={onClick} type="button" className="btn  btn-lg btn-block facebook fw_700"><img src="/assets/img/ic_face.png" alt="페이스북으로 회원가입" /> 페이스북으로 바로시작</button>
                                    )
                                }}
                            />
                            <button onClick={()=>{window.location.href=NAVER_AUTH_URL}} type="button" className="btn  btn-lg btn-block naver fw_700"><img src="/assets/img/ic_naver.png" alt="네이버 회원가입" /> 네이버로 바로시작</button>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default NomalJoinSelect;
