import React from 'react';

import classNames from 'classnames';
import CustomSelectBox from './CustomSelectBox';
import { MarginY } from '../../inc/FlexStyle';
import { usePostQuery } from '../../inc/axiosQuery';

type selectType = {
    value : string,
    label : string,
}

interface LocationSelectType {
    index:number,
    selValue1 : string,
    selValue2 : string,
    countryChange : (sel : string , type? : string) => void,
    locationChange : (sel : string , type? : string) => void,
    delAction : (idx?:number) => void,
    countryList : selectType[],
}

function LocationSelect({
    index,
    selValue1,
    selValue2,
    countryChange,
    locationChange,
    delAction,
    countryList,
}:LocationSelectType) { //국가-지역 설정(지역 불러오기)
    const {data:locList, isLoading : locLoading, isError: locError} = usePostQuery('locationList',{loc:selValue1},'cate/loc_list_1.php');

    const [locationList, setLocationList] = React.useState<selectType[]>([]);

    React.useEffect(()=>{   
        if(locList){

            let tempList:selectType[] = [];

            locList.data.loc_list.forEach((item:{loc:string,location:string} , index : number) => {
                tempList.push({
                    value:item.loc,
                    label:item.location,
                })
            })
            setLocationList([...tempList]);
            // loc

        }
    },[locList,locLoading,locError])


    return (
        <div key={index}>
            <div className="d-flex flex-row w-100" style={{gap:10}}>
                <div style={{flex:1}}>
                    <CustomSelectBox 
                        title={'국가'}
                        optionList={countryList}
                        selValue={selValue1}
                        setSelValue={countryChange}
                        keyName={String(index)}
                    />
                </div>
                <div style={{flex:1}}>
                    <CustomSelectBox 
                        title={'지역'}
                        optionList={locationList}
                        selValue={selValue2}
                        setSelValue={locationChange}
                        keyName={String(index)}
                        isDelBtn={true}
                        delAction={delAction}
                    />
                </div>
            </div>
            <MarginY mb={1} />
        </div>
    );
}

export default LocationSelect;
