import React from 'react';
import { Link, useParams } from 'react-router-dom';
import Loading from '../../components/form/Loading';
import { usePostMutation, usePostQuery } from '../../inc/axiosQuery';

export default function NoticeDetail(){

    const {ntIdx} = useParams();
    const {data : detailData, isLoading : detailLoading} = usePostQuery('getNoticeDetail',{
        nt_idx : ntIdx,
    },'board/notice_detail.php');

    console.log(detailData);

    return(
        <div className="container sub_pg">
            <div className="row">
                {detailLoading ?
                    <Loading />
                :
                <>
                    <div className="col-12">            
                        <p className="board_tit">{detailData.data.nt_title}</p>
                        <span className="date">{detailData.data.wr_datetime}</span>
                        <div className="board_content" dangerouslySetInnerHTML={{__html:detailData.data.nt_content.replace('&lt;','<').replace('&gt;','>')}}>
                            {/* {
                                detailData.data.nt_content.replace('&lt;','<').replace('&gt;','>')
                            } */}
                        </div>
                    </div>        
                    <div className="col-12 d-flex justify-content-center">
                        <Link to='/serviceCenter/3'>
                            <button className="btn btn-lg btn-primary w_380 fw_700" type="button">
                                목록으로
                            </button>
                        </Link>
                    </div>
                </>
                }   
                
            </div>
        </div>
    )
}