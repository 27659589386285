import React from 'react';

import classNames from 'classnames';

interface optionType {
    key?: string | number,
    value:string | number,
    label: string | number,
}

interface CustomSelectType {
    title? : string,
    optionList : optionType[],
    selValue : string,
    setSelValue : (sel:string,type?:string) => void;
    keyName? : string,
    isDelBtn? : boolean,
    delAction? : (idx?:number) => void;
    style ? : object;
    disable? : boolean;
}

function CustomSelectBox({
    title,
    optionList,
    selValue,
    setSelValue,
    keyName,
    isDelBtn,
    delAction,
    style,
    disable,
}:CustomSelectType) {

    return (
        <div className="ip_wr" style={style}>
            <div className="ip_tit d-flex align-items-center justify-content-between">
                <h5>{title}</h5>
                {(isDelBtn && delAction && Number(keyName) !== 0) &&
                    <img src="/assets/img/ic_close2.png" style={{width:25,height:25,cursor:'pointer'}} onClick={()=>{delAction(Number(keyName));}}/>
                }
            </div>
            <select disabled={optionList.length === 0} className="form-control form-control-lg custom-select" value={selValue} onChange={(e)=>{
                if(keyName){
                    setSelValue(e.target.value,keyName)
                }
                else{
                    setSelValue(e.target.value)
                }
            }}>
                <option value='' style={{display:'none'}}>전체</option>
                {optionList.map((item,index) => {
                    return(
                        <option key={index} value={item.value}>{item.label}</option>
                    )
                })}
                {/* <option selected={true}>{}</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option> */}
            </select>
        </div>
    );
}

export default CustomSelectBox;
