import React from 'react';
import Calendar from 'react-calendar';
import { DateFormatter, DayPicker } from 'react-day-picker';
import { ko } from 'date-fns/locale';
import moment from 'moment';
import '../../assets/style/CustomCalender.css';
import CustomCaption from '../../components/form/DayPicker';
import CustomSelectBox from '../../components/form/CustomSelectBox';
import { usePostQuery } from '../../inc/axiosQuery';
import { MarginY } from '../../inc/FlexStyle';
import { RecProType } from './recType';

type countryType = {
    value : string,
    label : string,
}

function RecStep3({recInfo, setRecInfo,recStep,setRecStep,setShow,locationList,countryList,inputCheck} : RecProType) {    
    

    const setLocation = (loc:string,type?:string) => {
        if(type){
            setRecInfo({
                ...recInfo,
                [type] : loc
            })
        }
    }



    return (
        <div>
            <div className="modal-header justify-content-center">
                <h5 className="modal-title">행선지를 선택해주세요!</h5>
            </div>
            <div className="modal-body">
            <ul className="modal_tab">
                    <li><a onClick={()=>{setRecStep(1)}}>날짜 선택<span className="fc_red"> *</span></a></li>
                    <li className="on"><a onClick={()=>{if(inputCheck?.date)setRecStep(3)}}>지역 선택</a></li>
                    <li><a onClick={()=>{if(inputCheck?.date)setRecStep(4)}}>인원수 선택<span className="fc_red"> *</span></a></li>
                    <li><a onClick={()=>{if(inputCheck?.date && inputCheck?.people)setRecStep(5)}}>완료</a></li>
                </ul>
                {countryList &&
                    <CustomSelectBox 
                        title="대분류"
                        optionList={countryList}
                        selValue={recInfo.loc}
                        setSelValue={setLocation}
                        keyName={'loc'}
                        isDelBtn={false}
                    />
                }
                <MarginY mb={2} />
                {locationList &&
                    <CustomSelectBox 
                        title="소분류"
                        optionList={locationList}
                        selValue={recInfo.loc_1}
                        setSelValue={setLocation}
                        keyName={'loc_1'}
                        isDelBtn={false}
                    />
                }
            </div>
            <div className="modal-footer">
                <div className="d-flex w-100">
                    <button type="button" className="btn btn-lg btn-gray btn-block mb-0" onClick={()=>{setRecStep(recStep-1)}}>이전</button>
                    <button type="button" className="btn btn-lg btn-primary btn-block ml-4 mt-0" onClick={()=>{setRecStep(recStep+1)}}>다음</button>
                </div>
            </div>
        </div>
    )
}

export default RecStep3;
