import classNames from 'classnames';
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import { usePostMutation } from '../../inc/axiosQuery';
import { getDate, getTime, NumberComma } from '../../inc/func';
import { useAppDispatch } from '../../store/config';
import { setLoading } from '../../store/slices/LoadingSlice';
import { setReload } from '../../store/slices/ReloadSlice';
import AlertModal from '../AlertModal';
import ConfirmModal from '../ConfirmModal';
import { DetailInfoType, ProDetailModalType } from './DetailType';


function NoramalProDetail3({ //여행사 & 개인 기획상품 신청
    proStep,
    setProStep,
    proDetailInfo,
    setProDetailInfo,
    setShow,
    detailInfo,
    initialInfo,
}:ProDetailModalType) {

    const dispatch = useAppDispatch();
    const proConMutation = usePostMutation('proCont','doc/product_contract.php');

    const priceType = detailInfo.price_type === '달러' ? 'USD ' : 'KRW ';

    const totalPrice = Number(proDetailInfo.cnt_adult)*Number(detailInfo.price_adult) +
                       Number(proDetailInfo.cnt_child)*Number(detailInfo.price_child) +
                       Number(proDetailInfo.cnt_infant)*Number(detailInfo.price_infant);


    const [alertModal, setAlertModal] = React.useState({
        show : false,
        msg : '',
        type : '',
    })

    const [confirmModal, setConfirmModal] = React.useState({
        show:false,
        msg : '',
        type : '',
    })

    const alertModalOn = (msg:string,type:string) => {
        setAlertModal({
            show : true,
            msg : msg,
            type : type,
        })
    }
    const confirmModalOff = () => {
        setConfirmModal({
            show:false,
            msg:'',
            type:'',
        })
    }
    const alertModalOff = () => {
        setAlertModal({
            show : false,
            msg : '',
            type : '',
        })
    }
    const alertAction = () => {
        if(alertModal.type === 'step2'){
            setProStep(2);
            alertModalOff()
        }
        else if(alertModal.type === 'step3'){
            setProStep(3);
            alertModalOff()
        }
        else if(alertModal.type === 'write_fail'){
            alertModalOff();
            // setShow(false);
        }
        else if(alertModal.type === 'write_success'){
            if(initialInfo)setProDetailInfo(initialInfo);
            alertModalOff();
            setShow();
            dispatch(setReload(true));
        }
    }

    const confirmAction = () => {
        proContHandler();
    }
    const accessProCont = async () => {

        if(proDetailInfo.cnt_adult === 0 && proDetailInfo.cnt_child === 0 && proDetailInfo.cnt_infant === 0){
            alertModalOn('인원수를 입력해주세요.','step2');
        }
        else{
            setConfirmModal({
                show:true,
                msg:'계약을 하시겠습니까? 확인 시에는 취소가 안되며, 24시간 이내에 입금이 안 될 경우 자동적으로 취소되며 패널티가 부여됩니다.',
                type:'',
            })
        }
    }

    const proContHandler = async () => {
        dispatch(setLoading(true));
        const {result, msg} = await proConMutation.mutateAsync(proDetailInfo);
        if(result){
            alertModalOn(msg,'write_success');
        }
        else{
            alertModalOn(msg,'');
        }
        dispatch(setLoading(false));
    }

    return (
        <>
            <div className="modal-header">
                <h5 className="modal-title">명세서</h5>
                <button type="button" className="btn_close btn" data-dismiss="modal" aria-label="Close">
                    <img src="/assets/img/ic_colse.png" alt="닫기 아이콘" onClick={()=>{setShow();}} />
                </button>
            </div>
            <div className="modal-body">
                <div className="info_box">
                    <p className="fs_18 fw_700 mb-3 pb-3">{detailInfo.pt_title}</p>
                    <p className="info_p">
                        <span>지역</span>
                        <span className="fs_14">{detailInfo.loc_txt}{detailInfo.loc_1_txt !== '' && ' / '+detailInfo.loc_1_txt }</span>
                    </p>
                    <div className="d-flex">
                        <p className="info_p mr-5">
                            <span>출발</span>
                            <span className="fs_14">{detailInfo.pt_start_datetime}</span>
                        </p>
                        <p className="info_p">
                            <span>도착</span>
                            <span className="fs_14">{detailInfo.pt_end_datetime}</span>
                        </p>
                    </div>
                    <div className="d-flex">
                        <p className="info_p mr-5">
                            <span>담당자</span>
                            <span className="fs_14">{detailInfo.pt_mb_name}</span>
                        </p>
                        <p className="info_p">
                            <span>여행 인원수</span>
                            <span className="fs_14">성인 {detailInfo.cnt_adult}명 / 유아 {detailInfo.cnt_child}명 /소아 {detailInfo.cnt_infant}명</span>
                        </p>
                    </div>
                    <p className="info_p"><span>요청사항</span></p>
                    <div className="info_txt">
                        {detailInfo.pt_memo === '' ? '한줄요약이 등록되어있지 않습니다.' : detailInfo.pt_memo}
                    </div>
                </div>
                <p className="info_p mt-5"><span>해당업체</span></p>
                <div className="d-flex mt-3">
                    <div className="ic_dot"></div>
                    <div className="fs_14">
                        <p className="fw_600 mb-3">(주)아워랜드</p>
                        <p>제안금액 : 성인 {priceType}{NumberComma(Number(detailInfo.price_adult))} / 소아 {priceType}{NumberComma(Number(detailInfo.price_child))} / 유아 {priceType}{NumberComma(Number(detailInfo.price_infant))}</p>
                    </div>
                </div>
                <div className='mt-4'>
                    <p className="info_p"><span>비고</span></p>
                    <textarea className="form-control form-control-lg border-1" value={detailInfo.req_memo} onChange={(e)=>{setProDetailInfo({...proDetailInfo, req_memo:e.target.value})}} placeholder="남기고 싶은 말을 적어세요!(200자 이내)" rows={5} ></textarea>
                </div>
                <p className="info_p mt-3 pt-3"><span>합계</span></p>
                <div className="info_box py-4">
                    <p className="d-flex align-items-center justify-content-between fs_14 py-2">
                        <span className="d-flex justify-content-between">
                            <span className="span_cate">성인</span>
                            <span>x {proDetailInfo.cnt_adult}</span>
                        </span>
                        <span>{priceType}{NumberComma(Number(detailInfo.price_adult) * proDetailInfo.cnt_adult)}</span>
                    </p>
                    <p className="d-flex align-items-center justify-content-between fs_14 py-2">
                        <span className="d-flex justify-content-between">
                            <span className="span_cate">유아</span>
                            <span>x {proDetailInfo.cnt_child}</span>
                        </span>
                        <span>{priceType}{NumberComma(Number(detailInfo.price_child) * proDetailInfo.cnt_child)}</span>
                    </p>
                    <p className="d-flex align-items-center justify-content-between fs_14 py-2">
                        <span className="d-flex justify-content-between">
                            <span className="span_cate">소아</span>
                            <span>x {proDetailInfo.cnt_infant}</span>
                        </span>
                        <span>{priceType}{NumberComma(Number(detailInfo.price_infant) * proDetailInfo.cnt_infant)}</span>
                    </p>
                    {/* +<p className="d-flex align-items-center justify-content-between fs_14 py-2">
                        <span>할인 금액</span>
                        <span>₩0</span>
                    </p> */}
                    <div className="border-top my-4"></div>
                    <p className="d-flex align-items-center justify-content-between fs_14">
                        <span>합계</span>
                        <span className="fs_18 fw_600">{priceType}{NumberComma(totalPrice)}</span>
                    </p>
                </div>
            </div>
            <div className="modal-footer">
                <div className="d-flex w-100">
                    <button type="button" className="btn btn-lg btn-gray btn-block mb-0" onClick={()=>{setProStep(proStep-1)}}>이전</button>
                    <button type="button" className="btn btn-lg btn-primary btn-block ml-4 mt-0" onClick={()=>{accessProCont();}}>계약하기</button>
                </div>
            </div>
            <AlertModal 
                show={alertModal.show}
                modalOff={alertModalOff}
                alertAction={alertAction}
                btnLabel={'확인'}
                msg={alertModal.msg}
                type={alertModal.type}
            />
            <ConfirmModal 
                show={confirmModal.show}
                msg={confirmModal.msg}
                btnLabel={'확인'}
                modalOff={confirmModalOff}
                alertAction={confirmAction}
            />
        </>
    )
}

export default NoramalProDetail3;
