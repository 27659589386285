import React from 'react';


export default function UseDayTimer(limitTime : string,reload:Function){ // d-hh:mm:ss 카운트 반환

    const [time, setTime] = React.useState(limitTime);
    const [isTimerEnd, setIsTimerEnd] = React.useState(false);

    React.useEffect(() => {
        const interval = setInterval(() => {
        const [days, timeStr] = time.split('-');
        const [hour, minute, second] = timeStr.split(':').map(Number);
    
        let totalSeconds = (Number(days) * 24 + hour) * 3600 + minute * 60 + second - 1;
        if (totalSeconds < 0) {
            setIsTimerEnd(true);
            totalSeconds = 0;
        }
    
        const newDays = Math.floor(totalSeconds / 86400);
        const newHour = Math.floor(totalSeconds / 3600) % 24;
        const newMinute = Math.floor(totalSeconds / 60) % 60;
        const newSecond = totalSeconds % 60;
    
        const newTime = `${newDays.toString().padStart(2, '0')}-${newHour
            .toString()
            .padStart(2, '0')}:${newMinute.toString().padStart(2, '0')}:${newSecond
            .toString()
            .padStart(2, '0')}`;

        setTime(newTime);
        }, 1000);
    
        return () => clearInterval(interval);
      }, [time]);
    React.useEffect(()=>{
        if(isTimerEnd){
            reload();
        }
    },[isTimerEnd])

    return time;
}