/* store/slices/counterSlice.ts */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface CommonState {
  reload : Boolean
}

const initialState: CommonState = { 
  reload : false,
};

export const reloadSlice = createSlice({ //reload 변화감지  / true일때 리스트 reload
  name: 'reload',
  initialState,
  reducers: {
    setReload(state, action: PayloadAction<Boolean>) { //reload state update
      state.reload = action.payload;
    }
  }
});

export const { setReload } = reloadSlice.actions;

export default reloadSlice