import classNames from 'classnames';
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import { dateConverter, getDate, getTime } from '../../inc/func';
import ReqEstStep1 from './ReqEstStep1';
import ReqEstStep2 from './ReqEstStep2';
import ReqEstStep3 from './ReqEstStep3';
import ReqEstStep4 from './ReqEstStep4';
import ReqEstStep5 from './ReqEstStep5';
import ReqEstStep6 from './ReqEstStep6';
import ReqInputCheckItem, { ReqEstInfoType, countryType } from './reqType';
import { usePostQuery } from '../../inc/axiosQuery';

interface ReqEstModalType {
    show:boolean;
    setShow : (hide:boolean) => void;
}


function ReqEstIndex({ //여행사 - 견적요청하기
    show,
    setShow,
}:ReqEstModalType) { 

    const [reqStep, setReqStep] = React.useState(1);

    

    const [reqInfo , setReqInfo] = React.useState<ReqEstInfoType>({
        startDate : new Date(),
        startTime : `${getTime().nowHour}:${getTime().nowMin}`,
        start_hour : '',
        start_min : '',
        start_date : '',
        endDate : new Date(),
        endTime : `${getTime().nowHour}:${getTime().nowMin}`,
        end_hour : '',
        end_min : '',
        end_date : '',
        loc : '',
        loc_temp_name: '',
        loc_1 : '',
        loc1_temp_name: '',
        cnt_adult : 0,
        cnt_child : 0,
        cnt_infant : 0,
        req_name : '',
        req_mb_name : '',
        req_memo : '',
        limit_sec : '',
        req_file : {},
        req_file_name:'',
        
    })

    const [inputCheck , setInputCheck] = React.useState({
        date: false,
        people : false,
        deadLine : false,
    })

    const {data:conList, isLoading : conLoading, isError: conError} = usePostQuery('countryList',{},'cate/loc_list.php');
    const {data:locList, isLoading : locLoading, isError: locError} = usePostQuery('locationList',{loc:reqInfo.loc},'cate/loc_list_1.php');

    const [countryList, setCountryList] = React.useState<countryType[]>([]);
    const [locationList, setLocationList] = React.useState<countryType[]>([]);

    const clearInfo = () => {
        setReqInfo({
            startDate : new Date(),
            startTime : `${getTime().nowHour}:${getTime().nowMin}`,
            start_hour : '',
            start_min : '',
            start_date : '',
            endDate : new Date(),
            endTime : `${getTime().nowHour}:${getTime().nowMin}`,
            end_hour : '',
            end_min : '',
            end_date : '',
            loc : '',
            loc_temp_name: '',
            loc_1 : '',
            loc1_temp_name: '',
            cnt_adult : 0,
            cnt_child : 0,
            cnt_infant : 0,
            req_name : '',
            req_mb_name : '',
            req_memo : '',
            limit_sec : '',
            req_file : {},
            req_file_name:'',
        })
        setInputCheck({
            date:false,
            people:false,
            deadLine:false,
        })
    }

    React.useEffect(()=>{ //날짜선택 출발일 < 도착일 1일 이상
        const startYear = dateConverter(reqInfo.startDate).year;
        const startMonth = dateConverter(reqInfo.startDate).month;
        const startDay = dateConverter(reqInfo.startDate).day;

        const endYear = dateConverter(reqInfo.endDate).year;
        const endMonth = dateConverter(reqInfo.endDate).month;
        const endDay = dateConverter(reqInfo.endDate).day;

        const startDate : Date = new Date(`${startYear}-${startMonth}-${startDay} ${reqInfo.start_hour === '' ? '00' : reqInfo.start_hour}:${reqInfo.start_min === '' ? '0' : reqInfo.start_min}`);
        const endDate: Date = new Date(`${endYear}-${endMonth}-${endDay} ${reqInfo.end_hour === '' ? '00' : reqInfo.end_hour}:${reqInfo.end_min === '' ? '0' : reqInfo.end_min}`);

        const diffInMs = endDate.getTime() - startDate.getTime();


        const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
        if (diffInDays >= 1) {
            setInputCheck({
                ...inputCheck,
                date : true,
            })
        } else {
            setInputCheck({
                ...inputCheck,
                date : false,
            })
        }
    },[reqInfo.startDate,reqInfo.endDate,reqInfo.start_hour,reqInfo.start_min,reqInfo.end_hour,reqInfo.end_min])    

    React.useEffect(()=>{ //표시될 이름 구하기
        // console.log(reqInfo.loc);
        const countryFilter = countryList.filter(el => el.value === reqInfo.loc);
        const locationFilter = locationList.filter(el => el.value === reqInfo.loc_1);
        if(countryFilter.length > 0 ){
            console.log(countryFilter[0].label);
        }
        if(locationFilter.length > 0 ){
            console.log(locationFilter[0].label);
        }

        setReqInfo({
            ...reqInfo,
            loc_temp_name : countryFilter.length > 0 ? countryFilter[0].label : '전체',
            loc1_temp_name : locationFilter.length > 0 ? locationFilter[0].label : '전체',
        })
        
    },[reqInfo.loc,reqInfo.loc_1]);


    React.useEffect(()=>{ //국가 리스트 불러오기
        if(conList){
            let tempArray:countryType[] = [];
            
            conList.data.loc_list.forEach((item:{loc:string,location:string,},index:number) => {
                tempArray.push({
                    value : item.loc,
                    label : item.location,
                })
            })
            setCountryList(tempArray);
        }
    },[conList,conLoading,conError])

    React.useEffect(()=>{ //지역선택 소분류 리스트   
        if(locList){

            let tempList:countryType[] = [];

            locList.data.loc_list.forEach((item:{loc:string,location:string} , index : number) => {
                tempList.push({
                    value:item.loc,
                    label:item.location,
                })
            })
            setLocationList([...tempList]);
            
        }
    },[locList,locLoading,locError])

    React.useEffect(()=>{ //인원수 선택 체크
        setInputCheck({
            ...inputCheck,
            people : reqInfo.cnt_adult > 0 || reqInfo.cnt_child > 0 || reqInfo.cnt_infant > 0
        })
    },[reqInfo.cnt_adult,reqInfo.cnt_child,reqInfo.cnt_infant])

    React.useEffect(()=>{ //마감일 확인
        if(reqInfo.limit_sec !== ''){
            const [hour,min] = reqInfo.limit_sec.split(':');

            setInputCheck({
                ...inputCheck,
                deadLine : hour !== '00' || min !== '00'
            })
        }
    },[reqInfo.limit_sec])

    return (
        <Modal show={show} onHide={()=>{clearInfo(); setReqStep(1); setShow(false);}} className='modal' centered>
            {reqStep === 1 &&
                <ReqEstStep1 
                    reqInfo={reqInfo} 
                    setReqInfo={setReqInfo}
                    reqStep={reqStep}
                    setReqStep={setReqStep}
                    setShow={setShow}
                    clearInfo={clearInfo}
                    inputCheck={inputCheck}
                    setInputCheck={setInputCheck}
                />
            }
            {reqStep === 2 &&
                <ReqEstStep2 
                    reqInfo={reqInfo} 
                    setReqInfo={setReqInfo}
                    reqStep={reqStep}
                    setReqStep={setReqStep}
                    inputCheck={inputCheck}
                    setInputCheck={setInputCheck}
                />
            }
            {reqStep === 3 &&
                <ReqEstStep3 
                    reqInfo={reqInfo} 
                    setReqInfo={setReqInfo}
                    reqStep={reqStep}
                    setReqStep={setReqStep}
                    inputCheck={inputCheck}
                    setInputCheck={setInputCheck}
                    countryList={countryList}
                    locationList={locationList}
                />
            }
            {reqStep === 4 &&
                <ReqEstStep4 
                    reqInfo={reqInfo} 
                    setReqInfo={setReqInfo}
                    reqStep={reqStep}
                    setReqStep={setReqStep}
                    inputCheck={inputCheck}
                    setInputCheck={setInputCheck}
                />
            }
            {reqStep === 5 &&
                <ReqEstStep5 
                    reqInfo={reqInfo} 
                    setReqInfo={setReqInfo}
                    reqStep={reqStep}
                    setReqStep={setReqStep}
                    inputCheck={inputCheck}
                    setInputCheck={setInputCheck}
                />
            }
            {reqStep === 6 &&
                <ReqEstStep6 
                    reqInfo={reqInfo} 
                    setReqInfo={setReqInfo}
                    reqStep={reqStep}
                    setReqStep={setReqStep}
                    setShow={setShow}
                    clearInfo={clearInfo}
                    inputCheck={inputCheck}
                    setInputCheck={setInputCheck}
                />
            }
        </Modal>
    )
}

export default ReqEstIndex;
