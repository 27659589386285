import React from 'react';
import classNames from 'classnames';
import FindId from './FindId';
import FindPw from './FindPw';


function FindUserInfoIndex() {

    const [type, setType] = React.useState('id');

    return (
        <div className="container-fluid px-0">
            <div className="sub_pg">
                <section className="container-lg mx-auto">
                    <div className="lg_bx">
                        <div className="tit_wr">
                            <h3 className="tit_h3 tit_point">아이디/비밀번호 찾기</h3>
                        </div>
                        <div className="find_btnwr">
                            <a onClick={()=>{setType('id')}} className={classNames({'on':type === 'id'})}>아이디</a>
                            <a onClick={()=>{setType('pw')}} className={classNames({'on':type === 'pw'})}>비밀번호</a>
                        </div>
                        {type === 'id' ? 
                        <FindId />
                        :
                        <FindPw />
                    }
                    </div>
                </section>
            </div>
        </div>
    );
}

export default FindUserInfoIndex;
