import classNames from 'classnames';
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import { usePostMutation } from '../../inc/axiosQuery';
import { LandReqListType, TravleReqListType } from '../../inc/type/reqType';
import { useAppDispatch, useAppSelector } from '../../store/config';
import { setLoading } from '../../store/slices/LoadingSlice';
import { setReload } from '../../store/slices/ReloadSlice';
import AlertModal from '../AlertModal';

interface ModalType {
    show : boolean;
    setShow : (hide:boolean) => void;
    reqItem : TravleReqListType | LandReqListType,
    setSelTab : (index:string) => void;
}

function ReqCancleModal({ //여행사 - 견적요청 취소
    show,
    setShow,
    reqItem,
    setSelTab,
}:ModalType) {

    const dispatch = useAppDispatch();
    const {jwt_token} = useAppSelector(state => state.userInfo);
    const reqCancleMutation = usePostMutation('reqCancle','doc/request_cancel.php');

    const [alertModal, setAlertModal] = React.useState({
        show:false,
        msg:'',
        type:'',
    });

    const alertModalOff = () => {
        setAlertModal({
            show:false,
            msg:'',
            type:'',
        })
    }

    const alertAction = () => {
        if(alertModal.type === 'success'){
            setShow(false);
            dispatch(setReload(true));
            setSelTab('');
        }
        else{

        }
    }

    const cancleReqAccess = async () => {
        dispatch(setLoading(true));
        const {result,msg} = await reqCancleMutation.mutateAsync({req_idx : reqItem.req_idx,jwt_token:jwt_token});

        if(result){
            setAlertModal({
                show:true,
                msg:msg,
                type:'success'
            })
        }
        else{
            setAlertModal({
                show:true,
                msg:msg,
                type:'',
            })
        }
        dispatch(setLoading(false));
    }


    return (
        <Modal show={show} onHide={()=>{setShow(false);}} className='modal' centered>
            <div className="modal-header">
                <h5 className="modal-title">취소하기</h5>
                <button type="button" className="btn_close btn" onClick={()=>{setShow(false)}}>
                    <img src="/assets/img/ic_colse.png" alt="닫기 아이콘" />
                </button>
            </div>
            <div className="modal-body">
                <div className="info_box">
                    <p className="fs_18 fw_700 mb-3 pb-3">{reqItem.req_name}</p>
                    <p className="info_p">
                        <span>지역</span>
                        <span className="fs_14">{reqItem.loc_txt}{reqItem.loc_1_txt !== '' && ' / '+reqItem.loc_1_txt}</span>
                    </p>
                    <div className="d-flex">
                        <p className="info_p mr-5">
                            <span>출발</span>
                            <span className="fs_14">{reqItem.start_date_txt}</span>
                        </p>
                        <p className="info_p">
                            <span>도착</span>
                            <span className="fs_14">{reqItem.end_date_txt}</span>
                        </p>
                    </div>
                    <div className="d-flex">
                        <p className="info_p mr-5">
                            <span>담당자</span>
                            <span className="fs_14">{reqItem.req_mb_name}</span>
                        </p>
                        <p className="info_p">
                            <span>여행 인원수</span>
                            <span className="fs_14">성인 {reqItem.cnt_adult} / 유아 {reqItem.cnt_child} /소아 {reqItem.cnt_infant}</span>
                        </p>
                    </div>
                    <p className="info_p"><span>요청사항</span></p>
                    <div className="info_txt">
                        {reqItem.req_memo === '' ? '요청사항이 등록되어있지 않습니다.' : reqItem.req_memo }
                    </div>
                </div>
                <p className="mt-3 pt-3 line_h1_6 fc_red fs_14">취소하게 되면 견적서 내용을 불러올 수 없습니다. <br />그래도 취소하겠습니까?
                </p>
            </div>
            <div className="modal-footer">
                <div className="d-flex w-100">
                    <button type="button" className="btn btn-lg btn-red btn-block mr-4 mb-0" onClick={()=>{cancleReqAccess();}}>예</button>
                    <button type="button" className="btn btn-lg btn-gray btn-block mt-0" onClick={()=>{setShow(false)}}>아니오</button>
                </div>
            </div>
            <AlertModal 
                show={alertModal.show}
                msg={alertModal.msg}
                type={alertModal.type}
                modalOff={alertModalOff}
                alertAction={alertAction}
                btnLabel={'확인'} 
            />
        </Modal>
    )
}

export default ReqCancleModal;
