/* store/slices/counterSlice.ts */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface CommonState {
  isLoading : Boolean
}

const initialState: CommonState = {
    isLoading : false,
};

export const loadingSlice = createSlice({ //loading state
  name: 'loading',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<Boolean>) {
      state.isLoading = action.payload;
    }
  }
});

export const { setLoading } = loadingSlice.actions;

export default loadingSlice