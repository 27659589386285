import React from 'react';
import Calendar from 'react-calendar';
import { DateFormatter, DayPicker } from 'react-day-picker';
import { ko } from 'date-fns/locale';
import ReqEstType from './reqType';
import moment from 'moment';
import '../../assets/style/CustomCalender.css';
import CustomCaption from '../../components/form/DayPicker';
import { dateConverter } from '../../inc/func';
import { MarginY } from '../../inc/FlexStyle';
import CustomSelectBox from '../../components/form/CustomSelectBox';

function ReqEstStep2({reqInfo, setReqInfo,reqStep,setReqStep,inputCheck} : ReqEstType) {


    const [date, setDate] = React.useState(reqInfo.endDate);
    const [hour, setHour] = React.useState('00');
    const [min , setMin] = React.useState('00');

    let hourList = [];
    let minList = []
    for(let i=0; i<=24; i++){
        const tempHour = i<10 ? '0'+i : `${i}`;
        hourList.push({
            label:tempHour,
            value:tempHour,
        })
    }
    for(let j=0; j<=59; j++){
        const tempMin = j<10 ? '0'+j : `${j}`;
        minList.push({
            label:tempMin,
            value:tempMin,
        })
    }
    React.useEffect(()=>{
        setDate(reqInfo.endDate);
        if(reqInfo.end_hour !== ''){
            setHour(reqInfo.end_hour);
        }
        if(reqInfo.end_min !== ''){
            setMin(reqInfo.end_min);
        }
    },[])

    React.useEffect(()=>{ //st
        const endYear = dateConverter(reqInfo.endDate).year;
        const endMonth = dateConverter(reqInfo.endDate).month;
        const endDay = dateConverter(reqInfo.endDate).day;
        setReqInfo({
            ...reqInfo,
            endDate : date,
            end_hour : hour,
            end_min : min,
            end_date : `${endYear}-${endMonth}-${endDay} ${hour}:${min}`,
        })

    },[hour, min,date])
    
    return (
        <div>
            <div className="modal-header justify-content-center">
                <h5 className="modal-title">도착 일자를 선택해주세요!</h5>
            </div>
            <div className="modal-body">
                <ul className="modal_tab">
                    <li className="on"><a onClick={()=>{setReqStep(1)}}>날짜 선택<span className="fc_red"> *</span></a></li>
                    <li><a onClick={()=>{if(inputCheck?.date)setReqStep(3)}}>지역 선택</a></li>
                    <li><a onClick={()=>{if(inputCheck?.date)setReqStep(4)}}>인원수 선택<span className="fc_red"> *</span></a></li>
                    <li><a onClick={()=>{if(inputCheck?.date && inputCheck.people)setReqStep(5)}}>마감일 선택<span className="fc_red"> *</span></a></li>
                    <li><a onClick={()=>{if(inputCheck?.date && inputCheck.deadLine && inputCheck.people)setReqStep(6)}}>완료</a></li>
                </ul>
                <div style={{width:'100%'}}>
                    <p className='mb-4'><span className="fc_red">* </span>도착일은 출발일로부터 최소 24시간 뒤로 선택해주세요.</p>
                    <DayPicker 
                        mode="single"
                        defaultMonth={reqInfo.endDate}
                        selected={reqInfo.endDate}
                        fromDate={reqInfo.startDate}
                        onSelect={(date) => {
                            if(date){
                                setDate(date);
                            }
                        }}
                        locale={ko}
                        components={{
                            Caption: CustomCaption
                        }}
                    />
                </div>
                <MarginY mb={2} />
                <div className="form-row w-100">
                    <CustomSelectBox 
                        title={'시'}
                        optionList={hourList}
                        selValue={hour}
                        setSelValue={setHour}
                        style={{flex:1}}
                    />
                    <CustomSelectBox 
                        title={'분'}
                        optionList={minList}
                        selValue={min}
                        setSelValue={setMin}
                        style={{flex:1,marginLeft:10}}
                    />
                </div>
            </div>
            <div className="modal-footer">
                <div className="d-flex w-100">
                    <button type="button" className="btn btn-lg btn-gray btn-block mb-0" onClick={()=>{setReqStep(1)}}>이전</button>
                    {inputCheck?.date ?
                        <button type="button" className="btn btn-lg btn-primary btn-block ml-4 mt-0" onClick={()=>{setReqStep(3)}}>다음</button>
                    :
                        <button type="button" className="btn btn-lg btn-gray btn-block ml-4 mt-0 " onClick={()=>{}}>다음</button>  
                    }
                </div>
            </div>
        </div>
    )
}

export default ReqEstStep2;
