import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { combineReducers , configureStore, getDefaultMiddleware} from "@reduxjs/toolkit";
import reloadSlice from './slices/ReloadSlice';
import UserInfoSlice, { selectAutoLogin } from './slices/UserInfoSlice';
import loadingSlice from './slices/LoadingSlice';
import joinTypeSlice from './slices/JoinTypeSlice';

const rootReducer = combineReducers({ //reducer 묶기
    reload : reloadSlice.reducer, //리스트 데이터 reload state
    userInfo : UserInfoSlice.reducer, //유저 정보 state
    isLoading : loadingSlice.reducer, //로딩 state
    joinType : joinTypeSlice.reducer,
})

const persistedStateString = localStorage.getItem('autoLogin'); //자동로그인
const sessionStateString = sessionStorage.getItem('sessionLogin');


//자동로그인 체크를 하면 local 로그인정보 & 자동로그인 체크 안할경우 session
const persistedState = persistedStateString !== null && persistedStateString !== '{}'
  ? JSON.parse(persistedStateString)
  : sessionStateString !== null && sessionStateString !== ''
    ? JSON.parse(sessionStateString)
    : {};

export const store = configureStore({ //store 설정
    reducer : rootReducer, //rootReducer 설정 combineReducers로 병합한 slice reducer들을 병합한 rootReducer로 설정
    preloadedState : persistedState,// 리덕스 스토어 초기값
    middleware: getDefaultMiddleware({ serializableCheck: false, immutableCheck: false }),
    enhancers:(defaultEnhancers) => [...defaultEnhancers] //사용자 정의 미들웨어 설정   
})

store.subscribe(()=>{ //자동로그인시 로그인 정보 state local 저장
    const isAutoLogin = selectAutoLogin(store.getState());

    const state = store.getState();

    sessionStorage.setItem('sessionLogin' , JSON.stringify(state));
    
    if(isAutoLogin){
        localStorage.setItem('autoLogin', JSON.stringify(state));
    }
    else{
        localStorage.setItem('autoLogin',JSON.stringify({}));
    }
})

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppSelector : TypedUseSelectorHook<RootState> = useSelector; //useSelector 커스텀
export const useAppDispatch = () => useDispatch<AppDispatch>(); //useDispatch 커스텀

export default store;