import React from 'react';
import classNames from 'classnames';
import CustomInput from '../../../components/form/CustomInput';
import { MarginY } from '../../../inc/FlexStyle';
import CustomInputBtn from '../../../components/form/CustomBtnInput';
import CustomButton from '../../../components/form/CustomButton';
import { usePostMutation, usePostQuery } from '../../../inc/axiosQuery';
import { emailCheck, idCheck, phoneCheck, pwCheck, pwCheckRe } from '../../../inc/func';
import AlertModal from '../../../modal/AlertModal';
import { useNavigate } from 'react-router';
import AgreeModal from '../../../modal/AgreeModal';
import { useAppDispatch, useAppSelector } from '../../../store/config';
import ChangePwdModal from '../../../modal/user/ChangePwdModal';
import ChangePhoneModal from '../../../modal/user/ChangePhoneModal';
import { setLoading } from '../../../store/slices/LoadingSlice';

function NormalEditMyInfo() {

    const dispatch = useAppDispatch();
    const userInfo = useAppSelector(state => state.userInfo);

    const sendEmailAuthMutation = usePostMutation('sendEmailAuth','user/email_auth_send.php'); //이메일 인증 전송
    const emailAuthMutation = usePostMutation('emailAuth','user/email_auth_chk.php'); //이메일 인증
    const editNormalInfoMutation = usePostMutation('editNormalInfo','user/user_edit_1.php');

    const navigator = useNavigate();
    const [joinInfo , setJoinInfo] = React.useState({
        jwt_token : userInfo.jwt_token,
        mb_id : userInfo.mb_id,
        mb_email : userInfo.mb_email,
        mb_email_auth : '',
        mb_pw : '',
        mb_sex : userInfo.mb_sex,
        mb_birth : userInfo.mb_birth,

    })

    const [inputCheck, setInputCheck] = React.useState({
        mb_id : true,
        mb_birth : true,
        mb_email : true,
        mb_email_auth : true,
        agree1 : false,
        agree2 : false,
        mb_id_msg : '',
        birth_msg : '',
        mb_email_msg : '',
        mb_email_auth_msg : '',
    })
    const [isSendEmailAuth, setIsSendEmailAuth] = React.useState(false);

    const [alertModal, setAlertModal] = React.useState({
        show:false,
        msg:'',
        type:'',
    })

    const [chgPwdModal, setChgPwdModal] = React.useState(false);
    const [chgPhoneModal, setChgPhoneModal] = React.useState(false);

    const alertModalOn = (msg:string) => {
        setAlertModal({
            show:true,
            msg:msg,
            type:'',
        })
    }
    const modalOff = () => {
        setAlertModal({
            show : false,
            msg : '',
            type : '',
        })
    }

    const alertAction = () => {
        if(alertModal.type === 'success'){
            navigator('/');
        }
    }

    const inputController = (text:string , type?:string) => {
        if(type){
            setJoinInfo({
                ...joinInfo,
                [type] : text,
            })
        }
    }
    const genderController = (value:string) => {
        setJoinInfo({
            ...joinInfo,
            mb_sex : value,
        })
    }

    const sendEmailAuth = async () => {
        if(!inputCheck.mb_email){
            alertModalOn(inputCheck.mb_email_msg);
        }
        else{
            dispatch(setLoading(true));
            const {result,msg} = await sendEmailAuthMutation.mutateAsync({mb_email:joinInfo.mb_email});

            if(result){
                setAlertModal({
                    show : true,
                    msg : `인증번호가 \n 이메일로 발송되었습니다.`,
                    type : 'send-email',
                })
                setIsSendEmailAuth(true);
            }
            else{
                setInputCheck({
                    ...inputCheck,
                    mb_email:false,
                    mb_email_msg:msg,
                })
                setAlertModal({
                    show : true,
                    msg : msg,
                    type : '',
                })
            }
            dispatch(setLoading(true));
        }
    }

    const authEmailHandler = async () => {
        if(joinInfo.mb_email_auth === ''){
            setInputCheck({
                ...inputCheck,
                mb_email_auth : false,
                mb_email_msg : '인증번호를 입력해주세요.',
            });
        }
        else{
            const authParams = {
                mb_email : joinInfo.mb_email,
                auth_no : joinInfo.mb_email_auth
            }
            const {result,msg} = await emailAuthMutation.mutateAsync(authParams);

            if(result){
                setIsSendEmailAuth(false);
                setInputCheck({
                    ...inputCheck,
                    mb_email_auth:true,
                    mb_email_msg:msg,      
                })
            }
            else{
                setInputCheck({
                    ...inputCheck,
                    mb_email_auth : false,
                    mb_email_auth_msg: msg,
                })
                setAlertModal({
                    show:true,
                    msg:msg,
                    type:'',
                })
            }
        }
    }


    const handleNormalJoin = async () => {

        if(joinInfo.mb_id === ''){
            alertModalOn(inputCheck.mb_id_msg === '' ? '아이디를 입력해주세요.' : inputCheck.mb_id_msg);
            return;
        }
        else if(!inputCheck.mb_id){
            alertModalOn(inputCheck.mb_id_msg);
        }
        else if(joinInfo.mb_email === ''){
            alertModalOn('변경하실 이메일을 입력해주세요.');
            return;
        }
        else if(!inputCheck.mb_email_auth){
            alertModalOn('이메일 인증을 진행해주세요.');
        }
        else if(joinInfo.mb_birth === ''){
            alertModalOn('생년월일을 입력해주세요.');
            return;
        }
        else{

            dispatch(setLoading(true));

            const {result, msg} = await editNormalInfoMutation.mutateAsync(joinInfo);

            if(result){
                setAlertModal({
                    show:true,
                    msg:msg,
                    type:'success',
                })
            }
            else{
                setAlertModal({
                    show:true,
                    msg:msg,
                    type:'',
                })
            }
            
            dispatch(setLoading(false));
        }
        
        // const {result,msg,data} = await normalJoinMutation.mutateAsync(joinInfo);

        // if(!result){
        //     setAlertModal({
        //         show:true,
        //         msg : msg,
        //         type : '',
        //     })
        // }
        // else{
        //     setAlertModal({
        //         show:true,
        //         msg:msg,
        //         type : 'success',
        //     })
        // }
    }
    

    React.useEffect(()=>{
        setInputCheck({
            ...inputCheck,
            mb_id : idCheck(joinInfo.mb_id).result,
            mb_id_msg : idCheck(joinInfo.mb_id).msg,
        })
    },[joinInfo.mb_id])

    React.useEffect(()=>{
        if(joinInfo.mb_email === userInfo.mb_email){
            setInputCheck({
                ...inputCheck,
                mb_email:true,
                mb_email_auth : true,
                mb_email_auth_msg : '',
            })
        }
        else{
            setInputCheck({
                ...inputCheck,
                mb_email:emailCheck(joinInfo.mb_email).result,
                mb_email_msg : emailCheck(joinInfo.mb_email).msg,
                mb_email_auth : false,
                mb_email_auth_msg : '',
            })
        }
    },[joinInfo.mb_email])

    return (
        <div className="container-fluid px-0">
            <AlertModal 
                show={alertModal.show}
                modalOff={modalOff}
                btnLabel={'확인'}
                msg={alertModal.msg}
                type={alertModal.type}
                alertAction={alertAction}
            />
            <div className="sub_pg">
                <section className="container-lg mx-auto">
                    <div className="lg_bx">
                        <div className="tit_wr">
                            <h3 className="tit_h3 tit_point gmarket">로그인 정보</h3>
                        </div>
                        <form>
                            {userInfo.mb_reg_type === 'E' &&
                                <>
                                    <CustomInput 
                                        title={'아이디'}
                                        placeHolder={'아이디를 입력해주세요.'}
                                        input={joinInfo.mb_id}
                                        setInput={inputController}
                                        isValidShow={userInfo.mb_id !== joinInfo.mb_id}
                                        isValid={inputCheck.mb_id}
                                        validText={inputCheck.mb_id_msg}
                                        keyName={'mb_id'}
                                    />
                                    {/* <CustomInput 
                                        title='비밀번호'
                                        input={joinInfo.mb_pw}
                                        isReadOnly={true}
                                        setInput={()=>{}}
                                        placeHolder={''}
                                        isValidShow={false}
                                    /> */}
                                    <MarginY mt={1} />
                                    <CustomButton 
                                        btnLabel='비밀번호 변경하기'
                                        color='green'
                                        type='lg'
                                        clickEvent={()=>{setChgPwdModal(true)}}
                                    />
                                </>
                            }

                            <MarginY mt={2} />
                            <CustomInputBtn 
                                title='이메일'
                                input={joinInfo.mb_email}
                                setInput={inputController}
                                placeHolder={'이메일을 입력해주세요.'}
                                isValidShow={joinInfo.mb_email !== '' && userInfo.mb_email !== joinInfo.mb_email}
                                isValid={inputCheck.mb_email}
                                validText={inputCheck.mb_email_msg}
                                btnLabel={'인증하기'}
                                inputStyle={userInfo.mb_email !== joinInfo.mb_email ? 'col-9 pr-0' : 'w-100'}
                                btnStyle={'col-3 pl-0'}
                                keyName={'mb_email'}
                                isBtnHide={userInfo.mb_email === joinInfo.mb_email}
                                btnAction={sendEmailAuth}
                            />
                            {isSendEmailAuth &&
                                <>
                                    <MarginY mt={1} />
                                    <CustomInputBtn 
                                        title={'인증번호'}
                                        placeHolder={'입력해주세요.'}
                                        input={joinInfo.mb_email_auth}
                                        setInput={inputController}
                                        isValidShow={joinInfo.mb_email_auth !== '' && inputCheck.mb_email_auth_msg !== ''}
                                        isValid={inputCheck.mb_email_auth}
                                        validText={inputCheck.mb_email_auth_msg}
                                        btnLabel={'확인'}
                                        inputStyle={'col-9 pr-0'}
                                        btnStyle={'col-3 pl-0'}
                                        btnLabelStyle={'fs_16'}
                                        keyName={'mb_email_auth'}
                                        btnAction={authEmailHandler}
                                    />
                                </>
                            }
                            <MarginY mt={1} />
                            <div className="ip_wr">
                                <div className="ip_tit">
                                    <h5>성별</h5>
                                </div>
                                <div className="input-group-prepend">
                                    {userInfo.mb_sex === '남' ? 
                                        <div className="checks">
                                            <input 
                                                id="male"
                                                name="mb_sex" 
                                                checked={joinInfo.mb_sex === '남'} 
                                                type="radio" 
                                                aria-label="male" 
                                                value={'남'}
                                                onChange={(e)=>{genderController(e.target.value)}} 
                                                disabled
                                            />
                                            <label htmlFor="male">남자</label>
                                        </div>
                                        :
                                        <div className="checks">
                                            <input 
                                                id="female" 
                                                name="mb_sex" 
                                                checked={joinInfo.mb_sex === '여'} 
                                                type="radio" 
                                                aria-label="female" 
                                                value={'여'}
                                                onChange={(e)=>{genderController(e.target.value)}} 
                                                disabled
                                            />
                                            <label htmlFor="female">여자</label>
                                        </div>
                                    }
                                </div>
                            </div>
                            <MarginY mt={3} />
                            <CustomInput 
                                title={'생년월일'}
                                placeHolder={'ex) 19970804'}
                                input={joinInfo.mb_birth}
                                setInput={inputController}
                                isValidShow={false}
                                isValid={inputCheck.mb_birth}
                                validText={inputCheck.birth_msg}
                                keyName={'mb_birth'}
                            />
                            <CustomInput 
                                title={'핸드폰 번호'}
                                placeHolder={'ex) 19970804'}
                                input={userInfo.mb_phone}
                                setInput={()=>{}}
                                isValidShow={false}
                                isReadOnly={true}
                            />
                            {/* <CustomInputBtn 
                                title={'핸드폰번호'}
                                placeHolder={'번호를 입력해주세요'}
                                input={joinInfo.mb_phone}
                                setInput={inputController}
                                isValidShow={joinInfo.mb_phone !== ''}
                                isValid={inputCheck.mb_phone}
                                validText={inputCheck.mb_phone_msg}
                                keyName={'mb_phone'}
                                btnLabel={'인증번호 받기'}
                                inputStyle={'col-9 pr-0'}
                                btnStyle={'col-3 pl-0'}
                                btnLabelStyle={'fs_16'}
                                btnAction={sendPhoneCodeHandler}
                                isReadOnly={inputCheck.mb_phone && inputCheck.authCode}
                            /> */}
                            <MarginY mt={2} />
                            <CustomButton 
                                btnLabel='핸드폰번호 변경하기'
                                color='green'
                                type='lg'
                                clickEvent={()=>{setChgPhoneModal(true)}}
                            />
                            <MarginY mt={5} />
                            <CustomButton 
                                btnLabel='수정하기'
                                color='green'
                                type='lg'
                                labelStyle='fw_700'
                                clickEvent={()=>{handleNormalJoin()}}
                            />
                        </form>
                    </div>
                    <ChangePwdModal 
                        show={chgPwdModal}
                        setShow={setChgPwdModal}
                        mb_id={userInfo.mb_id}
                        type={'edit'}
                    />
                    <ChangePhoneModal 
                        show={chgPhoneModal}
                        setShow={setChgPhoneModal}
                    />
                </section>
            </div>
        </div>
    );
}

export default NormalEditMyInfo;
