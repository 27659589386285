import React, { useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Link, useNavigate } from 'react-router-dom';
import CustomInput from '../../components/form/CustomInput';
import {useMutation} from 'react-query';
import { usePostMutation } from '../../inc/axiosQuery';
import { MarginY } from '../../inc/FlexStyle';
import { useAppDispatch, useAppSelector } from '../../store/config';
import { setAutoLogin, setUserInfo } from '../../store/slices/UserInfoSlice';
import { pwCheck, pwCheckRe } from '../../inc/func';
import AlertModal from '../AlertModal';
import { setLoading } from '../../store/slices/LoadingSlice';

interface ChgPwdModalType {
    show : boolean;
    setShow : () => void;
    findId : string;
}

function FindIdResultModal({
    show,
    setShow,
    findId,
}:ChgPwdModalType) {

    return (
        <Modal show={show} onHide={()=>{setShow()}} className='modal' centered>
            <div className="modal-header">
                <h5 className="modal-title">아이디 찾기</h5>
                <button type="button" className="btn_close btn" onClick={()=>{setShow()}}>
                    <img src="/assets/img/ic_colse.png" alt="닫기 아이콘" />
                </button>
            </div>
            <form className="modal-body">
                <div className="info_box text-center fs_16">
                    <p>조회된 계정정보는</p>
                    <p className='my-4 fs_18 fw_700'>{findId}</p>
                    <p>입니다.</p>
                </div>
            </form>
            <div className="modal-footer mt-5">
                <div className="d-flex w-100">
                    <button type="button" className="btn btn-lg btn-primary btn-block mt-0" onClick={setShow}>확인</button>
                </div>
            </div>
        </Modal>
    )
}

export default FindIdResultModal;
