import React from 'react';
import { useNavigate } from "react-router";
import Loading from "../../../components/form/Loading";
import QueryString from "qs";
import axios from "axios";
import { baseUrl, usePostMutation } from '../../../inc/axiosQuery';
import AlertModal from '../../../modal/AlertModal';
import { useAppDispatch } from '../../../store/config';
import { setAutoLogin, setUserInfo } from '../../../store/slices/UserInfoSlice';

const REDIRECT_URI =
  "https://tobyfrom.com/naverAuth?type=naver";

export const NAVER_AUTH_URL = 
  `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${process.env.REACT_APP_NAVER_CLIENT_KEY}&state=${Math.random().toString(36).substr(3, 14)}&redirect_uri=${REDIRECT_URI}`;

export const NaverLoginAuth = () => {

    const dispatch = useAppDispatch();
    const navigator = useNavigate();
    const code = new URL(window.location.href).searchParams.get("code");
    const state = new URL(window.location.href).searchParams.get('state');

    const initialAlertModal = {
      alert:false,
      msg :'',
      type : '',
    }
    const [alertModal, setAlertModal] = React.useState(()=>initialAlertModal);

    const alertModalOn = (msg:string) => {
      setAlertModal({
        alert:true,
        msg:msg,
        type:'',
      })
    }
    const alertModalOff = () => {
      setAlertModal(()=>initialAlertModal);
    }

    const getNaverInfoMutation = usePostMutation('getNaverInfo' , 'user/sns_naver_info.php');
    const naverLoginMutation = usePostMutation('accessSnsLogin','user/sns_login.php');

    let api_url = baseUrl+'/user/sns_naver_get_token.php?code='+code+'&state='+state;

    let access_token;
    let refresh_token;
    let email;
    let domain = 'naver';

    
    React.useEffect(()=>{
      axios({
        method: 'get',
        url: api_url,
        // headers: {
            
        //   'X-Naver-Client-Id': process.env.REACT_APP_NAVER_CLIENT_KEY,
        //   'X-Naver-Client-Secret': process.env.REACT_APP_NAVER_CLIENT_SCRET_KEY
        // }
      })
      .then(async res => {
        if(res.status === 200 && res.statusText === 'OK'){
            const tokenData = res.data.data;


            const {result : infoResult ,msg : infoMsg, data : infoData} = await getNaverInfoMutation.mutateAsync({
              access_token:tokenData.token
            })
            

            const {result, msg, data} = await naverLoginMutation.mutateAsync({
              mb_id : infoData.mb_id,
              mb_reg_type : 'N'
            })

            if(result){
              await dispatch(setAutoLogin(true));
              await dispatch(setUserInfo(data));
            }
            else{
              navigator(`/normalJoin?type=naver&token=${tokenData.token}`,{replace:true});
            }

            // console.log({
            //   mb_id : infoData.mb_id,
            //   mb_reg_type : 'N'
            // })
            // console.log(result, msg, data);

        }
        else{
          alertModalOn(`네이버 로그인 서비스 통신이 불안정합니다.`);
        }
        // console.log(ans);
      })
      .catch(err => {
        alertModalOn(`네이버 로그인에 실패하였습니다. ${err}`);
      })
    },[])

//   const navigator = useNavigate();

//   const getToken = async () => { //토큰 가져오기 및 설정
//     const payload = QueryString.stringify({
//       grant_type: "authorization_code",
//       client_id: process.env.REACT_APP_KAKAO_REST_API_KEY,
//       redirect_uri: REDIRECT_URI,
//       code: code,
//       client_secret: process.env.REACT_APP_KAKAO_CLIENT_SCRET_KEY,
//     });
//     try {
//       // access token 가져오기
//       const res = await axios.post(
//         "https://kauth.kakao.com/oauth/token",
//         payload
//       );
      
//       // Kakao Javascript SDK 초기화
//       // window.Kakao.init(REST_API_KEY);
//       // access token 설정
//       window.Kakao.Auth.setAccessToken(res.data.access_token);
//       navigator('/normalJoin?type=naver',{replace:true});
//     } catch (err) {
//       console.log(err);
//     }
//   };
//   React.useEffect(() => {
//     getToken();
//   }, []);
  return (
    <AlertModal 
      show={alertModal.alert}
      modalOff={alertModalOff}
      alertAction={()=>{navigator('/nomalJoinSel',{replace:true})}}
      btnLabel='확인'
      msg={alertModal.msg}
      type={alertModal.type}
    />
  );
  
  return (
    <>
      <Loading />
    </>
  )
}