import classNames from 'classnames';
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import { getDate, getTime } from '../../inc/func';
import { ProductItemType } from '../../inc/type/ProductType';
import { useAppSelector } from '../../store/config';
import { RecInfoType } from '../recommandProduct/recType';
import { DetailInfoType } from './DetailType';
import NoramalProDetail1 from './NoramalProDetail1';
import NoramalProDetail2 from './NoramalProDetail2';
import NoramalProDetail3 from './NoramalProDetail3';


interface ProDetailModalType {
    show:boolean;
    selIdx:string;
    setShow : () => void;
    detailInfo : ProductItemType
}

function NormalProductDetailIndex({ //여행사 & 개인 기획상품 신청
    show,
    setShow,
    selIdx,
    detailInfo,
}:ProDetailModalType) {

    const {jwt_token} = useAppSelector(state => state.userInfo);
    const [proStep, setProStep] = React.useState(1);

    const initialInfo = {
        cnt_adult : 0,
        cnt_child : 0,
        cnt_infant : 0 ,
        price_adult : 0,
        price_child : 0,
        price_infant : 0,
        pt_idx:detailInfo.pt_idx,
        req_memo:'',
        jwt_token:jwt_token,
    }

    const [proDetailInfo, setProDetailInfo] = React.useState<DetailInfoType>(()=>initialInfo)

    React.useEffect(()=>{
        if(show && detailInfo){
        }
    },[show])


    return (
        <Modal show={show} onHide={()=>{setProDetailInfo(initialInfo); setShow();}} className='modal' centered>
            {proStep === 1 &&
                <NoramalProDetail1 
                    proStep={proStep}
                    setProStep={setProStep}
                    proDetailInfo={proDetailInfo}
                    setProDetailInfo={setProDetailInfo}
                    setShow={setShow}
                    detailInfo={detailInfo}
                    initialInfo={initialInfo}
                />
            }
            {proStep === 2 &&
               <NoramalProDetail2 
                    proStep={proStep}
                    setProStep={setProStep}
                    proDetailInfo={proDetailInfo}
                    setProDetailInfo={setProDetailInfo}
                    setShow={setShow}
                    detailInfo={detailInfo}
                /> 
            }
            {proStep === 3 &&
                <NoramalProDetail3 
                    proStep={proStep}
                    setProStep={setProStep}
                    proDetailInfo={proDetailInfo}
                    setProDetailInfo={setProDetailInfo}
                    setShow={setShow}
                    detailInfo={detailInfo}
                    initialInfo={initialInfo}
                />
            }
        </Modal>
    )
}

export default NormalProductDetailIndex;
