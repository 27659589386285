import React from 'react';
import './assets/style/boot_custom.css';
import './assets/style/common.css';
import './assets/style/custom.css';
import './assets/style/design_yh.css';
import './assets/style/design.css';
import './assets/style/modal.css';

//swiper
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

//calender
import 'react-calendar/dist/Calendar.css';

//dat picker
import 'react-day-picker/dist/style.css';

import { useAppDispatch, useAppSelector } from './store/config';

import Header from './inc/header/Header';
import { Route, Routes, useLocation, useNavigate } from 'react-router';
import { Navigate } from "react-router-dom";
import Main from './page/Main';
import FindUserInfoIndex from './page/user/find/FindUserInfoIndex';
import SelectJoinType from './page/user/SelectJoinType';
import NomalJoinSelect from './page/user/nomalJoin/NomalJoinSelect';
import NormalJoin from './page/user/nomalJoin/NormalJoin';
import Footer from './inc/footer/Footer';
import TravelJoin from './page/user/travelJoin/TravelJoin';
import LandSelJoinType from './page/user/LandSelJoinType';
import LandJoin from './page/user/landJoin/LandJoin';
import GuideJoin from './page/user/guideJoin/GuideJoin';
import TravleMain from './page/travle/TravleMain';
import LandMain from './page/land/LandMain';
import NormalEditMyInfo from './page/user/nomalJoin/NormalEditMyInfo';
import ServCenterIndex from './page/serviceCenter/ServCenterIndex';
import NoticeDetail from './page/serviceCenter/NoticeDetail';
import LoadingModal from './modal/LoadingModal';
import TravleEditMyInfo from './page/user/travelJoin/TravleEditMyInfo';
import LandEditMyInfo from './page/user/landJoin/LandEditMyInfo';
import GuideEditMyInfo from './page/user/guideJoin/GuideEditMyInfo';
import MyReview from './page/MyReview';
import AlertModal from './modal/AlertModal';
import { KakaoLoginAuth } from './page/user/nomalJoin/KakaoAuth';
import { NaverLoginAuth } from './page/user/nomalJoin/NaverAuth';
import { usePostMutation, usePostQuery } from './inc/axiosQuery';
import { setAutoLogin, setLogOut } from './store/slices/UserInfoSlice';

function App() {

  const dispatch = useAppDispatch();
  const {mb_id,mb_type,mb_type_1,jwt_token} = useAppSelector(state => state.userInfo);
  const {isLoading} = useAppSelector(state => state.isLoading);
  const memCheckMutation = usePostMutation('checkMember','user/auto_login_check.php');
  // const {data : memCheckData, isLoading : memCheckLoading, isError : memCheckError} = usePostQuery('checkMember',{
  //   jwt_token : jwt_token,
  // },'user/auto_login_check.php');
  const navigate = useNavigate();

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const tabs = queryParams.get('seltab');

  const initialAlert = {
    alert : false,
    msg : '',
    type : '',
  }

  const [alertModal, setAlertModal] = React.useState(()=>initialAlert);

  const alertModalOn = (msg:string, type:string) => {
    setAlertModal({
      alert:true,
      msg:msg,
      type:type,
    })
  }

  const alertModalOff = () => {
    setAlertModal(initialAlert);
  }

  const alertAction = () => {
    if(alertModal.type === 'none_login'){
      navigate('/',{replace:true})
    }
    else if(alertModal.type === 'go_travle'){
      navigate('/travleMain',{replace:true})
    }
    else if(alertModal.type === 'go_land'){
      navigate('/landMain',{replace:true});
    }
    else if(alertModal.type === 'end_token'){
      navigate('/',{replace:true});
      dispatch(setAutoLogin(false));
      dispatch(setLogOut(''));
    }
  }

  const memberCheckHandler = async () => {
      const {result} = await memCheckMutation.mutateAsync({jwt_token:jwt_token});

      if(result === 'false'){
        alertModalOn('토큰정보가 만료되어 메인페이지로 이동합니다.','end_token');
      }
  }


  React.useEffect(()=>{
    if(mb_id === ''){
      if(location.pathname === '/landMain' || location.pathname === '/travleMain'){
        alertModalOn('로그인 후 이용 가능한 서비스입니다.','none_login');
        
        return;
      }
      // navigate('/',{replace:true})
    }
    else if(mb_id !== '' && mb_type === '1'){
      if(location.pathname === '/landMain'){
        alertModalOn('랜드사 및 가이드 회원만 이용가능한 서비스입니다.','go_travle');
        return;
      }
      else{
        if(!tabs){
          navigate('/travleMain',{replace:true});
        }
        else{
          navigate(`/travleMain?seltab=${tabs}`,{replace:true});
        }
      }
    }
    else{
      if(location.pathname === '/travleMain'){
        alertModalOn('일반회원 및 여행사 회원만 이용가능한 서비스입니다.','go_land');
      
        return;
      }
      else{
        if(!tabs){
          navigate(`/landMain`,{replace:true})  
        }
        else{
          navigate(`/landMain?seltab=${tabs}`,{replace:true})
        }
      }
    }
  },[mb_type])

  React.useEffect(()=>{
    // if(jwt_token !== ''){
      memberCheckHandler();
    // }
  },[location.pathname])

  React.useEffect(()=>{
    window.Kakao.init('3b84c99a6d5aa01bf1ed8df63233b61f');
  },[])

  if (process.env.NODE_ENV === "production") {
    console.log = function no_console() {};
    console.warn = function no_console() {};
    console.warn = function () {};
  }


  return (
      <>
        {isLoading &&
          <LoadingModal />
        }
        <Header />
        <Routes>
          <Route path="/" element={<Main />} />
          {/* <Route path="/"  /> */}
          <Route path="/travleMain" element={<TravleMain />} />
          <Route path="/landMain" element={<LandMain />} />
          <Route path="/find" element={<FindUserInfoIndex />} />

          <Route path="/editInfo" element={ //회원정보 수정
            mb_type=== '1'?
              mb_type_1 === '1' ?
                <NormalEditMyInfo />
              :
                <TravleEditMyInfo />            
            : 
              mb_type_1 === '1'?
                <LandEditMyInfo />  
              :
                <GuideEditMyInfo />
          }/>

          {/** join  **/}
          <Route path="/selJoinType" element={<SelectJoinType />} />
          <Route path="/nomalJoinSel" element={<NomalJoinSelect />} />
          <Route path="/kakaoAuth" element={<KakaoLoginAuth />} />
          <Route path="/naverAuth" element={<NaverLoginAuth />} />

          <Route path="/normalJoin" element={<NormalJoin />} />
          <Route path="/travelJoin" element={<TravelJoin />} />
          <Route path="/landSelJoinType" element={<LandSelJoinType />} />
          <Route path="/landJoin" element={<LandJoin />} />
          <Route path="/guideJoin" element={<GuideJoin />} />

          {/** 고객센터 */}
          <Route path="/serviceCenter/:pageType" element={<ServCenterIndex />} />
          <Route path="/NoticeDetail/:ntIdx" element={<NoticeDetail />} />

          {/* 나의 별점 */}
          <Route path="myReview" element={<MyReview />} />
        </Routes>
        <AlertModal 
          show={alertModal.alert}
          modalOff={alertModalOff}
          msg={alertModal.msg}
          btnLabel='확인'
          alertAction={alertAction}
          type={alertModal.type}
        />
        <Footer />
      </>
    );
}

export default App;
