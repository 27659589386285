import React from 'react';
import Loading from '../components/form/Loading';
import PageNation from '../components/form/PageNation';
import { usePostQuery } from '../inc/axiosQuery';
import { ReviewItemType } from '../inc/type/ReviewType';
import ServiceCenterModal from '../modal/ServiceCenterModal';
import { useAppSelector } from '../store/config';

export default function MyReview(){

    const userInfo = useAppSelector(state => state.userInfo);

    const [page, setPage] = React.useState(1);
    const [totalItem, setTotalItem] = React.useState(1);

    const [serCenModal, setSerCenModal] = React.useState(false);

    const {data : rvList, isLoading: rvLoading} = usePostQuery('getRvList',{
        jwt_token : userInfo.jwt_token,
        mb_idx_target : userInfo.mb_idx,
        page : page,
        limit : 5,
    },'review/review_list.php');

    React.useEffect(()=>{
        if(rvList){
            setTotalItem(rvList.data.tot_cnt);
        }
    },[rvList])

    return(
        <div className="container sub_pg">
            <div className="cont_wr">
                <div className="row">
                    <div className="col-7 pr-5">
                        <div className="tit_wr mb-2">
                            <h2 className="tit_h2 tit_point">리뷰</h2>
                        </div>
                        {rvLoading ?
                            <Loading />
                        :
                            <div>
                                {/* <div className="accordion acc_style_1 border-top-0" id="accordion_faq">
                                    <div className="card">
                                        <div className="card-header px-0">
                                            <button className="btn btn-block text-left accordion_tit" type="button" data-toggle="collapse" data-target="#collapse1" aria-expanded="false" aria-controls="collapse1">                                
                                                <div className="fw_400">
                                                    <div className="d-flex align-items-center mb-3 fs_13">
                                                        <div className="d-flex align-items-center">
                                                            <img src="/assets/img/ic_star_on.png" alt="별 아이콘" style={{width:10}} />
                                                            <span className="ml-2">4</span>
                                                        </div>
                                                        <span className="col_bar"></span>
                                                        <p>Dddkasl88</p>
                                                    </div>
                                                    <p className="pr-3 fs_16">너무 좋았습니다. 친절하시고, 인원 관린도 좋았습니다.</p>
                                                </div>
                                                <div className="btn_accordion">
                                                    <img src="/assets/img/arr_accodion.png" />
                                                </div>
                                            </button>
                                        </div>
                                        <div id="collapse1" className="collapse" data-parent="#accordion_faq">
                                            <div className="card-body bg-white px-0 pt-2 pb-4">
                                                너무 좋았습니다. 친절하시고, 인원 관린도 좋았습니다. 너무 좋았습니다. 친절하시고, 인원 관린도
                                                좋았습니다.
                                            </div>
                                        </div>
                                    </div>              
                                </div> */}
                                <ul>
                                    {rvList.data.list.length > 0 ?
                                        rvList.data.list.map((item:ReviewItemType,index:number) => {
                                            return(
                                                <li className="fs_13 border-bottom mt-4" key={index}>
                                                    <div className="d-flex align-items-center mb-3">
                                                        <div className="d-flex align-items-center">
                                                            <img src="/assets/img/ic_star_on.png" alt="별 아이콘" style={{width:10}} />
                                                            <span className="ml-2 fs_16">{item.rating}</span>
                                                        </div>
                                                        <span className="col_bar"></span>
                                                        <p className="fs_16">{item.mb_id}</p>
                                                    </div>
                                                    <p className="mb-4 fs_16">{item.cmt_content} </p>
                                                </li>
                                            )
                                        })
                                    :
                                        <p>작성된 리뷰가 없습니다.</p>
                                    }
                                </ul>
                                
                                {rvList && rvList.data.list.length > 0 &&
                                    <PageNation 
                                        page={page}
                                        setPage={setPage}
                                        totalItem={totalItem}
                                        limitItem={5}
                                    />
                                }
                            </div>
                        }
                        

                        <div className="tit_wr mb-4">
                            <h2 className="tit_h2 tit_point mt-5">별점 제도</h2>
                        </div>
                        <p className="mb-4 fs_18 line_h1_5">모든 회원사들은 동일하게 5.0의 별점이 주어집니다.<br />
                        가입 후에 첫 3번의 의뢰 건수의 평점은 점수에 반영 되지 않습니다. <br />
                        4번째부터 평점에 반영되므로 3번의 5.0을 포함한 평균 점수로 반영이 됩니다.
                        </p>
                        <p className="fs_18 text_gray mb-5 pb-5">예) 4번째 4.0을 받았을 경우에 평균점수는 (5.0+5.0+5.0+4.0)/4 =4.75가 됩니다.</p>    

                        <div className="tit_wr mb-5 mt-5">
                            <h2 className="tit_h2 tit_point">패널티 기준</h2>
                        </div>
                        <div className="d-flex align-items-start mb-5">
                            <img src="/assets/img/ic_star_on.png" alt="별 아이콘" style={{width:25 , marginTop:-2}} />
                            <div className="ml-3">
                                <strong className="fs_18">3.0~5.0</strong>
                                <p className="mt-3">견적의뢰, 견적 신청의 권한 무제한</p>
                            </div>
                        </div>
                        <div className="d-flex align-items-start mb-5">
                        <img src="/assets/img/ic_star_on.png" alt="별 아이콘" style={{width:25 , marginTop:-2}} />

                            <div className="ml-3">
                                <strong className="fs_18">2.5~3.0</strong>
                                <p className="mt-3">견적 의뢰 및 견적 신청의 동시 신청 가능 횟 수 1회로 제한. 동시에 두개의 견적을 의뢰 및 신청이 불가능함. </p>
                            </div>
                        </div>
                        <div className="d-flex align-items-start mb-5">
                            <img src="/assets/img/ic_star_on.png" alt="별 아이콘" style={{width:25 , marginTop:-2}} />
                            <div className="ml-3">
                                <strong className="fs_18">2.0 이하 경우</strong>
                                <p className="mt-3">모든 권한이 제한됨. 고객문의 요망. </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-5 pl-5">
                        <div className="info_box py-5">
                            <div className="com_info py-3">
                                <p className="fs_18 fw_700 py-4">현재 내 별점</p>
                                <p className="fs_35 fw_700 ff_gmarket mb-4">{userInfo.rating.toFixed(1)}</p>
                                <div className="d-flex justify-content-center com_star">
                                    {[...Array(5)].map((x,index) => {
                                        return(
                                            <img 
                                                src={index+1 <= userInfo.rating ?
                                                    '/assets/img/ic_star_on.png' 
                                                    :
                                                    '/assets/img/ic_star_off.png' 
                                                    }
                                                alt="별 아이콘" 
                                                key={index}
                                            />
                                        )
                                    })}
                                </div>
                                <img src="/assets/img/gold_leaf.png" alt="" className="com_info_bg" />
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-12 d-flex justify-content-center">
                        <button className="btn btn-lg btn-primary w_380 fw_700" type="button" onClick={()=>{setSerCenModal(true)}}>
                            고객문의
                        </button>
                    </div>
                </div>        
            </div>

            <ServiceCenterModal 
                show={serCenModal}
                setShow={setSerCenModal}
            />
        </div>

    )
}