/* store/slices/counterSlice.ts */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Root } from 'react-dom/client';
import { RootState } from '../config';
interface CommonState {
  mb_id : string,
  mb_idx :string,
  mb_type : string,
  mb_type_1 : string,
  mb_name : string,
  mb_name_ : string,
  mb_sex : string,
  mb_phone : string,
  mb_birth : string,
  mb_email : string,
  mb_intro : string,
  pass_file : string | object,
  pass_file_name : string,
  company_name : string,
  company_address_1 : string,
  company_address_2 : string,
  company_number : string,
  company_cate_1 : string,
  company_cate_2 : string,
  rating : number,
  company_reg_file : string | object,
  company_reg_file_name : string,
  bank_name : string,
  bank_number : string,
  bank_reg_file : string | object,
  bank_reg_file_name : string,
  pass_number : string,
  wr_datetime :string,
  loc_token : string,
  loc_array : {loc:string,loc_1:string}[],
  jwt_token : string,
  mb_reg_type : string,

  isAutoLogin : boolean,
}


const initialState: CommonState = {
  mb_id : '',
  mb_idx :'',
  mb_type : '',
  mb_type_1 : '',
  mb_name : '',
  mb_name_ : '',
  mb_sex : '',
  mb_phone : '',
  mb_birth : '',
  mb_email : '',
  mb_intro : '',
  pass_file : '',
  pass_file_name : '',
  company_name : '',
  company_number : '',
  company_address_1 : '',
  company_address_2 : '',
  company_cate_1 : '',
  company_cate_2 : '',

  rating : 0,
  company_reg_file : '',
  company_reg_file_name : '',
  bank_reg_file : '',
  bank_reg_file_name : '',
  bank_name : '',
  bank_number : '',
  pass_number : '',
  wr_datetime :'',
  loc_token : '',
  loc_array : [],
  jwt_token : '',
  mb_reg_type :'',

  isAutoLogin : false,
};

export const UserInfoSlice = createSlice({
  name: 'userInfo',
  initialState,
  reducers: {
    setUserInfo(state, action: PayloadAction<CommonState>) { //유저정보
      state.mb_id = action.payload.mb_id;
      state.mb_idx = action.payload.mb_idx;
      state.mb_type = action.payload.mb_type;
      state.mb_type_1 = action.payload.mb_type_1;
      state.mb_name = action.payload.mb_name;
      state.mb_name_ = action.payload.mb_name_;
      state.mb_sex = action.payload.mb_sex;
      state.mb_phone = action.payload.mb_phone;
      state.mb_birth = action.payload.mb_birth;
      state.mb_email = action.payload.mb_email;
      state.mb_intro = action.payload.mb_intro;
      state.company_name = action.payload.company_name;
      state.company_number = action.payload.company_number;
      state.company_address_1 = action.payload.company_address_1;
      state.company_address_2 = action.payload.company_address_2;
      state.company_cate_1 = action.payload.company_cate_1;
      state.company_cate_2 = action.payload.company_cate_2;

      state.rating = action.payload.rating;
      state.pass_file = action.payload.pass_file;
      state.pass_file_name = action.payload.pass_file_name;
      state.company_reg_file = action.payload.company_reg_file;
      state.company_reg_file_name = action.payload.company_reg_file_name;
      state.bank_name = action.payload.bank_name;
      state.bank_number = action.payload.bank_number;
      state.bank_reg_file = action.payload.bank_reg_file;
      state.bank_reg_file_name = action.payload.bank_reg_file_name;
      state.pass_number = action.payload.pass_number;
      state.wr_datetime = action.payload.wr_datetime;
      state.loc_token = action.payload.loc_token;
      state.loc_array = action.payload.loc_array;
      state.jwt_token = action.payload.jwt_token;
      state.mb_reg_type = action.payload.mb_reg_type;
    },
    setAutoLogin(state,action){ //자동로그인 정보
      state.isAutoLogin = action.payload;
    },
    setLogOut(state,action){ //로그아웃
      state.mb_id = '';
      state.mb_idx = '';
      state.mb_type = '';
      state.mb_type_1 = '';
      state.mb_name = '';
      state.mb_name_ = '';
      state.mb_sex = '';
      state.mb_phone = '';
      state.mb_birth = '';
      state.mb_email = '';
      state.mb_intro = '';
      state.company_name = '';
      state.company_number = '';
      state.company_address_1 ='';
      state.company_address_2 = '';
      state.company_cate_1 = '';
      state.company_cate_2 = '';

      state.rating = 0;
      state.pass_file = '';
      state.pass_file_name = '';
      state.company_reg_file = '';
      state.company_reg_file_name = '';
      state.bank_name = '';
      state.bank_number = '';
      state.bank_reg_file = '';
      state.bank_reg_file_name = '';
      state.pass_number = '';
      state.wr_datetime = '';
      state.loc_token = '';
      state.loc_array = [];
      state.jwt_token = '';
      state.mb_reg_type = '';
    }
  }
});

export const { setUserInfo,setAutoLogin,setLogOut } = UserInfoSlice.actions;

export default UserInfoSlice;

export const selectAutoLogin = (state:RootState) => state.userInfo.isAutoLogin;