import React from 'react';
import classNames from 'classnames';
import CustomInput from '../../../components/form/CustomInput';
import { MarginY } from '../../../inc/FlexStyle';
import CustomInputBtn from '../../../components/form/CustomBtnInput';
import CustomButton from '../../../components/form/CustomButton';
import CustomFile from '../../../components/form/CustomFile';
import CustomSelectBox from '../../../components/form/CustomSelectBox';
import AlertModal from '../../../modal/AlertModal';
import AgreeModal from '../../../modal/AgreeModal';
import { emailCheck, idCheck, phoneCheck, pwCheck, pwCheckRe } from '../../../inc/func';
import LocationSelect from '../../../components/form/LocationSelect';
import { usePostMutation, usePostQuery } from '../../../inc/axiosQuery';
import CustomTextarea from '../../../components/form/CustomTextarea';
import { useAppDispatch, useAppSelector } from '../../../store/config';
import { setLoading } from '../../../store/slices/LoadingSlice';
import ChangePwdModal from '../../../modal/user/ChangePwdModal';
import ChangePhoneModal from '../../../modal/user/ChangePhoneModal';
import { setUserInfo } from '../../../store/slices/UserInfoSlice';

type countryType = {
    value : string,
    label : string,
}

function GuideEditMyInfo() {

    const dispatch = useAppDispatch();
    const userInfo = useAppSelector(state => state.userInfo);
    const {data:conList, isLoading : conLoading, isError: conError} = usePostQuery('countryList',{},'cate/loc_list.php');
    const editGuideInfoMutation = usePostMutation('editGuideInfo','user/user_edit_2.php');

    const sendEmailAuthMutation = usePostMutation('sendEmailAuth','user/email_auth_send.php'); //이메일 인증 전송
    const emailAuthMutation = usePostMutation('emailAuth','user/email_auth_chk.php'); // 이메일 인증


    const [joinInfo , setJoinInfo] = React.useState({
        jwt_token : userInfo.jwt_token,
        mb_type_1 : '3',
        mb_id : userInfo.mb_id,
        mb_name : userInfo.mb_name,
        mb_email : userInfo.mb_email,
        mb_intro : userInfo.mb_intro,
        mb_email_auth : '',
        pass_number : userInfo.pass_number,
        pass_file_name : userInfo.pass_file_name,
        pass_file : {},
    })

    const [inputCheck, setInputCheck] = React.useState({
        mb_id : true,
        mb_email : true,
        mb_email_auth : true,

        mb_id_msg : '',
        mb_email_msg : '',
        mb_email_auth_msg : '',
    })

    const [isSendEmailAuth, setIsSendEmailAuth] = React.useState(false);

    const [chgPwdModal, setChgPwdModal] = React.useState(false);
    const [chgPhoneModal, setChgPhoneModal] = React.useState(false);

    const [countryList, setCountryList] = React.useState<countryType[]>([]);

    const [selPlaceList, setSelPlaceList] = React.useState([ //선택 나라 리스트
        {loc : '' , loc_1 : ''},
    ])

    const [alertModal, setAlertModal] = React.useState({
        show : false,
        msg : '',
        type : '',
    });
    const [agreeModal, setAgreeModal] = React.useState({
        show : false,
        type :'',
        title : '',
    })

    const alertModalOn = (msg:string) => {
        setAlertModal({
            show:true,
            type:'',
            msg:msg,
        })
    }

    const modalOff = () => {
        setAgreeModal({
            show:false,
            type :'',
            title : '',
        })
        setAlertModal({
            show : false,
            msg : '',
            type : '',
        })
        
    }
    const alertModalAction = () => {
        // alert('is action!');
        if(alertModal.type === 'succes'){

        }
    }
    

    const inputController = (text:string , type?:string) => { //입력 컨트롤러
        if(type){
            setJoinInfo({
                ...joinInfo,
                [type] : text,
            })
        }
    }

    const passFileChange = (file: FileList | null) => { //통장사본 업로드
        if(file){
            setJoinInfo({
                ...joinInfo,
                pass_file_name : file[0].name,
                pass_file : file[0],
            })
        }
    }

    const addCountry = () => {
        setSelPlaceList([...selPlaceList, {loc:'',loc_1:'0'}]);
    }
    const delCountry = (idx?:number) => {
        const filterArray = selPlaceList.filter((el,fIdx) => idx !== fIdx);
        setSelPlaceList([...filterArray]);
    }

    const countryChange = (sel : string , type? : string) => { //나라선택
        if(type){
            let tempObj = [...selPlaceList];
            tempObj[Number(type)].loc = sel;
            setSelPlaceList([...tempObj]);
        }
    }
    const locationChange = (sel : string, type? : string) => { //지역선택
        if(type){
            let tempObj = [...selPlaceList];
            tempObj[Number(type)].loc_1 = sel;
            setSelPlaceList([...tempObj]);
        }
    }

    const sendEmailAuth = async () => {
        if(!inputCheck.mb_email){
            alertModalOn(inputCheck.mb_email_msg);
        }
        else{
            dispatch(setLoading(true));
            const {result,msg} = await sendEmailAuthMutation.mutateAsync({mb_email:joinInfo.mb_email});

            if(result){
                setAlertModal({
                    show : true,
                    msg : `인증번호가 \n 이메일로 발송되었습니다.`,
                    type : 'send-email',
                })
                setIsSendEmailAuth(true);
            }
            else{
                setInputCheck({
                    ...inputCheck,
                    mb_email:false,
                    mb_email_msg:msg,
                })
                setAlertModal({
                    show : true,
                    msg : msg,
                    type : '',
                })
            }
            dispatch(setLoading(false));
        }
    }

    const authEmailHandler = async () => {
        if(joinInfo.mb_email_auth === ''){
            setInputCheck({
                ...inputCheck,
                mb_email_auth : false,
                mb_email_msg : '인증번호를 입력해주세요.',
            });
        }
        else{
            dispatch(setLoading(true));
            const authParams = {
                mb_email : joinInfo.mb_email,
                auth_no : joinInfo.mb_email_auth
            }
            const {result,msg} = await emailAuthMutation.mutateAsync(authParams);

            if(result){
                setIsSendEmailAuth(false);
                setInputCheck({
                    ...inputCheck,
                    mb_email_auth:true,
                    mb_email_msg:msg,      
                })
            }
            else{
                setInputCheck({
                    ...inputCheck,
                    mb_email_auth : false,
                    mb_email_auth_msg: msg,
                })
                setAlertModal({
                    show:true,
                    msg:msg,
                    type:'',
                })
            }
            dispatch(setLoading(false));
        }
    }

    

    const handleGuideJoin = async () => {
        if(!inputCheck.mb_id){
            alertModalOn(inputCheck.mb_id_msg === '' ? '아이디를 입력해주세요.' : inputCheck.mb_id_msg);
        }
        else if(!inputCheck.mb_email){
            alertModalOn(inputCheck.mb_email_msg === '' ? '유효한 이메일을 입력해주세요.' : inputCheck.mb_email_msg);
        }
        else if(!inputCheck.mb_email_auth){
            alertModalOn('이메일 인증을 진행해주세요.');
        }
        else if(joinInfo.mb_name === ''){
            alertModalOn('담당자 이름을 입력해주세요.');
        }
        else if(joinInfo.mb_intro === ''){
            alertModalOn('담당자 소개를 입력해주세요.');
        }
        else if(joinInfo.pass_number === ''){
            alertModalOn('여권번호를 입력해주세요.');
        }
        else if(joinInfo.pass_file_name === ''){
            alertModalOn('여권 사본을 업로드해주세요.');
        }
        else{

            let tempList:any = [];

            selPlaceList.forEach((item,index) => {
                tempList.push(JSON.stringify(item));  
            })
            dispatch(setLoading(true));
            const {result,msg} = await editGuideInfoMutation.mutateAsync({...joinInfo,loc_list:tempList});

            if(!result){
                setAlertModal({
                    show:true,
                    msg : msg,
                    type : '',
                })
            }
            else{

                dispatch(setUserInfo({
                    ...userInfo,
                    ...joinInfo,
                }))

                setAlertModal({
                    show:true,
                    msg:msg,
                    type : 'success',
                })
            }
            dispatch(setLoading(false));
        }
    }

    React.useEffect(()=>{
        console.log(userInfo);
        if(userInfo.loc_array.length > 0){
            const tempLocArray = [...userInfo.loc_array];
            setSelPlaceList([...tempLocArray]);
        }
    },[])

    React.useEffect(()=>{
        setInputCheck({
            ...inputCheck,
            mb_id : idCheck(joinInfo.mb_id).result,
            mb_id_msg : idCheck(joinInfo.mb_id).msg,
        })
    },[joinInfo.mb_id])

    React.useEffect(()=>{
        if(joinInfo.mb_email === userInfo.mb_email){
            setInputCheck({
                ...inputCheck,
                mb_email:true,
                mb_email_auth : true,
                mb_email_auth_msg : '',
            })
        }
        else{
            setInputCheck({
                ...inputCheck,
                mb_email:emailCheck(joinInfo.mb_email).result,
                mb_email_msg : emailCheck(joinInfo.mb_email).msg,
                mb_email_auth : false,
                mb_email_auth_msg : '',
            })
        }
    },[joinInfo.mb_email])

    React.useEffect(()=>{ //국가 리스트 불러오기
        if(conList){
            let tempArray:countryType[] = [];
            
            conList.data.loc_list.forEach((item:{loc:string,location:string,},index:number) => {
                tempArray.push({
                    value : item.loc,
                    label : item.location,
                })
            })
            setCountryList(tempArray);
        }
    },[conList,conLoading,conError])
    return (
        <div className="container-fluid px-0">
            <AlertModal 
                show={alertModal.show}
                modalOff={modalOff}
                btnLabel={'확인'}
                msg={alertModal.msg}
                type={alertModal.type}
                alertAction={alertModalAction}  
            />
            <AgreeModal 
                show={agreeModal.show}
                title={agreeModal.title}
                type={agreeModal.type}
                modalOff={modalOff}
            />
            <div className="sub_pg">
                <section className="container-lg mx-auto">
                    <div className="lg_bx">
                        <div className="tit_wr">
                            <h3 className="tit_h3 tit_point gmarket">로그인 정보</h3>
                        </div>
                        <form>
                            <CustomInput 
                                title={'아이디'}
                                placeHolder={'아이디를 입력해주세요.'}
                                input={joinInfo.mb_id}
                                setInput={inputController}
                                isValidShow={userInfo.mb_id !== joinInfo.mb_id}
                                isValid={inputCheck.mb_id}
                                validText={inputCheck.mb_id_msg}
                                keyName={'mb_id'}
                            />
                            <MarginY mt={2} />
                            <CustomButton 
                                btnLabel='비밀번호 변경하기'
                                color='green'
                                type='lg'
                                clickEvent={()=>{setChgPwdModal(true)}}
                            />
                            <MarginY mt={2} />

                            <h4 className="tit_h4 mt-5 mb-4">회원정보</h4>

                            <CustomInput 
                                title={'담당자 이름'}
                                placeHolder={'이름을 입력해주세요.'}
                                input={joinInfo.mb_name}
                                setInput={inputController}
                                isValidShow={false}
                                // isValid={!inputCheck.mb_name}
                                // validText={inputCheck.tr_name_msg}
                                keyName={'mb_name'}
                            />
                            <CustomTextarea 
                                title={'담당자 소개'}
                                placeHolder={'담당자 소개를 입력해주세요. (최대 500자)'}
                                contents={joinInfo.mb_intro}
                                setContents={inputController}
                                limit={500}
                                keyName={'mb_intro'}
                            />
                            <MarginY mt={2} />

                            <CustomInputBtn 
                                title='이메일'
                                input={joinInfo.mb_email}
                                setInput={inputController}
                                placeHolder={'이메일을 입력해주세요.'}
                                isValidShow={joinInfo.mb_email !== '' && userInfo.mb_email !== joinInfo.mb_email}
                                isValid={inputCheck.mb_email}
                                validText={inputCheck.mb_email_msg}
                                btnLabel={'인증하기'}
                                inputStyle={userInfo.mb_email !== joinInfo.mb_email ? 'col-9 pr-0' : 'w-100'}
                                btnStyle={'col-3 pl-0'}
                                keyName={'mb_email'}
                                isBtnHide={userInfo.mb_email === joinInfo.mb_email}
                                btnAction={sendEmailAuth}
                            />
                            {isSendEmailAuth &&
                                <>
                                    <MarginY mt={1} />
                                    <CustomInputBtn 
                                        title={'인증번호'}
                                        placeHolder={'입력해주세요.'}
                                        input={joinInfo.mb_email_auth}
                                        setInput={inputController}
                                        isValidShow={joinInfo.mb_email_auth !== '' && inputCheck.mb_email_auth_msg !== ''}
                                        isValid={inputCheck.mb_email_auth}
                                        validText={inputCheck.mb_email_auth_msg}
                                        btnLabel={'확인'}
                                        inputStyle={'col-9 pr-0'}
                                        btnStyle={'col-3 pl-0'}
                                        btnLabelStyle={'fs_16'}
                                        keyName={'mb_email_auth'}
                                        btnAction={authEmailHandler}
                                    />
                                </>
                            }
                            <MarginY mt={2} />
                            <CustomInput
                                title={'여권번호'}
                                placeHolder={'여권번호를 입력해주세요.'}
                                input={joinInfo.pass_number}
                                setInput={inputController}
                                isValidShow={false}
                                keyName={'pass_number'}
                            />

                            <CustomFile 
                                title={'여권 사본'}
                                placeHolder={'여권 사본을 업로드해주세요.'}
                                input={joinInfo.pass_file_name}
                                setInput={passFileChange}
                                btnLabel={'업로드'}
                                inputStyle={'col-9 pr-0'}
                                btnStyle={'col-3 pl-0'}
                                btnLabelStyle={'fs_16'}
                                fileId={'bank'}
                            />

                            <div className="d_sp_center mt-5 mb-4">
                                <h4 className="tit_h4 ">당신이 담당하고 있는 지역을 선택해주세요!</h4>
                                <button type="button" className="btn xs_btn bg_bk" onClick={()=>{addCountry();}}>추가</button>
                            </div>
                            {selPlaceList.map((item,index) => {
                                return(
                                    <div key={index}>
                                        <LocationSelect 
                                            index={index}
                                            selValue1={selPlaceList[index].loc}
                                            selValue2={selPlaceList[index].loc_1}
                                            countryChange={countryChange}
                                            locationChange={locationChange}
                                            delAction={delCountry}
                                            countryList={countryList}
                                        />
                                    </div>
                                )
                            })}
                            
                            

                            <MarginY mt={3} />
                            <CustomInput 
                                title={'핸드폰 번호'}
                                placeHolder={'하이폰(-)을 제외하고 입력해주세요.'}
                                input={userInfo.mb_phone}
                                setInput={()=>{}}
                                isValidShow={false}
                                isReadOnly={true}
                            />
                            <MarginY mt={2} />
                            <CustomButton 
                                btnLabel='핸드폰번호 변경하기'
                                color='green'
                                type='lg'
                                clickEvent={()=>{setChgPhoneModal(true)}}
                            />
                            
                            <MarginY mt={5} />
                            <CustomButton 
                                btnLabel='수정하기'
                                color='green'
                                type='lg'
                                labelStyle='fw_700'
                                clickEvent={()=>{handleGuideJoin()}}
                            />
                        </form>
                        
                    </div>
                    <ChangePwdModal 
                        show={chgPwdModal}
                        setShow={setChgPwdModal}
                        mb_id={userInfo.mb_id}
                        type={'edit'}
                    />
                    <ChangePhoneModal 
                        show={chgPhoneModal}
                        setShow={setChgPhoneModal}
                    />
                </section>
            </div>
        </div>
    );
}

export default GuideEditMyInfo;
