import React from 'react';
import classNames from 'classnames';
import CustomInput from '../../../components/form/CustomInput';
import { MarginY } from '../../../inc/FlexStyle';
import CustomInputBtn from '../../../components/form/CustomBtnInput';
import CustomButton from '../../../components/form/CustomButton';
import { usePostMutation, usePostQuery } from '../../../inc/axiosQuery';
import { emailCheck, idCheck, phoneCheck, pwCheck, pwCheckRe } from '../../../inc/func';
import AlertModal from '../../../modal/AlertModal';
import { useLocation, useNavigate } from 'react-router';
import AgreeModal from '../../../modal/AgreeModal';
import { useAppDispatch, useAppSelector } from '../../../store/config';
import { setLoading } from '../../../store/slices/LoadingSlice';

function NormalJoin() {

    const {mb_type} = useAppSelector(state => state.userInfo);
    const dispatch = useAppDispatch();
    const normalJoinMutation = usePostMutation('normalJoin','user/user_reg_1.php');

    const sendEmailAuthMutation = usePostMutation('sendEmailAuth','user/email_auth_send.php'); //이메일 인증 전송
    const emailAuthMutation = usePostMutation('emailAuth','user/email_auth_chk.php'); //이메일 인증

    const sendPhoneAuthMutation = usePostMutation('sendPhoneAuth' , 'user/auth_msg.php'); //휴대폰 인증 전송
    const phoneAuthMutation = usePostMutation('phoneAuth' , 'user/auth_msg_chk.php'); //휴대폰 인증

    const getNaverInfoMutation = usePostMutation('getNaverInfo' , 'user/sns_naver_info.php'); //네이버 회원가입 정보불러오기

    
    const location = useLocation();
    const joinType = new URLSearchParams(location.search).get('type');  



    const navigator = useNavigate();
    const [joinInfo , setJoinInfo] = React.useState({
        mb_type_1 : '1',
        mb_id : '',
        mb_pw : '',
        mb_pw_re : '',
        mb_email : '',
        mb_email_auth : '',
        mb_sex : '남',
        mb_birth : '',
        mb_phone : '',
        authCode : '',
        agree1 : false,
        agree2 : false,
        mb_reg_type : 'E',
        mb_name : '',
    })

    const [inputCheck, setInputCheck] = React.useState({
        mb_id : false,
        mb_pw : false,
        mb_pw_re : false,
        mb_email : false,
        mb_email_auth : false,
        mb_birth : false,
        mb_phone : false,
        authCode : false,
        agree1 : false,
        agree2 : false,

        mb_id_msg : '',
        mb_pw_msg : '',
        mb_pw_re_msg : '',
        mb_email_msg : '',
        mb_email_auth_msg : '',
        birth_msg : '',
        mb_phone_msg : '',
        authCode_msg : '',
        agree1_msg : '',
        agree2_msg : '',
    })

    const [isSendEmailAuth, setIsSendEmailAuth] = React.useState(false);
    const [isSendPhoneAuth , setIsSendPhoneAuth] = React.useState(false);

    
    const [alertModal, setAlertModal] = React.useState({
        show:false,
        msg:'',
        type:'',
    })
    const [agreeModal, setAgreeModal] = React.useState({
        show : false,
        type :'',
        title : '',
    })
    const alertModalOn = (msg:string) => {
        setAlertModal({
            show:true,
            msg:msg,
            type:'',
        })
    }
    const modalOff = () => {
        setAgreeModal({
            show:false,
            type :'',
            title : '',
        })
        setAlertModal({
            show : false,
            msg : '',
            type : '',
        })
    }

    const alertAction = () => {
        if(alertModal.type === 'success'){
            navigator('/');
        }
        else if(alertModal.type === 'none_token'){
            navigator('/nomalJoinSel',{replace:true})
        }
    }

    const getKakaoInfo = async () => { //카카오 회원 정보 가져오기
        try {
          // Kakao SDK API를 이용해 사용자 정보 획득
          let data = await window.Kakao.API.request({
            url: "/v2/user/me",
          });
          if(data){
            console.log(data);
            const myInfo = data.kakao_account;
            setJoinInfo({
                ...joinInfo,
                mb_id : data.id,
                mb_email : myInfo.email ? myInfo.email : '',
                mb_reg_type : 'K',
            })
            setInputCheck({
                ...inputCheck,
                mb_id : true,
                mb_pw : true,
                mb_pw_re : true,
                mb_email : myInfo.is_email_valid ? myInfo.is_email_valid : false,
                mb_email_msg : '',
                mb_email_auth : myInfo.isEmail_valid ? myInfo.is_email_valid : false,
            })

          }
          // 사용자 정보 변수에 저장
        } catch (err) {
            console.log(err);
        //   navigator('/nomalJoinSel',{replace:true});
        }
      };

    const getNaverInfo = async () => {
        try {
            const token = new URLSearchParams(location.search).get('token');
            
            if(token === 'undefined' || !token){
                setAlertModal({
                    show:true,
                    msg:'비정상적인 접근입니다.',
                    type : 'none_token',
                })

                return;
            }

            const {result, data, msg} = await getNaverInfoMutation.mutateAsync({
                access_token : token,
            })

            console.log(result, data, msg);

            if(result){

                const birth = data.birthday === '' ? '' : data.birthday.split('-')[0]+data.birthday.split('-')[1];

                setJoinInfo({
                    ...joinInfo,
                    mb_id : data.mb_id,
                    mb_name : data.name,
                    mb_email : data.email,
                    mb_sex : data.mb_sex === '' ? '남' : data.mb_sex,
                    mb_birth : data.birthday !== '' && data.birthyear !== '' ? data.birthyear+birth : '',
                    mb_reg_type : 'N',
                })
                setInputCheck({
                    ...inputCheck,
                    mb_id : true,
                    mb_pw : true,
                    mb_pw_re : true,
                    mb_email : data.email !== '',
                    mb_email_msg : '',
                    mb_email_auth : data.email !== '',
                })
            }
        }
        catch (err) {
            console.log(err);
        }
    }

    const getFacebookInfo = () => {
        const id = new URLSearchParams(location.search).get('id');
        const email = new URLSearchParams(location.search).get('email');
        const name = new URLSearchParams(location.search).get('name');

        if(!id){
            setAlertModal({
                show:true,
                msg:'비정상적인 접근입니다.',
                type : 'none_token',
            })

            return;
        }
        else{
            setJoinInfo({
                ...joinInfo,
                mb_id : id,
                mb_name : name ? name : '',
                mb_email : email ? email : '',
                mb_reg_type : 'N',
            })
            setInputCheck({
                ...inputCheck,
                mb_id : true,
                mb_pw : true,
                mb_pw_re : true,
                mb_email : email || email !== '' ? true : false,
                mb_email_msg : '',
                mb_email_auth : email || email !== '' ? true : false,
            })
        }
    }

      

    const inputController = (text:string , type?:string) => {
        if(type){
            setJoinInfo({
                ...joinInfo,
                [type] : text,
            })
        }
    }
    const genderController = (value:string) => {
        setJoinInfo({
            ...joinInfo,
            mb_sex : value,
        })
    }

    const checkBoxController = (type : string) => {
        setJoinInfo({
            ...joinInfo,
            [type] : type === 'agree1' ? !joinInfo.agree1 : !joinInfo.agree2 
        })
    } 

    const sendEmailAuth = async () => {
        if(!inputCheck.mb_email){
            alertModalOn(inputCheck.mb_email_msg);
        }
        else{
            dispatch(setLoading(true));
            const {result,msg} = await sendEmailAuthMutation.mutateAsync({mb_email:joinInfo.mb_email});

            if(result){
                setAlertModal({
                    show : true,
                    msg : `인증번호가 \n 이메일로 발송되었습니다.`,
                    type : 'send-email',
                })
                setIsSendEmailAuth(true);
            }
            else{
                setInputCheck({
                    ...inputCheck,
                    mb_email:false,
                    mb_email_msg:msg,
                })
                setAlertModal({
                    show : true,
                    msg : msg,
                    type : '',
                })
            }
            dispatch(setLoading(false));
        }
    }

    const authEmailHandler = async () => {
        if(joinInfo.mb_email_auth === ''){
            setInputCheck({
                ...inputCheck,
                mb_email_auth : false,
                mb_email_msg : '인증번호를 입력해주세요.',
            });
        }
        else{
            dispatch(setLoading(true));
            const authParams = {
                mb_email : joinInfo.mb_email,
                auth_no : joinInfo.mb_email_auth
            }
            const {result,msg} = await emailAuthMutation.mutateAsync(authParams);

            if(result){
                setIsSendEmailAuth(false);
                setInputCheck({
                    ...inputCheck,
                    mb_email_auth:true,
                    mb_email_msg:msg,      
                })
            }
            else{
                setInputCheck({
                    ...inputCheck,
                    mb_email_auth : false,
                    mb_email_auth_msg: msg,
                })
                setAlertModal({
                    show:true,
                    msg:msg,
                    type:'',
                })
            }
            dispatch(setLoading(false));
        }
    }

    const sendPhoneCodeHandler = async () => {
        if(!phoneCheck(joinInfo.mb_phone).result){
            setInputCheck({
                ...inputCheck,
                mb_phone : false,
                mb_phone_msg : phoneCheck(joinInfo.mb_phone).msg,
            })
            alertModalOn(phoneCheck(joinInfo.mb_phone).msg);
        }
        else{
            dispatch(setLoading(true));
            const {result,msg} = await sendPhoneAuthMutation.mutateAsync({mb_phone:joinInfo.mb_phone});

            setIsSendPhoneAuth(result);
            
            if(result){
                alertModalOn('인증번호가 발송되었습니다.');
                setInputCheck({
                    ...inputCheck,
                    mb_phone : true,
                    mb_phone_msg : '인증번호가 발송되었습니다.',
                })
            }
            else{
                setInputCheck({
                    ...inputCheck,
                    mb_phone:false,
                    mb_phone_msg : msg,
                })
                alertModalOn(msg);
            }
            dispatch(setLoading(false));
        }
    }

    const authPhoneCodeHandler = async () => {

        if(!phoneCheck(joinInfo.mb_phone).result){
            setInputCheck({
                ...inputCheck,
                mb_phone : false,
                mb_phone_msg : phoneCheck(joinInfo.mb_phone).msg,
            })
            alertModalOn(phoneCheck(joinInfo.mb_phone).msg);
            return false;
        }
        if(joinInfo.authCode === ''){
            setInputCheck({
                ...inputCheck,
                authCode : false,
                authCode_msg : '인증번호를 입력해주세요.',
            })
            alertModalOn('인증번호를 입력해주세요.');
        }
        else{
            dispatch(setLoading(true));
            const {result,msg} = await phoneAuthMutation.mutateAsync({
                mb_phone:joinInfo.mb_phone,
                auth_number : joinInfo.authCode,
            })
            if(result){
                
                setIsSendPhoneAuth(false);
                alertModalOn(msg);
                setInputCheck({
                    ...inputCheck,
                    mb_phone:true,
                    authCode:true,
                    mb_phone_msg : msg,
                })
                
            }
            else{
                setInputCheck({
                    ...inputCheck,
                    authCode:false,
                    authCode_msg : msg,
                })
                alertModalOn(msg);
            }
            dispatch(setLoading(false));
        }
    }

    const handleNormalJoin = async () => {
        if(joinType === 'normal'){
            if(joinInfo.mb_id === ''){
                alertModalOn(inputCheck.mb_id_msg === '' ? '아이디를 입력해주세요.' : inputCheck.mb_id_msg);
            }
            else if(!inputCheck.mb_pw){
                alertModalOn(inputCheck.mb_pw_msg === '' ? '올바른 비밀번호를 입력해주세요.' : inputCheck.mb_pw_msg);
            }
            else if(!inputCheck.mb_pw_re){
                alertModalOn(inputCheck.mb_pw_msg === '' ? '비밀번호를 한번더 동일하게 입력해주세요.' : inputCheck.mb_pw_re_msg);
            }
            else if(!inputCheck.mb_email){
                alertModalOn(inputCheck.mb_email_msg === '' ? '유효한 이메일을 입력해주세요.' : inputCheck.mb_email_msg);
            }
            else if(!inputCheck.mb_email_auth){
                alertModalOn(inputCheck.mb_email_auth_msg === '' ? '이메일 인증을 진행해주세요.' : inputCheck.mb_email_auth_msg);
            }
            else if(joinInfo.mb_birth === ''){
                alertModalOn('생년월일을 입력해주세요.');
            }
            else if(!inputCheck.mb_phone){
                alertModalOn(inputCheck.mb_phone_msg === '' ? '핸드폰번호를 올바르게 입력해주세요.' : inputCheck.mb_phone_msg);
            }
            else if(!inputCheck.authCode){
                alertModalOn('휴대폰 인증을 진행해주세요.');
            }
            else if(!joinInfo.agree1){
                alertModalOn('서비스 이용약관에 동의해주세요.');
            }
            else if(!joinInfo.agree2){
                alertModalOn('개인정보 수집 및 이용에 동의해주세요.');
            }
            else{

                dispatch(setLoading(true));
                const {result,msg} = await normalJoinMutation.mutateAsync(joinInfo);

                if(!result){
                    setAlertModal({
                        show:true,
                        msg : msg,
                        type : '',
                    })
                }
                else{
                    setAlertModal({
                        show:true,
                        msg:msg,
                        type : 'success',
                    })
                }

                dispatch(setLoading(false));
            }
        }
        else{
            if(!inputCheck.mb_email){
                alertModalOn(inputCheck.mb_email_msg === '' ? '유효한 이메일을 입력해주세요.' : inputCheck.mb_email_msg);
            }
            else if(!inputCheck.mb_email_auth){
                alertModalOn(inputCheck.mb_email_auth_msg === '' ? '이메일 인증을 진행해주세요.' : inputCheck.mb_email_auth_msg);
            }
            else if(joinInfo.mb_birth === ''){
                alertModalOn('생년월일을 입력해주세요.');
            }
            else if(!inputCheck.mb_phone){
                alertModalOn(inputCheck.mb_phone_msg === '' ? '핸드폰번호를 올바르게 입력해주세요.' : inputCheck.mb_phone_msg);
            }
            else if(!inputCheck.authCode){
                alertModalOn('휴대폰 인증을 진행해주세요.');
            }
            else if(!joinInfo.agree1){
                alertModalOn('서비스 이용약관에 동의해주세요.');
            }
            else if(!joinInfo.agree2){
                alertModalOn('개인정보 수집 및 이용에 동의해주세요.');
            }
            else{

                console.log(joinInfo);

                dispatch(setLoading(true));
                const {result,msg} = await normalJoinMutation.mutateAsync(joinInfo);

                if(!result){
                    setAlertModal({
                        show:true,
                        msg : msg,
                        type : '',
                    })
                }
                else{
                    setAlertModal({
                        show:true,
                        msg:msg,
                        type : 'success',
                    })
                }

                dispatch(setLoading(false));
            }
        }
    }
    

    React.useEffect(()=>{
        setInputCheck({
            ...inputCheck,
            mb_id : idCheck(joinInfo.mb_id).result,
            mb_id_msg : idCheck(joinInfo.mb_id).msg,
            mb_email : emailCheck(joinInfo.mb_email).result,
            mb_email_msg : emailCheck(joinInfo.mb_email).msg,
            mb_pw : pwCheck(joinInfo.mb_pw).result,
            mb_pw_msg : pwCheck(joinInfo.mb_pw).msg,
            mb_pw_re : pwCheckRe(joinInfo.mb_pw,joinInfo.mb_pw_re).result,
            mb_pw_re_msg : pwCheckRe(joinInfo.mb_pw,joinInfo.mb_pw_re).msg,
            mb_phone : phoneCheck(joinInfo.mb_phone).result,
            mb_phone_msg : phoneCheck(joinInfo.mb_phone).msg,
        })
    },[joinInfo])

    React.useEffect(() => {
        if(joinType === 'kakao'){
            getKakaoInfo(); //카카오 정보 가져오기
        }
        else if(joinType === 'naver'){
            getNaverInfo();
        }
        else if(joinType === 'facebook'){
            getFacebookInfo();
        }
      }, []);

    React.useEffect(()=>{
        if(mb_type === '1'){
            navigator('/travleMain',{replace:true});
        }
        else if(mb_type === '2'){
            navigator('/landMain',{replace:true});
        }
    },[])
    

    return (
        <div className="container-fluid px-0">
            <AlertModal 
                show={alertModal.show}
                modalOff={modalOff}
                btnLabel={'확인'}
                msg={alertModal.msg}
                type={alertModal.type}
                alertAction={alertAction}
            />
            <AgreeModal 
                show={agreeModal.show}
                title={agreeModal.title}
                type={agreeModal.type}
                modalOff={modalOff}
            />
            <div className="sub_pg">
                <section className="container-lg mx-auto">
                    <div className="lg_bx">
                        <div className="tit_wr">
                            <h3 className="tit_h3 tit_point gmarket">일반 회원가입</h3>
                            <p className="fs_18 mt-2">내 입맛에 맞는 여행을 골라 보세요!</p>
                        </div>
                        <form>
                            {joinType === 'normal' &&
                                <>
                                    <CustomInput 
                                        title={'아이디'}
                                        placeHolder={'아이디를 입력해주세요.'}
                                        input={joinInfo.mb_id}
                                        setInput={inputController}
                                        isValidShow={joinInfo.mb_id !== ''}
                                        isValid={inputCheck.mb_id}
                                        validText={inputCheck.mb_id_msg}
                                        keyName={'mb_id'}
                                    />
                                    <MarginY mt={2} />
                                    <CustomInput 
                                        title={'비밀번호'}
                                        placeHolder={'비밀번호를 입력해주세요.'}
                                        input={joinInfo.mb_pw}
                                        setInput={inputController}
                                        isValidShow={joinInfo.mb_pw !== ''}
                                        isValid={inputCheck.mb_pw}
                                        validText={inputCheck.mb_pw_msg}
                                        keyName={'mb_pw'}
                                        type={'password'}
                                    />
                                    <CustomInput 
                                        placeHolder={'비밀번호를 한번더 입력해주세요.'}
                                        input={joinInfo.mb_pw_re}
                                        setInput={inputController}
                                        isValidShow={joinInfo.mb_pw_re !== ''}
                                        isValid={inputCheck.mb_pw_re}
                                        validText={inputCheck.mb_pw_re_msg}
                                        keyName={'mb_pw_re'}
                                        type={'password'}
                                    />
                                    <MarginY mt={2} />
                                </>
                            }
                            <CustomInputBtn 
                                title={'이메일'}
                                placeHolder={'이메일을 입력해주세요.'}
                                input={joinInfo.mb_email}
                                setInput={inputController}
                                isValidShow={joinInfo.mb_email !== ''}
                                isValid={inputCheck.mb_email}
                                validText={inputCheck.mb_email_msg}
                                btnLabel={'인증발송'}
                                inputStyle={inputCheck.mb_email_auth ? 'w-100' :'col-9 pr-0'}
                                btnStyle={'col-3 pl-0'}
                                btnLabelStyle={'fs_16'}
                                keyName={'mb_email'}
                                btnAction={sendEmailAuth}
                                isReadOnly={inputCheck.mb_email_auth}
                                isBtnHide={inputCheck.mb_email_auth}
                            />
                            {isSendEmailAuth &&
                                <>
                                    <MarginY mt={1} />
                                    <CustomInputBtn 
                                        title={'인증번호'}
                                        placeHolder={'입력해주세요.'}
                                        input={joinInfo.mb_email_auth}
                                        setInput={inputController}
                                        isValidShow={joinInfo.mb_email_auth !== '' && inputCheck.mb_email_auth_msg !== ''}
                                        isValid={inputCheck.mb_email_auth}
                                        validText={inputCheck.mb_email_auth_msg}
                                        btnLabel={'확인'}
                                        inputStyle={'col-9 pr-0'}
                                        btnStyle={'col-3 pl-0'}
                                        btnLabelStyle={'fs_16'}
                                        keyName={'mb_email_auth'}
                                        btnAction={authEmailHandler}
                                    />
                                </>
                            }

                            
                            <MarginY mt={2} />
                            <CustomInput 
                                title={'이름'}
                                placeHolder={'이름을 입력해주세요.'}
                                input={joinInfo.mb_name}
                                setInput={inputController}
                                isValidShow={false}
                                // isValid={inputCheck.mb_id}
                                // validText={inputCheck.mb_id_msg}
                                keyName={'mb_name'}
                            />
                            <MarginY mt={1} />
                            
                            <div className="ip_wr">
                                <div className="ip_tit">
                                    <h5>성별</h5>
                                </div>
                                <div className="input-group-prepend">
                                    <div className="checks mr-5">
                                        <input 
                                            id="male"
                                            name="mb_sex" 
                                            checked={joinInfo.mb_sex === '남'} 
                                            type="radio" 
                                            aria-label="male" 
                                            value={'남'}
                                            onChange={(e)=>{genderController(e.target.value)}} 
                                        />
                                        <label htmlFor="male">남자</label>
                                    </div>
                                    <div className="checks">
                                        <input 
                                            id="female" 
                                            name="mb_sex" 
                                            checked={joinInfo.mb_sex === '여'} 
                                            type="radio" 
                                            aria-label="female" 
                                            value={'여'}
                                            onChange={(e)=>{genderController(e.target.value)}} 
                                        />
                                        <label htmlFor="female">여자</label>
                                    </div>
                                </div>
                            </div>
                            <MarginY mt={3} />
                            <CustomInput 
                                title={'생년월일'}
                                placeHolder={'ex) 19970804'}
                                input={joinInfo.mb_birth}
                                setInput={inputController}
                                isValidShow={false}
                                isValid={inputCheck.mb_birth}
                                validText={inputCheck.birth_msg}
                                keyName={'mb_birth'}
                            />
                            <CustomInputBtn 
                                title={'핸드폰번호'}
                                placeHolder={'하이폰(-)을 제외하고 입력해주세요.'}
                                input={joinInfo.mb_phone}
                                setInput={inputController}
                                isValidShow={joinInfo.mb_phone !== ''}
                                isValid={inputCheck.mb_phone}
                                validText={inputCheck.mb_phone_msg}
                                keyName={'mb_phone'}
                                btnLabel={'인증번호 받기'}
                                inputStyle={'col-9 pr-0'}
                                btnStyle={'col-3 pl-0'}
                                btnLabelStyle={'fs_16'}
                                btnAction={sendPhoneCodeHandler}
                                isReadOnly={inputCheck.mb_phone && inputCheck.authCode}
                            />
                            <MarginY mt={1} />
                            {isSendPhoneAuth &&
                                <CustomInputBtn 
                                    placeHolder={'인증번호를 입력해주세요'}
                                    input={joinInfo.authCode}
                                    setInput={inputController}
                                    isValidShow={joinInfo.authCode !== ''}
                                    isValid={inputCheck.authCode}
                                    validText={inputCheck.authCode_msg}
                                    keyName={'authCode'}
                                    btnLabel={'확인'}
                                    inputStyle={'col-9 pr-0'}
                                    btnStyle={'col-3 pl-0'}
                                    btnLabelStyle={'fs_16'}
                                    btnAction={authPhoneCodeHandler}
                                />
                            }
                            <div className="ip_wr mt-5 agree mb-3 flex-wrap ">
                                <div className="input-group w-100  mb-3">
                                    <div className="input-group-prepend ">
                                        <div className="checks">
                                            <input 
                                                id="agree1" 
                                                name="agree" 
                                                checked={joinInfo.agree1} 
                                                value={'Y'} 
                                                type="checkbox" 
                                                aria-label="ck1 " 
                                                data-toggle="modal" 
                                                data-target="#terms_modal" 
                                                onChange={(e)=>{checkBoxController('agree1')}} 
                                            />
                                            <label htmlFor="agree1">서비스 이용약관동의</label>
                                        </div>
                                        <span onClick={()=>{
                                            setAgreeModal({
                                                title:'서비스 이용약관',
                                                show:true,
                                                type:'1'
                                            })
                                        }}>보기</span>
                                    </div>
                                </div>
                                <div className="input-group w-100">
                                    <div className="input-group-prepend ">
                                        <div className="checks">
                                            <input 
                                                id="agree2" 
                                                name="agree" 
                                                checked={joinInfo.agree2} 
                                                value={'Y'} 
                                                type="checkbox" 
                                                aria-label="ck2 " 
                                                data-toggle="modal" 
                                                data-target="#terms_modal2" 
                                                onChange={(e)=>{checkBoxController('agree2')}}
                                            />
                                            <label htmlFor="agree2">개인정보 수집 및 이용 동의</label>
                                        </div>
                                        <span onClick={()=>{
                                            setAgreeModal({
                                                title:'개인정보처리방침',
                                                show:true,
                                                type:'2'
                                            })
                                        }}>보기</span>
                                    </div>
                                </div>
                            </div>
                            <MarginY mt={5} />
                            <CustomButton 
                                btnLabel='회원가입'
                                color='green'
                                type='lg'
                                labelStyle='fw_700'
                                clickEvent={()=>{handleNormalJoin()}}
                            />
                        </form>
                        
                    </div>
                </section>
            </div>
        </div>
    );
}

export default NormalJoin;
