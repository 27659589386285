import React from 'react';
import { useNavigate } from "react-router";
import Loading from "../../../components/form/Loading";
import QueryString from "qs";
import axios from "axios";
import AlertModal from '../../../modal/AlertModal';
import { usePostMutation } from '../../../inc/axiosQuery';
import { useAppDispatch } from '../../../store/config';
import { setAutoLogin, setUserInfo } from '../../../store/slices/UserInfoSlice';

const REDIRECT_URI =
  "https://tobyfrom.com/kakaoAuth?type=kakao";

export const KAKAO_AUTH_URL = 
  `https://kauth.kakao.com/oauth/authorize?client_id=${process.env.REACT_APP_KAKAO_REST_API_KEY}&redirect_uri=${REDIRECT_URI}&response_type=code`;

export const KakaoLoginAuth = () => {

  const dispatch = useAppDispatch();
  const navigator = useNavigate();
  const code = new URL(window.location.href).searchParams.get("code");
  const kakaoLoginMutation = usePostMutation('accessSnsLogin','user/sns_login.php');

  const initialAlertModal = {
    alert:false,
    msg :'',
    type :'',
  }
  const [alertModal, setAlertModal] = React.useState(()=>initialAlertModal);

  const alertModalOn = (msg:string) => {
    setAlertModal({
      alert:true,
      msg:msg,
      type:'',
    })
  }

  

  const getToken = async () => { //토큰 가져오기 및 설정
    const payload = QueryString.stringify({
      grant_type: "authorization_code",
      client_id: process.env.REACT_APP_KAKAO_REST_API_KEY,
      redirect_uri: REDIRECT_URI,
      code: code,
      client_secret: process.env.REACT_APP_KAKAO_CLIENT_SCRET_KEY,
    });
    try {
      // access token 가져오기
      await axios.post(
        "https://kauth.kakao.com/oauth/token",
        payload
      )
      .then(async res => {
        window.Kakao.Auth.setAccessToken(res.data.access_token);

        let data = await window.Kakao.API.request({
          url: "/v2/user/me",
        });
        if(data){
          const {data : kData, result, msg} = await kakaoLoginMutation.mutateAsync({
            mb_id : data.id,
            mb_reg_type : 'K',
          })

          console.log(result, kData,msg);

          if(result){
            await dispatch(setAutoLogin(true));
            await dispatch(setUserInfo(kData));
          }
          else{
            navigator('/normalJoin?type=kakao',{replace:true});
          }
        }
      })
      .catch(err => {
        alertModalOn(`카카오 로그인에 실패하였습니다.. ${err}`);
      });
      
      // Kakao Javascript SDK 초기화
      // window.Kakao.init(REST_API_KEY);
      // access token 설정
      
    } catch (err) {
      alertModalOn(`카카오 로그인에 실패하였습니다.. ${err}`)
    }
  };
  React.useEffect(() => {
    getToken();
  }, []);
  return (
    <AlertModal 
      alertAction={()=>{navigator('/nomalJoinSel',{replace:true})}}
      btnLabel='확인'
      modalOff={()=>{setAlertModal(()=>initialAlertModal)}}
      msg={alertModal.msg}
      show={alertModal.alert}
      type={alertModal.type}
    />
  );
  
  return (
    <>
      <Loading />
    </>
  )
}