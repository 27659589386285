import React from 'react';
import { Accordion } from 'react-bootstrap';
import Loading from '../../components/form/Loading';
import PageNation from '../../components/form/PageNation';
import { FaqListType } from './ServiceType';



export default function FaqList({page,setPage,totalItem,faqList,isLoading}:FaqListType) {

    const faqKategory = ['견적요청','견적수령','입금방법','수수료 관련','기타'];

    const [selItem, setSelItem] = React.useState<string>('');



    const selItemHanlder = (index:string) => {
        if(selItem === index){
            setSelItem('');
        }
        else{
            setSelItem(index);
        }
    }


    return(
        <>
            {isLoading ? 
                <Loading />
            :
                <Accordion defaultActiveKey={selItem}>
                    <div className="accordion acc_style_1" id="accordion_faq" >
                        {faqList.length > 0 && faqList.map((item,index) => {
                        return(
                            <div className="card" onClick={()=>{selItemHanlder(String(index))}} key={index}>
                                <div className="card-header">
                                    <button className="btn btn-block text-left accordion_tit" type="button" data-toggle="collapse" data-target="#collapse1" aria-expanded="false" aria-controls="collapse1">                                
                                        <div className="d-flex line_h1_4">
                                            <strong className="fs_22 fw_700 mr-4">Q.</strong>
                                            <p>
                                                <span className="text-primary mr-2">[{faqKategory[Number(item.content_type)-1]}]</span>
                                                <span>{item.content_q}</span>
                                            </p>                                
                                        </div>
                                        <div className="btn_accordion">
                                            <img src="/assets/img/arr_accodion.png" />
                                        </div>
                                    </button>
                                </div>
                                {/* <div id="collapse1" className="collapse" data-parent="#accordion_faq">
                                */}
                                <Accordion.Collapse eventKey={String(index)} in={selItem === String(index)}>
                                    <div className="card-body">
                                        <div className="d-flex">
                                            <strong className="fs_22 fw_700 mr-4 text-primary">A.</strong>
                                            <p className={'mt-2'}>                                    
                                                {item.content_a}
                                            </p>                                
                                        </div>                        
                                    </div>
                                </Accordion.Collapse>
                            </div>
                        ) 
                        })}
                    </div>
                    {faqList.length > 0 &&
                        <PageNation 
                            page={page}
                            setPage={setPage}
                            totalItem={totalItem}
                            limitItem={10}
                        />
                    }
                </Accordion>
            }
        </>
    )
}