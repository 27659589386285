import React from 'react';
import { RecProType } from './recType';

function RecStep4({recInfo, setRecInfo,recStep,setRecStep,setShow,inputCheck} : RecProType) {    

    const countSelPeople = (type:string, count:'up' | 'down') => {
        switch(type){
            case 'cnt_adult' : 
                if(recInfo.cnt_adult !== 0 || count !== 'down'){
                    setRecInfo({
                        ...recInfo,
                        [type] : count === 'up' ? recInfo.cnt_adult +1 : recInfo.cnt_adult -1 ,
                    })
                }
            break;
            case 'cnt_child' :
                if(recInfo.cnt_child !== 0 || count !== 'down'){
                    setRecInfo({
                        ...recInfo,
                        [type] : count === 'up' ? recInfo.cnt_child +1 : recInfo.cnt_child -1 ,
                    })
                }
            break;
            case 'cnt_infant' :
                if(recInfo.cnt_infant || count !== 'down'){
                    setRecInfo({
                        ...recInfo,
                        [type] : count === 'up' ? recInfo.cnt_infant +1 : recInfo.cnt_infant -1 ,
                    })
                }
            break;
        }
    }

    const countInputHandler = (type:string, count:string) => {
        // if(count > 0){
            if(!isNaN(Number(count))){
                setRecInfo({
                    ...recInfo,
                    [type] : Number(count),
                })
            }
        // }
    }
    

    return (
        <div>
            <div className="modal-header justify-content-center">
                <h5 className="modal-title">최소 인원수를 선택해주세요!</h5>
            </div>
            <div className="modal-body">
            <ul className="modal_tab">
                    <li><a onClick={()=>{setRecStep(1)}}>날짜 선택<span className="fc_red"> *</span></a></li>
                    <li><a onClick={()=>{if(inputCheck?.date)setRecStep(3)}}>지역 선택</a></li>
                    <li className="on"><a onClick={()=>{if(inputCheck?.date)setRecStep(4)}}>인원수 선택<span className="fc_red"> *</span></a></li>
                    <li><a onClick={()=>{if(inputCheck?.date && inputCheck?.people)setRecStep(5)}}>완료</a></li>
                </ul>
                <p className='mb-5'><span className="fc_red">* </span>성인 / 아동 / 유아 중 최소 1명 이상 선택해주셔야합니다.</p>
                <ul>
                    <li className="d_sp_center mb-3">
                        <p>성인</p>
                        <div className="count">
                            <a type="button" onClick={()=>{countSelPeople('cnt_adult','down')}}><img src="/assets/img/ic_minus.png" alt="빼기" /></a>
                            <div style={{flex:1,textAlign:'center',alignItems:'center',justifyContent:'center'}}>
                                <input type="text" className='count-input' value={Number(recInfo.cnt_adult)/1} onChange={(e)=>{countInputHandler('cnt_adult',e.target.value)}}/>
                            </div>
                            <a type="button" onClick={()=>{countSelPeople('cnt_adult','up')}}><img src="/assets/img/ic_plus.png" alt="더하기" /></a>
                        </div>
                    </li>
                    <li className="d_sp_center mb-3">
                        <div>
                            <p>아동</p>
                            <p className='fs_13 mt-2'>- 만 12세미만</p>
                        </div>
                        <div className="count">
                            <div style={{flex:1}}>
                                <a type="button" onClick={()=>{countSelPeople('cnt_child','down')}}><img src="/assets/img/ic_minus.png" alt="빼기" /></a>
                            </div>
                            {/* <p>{recInfo.cnt_child}</p> */}
                            <div style={{flex:1,textAlign:'center',alignItems:'center',justifyContent:'center'}}>
                                <input type="text" className='count-input' value={Number(recInfo.cnt_child)/1} onChange={(e)=>{countInputHandler('cnt_child',e.target.value)}}/>
                            </div>
                            <div style={{flex:1}}>
                                <a type="button" onClick={()=>{countSelPeople('cnt_child','up')}}><img src="/assets/img/ic_plus.png" alt="더하기" /></a>
                            </div>
                        </div>
                    </li>
                    <li className="d_sp_center ">
                        <div>
                            <p>유아</p>
                            <p className="fs_13 mt-2">- 만 2세 미만</p>
                        </div> 
                        <div className="count">
                            <a type="button" onClick={()=>{countSelPeople('cnt_infant','down')}}><img src="/assets/img/ic_minus.png" alt="빼기" /></a>
                            <div style={{flex:1,textAlign:'center',alignItems:'center',justifyContent:'center'}}>
                                <input type="text" className='count-input' value={Number(recInfo.cnt_infant)/1} onChange={(e)=>{countInputHandler('cnt_infant',e.target.value)}}/>
                            </div>
                            <a type="button" onClick={()=>{countSelPeople('cnt_infant','up')}}><img src="/assets/img/ic_plus.png" alt="더하기" /></a>
                        </div>
                    </li>
                </ul>
            </div>
            <div className="modal-footer">
                <div className="d-flex w-100">
                    <button type="button" className="btn btn-lg btn-gray btn-block mb-0" onClick={()=>{setRecStep(recStep-1)}}>이전</button>
                    {inputCheck?.people ?
                    <button type="button" className="btn btn-lg btn-primary btn-block ml-4 mt-0" onClick={()=>{setRecStep(recStep+1)}}>다음</button>
                    :
                    <button type="button" className="btn btn-lg btn-gray btn-block ml-4 mt-0" onClick={()=>{}}>다음</button>
                    }
                </div>
            </div>
        </div>
    )
}

export default RecStep4;
