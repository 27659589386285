import classNames from 'classnames';
import React from 'react';
import CustomInput from '../../components/form/CustomInput';
import CustomSelectBox from '../../components/form/CustomSelectBox';
import { usePostMutation } from '../../inc/axiosQuery';
import AlertModal from '../../modal/AlertModal';
import MyEstListModal from '../../modal/MyEstListModal';
import { useAppSelector } from '../../store/config';

interface QnaWriteType {
    setSelTab : (tab:string) => void;
}

export default function QnaWrite({setSelTab}:QnaWriteType){

    const {jwt_token} = useAppSelector(state => state.userInfo);
    const qnaWirteMutatioin = usePostMutation('qnaWrite','board/q_1_1_write.php');

    const [writeInfo, setWriteInfo] = React.useState({
        jwt_token : jwt_token,
        title:'',
        q_file : {},
        q_file_name : '',
        content_q:'',
        q_type : '',
        req_idx : '',
    })
    const [selEstIdx, setSelEstIdx] = React.useState({ //선택한 견적서 정보
        label : '',
        value : '',
    });
    const [selEstModal, setSelEstModal] = React.useState(false);

    const initialAlert = {
        show:false,
        msg:'',
        type:'',
    }
    const [alertModal, setAlertModal] = React.useState(()=>initialAlert);

    const alertModalOn = (msg:string,type:string) => {
        setAlertModal({
            show:true,
            msg:msg,
            type:type,
        })
    }

    const alertModalOff = () => {
        setAlertModal(initialAlert);
    }
    const alertAction = () => {
        if(alertModal.type === 'write_success'){
            setSelTab('4');
        }
        setAlertModal(initialAlert);
    }

    const qTypeList = [
        {
            value : '1',
            label : '견적요청'
        },
        {
            value : '2',
            label : '견적수령'
        },
        {
            value : '3',
            label : '입금방법'
        },
        {
            value : '4',
            label : '수수료 관련'
        },
        {
            value : '5',
            label : '기타',
        }
    ]
    
    const inputHandler = (text:string, key?:string) => {
        if(key){
            setWriteInfo({
                ...writeInfo,
                [key]:text,
            })
        }
    }

    const insertFileHanlder = (file: FileList | null) => { //통장사본 업로드
        if(file){
            setWriteInfo({
                ...writeInfo,
                q_file_name : file[0].name,
                q_file : file[0],
            })
        }
    }

    const writeQnaHandler = async () =>{
        if(writeInfo.q_type === ''){
            alertModalOn('질문유형을 선택해주세요.', '');
        }
        else if(writeInfo.title === ''){
            alertModalOn('문의 제목을 입력해주세요.','');
        }
        else if(writeInfo.content_q === ''){
            alertModalOn('문의 내용을 입력해주세요.','');
        }
        else{
            const params = {
                ...writeInfo,
                req_idx : selEstIdx.value,
            }

            const {result, msg} = await qnaWirteMutatioin.mutateAsync(params);

            if(result){
                alertModalOn(msg,'write_success');
            }
            else{
                alertModalOn(msg,'');
            }
        }

    }

    return(
        <div className="col-12">
            <form className="form-row">
                <CustomSelectBox 
                    title={'질문유형'}
                    optionList={qTypeList}
                    selValue={writeInfo.q_type}
                    setSelValue={inputHandler}
                    style={{flex:1}}
                    keyName={'q_type'}
                />
                <div className='ip_wr' style={{flex:1, marginLeft:20}}>
                    <div className="ip_tit d-flex align-items-center justify-content-between">
                        <h5>관련 견적서</h5>
                    </div>
                    <div className="form-control form-control-lg d-flex align-items-center" style={{cursor:'pointer'}} onClick={()=>{setSelEstModal(true)}}>
                        <p>{selEstIdx.value === '' ? '선택하세요.' : selEstIdx.label}</p>
                    </div>   
                </div> 
                <CustomInput 
                    title={'제목'}
                    input={writeInfo.title}
                    setInput={inputHandler}
                    isValidShow={false}
                    placeHolder={'문의 제목을 입력해주세요.'}
                    keyName={'title'}
                    style={'col-12 mt-5'}                    
                />
                <div className="ip_wr mt-3 col-12">
                    <div className="ip_tit d-flex align-items-center justify-content-between">
                        <h5>내용</h5>
                    </div>
                    <textarea value={writeInfo.content_q} className="form-control form-control-lg" placeholder="내용을 기재 해주세요." rows={18} onChange={(e)=>{setWriteInfo({...writeInfo,content_q:e.target.value})}}></textarea>
                    <div className="invalid-feedback"></div>
                </div>
                <div className="col-12 mt-5">
                    <input id="qna-file" type="file" className='d-none' onChange={(e)=>{insertFileHanlder(e.target.files)}}/>
                    <label htmlFor="qna-file">
                        <div className={classNames('btn btn_black btn_attachment d-flex align-items-center',{attached:writeInfo.q_file_name !== ''})}>
                            파일 첨부하기 (2MB용량 이하)
                        </div>
                    </label>
                    {/* <button className="btn btn_black btn_attachment attached">
                        파일 첨부하기 (2MB용량 이하)
                    </button> */}
                    {writeInfo.q_file_name !== '' &&
                        <p className="mt-4">
                            * {writeInfo.q_file_name}
                        </p>
                    }
                </div>
                <div className="col-12 d-flex justify-content-center">
                    <button type="button" className="btn btn-lg btn-primary w_380 fw_700" onClick={writeQnaHandler}>
                        보내기
                    </button>
                </div>
            </form>
            <AlertModal 
                show={alertModal.show}
                msg={alertModal.msg}
                type={alertModal.type}
                alertAction={alertAction}
                btnLabel={'확인'}
                modalOff={alertModalOff}
            />
            <MyEstListModal 
                show={selEstModal}
                setShow={setSelEstModal}
                setSelEst={setSelEstIdx}
            />
        </div>
    )
}