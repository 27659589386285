import React from 'react';

import LoadingImg from '../assets/Loading.svg';

export default function LoadingModal(){

    const backdropStyle = {
        width:'100vw',
        height:'100vh',
        zIndex:999999,
        backgroundColor:'rgba(0,0,0,0.5)',
        top:0,
        left:0,
    }

    const spinerStyle = {
        width:120,
        height:120,
    }

    React.useEffect(() => {
        document.body.classList.add('stop-scroll');
        return ()=>{
            document.body.classList.remove('stop-scroll');
        }
    }, [])
      

    return(
        <div className={'position-fixed'} style={backdropStyle}>
            <div className={'d-flex w-100 align-items-center justify-content-center'} style={{height:'100%'}}>
                <img src={LoadingImg} style={spinerStyle}/>
            </div>
        </div>
    )
}