import React from 'react'

import {
  CardContainer,
  CardHeader,
  DetailBtn,
  ReqBtn,
} from '../../inc/ListStyle'
import { useAccordionButton } from 'react-bootstrap'

import { useAppDispatch, useAppSelector } from '../../store/config'

import ReqDetailType2 from './ReqDetailType2'
import ReqDetailType3 from './ReqDetailType3'
import ReqDetailType4 from './ReqDetailType4'
import ReqDetailType5 from './ReqDetailType5'

import SpecifiModal from '../../modal/trvReq/SpecifiModal'
import RateModal from '../../modal/RateModal'
import ConfirmModal from '../../modal/ConfirmModal'
import AlertModal from '../../modal/AlertModal'
import LandReqEstModal from '../../modal/landReq/LandReqEstModal'

import { ReqListPropType } from '../../inc/type/reqType'
import { usePostMutation } from '../../inc/axiosQuery'
import { setReload } from '../../store/slices/ReloadSlice'
import EmployeeModal from '../../modal/EmployeeModal'
import { setLoading } from '../../store/slices/LoadingSlice'

interface CustomToggleType {
  children: string
  eventKey: any
  index: number
  setSelListIdx?: (idx: string) => void
  selListIdx?: string
}

function ReqList({ //견적 리스트
  index,
  selListIdx,
  setSelListIdx,
  reqItem,
  setSelTab,
}: ReqListPropType) {
  const userInfo = useAppSelector((state) => state.userInfo)
  const dispatch = useAppDispatch()
  const reqContMutation = usePostMutation('reqCont', 'doc/request_contract.php')
  const landReqCancleMutation = usePostMutation(
    'landReqCancle',
    'doc/request_land_cancel.php'
  )

  const [specifiToggle, setSpecifiToggle] = React.useState(false) //계약하기 모달
  const [rateToggle, setRateToggle] = React.useState(false) //평가하기 모달
  const [alertModal, setAlertModal] = React.useState({
    alert: false,
    msg: '',
    type: '',
  })
  const [confirmModal, setConfirmModal] = React.useState({
    confirm: false,
    msg: '',
    type: '',
  })
  const [reqEstModal, setReqEstModal] = React.useState(false) //랜드사 - 견적신청 모달
  const [employeeModal, setEmployeeModal] = React.useState(false)
  const [selMbIdx, setSelMbIdx] = React.useState('')

  const [time, setTime] = React.useState('')
  const [returnTime, setReturnTime] = React.useState('')
  const [isTimerEnd, setIsTimerEnd] = React.useState(false)



  React.useEffect(() => {
    if (isTimerEnd) {
    }
  }, [isTimerEnd])

  React.useEffect(() => {
    setTime(reqItem.limit_e_date)
  }, [reqItem.limit_e_date])

  function CustomToggle({
    children,
    eventKey,
    index,
    setSelListIdx,
    selListIdx,
  }: CustomToggleType) {
    const decoratedOnClick = useAccordionButton(eventKey, () => {
      if (setSelListIdx) {
        if (eventKey === selListIdx) {
          setSelListIdx('')
        } else {
          setSelListIdx(eventKey)
        }
      }
    })

    return (
      <DetailBtn className="dt_btn" onClick={decoratedOnClick}>
        {selListIdx !== '' && String(index) === selListIdx
          ? '접기'
          : '상세보기'}
      </DetailBtn>
    )
  }

  const alertModalOff = () => {
    setAlertModal({
      alert: false,
      msg: '',
      type: '',
    })
  }
  const alertAction = () => {
    if (
      alertModal.type === 'req_con_success' ||
      alertModal.type === 'lnad_req_cancle_success'
    ) {
      dispatch(setReload(true))
    }
    alertModalOff()
  }

  const confirmModalOff = () => {
    setConfirmModal({
      confirm: false,
      msg: '',
      type: '',
    })
  }

  const confirmSpecifi = () => {
    //계약 컨펌
    setConfirmModal({
      confirm: true,
      msg: '계약을 하시겠습니까?확인 시에는 취소가 안되며, 24시간 이내에 입금이 안 될 경우 자동적으로 취소되며 패널티가 부여됩니다.',
      type: 'trv_req_con',
    })
  }

  const accessSpecifi = async () => {
    //계약하기
    dispatch(setLoading(true));
    const params = {
      jwt_token: userInfo.jwt_token,
      req_idx: reqItem.req_idx,
    }
    const { result, msg } = await reqContMutation.mutateAsync(params)

    if (result) {
      setAlertModal({
        alert: true,
        msg: `계약이 완료되었습니다! \n 24시간 이내에 지정된 계좌번호로 입금 부탁드립니다.`,
        type: 'req_con_success',
      })
    } else {
      setAlertModal({
        alert: true,
        msg: msg,
        type: '',
      })
    }
    dispatch(setLoading(false));
  }

  const cancleLandReq = async () => {
    //랜드사 견적 취소
    if (reqItem.est_sct_item) {
      const params = {
        jwt_token: userInfo.jwt_token,
        est_idx: reqItem.est_sct_item.est_idx,
      }
      const { result, msg } = await landReqCancleMutation.mutateAsync(params)

      if (result) {
        setAlertModal({
          alert: true,
          msg: msg,
          type: 'lnad_req_cancle_success',
        })
      } else {
        setAlertModal({
          alert: true,
          msg: msg,
          type: '',
        })
      }
    }
  }

  const cancleReq = () => {
    setAlertModal({
      alert: true,
      msg: '계약 취소가 완료되었습니다.',
      type: '',
    })
  }

  const confirmAction = () => {
    if (confirmModal.type == 'trv_req_con') {
      accessSpecifi()
    } else if (confirmModal.type === 'land_req_cancle') {
      //랜드사 견적 취소
      cancleLandReq()
    }
  }

  React.useEffect(() => {
    if (!reqItem.limit_e_date || reqItem.limit_e_date === '마감') return // 로드되지 않았으면 종료

    const [day, timeStr] = time.split('-')

    if (!timeStr) return
    const [hour, minute, second] = timeStr.split(':').map(Number)

      const interval = setInterval(() => {
      let totalSeconds = (Number(day) * 24 + hour) * 3600 + minute * 60 + second - 1

      if(totalSeconds === 0){
        dispatch(setReload(true));
        return;
      }

      const newDay = Math.floor(totalSeconds / 86400)
      const newHour = Math.floor(totalSeconds / 3600) % 24
      const returnHour = newHour + newDay * 24
      const newMinute = Math.floor(totalSeconds / 60) % 60
      const newSecond = totalSeconds % 60

      const newTime = `${newDay.toString().padStart(2, '0')}-${newHour
        .toString()
        .padStart(2, '0')}:${newMinute.toString().padStart(2, '0')}:${newSecond
        .toString()
        .padStart(2, '0')}`
      const returnNewTime = `${returnHour
        .toString()
        .padStart(2, '0')}:${newMinute.toString().padStart(2, '0')}:${newSecond
        .toString()
        .padStart(2, '0')}`
      setTime(newTime)
      setReturnTime(returnNewTime)
    }, 1000)

    return () => clearInterval(interval)
  }, [time, reqItem.limit_e_date])

  React.useEffect(()=>{
    console.log(reqItem);
  },[])

  return (
    <div className="card">
      <CardContainer>
        <CardHeader>
          <div className="left">
            <p className="line_text est_tit">{reqItem.req_name}</p>
            <ul className="info">
              <li>
                <span>지역</span>
                <p>
                  {reqItem.loc_txt}
                  {reqItem.loc_1_txt !== '' && ` / ${reqItem.loc_1_txt}`}
                </p>
              </li>
              <li>
                <span>여행기간</span>
                <p>
                  {reqItem.start_date_txt} ~ {reqItem.end_date_txt}
                </p>
              </li>
              <li>
                <span>요청날짜</span>
                <p>{reqItem.wr_datetime}</p>
              </li>
            </ul>
          </div>
          <div className="rg">
            {(reqItem.req_review_cnt !== 1 && (reqItem.review_chk === 'N')) &&
              <>
                {reqItem.limit_e_date !== '마감' ? (
                  <p className="timeout">
                    <span>남은시간</span>
                    {returnTime}
                  </p>
                ) : (
                  // <p className="timeout"><span>남은시간</span>{UseTimer(reqItem.limit_e_date,()=>{})}</p>
                  <p className="timeout pr-5">마감</p>
                )}
              </>
            }
            <div className="d-flex justify-content-end">
              {/* <DetailBtn>상세보기</DetailBtn> */}
              {userInfo.jwt_token !== '' &&
                <CustomToggle
                  index={Number(index)}
                  selListIdx={selListIdx}
                  setSelListIdx={setSelListIdx}
                  eventKey={String(index)}
                >
                  상세보기
                </CustomToggle>
              }
              {reqItem.req_status === '0' &&
              reqItem.req_me_chk === 'N' &&
              userInfo.mb_type === '2' ? (
                <ReqBtn
                  className={`lk_btn type01 pointer-click`}
                  onClick={() => {
                    if (reqItem.limit_e_date === '마감') { //랜드사 - 견적 신청하기
                      setAlertModal({
                        alert: true,
                        msg: '마감된 견적입니다.',
                        type: '',
                      })
                      return
                    }
                    setReqEstModal(true)
                  }}
                >
                  신청하기
                </ReqBtn>
              ) : reqItem.req_review_cnt === 1 ? (
                <ReqBtn className={`lk_btn type01 none-click`}>
                  {userInfo.mb_type === '1' ? '구매확정완료' : '평가완료'}
                </ReqBtn>
              ) : reqItem.req_status === '2' && reqItem.pay_status === '2' ? (
                <ReqBtn className={`lk_btn type05 pointer-click`}>
                  {reqItem.pay_status_txt}
                </ReqBtn>
              ) : reqItem.req_status === '2' && reqItem.pay_status === '1' ? ( 
                <ReqBtn className={`lk_btn type06`}>
                  {reqItem.pay_status_txt}
                </ReqBtn>
              ) : reqItem.review_chk === 'H' ? (
                <ReqBtn className={'lk_btn type04'}>
                  평가보류
                </ReqBtn>
              )
              : userInfo.mb_type === '1' && // 여행사 - 할인금액 넣었을때
                reqItem.price_dis_chk === 'Y' &&
                reqItem.req_status === '2' ? (
                <ReqBtn
                  className={`lk_btn type01 pointer-click`}
                  onClick={() => {
                    if (reqItem.limit_e_date === '마감') {
                      setAlertModal({
                        alert: true,
                        msg: '마감된 견적입니다.',
                        type: '',
                      })
                      return
                    }
                    setSpecifiToggle(true)
                  }}
                >
                  계약하기
                </ReqBtn>
              ) : 
                reqItem.req_status_txt === '계약단계' ?
                <ReqBtn
                  className={`lk_btn type03 , 'pointer-click'}`
                  }
                >
                  {reqItem.pay_status_txt}
                </ReqBtn>
                :
              (
                <ReqBtn
                  className={`
                    lk_btn type0${Number(reqItem.req_status) + 2} 
                  ${(userInfo.mb_type !== '' && 
                  reqItem.req_status !== '0'
                  && reqItem.req_status !== '1' &&  (reqItem.req_me_chk && reqItem.req_me_chk !== 'Y')) 
                  && reqItem.req_status_txt !== '견적선택완료' && reqItem.req_status_txt !== '계약단계' && reqItem.req_status_txt !== '계약완료'
                  &&
                    'pointer-click'}`
                  }
                >
                  {reqItem.req_status_txt}
                </ReqBtn>
              )}
            </div>
          </div>
        </CardHeader>
        {reqItem.req_status === '0' ? ( //견적 선택전
          <ReqDetailType2
            index={String(index)}
            cancleReq={cancleReq}
            reqItem={reqItem}
            selListIdx={selListIdx}
            setSelTab={setSelTab}
            setConfirmModal={setConfirmModal}
            setSelMbIdx={setSelMbIdx}
            setEmployeeModal={setEmployeeModal}
          />
        ) : reqItem.req_status === '1' ? ( //견적 선택중
          <ReqDetailType3
            index={String(index)}
            cancleReq={cancleReq}
            reqItem={reqItem}
            selListIdx={selListIdx}
            setSelTab={setSelTab}
            setSelMbIdx={setSelMbIdx}
            setEmployeeModal={setEmployeeModal}
          />
        ) : reqItem.req_status === '4' || //계약완료
          (reqItem.req_status === '2' && //입금대기중상태
            reqItem.pay_status_txt == '입금대기중') ? (
          <ReqDetailType5
            index={String(index)}
            reqItem={reqItem}
            selListIdx={selListIdx}
            setSelTab={setSelTab}
            setSelMbIdx={setSelMbIdx}
            setEmployeeModal={setEmployeeModal}
            setRateToggle={setRateToggle}
          />
        ) : (
          //견적선택완료 ,
          <ReqDetailType4
            index={String(index)}
            reqItem={reqItem}
            selListIdx={selListIdx}
            setSelTab={setSelTab}
            setSelMbIdx={setSelMbIdx}
            setEmployeeModal={setEmployeeModal}
          />
        )}
      </CardContainer>

      <RateModal 
        show={rateToggle} 
        setShow={setRateToggle}
        reqItem={reqItem} 
      />
      {selMbIdx !== '' && (
        <EmployeeModal
          show={employeeModal}
          setShow={setEmployeeModal}
          mbIdx={selMbIdx}
        />
      )}
      <ConfirmModal
        show={confirmModal.confirm}
        modalOff={confirmModalOff}
        alertAction={confirmAction}
        btnLabel={'확인'}
        msg={confirmModal.msg}
      />
      <AlertModal
        show={alertModal.alert}
        modalOff={alertModalOff}
        msg={alertModal.msg}
        type={alertModal.type}
        btnLabel={'확인'}
        alertAction={alertAction}
      />
      {/* 랜드사 견적요청중-견적내기 */}
      <LandReqEstModal
        show={reqEstModal}
        setShow={setReqEstModal}
        reqItem={reqItem}
      />
      {/* 여행사 선택완료-계약하기 */}
      <SpecifiModal
        show={specifiToggle}
        setShow={setSpecifiToggle}
        confirmSpecifi={confirmSpecifi}
        reqItem={reqItem}
      />
    </div>
  )
}

export default ReqList
