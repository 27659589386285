import React from 'react';
import classNames from 'classnames';
import { Link, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../store/config';

function LandSelJoinType() {

    const {mb_type} = useAppSelector(state => state.userInfo);
    const navigator = useNavigate();


    React.useEffect(()=>{
        if(mb_type === '1'){
            navigator('/travleMain',{replace:true});
        }
        else if(mb_type === '2'){
            navigator('/landMain',{replace:true});
        }
    },[])


    return (
        <div className="container-fluid px-0">
            <div className="sub_pg">
                <section className="container-lg mx-auto">
                    <div className="lg_selctbx">
                        <div className="tit_wr">
                            <h3 className="tit_h3 tit_point">랜드사&가이드 회원가입</h3>
                        </div>
                        <div className="sel_wr">
                            <Link to="/landJoin" className="join_lk ">
                                <div className="top">
                                    <h4 className="tit_h4">랜드사(법인/개인사업자)</h4>
                                    <figure>
                                        <img src="/assets/img/ic_join01.png" alt="여행사법인 회원가입" />
                                    </figure>
                                </div>
                                <div className="bom">
                                    <p className="fs_20 fw_700">회원가입</p>
                                </div>
                            </Link>
                            <Link to="/guideJoin" className="join_lk">
                                <div className="top">
                                    <h4 className="tit_h4">가이드(개인)</h4>
                                    <figure>
                                        <img src="/assets/img/ic_join02.png" alt="개인 회원가입" />
                                    </figure>
                                </div>
                                <div className="bom">
                                    <p className="fs_20 fw_700">회원가입</p>
                                </div>
                            </Link>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default LandSelJoinType;
