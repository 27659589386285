import React from 'react';
import { Accordion, Pagination } from 'react-bootstrap';
import Loading from '../../components/form/Loading';
import PageNation from '../../components/form/PageNation';
import ReqList from '../../components/list/ReqList';
import {fetchPostData, usePostQuery} from '../../inc/axiosQuery';
import { TravleReqListType } from '../../inc/type/reqType';
import { useAppSelector } from '../../store/config';

interface TravleListPropType {
    searchKey:string,
    setSearchKey:(search:string)=>void;
    getTabList:()=>void;
    selTab:string,
    setSelTab:(idx:string)=>void;
}

function TravleList({searchKey,setSearchKey,getTabList,selTab,setSelTab}:TravleListPropType) { 

    const {reload} = useAppSelector(state => state.reload);
    const {jwt_token} = useAppSelector(state => state.userInfo);

    const [selListIdx,setSelListIdx] = React.useState('');

    const [isLoading, setIsLoading] = React.useState(false);

    const [page, setPage] = React.useState(1);
    const [totalItem, setTotalItem] = React.useState(1);
    const [travleList, setTravleList] = React.useState<TravleReqListType[]>([]);


    const getTravleList = async() => {
        setIsLoading(true);
        const params ={
            limit:10,
            page:page,
            jwt_token:jwt_token,
            sch_txt : searchKey,
            req_status : selTab,
        }
        await fetchPostData(params,'doc/request_tvl_list.php')
        .then(res => {
            setTotalItem(res.data.tot_cnt);
            setTravleList([...res.data.req_list]);
            setIsLoading(false);
        })
    }

    React.useEffect(()=>{
        getTravleList();
        const selClear = async () => {
            await setSelListIdx('');
        }
        selClear();
    },[page,selTab])

    React.useEffect(()=>{
        if(reload){getTravleList(); setSelListIdx('');};
    },[reload])

    return (
        <>
            <div className="ip_wr search_input">
                <div className="form-row input_row">
                    <div className="col-9 pr-0 ">
                        <input type="search" className="form-control form-control-lg" value={searchKey} onChange={(e)=>{setSearchKey(e.target.value)}} placeholder="지역 또는 제목으로 검색하기" />
                    </div>
                    <div className="col-3 pl-0">
                        <button type="button" className="btn btn-lg btn-primary btn-block" onClick={()=>{getTravleList(); getTabList();}}>검색</button>
                    </div>
                </div>
            </div>
            {isLoading ?
                <Loading />
            :
                <>
                    <Accordion defaultActiveKey={selListIdx}>
                        <div className="accordion est_acc">
                        
                        {travleList.length > 0 ?
                                travleList.map((item,index) => {
                                    return(
                                        <ReqList 
                                            key={index} 
                                            setSelListIdx={setSelListIdx} 
                                            selListIdx={selListIdx} 
                                            index={index}
                                            reqItem={item}
                                            setSelTab={setSelTab}
                                            reload={getTravleList}
                                        />
                                    )
                                })
                                :
                                <div className="w-100 d-flex flex-row justify-content-center align-items-center m-5">
                                    <p>조회된 견적이 존재하지 않습니다.</p>
                                </div>
                            }
                        </div>
                    </Accordion>
                    {travleList.length > 0 &&
                        <PageNation 
                            page={page}
                            totalItem={totalItem}
                            limitItem={10}
                            setPage={setPage}
                        />
                    }
                </>
            }
        </>
    );
}

export default TravleList;
