import React from 'react';

import { format } from 'date-fns';
import { CaptionProps, useNavigation } from 'react-day-picker';


export default function CustomCaption(props: CaptionProps) { // yyyy년 mm월 표시 (달력 header)
    // console.log(props.displayMonth);
    const { goToMonth, nextMonth, previousMonth } = useNavigation();

    const captionStyle = {
        button : {
            backgroundColor : 'white',
            border:'none',
            width:'3rem',
            height:'3rem',
        },

    }

    return (
    <div className="d-flex flex-row justify-content-between align-items-center px-2">
        <button
            disabled={!previousMonth}
            onClick={() => previousMonth && goToMonth(previousMonth)}
            style={captionStyle.button}
        >
            <img src="/assets/img/ic_lf.png" alt="이전월" />
        </button>

        {/* {format(props.displayMonth, 'yyy MMM')} */}
        <span className="fs_18 fw_700">
            {props.displayMonth.getFullYear()}년 {props.displayMonth.getMonth()+1 < 10 ? `0${props.displayMonth.getMonth()+1}` : props.displayMonth.getMonth()+1}월
        </span>

        <button
            disabled={!nextMonth}
            onClick={() => nextMonth && goToMonth(nextMonth)}
            style={captionStyle.button}
        >
            <img src="/assets/img/ic_rg.png" alt="다음월" />
        </button>
    </div>
    );
}
