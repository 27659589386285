import React from 'react';

import classNames from 'classnames';
import AlertModal from '../../modal/AlertModal';

interface CustomFileType {
    title? : string,
    input : string | number,
    setInput : (file:FileList | null) => void;
    placeHolder : string,
    btnAction? : () => void,
    btnLabel : string,
    inputStyle : string;
    btnStyle : string;
    btnLabelStyle? : string;
    fileId:string;
}

function CustomFile({
    title,
    input,
    setInput,
    placeHolder,
    btnLabel,
    btnAction,
    inputStyle,
    btnStyle,
    btnLabelStyle,
    fileId,
}:CustomFileType) {

    const [alertModal, setAlertModal] = React.useState({
        alert:false,
        msg : '',
    });


    const alertModalOff = () => {
        setAlertModal({
            alert:false,
            msg : '',
        })
    }
    const fileHandler = (e:any) => {

        const selectedFile = e.target.files[0];

        if (!selectedFile) {
          return;
        }
      
        const fileType = selectedFile.type;
        const fileName = selectedFile.name;
        const validImageTypes = ["image/png", "image/jpeg","image/jpg"];
        const validPdfTypes = ["application/pdf"];
        
        var maxSize = 100 * 1024 * 1024;
		var fileSize = selectedFile.size;

		if(fileSize > maxSize){
			setAlertModal({
                alert:true,
                msg:'첨부파일 사이즈는 100M 이내로 등록 가능합니다.',
            })
			return false;
		}


        if (validImageTypes.includes(fileType)) {
            setInput(e.target.files);
        } else if (validPdfTypes.includes(fileType)) {
            setInput(e.target.files);
        } else {
            setAlertModal({
                alert:true,
                msg:'이미지파일 및 PDF 파일만 업로드 가능합니다.',
            })
            return;
        }
    }

    return (
        <>
            <input id={fileId} type="file" className="d-none" onChange={(e)=>{fileHandler(e)}} accept='.pdf, .png, .jpg, .jpeg'/>
            <label htmlFor={fileId} className="w-100">
                <div className={classNames('ip_wr')}>
                    {title && 
                        <div className="ip_tit">
                            <h5>{title}</h5>
                        </div>
                    }
                    <div className="form-row input_row">
                        <div className={inputStyle}>
                            <div
                                className="form-control form-control-lg d-flex align-items-center"
                                style={{WebkitBorderTopRightRadius:0, borderBottomRightRadius:0}}
                            >
                                {input !== '' ?                             
                                    <p>{input}</p>
                                :
                                    <p className="file-placeholder">{placeHolder}</p>    
                                }
                            </div>
                        </div>
                        <div className={`${btnStyle}`}>
                            <div className={`btn ${btnLabelStyle} d-flex align-items-center justify-content-center btn-lg btn-primary btn-block `} onClick={()=>{if(btnAction)btnAction()}}>{btnLabel}</div>
                        </div>
                    </div>
                </div>
            </label>

            <AlertModal 
                show={alertModal.alert}
                msg={alertModal.msg}
                alertAction={()=>{}}
                btnLabel='확인'
                modalOff={alertModalOff}
                type=''
            />
        </>
    );
}

export default CustomFile;
