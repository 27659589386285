import classNames from 'classnames';
import React from 'react';
import { Accordion } from 'react-bootstrap';
import Loading from '../../components/form/Loading';
import PageNation from '../../components/form/PageNation';
import { usePostQuery } from '../../inc/axiosQuery';
import { downloadFile } from '../../inc/func';
import { useAppSelector } from '../../store/config';
import { QnaItemType } from './ServiceType';

export default function MyQnaList(){
    
    const {jwt_token} = useAppSelector(state => state.userInfo);

    const [selItem, setSelItem] = React.useState<string>('');

    const [page, setPage] = React.useState(1);
    const [totalItem, setTotalItem] = React.useState(1);

    const {data : qnaList, isLoading : qnaLoading} = usePostQuery('getQnaList',{
        jwt_token : jwt_token,
        page : page,
        limit:10,
    },'board/q_1_1_list.php')

    const selItemHanlder = (index:string) => {
        if(selItem === index){
            setSelItem('');
        }
        else{
            setSelItem(index);
        }
    }

    React.useEffect(()=>{
        if(qnaList){
            setTotalItem(qnaList.data.tot_cnt);
            console.log(qnaList.data.q_list);
        }
    },[qnaList, qnaLoading])

    return(
        <>
            {qnaLoading ? 
                <Loading />
            : 
                <>
                    <Accordion defaultActiveKey={selItem}>
                        <div className="accordion acc_style_1" id="accordion_faq" >
                            {qnaList && 
                            <div>
                                {qnaList.data.q_list.length > 0 ? qnaList.data.q_list.map((item:QnaItemType,index:Number) => {
                                return(
                                    <div className="card" onClick={()=>{selItemHanlder(String(index))}} key={String(index)}>
                                        <div className="card-header">
                                            <button className="btn btn-block text-left accordion_tit" type="button" data-toggle="collapse" data-target="#collapse1" aria-expanded="false" aria-controls="collapse1">                                
                                                <div className="d-flex line_h1_4">
                                                    <strong className="fs_22 fw_700 mr-4">Q.</strong>
                                                    <p>
                                                        <span className={classNames('mr-2',{'text-primary':item.qstatus === 'Y' , 'text_gray':item.qstatus === 'N'})}>[{item.qstatus_txt}]</span>
                                                        <span>{item.title}</span>
                                                    </p>                                
                                                </div>
                                                <div className="btn_accordion">
                                                    <img src="/assets/img/arr_accodion.png" />
                                                </div>
                                            </button>
                                        </div>
                                        <Accordion.Collapse eventKey={String(index)} in={selItem === String(index)}>
                                            <div className="card-body">
                                                <div className="d-flex">
                                                    <strong className="fs_22 fw_700 mr-4 text-primary">Q.</strong>
                                                    <div className={'mt-2'}>
                                                        <p>                                    
                                                            {item.content_q}
                                                        </p>
                                                        {item.q_file !== '' &&
                                                            <p className="mt-3 fw_600">
                                                                <span>* 첨부파일 : </span>
                                                                <a onClick={()=>{downloadFile(item.q_file,item.q_file_name)}} className="underline">{item.q_file_name}</a>      
                                                            </p>
                                                        }
                                                    </div>                        
                                                </div>  
                                                <div className="d-flex mt-4">
                                                    <strong className="fs_22 fw_700 mr-4 text-primary">A.</strong>
                                                    <p className={classNames('mt-2',{'text_gray' : item.qstatus === 'N'})}>                                    
                                                        {item.qstatus === 'N' ? '답변대기 상태입니다.' : item.content_a }
                                                    </p>                                
                                                </div>                        
                                            </div>
                                        </Accordion.Collapse>
                                    </div>
                                ) 
                                })
                                :
                                <div className="text-center mt-5">
                                    <p>조회된 나의 질문이 없습니다.</p>
                                </div>
                            }
                            </div>
                        }
                        </div>
                    </Accordion>
                    <PageNation 
                        page={page}
                        setPage={setPage}
                        totalItem={totalItem}
                        limitItem={10}
                    />
                </>
            }
        </>
    )
}