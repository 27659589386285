import React from 'react';

import classNames from 'classnames';

interface CustomInputBtnType {
    title? : string,
    input : string | number,
    setInput : (text:string,key?:string) => void;
    type? : 'password' | 'number',
    placeHolder : string,
    isValidShow : Boolean,
    isValid? : Boolean,
    validText? : string,
    btnAction? : () => void,
    btnLabel : string,
    inputStyle : string;
    btnStyle : string;
    btnLabelStyle? : string;
    keyName? : string;
    isReadOnly? : boolean;
    isBtnHide? : boolean;
}

function CustomInputBtn({
    title,
    input,
    setInput,
    type,
    placeHolder,
    isValidShow,
    isValid,
    validText,
    btnLabel,
    btnAction,
    inputStyle,
    btnStyle,
    btnLabelStyle,
    keyName,
    isReadOnly,
    isBtnHide,
}:CustomInputBtnType) {

    const validTextStyle = {
        'ip_valid' : isValid,
        'ip_invalid' : !isValid,
    }

    const inputConStyle = {
        'input_row' : !isBtnHide
    }

    return (
        <div className={classNames('ip_wr',validTextStyle)}>
            {title && 
                <div className="ip_tit">
                    <h5>{title}</h5>
                </div>
            }
            <div className={classNames('form-row',inputConStyle)}>
                <div className={inputStyle}>
                <input 
                    type={type ? type : 'text'} 
                    value={input} 
                    className="form-control form-control-lg" 
                    placeholder={placeHolder} 
                    onChange={(e)=>{
                        if(keyName){
                            setInput(e.target.value,keyName);
                        }
                        else{
                            setInput(e.target.value);
                        }
                    }} 
                    autoComplete="off"
                    readOnly={isReadOnly}
                />
                </div>
                {!isBtnHide &&
                    <div className={btnStyle}>
                        <button type="button" className={`btn ${btnLabelStyle} btn-lg btn-primary btn-block `} onClick={()=>{if(btnAction)btnAction()}}>{btnLabel}</button>
                    </div>
                }
            </div>
            {isValidShow &&
                <div className={classNames('form-text',validTextStyle)}>* {validText}</div>
            }
        </div>
    );
}

export default CustomInputBtn;
