import React from 'react';
import { Modal } from 'react-bootstrap';

interface ServiceCenterModalType {
    show : boolean,
    setShow : (hide:boolean) => void;
}

export default function ServiceCenterModal({
    show,
    setShow,

}:ServiceCenterModalType){
    return(
        <Modal show={show} onHide={()=>{setShow(false);}} className='modal' centered>
            <div className="modal-header">
                <h5 className="modal-title">고객 문의하기</h5>
                <button type="button" className="btn_close btn" data-dismiss="modal" aria-label="Close">
                    <img src="/assets/img/ic_colse.png" alt="닫기 아이콘" />
                </button>
            </div>
            <div className="modal-body">
                <div className="bg-light rounded py-4 px-5">
                    <p className="fs_18 mb-3">고객센터 전화</p>
                    <p className="fs_24 mb-5 gmarket fw_600"><a href="tel:02-1234-5678">02.1234.5678</a></p>
                    <p className="fs_18 mb-3 ">이메일 문의</p>
                    <p className="fs_24 gmarket fw_600"><a href="mailto:unitour@unitour.co.kr">unitour@unitour.co.kr</a></p>
                </div>
            </div>
            <div className="modal-footer">
                <div className="d-flex w-100">
                    <button type="button" className="btn btn-lg btn-primary btn-block  mt-0" onClick={()=>{setShow(false);}}>확인</button>
                </div>
            </div>
        </Modal>
    )
}