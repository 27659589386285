import React from 'react';
import { Link } from 'react-router-dom';
import Loading from '../../components/form/Loading';
import PageNation from '../../components/form/PageNation';
import { usePostQuery } from '../../inc/axiosQuery';
import { NoticeItemType } from './ServiceType';

export default function NoticeList(){

    const [page, setPage] = React.useState(1);
    const [totalItem, setTotalItem] = React.useState(1);

    const {data:noticeList, isLoading:noticeLoading} = usePostQuery('getNoticeList',{
        page:page,
        limit:10,
    },'board/notice_list.php');

    React.useEffect(()=>{
        if(noticeList){
            setTotalItem(noticeList.data.tot_cnt);
        }
    },[noticeList, noticeLoading])

    return(
        <div className="col-12">
            {noticeLoading ?
                <Loading />
            :
                <>
                    <ul className="list_style_1">
                        {noticeList &&
                            <>
                                {noticeList.data.list.length > 0 ? noticeList.data.list.map((item:NoticeItemType,index:Number) => {
                                    return(
                                        <li key={String(index)}>
                                            <Link to={`/noticeDetail/${item.nt_idx}`}>
                                                <strong className="list_left line1_text pr-4">{item.nt_title}</strong>
                                                <span className="list_right date">{item.wr_datetime}</span>
                                            </Link>
                                        </li>
                                    )
                                })
                                :
                                <div className="text-center mt-5">
                                    <p>조회된 공지사항이 없습니다.</p>
                                </div>
                                }
                            </>
                        }
                    </ul>
                    {noticeList &&
                        <> 
                            {noticeList.data.list.length > 0 &&
                                <PageNation 
                                    page={page}
                                    setPage={setPage}
                                    totalItem={totalItem}
                                    limitItem={10}
                                />
                            }  
                        </>
                    }
                </>
            }
        </div>
    )
}