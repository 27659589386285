import React from 'react';
import Calendar from 'react-calendar';
import { DateFormatter, DayPicker } from 'react-day-picker';
import { ko } from 'date-fns/locale';
import ReqEstType from './reqType';
import moment from 'moment';
import '../../assets/style/CustomCalender.css';
import CustomCaption from '../../components/form/DayPicker';
import CustomSelectBox from '../../components/form/CustomSelectBox';
import { dateConverter } from '../../inc/func';
import { usePostMutation } from '../../inc/axiosQuery';
import { useAppDispatch, useAppSelector } from '../../store/config';
import AlertModal from '../AlertModal';
import { setReload } from '../../store/slices/ReloadSlice';
import { setLoading } from '../../store/slices/LoadingSlice';

function ReqEstStep5({reqInfo, setReqInfo,reqStep,setReqStep,setShow,clearInfo} : ReqEstType) {    

    const dispatch = useAppDispatch();
    const reqEstMutation = usePostMutation('reqEst','doc/request_write.php');
    const {jwt_token} = useAppSelector(state => state.userInfo);
    const [alertModal, setAlertModal] = React.useState({
        show : false,
        msg : '',
        type : '',
    })

    const reqFileHandler = (file : FileList | null) => {
        if(file){
            setReqInfo({
                ...reqInfo,
                req_file : file[0],
                req_file_name : file[0].name,
            })
        }
    }

    

    const alertModalOn = (msg:string,type:string) => {
        setAlertModal({
            show : true,
            msg : msg,
            type : type,
        })
    }
    const alertModalOff = () => {
        setAlertModal({
            show : false,
            msg : '',
            type : '',
        })
    }

    const alertAction = () => {
        if(alertModal.type === 'step2'){
            setReqStep(2);
            alertModalOff()
        }
        else if(alertModal.type === 'step3'){
            setReqStep(3);
            alertModalOff()
        }
        else if(alertModal.type === 'step4'){
            setReqStep(4);
            alertModalOff()
        }
        else if(alertModal.type === 'write_fail'){
            alertModalOff();
            // if(setShow)setShow(false);
        }
        else if(alertModal.type === 'write_success'){
            alertModalOff();
            setReqStep(1);
            if(clearInfo)clearInfo();
            if(setShow)setShow(false);
        }
    }

    const reqInfoPost = async () => {
        if(reqInfo.endDate === reqInfo.startDate){
            alertModalOn('출발일과 도착일이 동일합니다.','step2');
        }
        // else if(reqInfo.loc === ''){
        //     alertModalOn('행선지를 선택해주세요','step3');
        // }
        else if(reqInfo.cnt_adult === 0 && reqInfo.cnt_child === 0 && reqInfo.cnt_infant === 0){
            alertModalOn('최소 인원수를 입력해주세요.','step4');
        }
        else if(reqInfo.req_name === ''){
            alertModalOn('제목을 입력해주세요.' , '');
        }
        else if(reqInfo.req_name.length > 30){
            alertModalOn('제목은 30자 이내로 작성해주세요.','');
        }
        else if(reqInfo.req_mb_name === ''){
            alertModalOn('담당자를 입력해주세요.' , '');
        }
        else if(reqInfo.req_mb_name === ''){
            alertModalOn('담당자 이름은 10자 이내로 작성해주세요.','');
        }
        else{
            dispatch(setLoading(true));
            const resultDate = {
                ...reqInfo,
                jwt_token,
                startDate : `${dateConverter(reqInfo.startDate).year}-${dateConverter(reqInfo.startDate).month}-${dateConverter(reqInfo.startDate).day}`,
                endDate : `${dateConverter(reqInfo.endDate).year}-${dateConverter(reqInfo.endDate).month}-${dateConverter(reqInfo.endDate).day}`,
            }
            const {data,result,msg} = await reqEstMutation.mutateAsync(resultDate);

            if(result){
                alertModalOn('견적신청이 완료되었습니다.','write_success');
                dispatch(setReload(true));
            }
            else{
                alertModalOn(msg,'write_fail');
            }
            dispatch(setLoading(false));
        }
        

    }
    

    return (
        

        <div>
            <div className="modal-header">
                <h5 className="modal-title">최종 요청서를 확인해주세요!</h5>
                <button type="button" className="btn_close btn" onClick={()=>{setReqStep(1); if(setShow)setShow(false); if(clearInfo)clearInfo()}}>
                    <img src="/assets/img/ic_colse.png" alt="닫기 아이콘" />
                </button>
            </div>
            <div className="modal-body">
                <div className="info_box">
                    <p className="info_p">
                        <span>지역</span>
                        <span className="fs_14">{reqInfo.loc_temp_name} {reqInfo.loc_temp_name !== '전체' && `/ ${reqInfo.loc1_temp_name}` }</span>
                    </p>
                    <div className="d-flex">
                        <p className="info_p mr-5">
                            <span>출발</span>
                            <span className="fs_14">{`${dateConverter(reqInfo.startDate).year}.${dateConverter(reqInfo.startDate).month}.${dateConverter(reqInfo.startDate).day}`}</span>
                        </p>
                        <p className="info_p">
                            <span>도착</span>
                            <span className="fs_14">{`${dateConverter(reqInfo.endDate).year}.${dateConverter(reqInfo.endDate).month}.${dateConverter(reqInfo.endDate).day}`}</span>
                        </p>
                    </div>
                    <div className="d-flex">
                        {/* <p className="info_p mr-5">
                            <span>담당자</span>
                            <span className="fs_14">김경태</span>
                        </p> */}
                        <p className="info_p">
                            <span>여행 인원수</span>
                            <span className="fs_14">성인 {reqInfo.cnt_adult} / 소아 {reqInfo.cnt_child} /유아 {reqInfo.cnt_infant}</span>
                        </p>
                    </div>
                    <p className="info_p row mx-0 align-items-center mt-3">
                        <span className="col-2 pl-0 mr-0">제목 <em className="essential">*</em></span>
                        <input type="text" value={reqInfo.req_name} className="form-control form-control-sm col-10 border-0" placeholder="입력해주세요." onChange={(e)=>{setReqInfo({...reqInfo,req_name:e.target.value})}} />
                    </p>
                    <p className="info_p row mx-0 align-items-center">
                        <span className="col-2 pl-0 mr-0">담당자<em className="essential">*</em></span>
                        <input type="text" value={reqInfo.req_mb_name} className="form-control form-control-sm col-10 border-0" placeholder="입력해주세요." onChange={(e)=>{setReqInfo({...reqInfo,req_mb_name:e.target.value})}} />
                    </p>
                    <p className="info_p"><span>요청사항</span></p>
                    <textarea value={reqInfo.req_memo} maxLength={200} className="form-control form-control-lg border-0" placeholder="남기고 싶은 말을 적어세요!(200자 이내)" rows={5} onChange={(e)=>{setReqInfo({...reqInfo,req_memo:e.target.value})}}></textarea>
                </div>
                <input id="reqFile" type="file" className="d-none" onChange={(e)=>{reqFileHandler(e.target.files)}}/>
                <label htmlFor="reqFile">    
                    <div className="btn btn-dark text-white file_btn d-flex align-items-center justify-content-center"><span>파일첨부</span></div>
                </label>
                {reqInfo.req_file_name !== '' &&
                    <p className="mt-4">{reqInfo.req_file_name}</p>
                } 
            </div>
            <div className="modal-footer">
                <div className="d-flex w-100">
                    <button type="button" className="btn btn-lg btn-gray btn-block mb-0" onClick={()=>{setReqStep(5)}}>이전</button>
                    <button type="button" className="btn btn-lg btn-primary btn-block ml-4 mt-0" onClick={()=>{reqInfoPost()}}>완료</button>
                </div>
            </div>
            <AlertModal 
                show={alertModal.show}
                modalOff={()=>{alertModalOff()}}
                msg={alertModal.msg}
                alertAction={()=>{alertAction()}}
                btnLabel={'확인'}
                type={alertModal.type}
            />
        </div>
    )
}

export default ReqEstStep5;
