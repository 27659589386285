import React from 'react';

import classNames from 'classnames';

interface PageNationType {
    page:number,
    totalItem : number,
    setPage : (page:number) => void;
    limitItem : number,
}

function PageNation({page,totalItem,limitItem,setPage} : PageNationType) {

    const limitPage = Math.ceil(totalItem/limitItem);

    React.useEffect(()=>{
        window.scrollTo(0, 0);
    },[page])

    return (
        <ul className="pagination">
            {page-1 > 0 &&
                <li className="mr-2">
                    <a onClick={()=>{setPage(page-1)}} className="disabled pg_arr">
                        {/* <i className="xi-angle-left fs_14"></i> */}
                        <img src="/assets/img/ic_lf.png" />
                    </a>
                </li>
            }
            {page - 2 > 0 && <li className="mb-n1"><a onClick={()=>{setPage(page-2)}}>{page-2}</a></li>}
            {page -1 > 0 && <li className="mb-n1"><a onClick={()=>{setPage(page-1)}}>{page-1}</a></li>}
            <li className="mb-n1"><a className="on">{page}</a></li>
            {page+1 <= limitPage && <li className="mb-n1"><a onClick={()=>{setPage(page+1)}}>{page+1}</a></li>}
            {page+2 <= limitPage &&<li className="mb-n1"><a onClick={()=>{setPage(page+2)}}>{page+2}</a></li>}
            {page+1 <= limitPage &&
                <li className="ml-2">
                    <a onClick={()=>{setPage(page+1)}} className="pg_arr">
                        <img src="/assets/img/ic_rg.png" />
                    </a>
                </li>
            }
        </ul>
    )
}

export default PageNation;
