import React from 'react';
import { NumberComma } from '../../inc/func';
import { ProductItemType } from '../../inc/type/ProductType';
import UseDayTimer from '../../inc/UseDayTimer';
import CustomButton from '../form/CustomButton';
import { useAppDispatch, useAppSelector } from '../../store/config';
import { usePostMutation } from '../../inc/axiosQuery';
import { setReload } from '../../store/slices/ReloadSlice';
import { setLoading } from '../../store/slices/LoadingSlice';
import AlertModal from '../../modal/AlertModal';
import ConfirmModal from '../../modal/ConfirmModal';
interface TravleReqListType {
    detailOn? : (on:{show:boolean,selIdx:string})=>void
    cancleOn? : (on:{show:boolean,selIdx:string})=>void;
    proItem: ProductItemType,
    type:'travle' | 'land',
    index:number,
}

function SpecialProductCom({detailOn,proItem,type,index,cancleOn}:TravleReqListType) {
    const dispatch = useAppDispatch();
    const {jwt_token} = useAppSelector(state => state.userInfo);
    const [selPtIdx, setSelPtIdx] = React.useState(''); //조기마감 pt Idx

    const earlyClosingMutation = usePostMutation('ealryClosing','product/product_close.php');

    const initialAlertState = {
        show:false,
        msg:'',
        type:'',
    }
    const initialConfirmState = {
        show:false,
        msg:'',
        type:'',
    }
    const [alertModal, setAlertModal] = React.useState(()=>initialAlertState);
    const [confirmModal, setConfirmModal] = React.useState(()=>initialConfirmState);


    const earlyClosingHandler = async (ptIdx:string) => { //조기마감
        dispatch(setLoading(true));
        const {data,result,msg} = await earlyClosingMutation.mutateAsync({
            jwt_token:jwt_token,
            pt_idx:ptIdx,
        })

        if(result){
            alertModalOn(msg,'earlyClosing-success');
        }
        else{
            alertModalOn(msg,'');
        }
        dispatch(setLoading(false));
    }

    const alertModalOff= () => {
        setAlertModal(()=>initialAlertState);
    }
    const confirmModalOff = () => {
        setConfirmModal(()=>initialConfirmState);
    }

    const alertModalOn = (msg: string, type : string) => {
        setAlertModal({
            show:true,
            msg:msg,
            type:type,
        })
    }

    const confirmModalOn = (msg:string, type : string) => {
        setConfirmModal({
            show:true,
            msg:msg,
            type:type,
        })
    }

    const alertAction = () => {
        if(alertModal.type === 'earlyClosing-success'){
            dispatch(setReload(true));
        }
    }
    const confirmAction = () => {
        if(confirmModal.type === 'early-closing'){
            earlyClosingHandler(selPtIdx);
        }
    }

    const detailOnHandler = () => {
        if(detailOn){
            detailOn({
                show:true,
                selIdx:proItem.pt_idx,
            })
        }
    }

    const cancleOnHandler = () => {
        if(cancleOn){
            cancleOn({
                show:true,
                selIdx:proItem.pt_idx,
            })
        }
    }

    return (
        <div className="col ">
            {type === 'travle' ? 
                <a className="card h-100" type="button" onClick={()=>{
                    if(proItem.limit_e_date !== '마감'){
                        detailOnHandler();
                    }
                }}>
                    <div className="card_top">
                            <img src={proItem.pt_image === '' ? "/assets/img/no-img.png" : proItem.pt_image} className="card-img-top" alt="기획상품이미지" />
                            <div className="prd_inf">
                                <div className="rv">
                                    <img src="/assets/img/ic_star_m.png" alt="리뷰별점" />
                                    <span>{proItem.pt_star}</span>
                                </div>
                                <div className="prd_pur">
                                    <p>구매건수</p> <span className="ml-2"><em>{proItem.pt_buy_cnt}</em> 건</span>
                                </div>
                            </div>
                        </div>
                    <div className="card-body">
                        <ul className="info">
                            <li>
                                <span className="">제목</span>
                                <p className={'ellipsis'}>{proItem.pt_title}</p>
                            </li>
                            <li>
                                <span className="">지역</span>
                                <p>{proItem.loc_txt}{proItem.loc_1_txt !== '' ? ' / '+proItem.loc_1_txt : ''}</p>
                            </li>
                            <li>
                                <span className="">최소출발인원</span>
                                <p>10명</p>
                            </li>
                            <li>
                                <span className="">행사기간</span>
                                <p>{proItem.pt_start_datetime_txt}~{proItem.pt_end_datetime_txt}</p>
                            </li>
                            <li>
                                <span className="">1인당 비용</span>
                                <p><span className="mr-1">￦</span>{NumberComma(Number(proItem.price_adult))}</p>
                            </li>
                            <li>
                                <span className="">담당자</span>
                                <p>{proItem.pt_mb_name}</p>
                            </li>
                            <li>
                                <span className="">상품코드</span>
                                <p>{proItem.pt_code}</p>
                            </li>
                            <li>
                                <span className="">업로드 날짜</span>
                                <p>{proItem.wr_datetime_txt}</p>
                            </li>
                            <li>
                                <span className="">남은 시간</span>
                                {proItem.limit_e_date === '마감' ? 
                                    <p>{proItem.limit_e_date}</p>
                                :
                                    <p>{UseDayTimer(proItem.limit_e_date,()=>{})}</p>
                                }
                            </li>
                        </ul>
                    </div>
                </a>
            
            :
                <>
                    <a className="card h-100" type="button" onClick={()=>{
                        if(proItem.limit_e_date !== '마감'){
                            detailOnHandler();
                        }
                    }}>
                        <div className="card_top">
                            <img src={proItem.pt_image === '' ? "/assets/img/no-img.png" : proItem.pt_image} className="card-img-top" alt="기획상품이미지" />
                            <div className="prd_inf">
                                <div className="rv">
                                    <img src="/assets/img/ic_star_m.png" alt="리뷰별점" />
                                    <span>{proItem.pt_star}</span>
                                </div>
                                <div className="prd_pur">
                                    <p>구매건수</p> <span className="ml-2"><em>{proItem.pt_buy_cnt}</em> 건</span>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <ul className="info">
                                <li>
                                    <span className="">제목</span>
                                    <p className={'ellipsis'}>{proItem.pt_title}</p>
                                </li>
                                <li>
                                    <span className="">지역</span>
                                    <p>{proItem.loc_txt}{proItem.loc_1_txt !== '' ? ' / '+proItem.loc_1_txt : ''}</p>
                                </li>
                                <li>
                                    <span className="">최소출발인원</span>
                                    <p>10명</p>
                                </li>
                                <li>
                                    <span className="">행사기간</span>
                                    <p>{proItem.pt_start_datetime_txt}~{proItem.pt_end_datetime_txt}</p>
                                </li>
                                <li>
                                    <span className="">1인당 비용</span>
                                    <p><span className="mr-1">￦</span> 60,000</p>
                                </li>
                                <li>
                                    <span className="">담당자</span>
                                    <p>{proItem.pt_mb_name}</p>
                                </li>
                                <li>
                                    <span className="">업로드 날짜</span>
                                    <p>{proItem.wr_datetime_txt}</p>
                                </li>
                                <li>
                                    <span className="">남은 시간</span>
                                    {proItem.limit_e_date === '마감' ? 
                                        <p>{proItem.limit_e_date}</p>
                                    :
                                        <p>{UseDayTimer(proItem.limit_e_date,()=>{})}</p>
                                    }
                                </li>
                            </ul>
                        </div>
                    </a>
                    {proItem.limit_e_date !== '마감' ? 
                    <div className="btn_wr mt-2">
                        <CustomButton 
                            btnLabel='조기마감'
                            type='normal'
                            color='green'
                            clickEvent={()=>{
                                setSelPtIdx(proItem.pt_idx);
                                confirmModalOn('정말 조기마감 하시겠습니까?','early-closing');
                            }}
                        />
                        <button className="btn btn-outline" onClick={cancleOnHandler}>취소하기</button>
                    </div>
                    :    
                    <div className="btn_wr mt-2">
                        <button className="btn btn-gr w-100">마감되었습니다</button>
                    </div>
                    }
                </>
            }
            <AlertModal 
                alertAction={alertAction}
                btnLabel='확인'
                modalOff={alertModalOff}
                msg={alertModal.msg}
                show={alertModal.show}
                type={alertModal.type}
            />
            <ConfirmModal 
                alertAction={confirmAction}
                btnLabel={'확인'}
                modalOff={confirmModalOff}
                msg={confirmModal.msg}
                show={confirmModal.show}
            />
        </div>
    )
}

export default SpecialProductCom;
