import React from 'react';
import classNames from 'classnames';
import CustomInput from '../../../components/form/CustomInput';
import { MarginY } from '../../../inc/FlexStyle';
import CustomInputBtn from '../../../components/form/CustomBtnInput';
import CustomButton from '../../../components/form/CustomButton';
import CustomFile from '../../../components/form/CustomFile';
import CustomSelectBox from '../../../components/form/CustomSelectBox';
import AlertModal from '../../../modal/AlertModal';
import AgreeModal from '../../../modal/AgreeModal';
import { usePostMutation, usePostQuery } from '../../../inc/axiosQuery';
import { emailCheck, idCheck, phoneCheck, pwCheck, pwCheckRe } from '../../../inc/func';
import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../../store/config';
import { setLoading } from '../../../store/slices/LoadingSlice';

type BankType = {
    value:string,
    label:string,
}

function TravelJoin() {

    const {mb_type} = useAppSelector(state => state.userInfo);
    const dispatch = useAppDispatch();

    const normalJoinMutation = usePostMutation('travelJoin','user/user_reg_1.php');
    
    const sendEmailAuthMutation = usePostMutation('sendEmailAuth','user/email_auth_send.php'); //이메일 인증 전송
    const emailAuthMutation = usePostMutation('emailAuth','user/email_auth_chk.php'); //이메일인증

    const sendPhoneAuthMutation = usePostMutation('sendPhoneAuth' , 'user/auth_msg.php'); //휴대폰 인증 전송
    const phoneAuthMutation = usePostMutation('phoneAuth' , 'user/auth_msg_chk.php'); //휴대폰 인증

    const corpNumCheckMutation = usePostMutation('corpNumCheck', 'user/corp_number_chk.php'); //사업자등록번호 인증



    const {data:bankList, isLoading : bankLoading, isError: bankError} = usePostQuery('bankList',{},'bank/bank_list.php');
    const navigator = useNavigate();
    
    const [joinInfo , setJoinInfo] = React.useState({
        mb_type_1 : '2',
        mb_id : '',
        mb_pw : '',
        mb_pw_re : '',
        mb_name : '',
        mb_email : '',
        mb_email_auth : '',
        mb_name_ : '',
        company_cate_1 : '',
        company_cate_2 : '',
        company_number : '',
        company_name : '',
        company_reg_file_name : '',
        company_reg_file : {},
        bank_name : '',
        bank_number : '',
        bank_reg_file_name : '',
        bank_reg_file : {},
        mb_birth : '',
        mb_phone : '',
        authCode : '',
        agree1 : false,
        agree2 : false,
        mb_reg_type : 'E',
    })

    const [inputCheck, setInputCheck] = React.useState({
        mb_id : false,
        mb_pw : false,
        mb_pw_re : false,
        mb_name : false,
        mb_email : false,
        mb_email_auth : false,
        company_name : false,
        company_cate_1 : false,
        company_cate_2 : false,
        company_number : false,
        mb_birth : false,
        mb_phone : false,
        authCode : false,

        mb_id_msg : '',
        mb_pw_msg : '',
        mb_pw_re_msg : '',
        mb_name_msg : '',
        mb_email_msg : '',
        mb_email_auth_msg : '',
        company_name_msg : '',
        company_cate_1_msg : '',
        company_cate_2_msg : '',
        company_number_msg : '',
        mb_birth_msg : '',
        mb_phone_msg : '',
        authCode_msg : '',
    })

    const [isSendEmailAuth, setIsSendEmailAuth] = React.useState(false);
    const [isSendPhoneAuth, setIsSendPhoneAuth] = React.useState(false);

    const [selBankList, setSelBankList] = React.useState<BankType[]>([]);


    // const [selPlaceList, setSelPlaceList] = React.useState([ //선택 나라 리스트
    //     {country : '' , location : ''},
    // ])


    const [alertModal, setAlertModal] = React.useState({
        show : false,
        msg : '',
        type : '',
    });
    const [agreeModal, setAgreeModal] = React.useState({
        show : false,
        type :'',
        title : '',
    })

    const modalOff = () => {
        setAgreeModal({
            show:false,
            type :'',
            title : '',
        })
        setAlertModal({
            show : false,
            msg : '',
            type : '',
        })
    }
    const alertModalOn = (msg:string) => {
        setAlertModal({
            show:true,
            msg,
            type:'',
        })
        return false;
    }
    const alertModalAction = () => {
        // alert('is action!');
        if(alertModal.type === 'success'){
            navigator('/');
        }
    }
    

    const inputController = (text:string , type?:string) => { //입력 컨트롤러
        if(type){
            setJoinInfo({
                ...joinInfo,
                [type] : text,
            })
        }
    }

    const busiNumberCheck = async () => {
        if(joinInfo.company_number === ''){
            alertModalOn('사업자등록번호를 입력해주세요.');
            setInputCheck({
                ...inputCheck,
                company_number : false,
                company_number_msg : '사업자등록번호를 입력해주세요.',
            })
        }
        else{
            dispatch(setLoading(true));

            const {result, msg} = await corpNumCheckMutation.mutateAsync({
                company_number : joinInfo.company_number
            })

            if(result){
                alertModalOn(msg);
                setInputCheck({
                    ...inputCheck,
                    company_number : true,
                    company_number_msg : msg,
                })
            }
            else{
                alertModalOn(msg);
                setInputCheck({
                    ...inputCheck,
                    company_number : false,
                    company_number_msg : msg,
                })
            }
            dispatch(setLoading(false));
            
        }
    }

    const busiFileChange = (file : FileList | null) => { //사업자등록증 사본
        console.log(file);
        if(file){
            setJoinInfo({
                ...joinInfo,
                company_reg_file_name : file[0].name,
                company_reg_file : file[0],
            })
        }
    }
    const bankFileChange = (file: FileList | null) => { //통장사본 업로드
        if(file){
            setJoinInfo({
                ...joinInfo,
                bank_reg_file_name : file[0].name,
                bank_reg_file : file[0],
            })
        }
    }
    const checkBoxController = (type : string) => { //약관 동의 체크
        setJoinInfo({
            ...joinInfo,
            [type] : type === 'agree1' ? !joinInfo.agree1 : !joinInfo.agree2 
        })
    }

    const sendEmailAuth = async () => { //이메일 인증 전송
        if(!inputCheck.mb_email){
            alertModalOn(inputCheck.mb_email_msg);
        }
        else{

            dispatch(setLoading(true));
            const {result,msg} = await sendEmailAuthMutation.mutateAsync({mb_email:joinInfo.mb_email});

            if(result){
                setAlertModal({
                    show : true,
                    msg : `인증번호가 \n 이메일로 발송되었습니다.`,
                    type : 'send-email',
                })
                setIsSendEmailAuth(true);
            }
            else{
                setInputCheck({
                    ...inputCheck,
                    mb_email:false,
                    mb_email_msg:msg,
                })
                setAlertModal({
                    show : true,
                    msg : msg,
                    type : '',
                })
            }
            dispatch(setLoading(false));
        }
    }

    const authEmailHandler = async () => { //이메일 인증
        if(joinInfo.mb_email_auth === ''){
            setInputCheck({
                ...inputCheck,
                mb_email_auth : false,
                mb_email_msg : '인증번호를 입력해주세요.',
            });
        }
        else{
            dispatch(setLoading(true));
            const authParams = {
                mb_email : joinInfo.mb_email,
                auth_no : joinInfo.mb_email_auth
            }
            const {result,msg} = await emailAuthMutation.mutateAsync(authParams);

            if(result){
                setIsSendEmailAuth(false);
                setInputCheck({
                    ...inputCheck,
                    mb_email_auth:true,
                    mb_email_msg:msg,      
                })
            }
            else{
                setInputCheck({
                    ...inputCheck,
                    mb_email_auth : false,
                    mb_email_auth_msg: msg,
                })
                setAlertModal({
                    show:true,
                    msg:msg,
                    type:'',
                })
            }
            dispatch(setLoading(false));
        }
    }

    const sendPhoneCodeHandler = async () => {
        if(!phoneCheck(joinInfo.mb_phone).result){
            setInputCheck({
                ...inputCheck,
                mb_phone : false,
                mb_phone_msg : phoneCheck(joinInfo.mb_phone).msg,
            })
            alertModalOn(phoneCheck(joinInfo.mb_phone).msg);
        }
        else{
            dispatch(setLoading(true));
            const {result,msg} = await sendPhoneAuthMutation.mutateAsync({mb_phone:joinInfo.mb_phone});

            setIsSendPhoneAuth(result);
            
            if(result){
                alertModalOn('인증번호가 발송되었습니다.');
                setInputCheck({
                    ...inputCheck,
                    mb_phone : true,
                    mb_phone_msg : '인증번호가 발송되었습니다.',
                })
            }
            else{
                setInputCheck({
                    ...inputCheck,
                    mb_phone:false,
                    mb_phone_msg : msg,
                })
                alertModalOn(msg);
            }
            dispatch(setLoading(false));
        }
    }

    const authPhoneCodeHandler = async () => {

        if(!phoneCheck(joinInfo.mb_phone).result){
            setInputCheck({
                ...inputCheck,
                mb_phone : false,
                mb_phone_msg : phoneCheck(joinInfo.mb_phone).msg,
            })
            alertModalOn(phoneCheck(joinInfo.mb_phone).msg);
            return false;
        }
        if(joinInfo.authCode === ''){
            setInputCheck({
                ...inputCheck,
                authCode : false,
                authCode_msg : '인증번호를 입력해주세요.',
            })
            alertModalOn('인증번호를 입력해주세요.');
        }
        else{
            dispatch(setLoading(true));
            const {result,msg} = await phoneAuthMutation.mutateAsync({
                mb_phone:joinInfo.mb_phone,
                auth_number : joinInfo.authCode,
            })
            if(result){
                
                setIsSendPhoneAuth(false);
                alertModalOn(msg);
                setInputCheck({
                    ...inputCheck,
                    mb_phone:true,
                    authCode:true,
                    mb_phone_msg : msg,
                })
                
            }
            else{
                setInputCheck({
                    ...inputCheck,
                    authCode:false,
                    authCode_msg : msg,
                })
                alertModalOn(msg);
            }
            dispatch(setLoading(false));
        }
    }

    const joinTravle = async () => { //회원가입

        if(joinInfo.mb_id === ''){
            alertModalOn(inputCheck.mb_id_msg === '' ? '아이디를 입력해주세요.' : inputCheck.mb_id_msg);
        }
        else if(!inputCheck.mb_id){
            alertModalOn(inputCheck.mb_id_msg);
        }
        else if(!inputCheck.mb_pw){
            alertModalOn(inputCheck.mb_pw_msg === '' ? '올바른 비밀번호를 입력해주세요.' : inputCheck.mb_pw_msg);
        }
        else if(!inputCheck.mb_pw_re){
            alertModalOn(inputCheck.mb_pw_msg === '' ? '비밀번호를 한번더 동일하게 입력해주세요.' : inputCheck.mb_pw_re_msg);
        }
        else if(joinInfo.mb_name === ''){
            alertModalOn('담당자 이름을 입력해주세요.');
        }
        else if(!inputCheck.mb_email){
            alertModalOn(inputCheck.mb_email_msg === '' ? '유효한 이메일을 입력해주세요.' : inputCheck.mb_email_msg);
        }
        else if(!inputCheck.mb_email_auth){
            alertModalOn('이메일 인증을 진행해주세요.');
        }
        else if(joinInfo.company_name === ''){
            alertModalOn('회사명을 입력해주세요.');
        }
        else if(joinInfo.company_number === ''){
            alertModalOn('사업자 등록번호를 입력해주세요.');
        }
        else if(!inputCheck.company_number){
            alertModalOn(inputCheck.company_number_msg === '' ? '사업자등록번호 인증을 진행해주세요.' : inputCheck.company_number_msg);
        }
        else if(joinInfo.company_cate_1 === ''){
            alertModalOn('업종을 입력해주세요.')
        }
        else if(joinInfo.company_cate_2 === ''){
            alertModalOn('업태를 입력해주세요.');
        }
        else if(joinInfo.company_reg_file_name === ''){
            alertModalOn('사업자등록증 사본을 등록해주세요.');
        }
        else if(joinInfo.bank_name === ''){
            alertModalOn('은행을 선택해주세요.');
        }
        else if(joinInfo.bank_number === ''){
            alertModalOn('계좌번호를 입력해주세요.(-포함)');
        }
        else if(joinInfo.bank_reg_file_name === ''){
            alertModalOn('통장사본을 등록해주세요.');
        }
        else if(!inputCheck.mb_phone){
            alertModalOn(inputCheck.mb_phone_msg === '' ? '핸드폰번호를 올바르게 입력해주세요.' : inputCheck.mb_phone_msg);
        }
        else if(!inputCheck.authCode){
            alertModalOn('휴대폰 인증을 진행해주세요.');
        }
        else if(!joinInfo.agree1){
            alertModalOn('서비스 이용약관에 동의해주세요.');
        }
        else if(!joinInfo.agree2){
            alertModalOn('개인정보 수집 및 이용에 동의해주세요.');
        }
        
        else{

            dispatch(setLoading(true));
            const {result,msg} = await normalJoinMutation.mutateAsync(joinInfo);

            if(!result){
                setAlertModal({
                    show:true,
                    msg : msg,
                    type : '',
                })
            }
            else{
                setAlertModal({
                    show:true,
                    msg:msg,
                    type : 'success',
                })
            }

            dispatch(setLoading(false));
        }

        
    }

    React.useEffect(()=>{
        if(bankList){
            const {data,result,msg} = bankList;

            let tempList:BankType[] = [];

            data.bank_list.forEach((item:string,index:number) => {
                tempList.push({
                    label:item,
                    value:item,
                })
            })
            setSelBankList([...tempList]);
        }
    },[bankList,bankLoading,bankError])

    React.useEffect(()=>{
        setInputCheck({
            ...inputCheck,
            mb_id : idCheck(joinInfo.mb_id).result,
            mb_id_msg : idCheck(joinInfo.mb_id).msg,
            mb_email : emailCheck(joinInfo.mb_email).result,
            mb_email_msg : emailCheck(joinInfo.mb_email).msg,
            mb_pw : pwCheck(joinInfo.mb_pw).result,
            mb_pw_msg : pwCheck(joinInfo.mb_pw).msg,
            mb_pw_re : pwCheckRe(joinInfo.mb_pw,joinInfo.mb_pw_re).result,
            mb_pw_re_msg : pwCheckRe(joinInfo.mb_pw,joinInfo.mb_pw_re).msg,
            mb_phone : phoneCheck(joinInfo.mb_phone).result,
            mb_phone_msg : phoneCheck(joinInfo.mb_phone).msg,
        })
    },[joinInfo])

    React.useEffect(()=>{
        if(mb_type === '1'){
            navigator('/travleMain',{replace:true});
        }
        else if(mb_type === '2'){
            navigator('/landMain',{replace:true});
        }
    },[])

    return (
        <div className="container-fluid px-0">
            <AlertModal 
                show={alertModal.show}
                modalOff={modalOff}
                btnLabel={'확인'}
                msg={alertModal.msg}
                type={alertModal.type}
                alertAction={alertModalAction}  
            />
            <AgreeModal 
                show={agreeModal.show}
                title={agreeModal.title}
                type={agreeModal.type}
                modalOff={modalOff}
            />
            <div className="sub_pg">
                <section className="container-lg mx-auto">
                    <div className="lg_bx">
                        <div className="tit_wr">
                            <h3 className="tit_h3 tit_point gmarket">여행사 회원가입</h3>
                            <p className="fs_18 mt-2">내 입맛에 맞는 여행을 골라 보세요!</p>
                        </div>
                        <form>
                            <CustomInput 
                                title={'아이디'}
                                placeHolder={'아이디를 입력해주세요.'}
                                input={joinInfo.mb_id}
                                setInput={inputController}
                                isValidShow={joinInfo.mb_id !== ''}
                                isValid={inputCheck.mb_id}
                                validText={inputCheck.mb_id_msg}
                                keyName={'mb_id'}
                            />
                            <MarginY mt={2} />
                            <CustomInput 
                                title={'비밀번호'}
                                placeHolder={'비밀번호를 입력해주세요.'}
                                input={joinInfo.mb_pw}
                                setInput={inputController}
                                isValidShow={joinInfo.mb_pw !== ''}
                                isValid={inputCheck.mb_pw}
                                validText={inputCheck.mb_pw_msg}
                                keyName={'mb_pw'}
                                type={'password'}
                            />
                            <CustomInput 
                                placeHolder={'비밀번호를 한번더 입력해주세요.'}
                                input={joinInfo.mb_pw_re}
                                setInput={inputController}
                                isValidShow={joinInfo.mb_pw_re !== ''}
                                isValid={inputCheck.mb_pw_re}
                                validText={inputCheck.mb_pw_re_msg}
                                keyName={'mb_pw_re'}
                                type={'password'}
                            />
                            <MarginY mt={2} />

                            <h4 className="tit_h4 mt-5 mb-4">회원정보</h4>

                            <CustomInput 
                                title={'담당자 이름'}
                                placeHolder={'이름을 입력해주세요.'}
                                input={joinInfo.mb_name}
                                setInput={inputController}
                                isValidShow={false}
                                isValid={inputCheck.mb_name}
                                validText={inputCheck.mb_name_msg}
                                keyName={'mb_name'}
                            />

                            <MarginY mt={1} />

                            <CustomInputBtn 
                                title={'담당자 이메일'}
                                placeHolder={'이메일을 입력해주세요.'}
                                input={joinInfo.mb_email}
                                setInput={inputController}
                                isValidShow={joinInfo.mb_email !== ''}
                                isValid={inputCheck.mb_email}
                                validText={inputCheck.mb_email_msg}
                                btnLabel={'인증발송'}
                                inputStyle={inputCheck.mb_email_auth ? 'w-100' : 'col-9 pr-0'}
                                btnStyle={'col-3 pl-0'}
                                btnLabelStyle={'fs_16'}
                                keyName={'mb_email'}
                                btnAction={sendEmailAuth}
                                isReadOnly={inputCheck.mb_email_auth}
                                isBtnHide={inputCheck.mb_email_auth}
                            />
                            {isSendEmailAuth &&
                                <>
                                    <MarginY mt={1} />
                                    <CustomInputBtn 
                                        title={'인증번호'}
                                        placeHolder={'입력해주세요.'}
                                        input={joinInfo.mb_email_auth}
                                        setInput={inputController}
                                        isValidShow={joinInfo.mb_email_auth !== '' && inputCheck.mb_email_auth_msg !== ''}
                                        isValid={inputCheck.mb_email_auth}
                                        validText={inputCheck.mb_email_auth_msg}
                                        btnLabel={'확인'}
                                        inputStyle={'col-9 pr-0'}
                                        btnStyle={'col-3 pl-0'}
                                        btnLabelStyle={'fs_16'}
                                        keyName={'mb_email_auth'}
                                        btnAction={()=>{authEmailHandler();}}
                                    />
                                </>
                            }
                            <MarginY mt={2} />
                            <CustomInput 
                                title={'회사명'}
                                placeHolder={'소속된 회사 이름을 입력해주세요.'}
                                input={joinInfo.company_name}
                                setInput={inputController}
                                isValidShow={false}
                                isValid={inputCheck.company_name}
                                validText={inputCheck.company_name_msg}
                                keyName={'company_name'}
                            />
                            <MarginY mt={1} />
                            <CustomInputBtn 
                                title={'사업자등록번호'}
                                placeHolder={'사업자등록번호를 적어 주세요.'}
                                input={joinInfo.company_number}
                                setInput={inputController}
                                isValidShow={inputCheck.company_number_msg !== ''}
                                isValid={inputCheck.company_number}
                                validText={inputCheck.company_number_msg}
                                btnLabel={'인증하기'}
                                inputStyle={inputCheck.company_number ? 'w-100' : 'col-9 pr-0'}
                                btnStyle={'col-3 pl-0'}
                                btnLabelStyle={'fs_16'}
                                keyName={'company_number'}
                                btnAction={busiNumberCheck}
                                isBtnHide={inputCheck.company_number}
                                isReadOnly={inputCheck.company_number}
                            />
                            <MarginY mt={2} />
                            <CustomInput 
                                title={'업종'}
                                placeHolder={'사업자등록증에 등록된 업종을 기재해주세요.'}
                                input={joinInfo.company_cate_1}
                                setInput={inputController}
                                isValidShow={inputCheck.company_cate_1}
                                isValid={!inputCheck.company_cate_1}
                                validText={inputCheck.company_cate_1_msg}
                                keyName={'company_cate_1'}
                            />
                            <MarginY mt={2} />
                            <CustomInput 
                                title={'업태'}
                                placeHolder={'사업자등록증에 등록된 업태를 기재해주세요.'}
                                input={joinInfo.company_cate_2}
                                setInput={inputController}
                                isValidShow={inputCheck.company_cate_2}
                                isValid={!inputCheck.company_cate_2}
                                validText={inputCheck.company_cate_2_msg}
                                keyName={'company_cate_2'}
                            />
                            <MarginY mt={2} />
                            <CustomFile 
                                title={'사업자등록증 사본'}
                                placeHolder={'사업자등록번호사본을 업로드해주세요'}
                                input={joinInfo.company_reg_file_name}
                                setInput={busiFileChange}
                                btnLabel={'업로드'}
                                inputStyle={'col-9 pr-0'}
                                btnStyle={'col-3 pl-0'}
                                btnLabelStyle={'fs_16'}
                                fileId={'company_name'}
                            />
                            <MarginY mt={2} />
                            <CustomSelectBox 
                                title={'계좌등록'}
                                optionList={selBankList}
                                selValue={joinInfo.bank_name}
                                setSelValue={inputController}
                                keyName={'bank_name'}
                            />
                            <MarginY mt={1} />
                            <CustomInput
                                placeHolder={'계좌번호를 입력해주세요.'}
                                input={joinInfo.bank_number}
                                setInput={inputController}
                                isValidShow={false}
                                keyName={'bank_number'}
                            />

                            <CustomFile 
                                title={'통장 사본'}
                                placeHolder={'통장 사본을 업로드해주세요.'}
                                input={joinInfo.bank_reg_file_name}
                                setInput={bankFileChange}
                                btnLabel={'업로드'}
                                inputStyle={'col-9 pr-0'}
                                btnStyle={'col-3 pl-0'}
                                btnLabelStyle={'fs_16'}
                                fileId={'bank'}
                            />

                            {/* <div className="d_sp_center mt-5 mb-4">
                                <h4 className="tit_h4 ">당신이 담당하고 있는 지역을 선택해주세요!</h4>
                                <button type="button" className="btn xs_btn bg_bk" onClick={()=>{addCountry();}}>추가</button>
                            </div>
                            {selPlaceList.map((item,index) => {
                                return(
                                    <div key={index}>
                                        <div className="d-flex flex-row w-100" style={{gap:10}}>
                                            <div style={{flex:1}}>
                                                <CustomSelectBox 
                                                    title={'국가'}
                                                    optionList={selBankList}
                                                    selValue={selPlaceList[index].country}
                                                    setSelValue={countryChange}
                                                    keyName={String(index)}
                                                />
                                            </div>
                                            <div style={{flex:1}}>
                                                <CustomSelectBox 
                                                    title={'지역'}
                                                    optionList={selBankList}
                                                    selValue={selPlaceList[index].location}
                                                    setSelValue={locationChange}
                                                    keyName={String(index)}
                                                    isDelBtn={true}
                                                    delAction={delCountry}
                                                />
                                            </div>
                                        </div>
                                        <MarginY mb={1} />
                                    </div>
                                )
                            })} */}
                            
                            

                            <MarginY mt={3} />
                            <CustomInputBtn 
                                title={'핸드폰번호'}
                                placeHolder={'하이폰(-)을 제외하고 입력해주세요.'}
                                input={joinInfo.mb_phone}
                                setInput={inputController}
                                isValidShow={joinInfo.mb_phone !== ''}
                                isValid={inputCheck.mb_phone}
                                validText={inputCheck.mb_phone_msg}
                                keyName={'mb_phone'}
                                btnLabel={'인증번호 받기'}
                                inputStyle={'col-9 pr-0'}
                                btnStyle={'col-3 pl-0'}
                                btnLabelStyle={'fs_16'}
                                btnAction={sendPhoneCodeHandler}
                                isReadOnly={inputCheck.mb_phone && inputCheck.authCode}
                            />
                            <MarginY mt={1} />
                            {isSendPhoneAuth &&
                                <CustomInputBtn 
                                    placeHolder={'인증번호를 입력해주세요'}
                                    input={joinInfo.authCode}
                                    setInput={inputController}
                                    isValidShow={inputCheck.authCode}
                                    isValid={!inputCheck.authCode}
                                    validText={inputCheck.authCode_msg}
                                    keyName={'authCode'}
                                    btnLabel={'확인'}
                                    inputStyle={'col-9 pr-0'}
                                    btnStyle={'col-3 pl-0'}
                                    btnLabelStyle={'fs_16'}
                                    btnAction={authPhoneCodeHandler}
                                />
                            }
                            <div className="ip_wr mt-5 agree mb-3 flex-wrap ">
                                <div className="input-group w-100  mb-3">
                                    <div className="input-group-prepend ">
                                        <div className="checks">
                                            <input 
                                                id="agree1" 
                                                name="agree" 
                                                checked={joinInfo.agree1} 
                                                value={'Y'} 
                                                type="checkbox" 
                                                aria-label="ck1 " 
                                                data-toggle="modal" 
                                                data-target="#terms_modal" 
                                                onChange={(e)=>{checkBoxController('agree1')}} 
                                            />
                                            <label htmlFor="agree1">서비스 이용약관동의</label>
                                        </div>
                                        <span onClick={()=>{
                                            setAgreeModal({
                                                title:'서비스 이용약관',
                                                show:true,
                                                type:'1'
                                            })
                                            }}>보기</span>
                                    </div>
                                </div>
                                <div className="input-group w-100">
                                    <div className="input-group-prepend ">
                                        <div className="checks">
                                            <input 
                                                id="agree2" 
                                                name="agree" 
                                                checked={joinInfo.agree2} 
                                                value={'Y'} 
                                                type="checkbox" 
                                                aria-label="ck2 " 
                                                data-toggle="modal" 
                                                data-target="#terms_modal2" 
                                                onChange={(e)=>{checkBoxController('agree2')}}
                                            />
                                            <label htmlFor="agree2">개인정보 수집 및 이용 동의</label>
                                        </div>
                                        <span onClick={()=>{
                                            setAgreeModal({
                                                title:'개인정보처리방침',
                                                show:true,
                                                type:'2'
                                            })
                                            }}>보기</span>
                                    </div>
                                </div>
                            </div>
                            <MarginY mt={5} />
                            <CustomButton 
                                btnLabel='회원가입'
                                color='green'
                                type='lg'
                                labelStyle='fw_700'
                                clickEvent={()=>{joinTravle();}}
                            />
                        </form>
                        
                    </div>
                </section>
            </div>
        </div>
    );
}

export default TravelJoin;
