import classNames from 'classnames';
import React from 'react';
import { useParams } from 'react-router';
import { usePostMutation } from '../../inc/axiosQuery';
import { useAppSelector } from '../../store/config';
import FaqList from './FaqList';
import MyQnaList from './MyQnaList';
import NoticeList from './NoticeList';
import QnaWrite from './QnaWrite';
import { FaqItemType } from './ServiceType';

export default function ServCenterIndex(){
   
    const {pageType} = useParams();
    const {jwt_token} = useAppSelector(state => state.userInfo);
    const [selTab , setSelTab] = React.useState(()=>pageType); 

    //자주묻는 질문
    const [isLoading, setIsLoading] = React.useState(false);
    const [searchKey, setSearchKey] = React.useState('');
    const faqListMutation = usePostMutation('faqList','board/qna_list.php');
    const [faqPage, setFaqPage] = React.useState(1);
    const [faqTotalItem, setFaqTotalItem] = React.useState(1);
    const [faqList, setFaqList] = React.useState<FaqItemType[]>([]);
    // const [conType, setConType] = React.useState('');
    

    const getFaqList = async () => {
        setIsLoading(true);
        const params = {
            jwt_token : jwt_token,
            page : faqPage,
            limit: 10,
            sch_txt : searchKey,
        }
        const {data , result, msg} = await faqListMutation.mutateAsync(params);

        if(result){
            setFaqList([...data.list]);
            setIsLoading(false);
        }        
    }

    React.useEffect(()=>{
        getFaqList();
    },[faqPage])
    React.useEffect(()=>{
        window.scrollTo(0, 0);
    },[selTab])

    return(
        <div className="container sub_pg">
            <div className="cont_wr">
                <div className="row">
                    <div className="col-12">
                        <p className="tit_h2 fs_38">고객센터</p>
                        {selTab === '2' &&
                            <div className="mt-4">
                                <strong className="fs_24">항상 노력하는 저희가 되겠습니다!</strong>
                                <p className="my-3">저희 플랫폼을 이용하시면서 느끼신 불편사항이나 바라는 점을 알려주세요</p>
                                <p className="fs_13 text_gray">문의량이 많아 24시간 이상 소요될 수 있습니다.</p>
                            </div>
                        }
                        <div className="d-flex justify-content-between tab_nav_wrap mb-5">
                            <div className="tab_nav">
                                <a onClick={()=>{setSelTab('1')}} className={classNames('btn_tab',{on:selTab === '1'})}>자주 묻는 질문</a>
                                <a onClick={()=>{setSelTab('2')}} className={classNames('btn_tab',{on:selTab === '2'})}>질문하기</a>
                                <a onClick={()=>{setSelTab('3')}} className={classNames('btn_tab',{on:selTab === '3'})}>공지사항</a>
                                <a onClick={()=>{setSelTab('4')}} className={classNames('btn_tab',{on:selTab === '4'})}>나의 질문</a>                
                            </div>
                            {selTab === '1' &&
                                <form className="form-row input_row search_wrap">
                                    <div className="col-8 pr-0 ">
                                        <input value={searchKey} onChange={(e)=>{setSearchKey(e.target.value)}} type="text" className="form-control form-control-lg" placeholder="자주 묻는 질문 검색" />
                                    </div>
                                    <div className="col-4 pl-0">
                                        <button type="button" className="btn btn-lg btn_search btn-primary btn-block">검색</button>
                                    </div>
                                </form>
                            }
                        </div>
                        {selTab === '1' &&
                            <FaqList page={faqPage} totalItem={faqTotalItem} setPage={setFaqPage} faqList={faqList} isLoading={isLoading}/>
                        }
                        {selTab === '2' &&
                            <QnaWrite setSelTab={setSelTab}/>
                        }
                        {selTab === '3' &&
                            <NoticeList />
                        }
                        {selTab === '4' &&
                            <MyQnaList />
                        }
                    </div>
                </div>
            </div>
        </div>

    )
    
}