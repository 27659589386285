import React, { useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Link, useNavigate } from 'react-router-dom';
import CustomInput from '../../components/form/CustomInput';
import {useMutation} from 'react-query';
import { usePostMutation } from '../../inc/axiosQuery';
import { MarginY } from '../../inc/FlexStyle';
import { useAppDispatch, useAppSelector } from '../../store/config';
import { setAutoLogin, setUserInfo } from '../../store/slices/UserInfoSlice';
import { phoneCheck, pwCheck, pwCheckRe } from '../../inc/func';
import AlertModal from '../AlertModal';
import CustomInputBtn from '../../components/form/CustomBtnInput';
import { setLoading } from '../../store/slices/LoadingSlice';

interface ChgPhoneModalType {
    show : boolean;
    setShow : (isLogin:boolean) => void;
}

function ChangePhoneModal({
    show,
    setShow,
}:ChgPhoneModalType) {

    const navigator = useNavigate();
    const dispatch = useAppDispatch();

    const userInfo = useAppSelector(state => state.userInfo);
    const sendPhoneCodeMutation = usePostMutation('sendPhoneCode','user/auth_msg.php');
    const authPhoneCodeMutation = usePostMutation('authPhoneCode','user/auth_msg_chk.php');
    const chgPhoneMutation = usePostMutation('chgPhone','user/mb_phone_chg.php');


    const [inputInfo, setInputInfo] = React.useState({
        mb_phone : '',
        authCode : '',
    });

    const [inputCheck, setInputCheck] = React.useState({
        mb_phone:false,
        authCode : false,
        mb_phone_msg : '',
        authCode_msg : '',
    })

    const [alertModal, setAlertModal] = React.useState({
        show:false,
        msg:'',
        type:'',
    })

    const [isCodeSend, setIsCodeSend] = React.useState(false);

    const alertModalOn = (msg:string, type:string) => {
        setAlertModal({
            show:true,
            msg:msg,
            type:type,
        })
    }

    const alertModalOff = () => {
        setAlertModal({
            show:false,
            msg:'',
            type:'',
        })
    }

    const alertAction = () => {
        if(alertModal.type === 'chg_success'){
            setShow(false);
        }
        alertModalOff();
        
    }

    const inputHandler = (text : string, key?:string) => {
        if(key){
            setInputInfo({
                ...inputInfo,
                [key] : text,
            })
        }
    }

    const sendPhoneCodeHandler = async () => {
        if(!phoneCheck(inputInfo.mb_phone).result){
            setInputCheck({
                ...inputCheck,
                mb_phone : false,
                mb_phone_msg : phoneCheck(inputInfo.mb_phone).msg,
            })
            alertModalOn(phoneCheck(inputInfo.mb_phone).msg,'');
        }
        else{
            const {result,msg} = await sendPhoneCodeMutation.mutateAsync({mb_phone:inputInfo.mb_phone});

            setIsCodeSend(result);
            
            if(result){
                alertModalOn('인증번호가 발송되었습니다.','');
                setInputCheck({
                    ...inputCheck,
                    mb_phone : true,
                    mb_phone_msg : '인증번호가 발송되었습니다.',
                })
            }
            else{
                setInputCheck({
                    ...inputCheck,
                    mb_phone:false,
                    mb_phone_msg : msg,
                })
                alertModalOn(msg,'');
            }
        }
    }

    const authPhoneCodeHandler = async () => {

        if(!phoneCheck(inputInfo.mb_phone).result){
            setInputCheck({
                ...inputCheck,
                mb_phone : false,
                mb_phone_msg : phoneCheck(inputInfo.mb_phone).msg,
            })
            alertModalOn(phoneCheck(inputInfo.mb_phone).msg,'');
            return false;
        }
        if(inputInfo.authCode === ''){
            setInputCheck({
                ...inputCheck,
                authCode : false,
                authCode_msg : '인증번호를 입력해주세요.',
            })
            alertModalOn('인증번호를 입력해주세요.','');
        }
        else{
            const {data,result,msg} = await authPhoneCodeMutation.mutateAsync({
                mb_phone:inputInfo.mb_phone,
                auth_number : inputInfo.authCode,
            })
            if(result){
                
                setIsCodeSend(false);
                alertModalOn(msg,'auth_success');
                setInputCheck({
                    ...inputCheck,
                    mb_phone:true,
                    authCode:true,
                    mb_phone_msg : msg,
                })
                
            }
            else{
                setInputCheck({
                    ...inputCheck,
                    authCode:false,
                    authCode_msg : msg,
                })
                alertModalOn(msg,'');
            }
        }
    }

    const chgPhoneHandler = async () => {
        if(inputInfo.mb_phone === ''){
            alertModalOn('휴대폰번호를 입력해주세요.','');
            return false;
        }
        else if(!inputCheck.authCode){
            alertModalOn('휴대폰인증을 진행해주세요.','');
        }
        else{
            dispatch(setLoading(true));
            const params ={
                jwt_token : userInfo.jwt_token,
                mb_phone_chg : inputInfo.mb_phone
            }
            const {result, msg} = await chgPhoneMutation.mutateAsync(params);

            if(result){
                alertModalOn(msg,'chg_success');
                dispatch(setUserInfo({
                    ...userInfo,
                    mb_phone : inputInfo.mb_phone,
                }))
            }
            else{
                alertModalOn(msg,'');
            }
            setInputCheck({
                mb_phone:false,
                authCode : false,
                mb_phone_msg : '',
                authCode_msg : '',
            });
            setInputInfo({
                mb_phone :'',
                authCode:'',
            })
            dispatch(setLoading(false));
        }
    }

    React.useEffect(()=>{
        setInputCheck({
            ...inputCheck,
            mb_phone : phoneCheck(inputInfo.mb_phone).result,
            mb_phone_msg : phoneCheck(inputInfo.mb_phone).msg
        })
    },[inputInfo.mb_phone])



    return (
        <Modal show={show} onHide={()=>{setShow(false)}} className='modal' centered>
            <div className="modal-header">
                <h5 className="modal-title">핸드폰번호 변경</h5>
                <button type="button" className="btn_close btn" onClick={()=>{setShow(false)}}>
                    <img src="/assets/img/ic_colse.png" alt="닫기 아이콘" />
                </button>
            </div>
            <form className="modal-body">
                <CustomInputBtn 
                    title={'핸드폰번호'}
                    placeHolder={'번호를 입력해주세요'}
                    input={inputInfo.mb_phone}
                    setInput={inputHandler}
                    isValidShow={inputInfo.mb_phone !== ''}
                    isValid={inputCheck.mb_phone}
                    validText={inputCheck.mb_phone_msg}
                    keyName={'mb_phone'}
                    btnLabel={'인증번호 받기'}
                    inputStyle={'col-9 pr-0'}
                    btnStyle={'col-3 pl-0'}
                    btnLabelStyle={'fs_16'}
                    btnAction={sendPhoneCodeHandler}
                    isReadOnly={inputCheck.mb_phone && inputCheck.authCode}
                />
                <MarginY mt={1} />
                {isCodeSend &&
                    <CustomInputBtn 
                        input={inputInfo.authCode}
                        setInput={inputHandler}
                        isValidShow={inputCheck.authCode || inputCheck.authCode_msg !== ''}
                        isValid={inputCheck.authCode}
                        validText={inputCheck.authCode_msg}
                        placeHolder={'인증번호를 입력해주세요.'}
                        btnLabel={'확인'}
                        btnAction={authPhoneCodeHandler}
                        inputStyle={'col-9 pr-0'}
                        btnStyle={'col-3 pl-0'}
                        btnLabelStyle={'fs_16'}
                        keyName={'authCode'}
                    />
                }
            </form>
            <div className="modal-footer mt-5">
                <div className="d-flex w-100">
                    <button type="button" className="btn btn-lg btn-gray btn-block mr-4" onClick={()=>{setShow(false);}}>취소</button>
                    <button type="button" className="btn btn-lg btn-primary btn-block mt-0" onClick={chgPhoneHandler}>확인</button>
                </div>
            </div>
            <AlertModal 
                show={alertModal.show}
                modalOff={alertModalOff}
                alertAction={alertAction}
                type={alertModal.type}
                msg={alertModal.msg}
                btnLabel={'확인'}
            />
        </Modal>
    )
}

export default ChangePhoneModal;
