import React from 'react';
import Calendar from 'react-calendar';
import { DateFormatter, DayPicker } from 'react-day-picker';
import { ko } from 'date-fns/locale';
import ReqEstType from './reqType';
import moment from 'moment';
import '../../assets/style/CustomCalender.css';
import CustomCaption from '../../components/form/DayPicker';
import CustomSelectBox from '../../components/form/CustomSelectBox';
import { dateConverter } from '../../inc/func';
import { MarginY } from '../../inc/FlexStyle';

function ReqEstStep1({reqInfo, setReqInfo,reqStep,setReqStep,setShow,clearInfo,inputCheck,setInputCheck} : ReqEstType) {

    const [date, setDate] = React.useState(reqInfo.startDate);
    const [hour, setHour] = React.useState('00');
    const [min , setMin] = React.useState('00');

    let hourList = [];
    let minList = []
    for(let i=0; i<=24; i++){
        const tempHour = i<10 ? '0'+i : `${i}`;
        hourList.push({
            label:tempHour,
            value:tempHour,
        })
    }
    for(let j=0; j<=59; j++){
        const tempMin = j<10 ? '0'+j : `${j}`;
        minList.push({
            label:tempMin,
            value:tempMin,
        })
    }
    React.useEffect(()=>{
        setDate(reqInfo.startDate);
        if(reqInfo.start_hour !== ''){
            setHour(reqInfo.start_hour);
        }
        if(reqInfo.start_min !== ''){
            setMin(reqInfo.start_min);
        }
    },[])

    React.useEffect(()=>{ //st
        const startYear = dateConverter(reqInfo.startDate).year;
        const startMonth = dateConverter(reqInfo.startDate).month;
        const startDay = dateConverter(reqInfo.startDate).day;

        const endYear = dateConverter(reqInfo.endDate).year;
        const endMonth = dateConverter(reqInfo.endDate).month;
        const endDay = dateConverter(reqInfo.endDate).day;

        setReqInfo({
            ...reqInfo,
            startDate : date,
            endDate : date > reqInfo.endDate ? date : reqInfo.endDate,
            start_hour : hour,
            start_min : min,
            start_date : `${startYear}-${startMonth}-${startDay} ${hour}:${min}`,
        })
        
    },[hour, min,date])
    
    return (
        <div>
            <div className="modal-header justify-content-center">
                <h5 className="modal-title">출발 일자를 선택해주세요!</h5>
            </div>
            <div className="modal-body">
                <ul className="modal_tab">
                    <li className="on"><a onClick={()=>{setReqStep(1)}}>날짜 선택<span className="fc_red"> *</span></a></li>
                    <li><a onClick={()=>{if(inputCheck?.date)setReqStep(3)}}>지역 선택</a></li>
                    <li><a onClick={()=>{if(inputCheck?.date)setReqStep(4)}}>인원수 선택<span className="fc_red"> *</span></a></li>
                    <li><a onClick={()=>{if(inputCheck?.date && inputCheck.people)setReqStep(5)}}>마감일 선택<span className="fc_red"> *</span></a></li>
                    <li><a onClick={()=>{if(inputCheck?.date && inputCheck.deadLine && inputCheck.people)setReqStep(6)}}>완료</a></li>
                </ul>
                <div style={{width:'100%'}}>
                    <DayPicker 
                        mode="single"
                        defaultMonth={reqInfo.startDate}
                        selected={reqInfo.startDate}
                        fromDate={new Date()}
                        onSelect={(date)=>{
                            if(date){
                                // setReqInfo({
                                //     ...reqInfo,
                                //     startDate :date, 
                                // })
                                setDate(date);
                            }
                        }}
                        locale={ko}
                        components={{
                            Caption: CustomCaption
                        }}
                    />
                </div>
                <MarginY mb={2} />
                <div className="form-row w-100">
                    <CustomSelectBox 
                        title={'시'}
                        optionList={hourList}
                        selValue={hour}
                        setSelValue={setHour}
                        style={{flex:1}}
                    />
                    <CustomSelectBox 
                        title={'분'}
                        optionList={minList}
                        selValue={min}
                        setSelValue={setMin}
                        style={{flex:1,marginLeft:10}}
                    />
                </div>
                {/* <div className="ip_wr mt-5">
                    <div className="ip_tit d-flex align-items-center justify-content-between">
                        <h5>시간</h5>
                    </div>
                    <select onChange={(e)=>{setReqInfo({...reqInfo, startTime : e.target.value})}} className="form-control form-control-lg custom-select">
                        <option selected={true}>00:00</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                    </select>
                </div> */}
            </div>
            <div className="modal-footer">
                <div className="d-flex w-100">
                    <button type="button" className="btn btn-lg btn-gray btn-block mb-0" onClick={()=>{
                        if(clearInfo){
                            clearInfo();
                        }
                        if(setShow){
                            setShow(false)
                        }
                        }}>취소</button>
                    <button type="button" className="btn btn-lg btn-primary btn-block ml-4 mt-0" onClick={()=>{setReqStep(2)}}>다음</button>
                </div>
            </div>
        </div>
    )
}

export default ReqEstStep1;
