import classNames from 'classnames';
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import { usePostQuery } from '../inc/axiosQuery';

interface AgreeModalType {
    show : boolean;
    modalOff : () => void;
    type : string;
    title : string;
}

function AgreeModal({
    show,
    modalOff,
    type,
    title,
}:AgreeModalType) {
	const {data: agreeData} = usePostQuery('agree_info',{},'info/service_info.php');
    return (
        <Modal show={show} onHide={()=>{modalOff();}} className='modal' centered>
            <div className={classNames('modal-header',{'justify-content-end' : !title})}>
                {title &&
                    <h5 className="modal-title">{title}</h5>
                }
                <button type="button" className="btn_close btn" onClick={()=>{modalOff();}}>
                    <img src="/assets/img/ic_colse.png" alt="닫기 아이콘" />
                </button>
            </div>
            <div className="modal-body">
                <div className="modal_box">
                    {agreeData &&
                    <div dangerouslySetInnerHTML={{__html:type === '1' ? agreeData.data.company_terms : agreeData.data.company_privacy }}>
                        {/* {agreeData &&
                            <>
                                {}
                            </>
                        } */}
                    </div>
                    }
                </div>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-primary btn-lg btn-block" onClick={()=>{modalOff();}}>확인</button>
            </div>
        </Modal>
    )
}

export default AgreeModal;
