import React from 'react';
import { Link } from 'react-router-dom';
import AgreeModal from '../../modal/AgreeModal';
import { useAppDispatch, useAppSelector } from '../../store/config';
import { usePostQuery } from '../axiosQuery';

function Footer() {

	const {data: footerData} = usePostQuery('footer_info',{},'info/footer_inc.php');

	const [agreeModal,setAgreeModal] = React.useState({
		show:false,
		type:'1',
		title:'서비스 이용 약관'
	})

	const modalOff = () => {
		setAgreeModal({
			show:false,
			type:'1',
			title:'서비스 이용 약관'
		})
	}

	const agreeModalOn = (type:'1'|'2') => {
		console.log(type);
		setAgreeModal({
			show:true,
			type:type,
			title:type === '1' ? '서비스 이용 약관' : '개인정보처리방침'
		})
	}


	const {mb_id} = useAppSelector(state => state.userInfo);
	const dispatch = useAppDispatch();


	// React.useEffect(()=>{
	//   console.log(isDarkMode);
	// },[isDarkMode])

	return (
		<>
			{footerData &&
				<>
					<div className="ft">
						<div className="container">
							<div className="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center">
								<div className="fs_15 ft_left">
									<div className="a_link">
										<a href="#">{footerData.data.company_name}</a>
										<a onClick={()=>{agreeModalOn('1')}}>이용약관</a>
										<a onClick={()=>{agreeModalOn('2')}}>개인정보처리방침</a>
									</div>
									<ul>
										<li>{footerData.data.company_address}</li>
										<div className="border_xl_rg"></div>
										<li>대표 {footerData.data.company_mb_name}</li>
										<div className="border_rg"></div>
										<li>대표전화 {footerData.data.company_center_hp}</li>
										<div className="border_rg"></div>
										<li>사업자 등록번호 {footerData.data.company_number}</li>
									</ul>
									<p>Copyright © tobyfrom All rights reserved.</p>
								</div>
								{/* <div>
									<div className="sns_link">
										<a href="#"><i className="xi xi-facebook"></i></a>
										<a href="#"><i className="xi xi-twitter"></i></a>
										<a href="#"><i className="xi xi-instagram"></i></a>
										<a href="#"><i className="xi xi-youtube"></i></a>
										<a href="#"><i className="xi xi-naver"></i></a>
									</div>
								</div> */}
							</div>
						</div>
					</div>
					<AgreeModal 
						show={agreeModal.show}
						modalOff={modalOff}
						type={agreeModal.type}
						title={agreeModal.title}
					/>
				</>
			}
		</>
	);
}

export default Footer;
