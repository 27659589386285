import classNames from 'classnames';
import React from 'react';
import CustomSelectBox from '../../components/form/CustomSelectBox';
import Loading from '../../components/form/Loading';
import PageNation from '../../components/form/PageNation';
import SpecialProductCom from '../../components/list/SpecialProductCom';
import {usePostMutation, usePostQuery} from '../../inc/axiosQuery';
import { ProductItemType } from '../../inc/type/ProductType';
import NormalSpeDetailIndex from '../../modal/normalProductDetail/NormalProductDetailIndex';
import { useAppDispatch, useAppSelector } from '../../store/config';
import { setReload } from '../../store/slices/ReloadSlice';

interface TravleProListPropType{
    loc:string,
    setLoc:(loc:string)=>void;
    rcmdType:string;
    setRcmdType:(rcmdType:string)=>void;
    searchKey:string,
    setSearchKey:(search:string)=>void;
    getTabList:()=>void;
    selTab : string,
}

type countryType = {
    value : string,
    label : string,
}

function ProductList({searchKey,setSearchKey,getTabList,selTab,loc,setLoc,rcmdType,setRcmdType}:TravleProListPropType) { 


    const dispatch = useAppDispatch();
    const {jwt_token} = useAppSelector(state => state.userInfo);
    const {reload} = useAppSelector(state => state.reload);
    const [isLoading, setIsLoading] = React.useState(false);
    const TrvProListMutation = usePostMutation('trvProList','product/product_tvl_list.php');
    const {data:conList, isLoading : conLoading, isError: conError} = usePostQuery('countryList',{},'cate/loc_list.php');
    const [trvProList, setTrvProList] = React.useState<ProductItemType[]>([]);

    const [page, setPage] = React.useState(1);
    const [totalItem, setTotalItem] = React.useState(1);
    const [showTab, setShowTab] = React.useState(1);
    const [orderby, setOrderby] = React.useState('1');
    const [countryList, setCountryList] = React.useState<countryType[]>([]);

    const [proDetailModal, setProDetailModal] = React.useState({
        show : false,
        selIdx : '',        
    })

    const getTravlProList = async () => {
        setIsLoading(true);
        const params = {
            page:page,
            limit:12,
            sch_txt : searchKey,
            jwt_token:jwt_token,
            orderby:orderby,
            rcmd_type:rcmdType,
            loc:rcmdType === 'N' ? loc : '',
        }

        const {data,result,msg} = await TrvProListMutation.mutateAsync(params);

        if(result){
            
            setTotalItem(data.pro_list.filter((el:ProductItemType) => el.limit_e_date !== '마감').length);
            setTrvProList([...data.pro_list]);
            setIsLoading(false);
        }
    }
    const {data:proDetailData} = usePostQuery('pro_detail',{pt_idx:proDetailModal.selIdx},'product/product_detail.php');


    const searchList = () => {
        setPage(1);
        getTabList();
        getTravlProList();
    }


    const proDetailModalOff = () => {
        setProDetailModal({
            show:false,
            selIdx : '' ,
        })
    }

    React.useEffect(()=>{
        getTravlProList();
        getTabList();
    },[page]);

    React.useEffect(()=>{
        setPage(1);
        getTravlProList();
        getTabList();
    },[showTab,rcmdType,loc,orderby]);

    React.useEffect(()=>{
        if(reload){
            getTravlProList();
            dispatch(setReload(false));
        }
    },[reload])

    React.useEffect(()=>{ //국가 리스트 불러오기
        if(conList){
            let tempArray:countryType[] = [{value:'',label:'전체'}];
            
            conList.data.loc_list.forEach((item:{loc:string,location:string,},index:number) => {
                tempArray.push({
                    value : item.loc,
                    label : item.location,
                })
            })
            setCountryList(tempArray);
        }
    },[conList,conLoading,conError])

    return (
        <>
            <div className="ip_wr search_input">
                <div className="form-row input_row">
                    <div className="col-9 pr-0 ">
                        <input 
                            type="search" 
                            className="form-control form-control-lg" 
                            value={searchKey} 
                            onChange={(e)=>{setSearchKey(e.target.value)}} 
                            placeholder="지역 또는 제목으로 검색하기" 
                            onKeyUp={(e)=>{if(e.key === 'Enter'){
                                searchList();
                            }}}
                        />
                    </div>
                    <div className="col-3 pl-0">
                        <button type="button" className="btn btn-lg btn-primary btn-block" onClick={searchList}>검색</button>
                    </div>
                </div>
            </div>
            <div className="prd_filter">
                <div className="filter_nav">
                    <ul className="nav nav-pills " id="pills-tab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className={classNames('nav-link',{active:rcmdType === 'Y'})} onClick={()=>{setRcmdType('Y')}}>추천상품</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className={classNames('nav-link',{active:rcmdType === ''})} onClick={()=>{setRcmdType('')}}>전체보기</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className={classNames('nav-link',{active:rcmdType === 'N'})} onClick={()=>{setRcmdType('N')}}>지역보기</button>
                        </li>
                    </ul>
                </div>
                <div className="filter_option">
                    <div className="ip_wr ">
                        <select value={orderby} onChange={(e)=>{setOrderby(e.target.value)}} className="form-control  custom-select">
                            <option value="1">최신순</option>
                            <option value="2">행사마감순</option>
                            <option value="3">구매건수</option>
                        </select>
                    </div>
                </div>
                {/* <div className="tab-content w-50" style={{overflow:'scroll'}} id="pills-tabContent">
                    {locList && locList.data.loc_list.map((item:any,index:any) => {
                        return(
                            <a className="mr-3">{item.location}</a>
                        )
                    })}
                </div> */}
                {rcmdType === 'N' &&
                    <CustomSelectBox 
                        optionList={countryList}
                        selValue={loc}
                        setSelValue={setLoc}
                    />
                }
            </div>
            {isLoading ?
                <Loading />
            :
                <>
                    <div className="product_list prd_1">
                        {trvProList.length > 0 ?
                        <div className="row row-cols-3 row-cols-xl-4">
                            {trvProList.map((item,index) => {
                                return(
                                    <>
                                        {item.limit_e_date !== '마감' &&
                                            <div key={index}>
                                                <SpecialProductCom 
                                                    proItem={item}
                                                    type={'travle'}
                                                    index={index}
                                                    detailOn={setProDetailModal}
                                                />
                                            </div>
                                        }
                                    </>
                                )
                            })}
                        </div>
                        :
                        <p className="text-center">조회된 기획상품이 존재하지 않습니다.</p>
                        }
                        {trvProList.filter(el=>el.limit_e_date !== '마감').length > 0 && 
                            <PageNation 
                                page={page}
                                totalItem={totalItem}
                                limitItem={12}
                                setPage={setPage}
                            />
                        }
                    </div>
                    {(proDetailModal.selIdx !== '' && proDetailData) &&
                        <NormalSpeDetailIndex 
                            show={proDetailModal.show}
                            selIdx={proDetailModal.selIdx}
                            setShow={proDetailModalOff}
                            detailInfo={proDetailData.data}
                        />   
                    }
                </>
            }
        </>
    );
}

export default ProductList;
