import React from 'react';
import { Accordion } from 'react-bootstrap';
import PageNation from '../../components/form/PageNation';
import ReqList from '../../components/list/ReqList';
import {fetchPostData} from '../../inc/axiosQuery';
import { useAppSelector } from '../../store/config';
import { LandReqListType } from '../../inc/type/reqType';
import Loading from '../../components/form/Loading';
import LoadingModal from '../../modal/LoadingModal';

interface LandListPropType {
    searchKey:string,
    setSearchKey:(search:string)=>void;
    getTabList:()=>void;
    selTab:string,
    setSelTab:(index:string)=>void;
}

function LandList({searchKey,setSearchKey,getTabList,selTab,setSelTab}:LandListPropType) { 

    const {jwt_token} = useAppSelector(state => state.userInfo);
    const {reload} = useAppSelector(state=>state.reload);

    const [isLoading, setIsLoading] = React.useState(false);
    const [selListIdx,setSelListIdx] = React.useState('');
    const [page, setPage] = React.useState(1);
    const [totalItem, setTotalItem] = React.useState(1);
    const [landList, setLandList] = React.useState<LandReqListType[]>([])

    const getLandList = async() => {
        setIsLoading(true);
        const params ={
            limit:10,
            page:page,
            jwt_token:jwt_token,
            sch_txt : searchKey,
        }
        if(selTab !== '10'){
            const tabParams = {
                req_status : selTab
            }
            Object.assign(params,tabParams);
        }

        await fetchPostData(params,selTab === '10' ? 'doc/request_now_list.php' : 'doc/request_land_list.php')
        .then(res => {
            if(res.data){
                setTotalItem(res.data.tot_cnt);
                setLandList([...res.data.req_list]);
            }
            setIsLoading(false);
        })
    }

    React.useEffect(()=>{
        getLandList();
        setSelListIdx('')
    },[page,selTab])

    React.useEffect(()=>{
        if(reload){getLandList(); setSelListIdx('')};
    },[reload])


    return (
        <>
            <div className="ip_wr search_input">
                <div className="form-row input_row">
                    <div className="col-9 pr-0 ">
                        <input 
                            type="search" 
                            className="form-control form-control-lg" 
                            value={searchKey} 
                            onChange={(e)=>{setSearchKey(e.target.value)}} 
                            placeholder="지역 또는 제목으로 검색하기" 
                            onKeyUp={(e)=>{
                                if(e.key === 'Enter'){
                                    getLandList(); 
                                    getTabList();
                                }
                            }}
                        />
                    </div>
                    <div className="col-3 pl-0">
                        <button type="button" className="btn btn-lg btn-primary btn-block" onClick={()=>{getLandList(); getTabList();}}>검색</button>
                    </div>
                </div>
            </div>
            {isLoading ?
                <Loading />
                :
                <>
                    <Accordion defaultActiveKey={selListIdx}>
                        <div className="accordion est_acc">
                            {landList.length > 0 ?
                                landList.map((item,index) => {
                                    return(
                                        <ReqList 
                                            key={index} 
                                            setSelListIdx={setSelListIdx} 
                                            selListIdx={selListIdx} 
                                            index={index}
                                            reqItem={item}
                                            setSelTab={setSelTab}
                                            reload={getLandList}
                                        />
                                    )
                                })
                                :
                                <div className="w-100 d-flex flex-row justify-content-center align-items-center m-5">
                                    <p>조회된 견적이 존재하지 않습니다.</p>
                                </div>
                            }
                        </div>
                    </Accordion>
                    {landList.length > 0 &&
                        <PageNation 
                            page={page}
                            totalItem={totalItem}
                            limitItem={10}
                            setPage={setPage}
                        />
                    }
                </>
            }
        </>
    );
}

export default LandList;
