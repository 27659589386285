import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';
import { usePostMutation } from '../../inc/axiosQuery';
import { NumberComma } from '../../inc/func';
import { LandReqListType, TravleReqListType } from '../../inc/type/reqType';
import { useAppSelector } from '../../store/config';
import { setReload } from '../../store/slices/ReloadSlice';

interface ModalType {
    show : boolean;
    setShow : (hide:boolean) => void;
    confirmSpecifi : () => void;
    reqItem:LandReqListType | TravleReqListType
}

function SpecifiModal({ //여행사 - 계약하기
    show,
    setShow,
    confirmSpecifi,
    reqItem,
}:ModalType) {
    const priceType = reqItem.est_sct_item.price_type === '달러' ? 'USD ' : 'KRW ';

    const totalPrice = Number(reqItem.cnt_adult)*Number(reqItem.est_sct_item.price_adult) +
                       Number(reqItem.cnt_child)*Number(reqItem.est_sct_item.price_child) +
                       Number(reqItem.cnt_infant)*Number(reqItem.est_sct_item.price_infant) -
                       Number(reqItem.est_sct_item.price_dis);
    


    return (
        <Modal show={show} onHide={()=>{setShow(false);}} className='modal' centered>
            <div className="modal-header">
                <h5 className="modal-title">명세서</h5>
                <button type="button" className="btn_close btn" onClick={()=>{setShow(false);}}>
                    <img src="/assets/img/ic_colse.png" alt="닫기 아이콘" />
                </button>
            </div>
            <div className="modal-body">
                <div className="info_box">
                    <p className="fs_18 fw_700 mb-3 pb-3">{reqItem.req_name}</p>
                    <p className="info_p">
                        <span>지역</span>
                        <span className="fs_14">{reqItem.loc_txt}{reqItem.loc_1_txt !== '' && ' / '+reqItem.loc_1_txt}</span>
                    </p>
                    <div className="d-flex">
                        <p className="info_p mr-5">
                            <span>출발</span>
                            <span className="fs_14">{reqItem.start_date_txt}</span>
                        </p>
                        <p className="info_p">
                            <span>도착</span>
                            <span className="fs_14">{reqItem.end_date_txt}</span>
                        </p>
                    </div>
                    <div className="d-flex">
                        <p className="info_p mr-5">
                            <span>담당자</span>
                            <span className="fs_14">{reqItem.req_mb_name}</span>
                        </p>
                        <p className="info_p">
                            <span>여행 인원수</span>
                            <span className="fs_14">성인 {reqItem.cnt_adult}명 / 유아 {reqItem.cnt_child}명 /소아 {reqItem.cnt_infant}명</span>
                        </p>
                    </div>
                    <p className="info_p"><span>요청사항</span></p>
                    <div className="info_txt">
                        {reqItem.req_memo === '' ? '요청사항이 등록되어있지 않습니다.' : reqItem.req_memo}
                    </div>
                </div>
                <p className="info_p mt-5"><span>해당업체</span></p>
                <div className="d-flex mt-3">
                    <div className="ic_dot"></div>
                    <div className="fs_14">
                        <p className="fw_600 mb-3">{reqItem.est_sct_item.company_name}</p>
                        <p>제안금액 : 성인 {priceType}{NumberComma(Number(reqItem.est_sct_item.price_adult))} / 소아 {priceType}{NumberComma(Number(reqItem.est_sct_item.price_child))} / 유아 {priceType}{NumberComma(Number(reqItem.est_sct_item.price_infant))}</p>
                    </div>
                </div>
                <p className="info_p mt-3 pt-3"><span>합계</span></p>
                <div className="info_box py-4">
                    <p className="d-flex align-items-center justify-content-between fs_14 py-2">
                        <span className="d-flex justify-content-between">
                            <span className="span_cate">성인(+)</span>
                            <span>x {reqItem.cnt_adult}</span>
                        </span>
                        <span>{priceType}{NumberComma(Number(reqItem.cnt_adult)*Number(reqItem.est_sct_item.price_adult))}</span>
                    </p>
                    <p className="d-flex align-items-center justify-content-between fs_14 py-2">
                        <span className="d-flex justify-content-between">
                            <span className="span_cate">유아(+)</span>
                            <span>x {reqItem.cnt_child}</span>
                        </span>
                        <span>{priceType}{NumberComma(Number(reqItem.cnt_child)*Number(reqItem.est_sct_item.price_child))}</span>
                    </p>
                    <p className="d-flex align-items-center justify-content-between fs_14 py-2">
                        <span className="d-flex justify-content-between">
                            <span className="span_cate">소아(+)</span>
                            <span>x {reqItem.cnt_infant}</span>
                        </span>
                        <span>{priceType}{NumberComma(Number(reqItem.cnt_infant)*Number(reqItem.est_sct_item.price_infant))}</span>
                    </p>
                    <p className="d-flex align-items-center justify-content-between fs_14 py-2">
                        <span>할인 금액(-)</span>
                        <span>{priceType}{NumberComma(Number(reqItem.est_sct_item.price_dis))}</span>
                    </p>
                    <div className="border-top my-4"></div>
                    <p className="d-flex align-items-center justify-content-between fs_14">
                        <span>합계</span>
                        <span className="fs_18 fw_600">{priceType}{NumberComma(totalPrice)}</span>
                    </p>
                </div>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-lg btn-primary btn-block" onClick={()=>{setShow(false); confirmSpecifi();}}>계약하기</button>
            </div>
        </Modal>
    )
}

export default SpecifiModal;
