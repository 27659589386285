import classNames from 'classnames';
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import { usePostMutation, usePostQuery } from '../../inc/axiosQuery';
import Loading from '../../components/form/Loading';
import { ProductItemType } from '../../inc/type/ProductType';
import { useAppDispatch, useAppSelector } from '../../store/config';
import { setReload } from '../../store/slices/ReloadSlice';
import AlertModal from '../AlertModal';

interface ModalType {
    show : boolean;
    setShow : (hide:boolean) => void;
    selIdx : string,
}

function ProductCancleModal({ // 랜드사 - 기획상품 취소하기
    show,
    setShow,
    selIdx,
}:ModalType) {

    const dispatch = useAppDispatch();
    const {jwt_token} = useAppSelector(state => state.userInfo);
    const {data , isLoading, isError} = usePostQuery('getProDetail',{pt_idx:selIdx},'product/product_detail.php');
    const cancleRecMutation = usePostMutation('getCncleRec','product/product_cancel.php');

    const initialAleretModal = {
        alert : false,
        msg : '',
        type : '',
    }
    const [proDetail, setProDetail] = React.useState<ProductItemType>();
    const [alertModal, setAlertModal] = React.useState(()=>initialAleretModal);

    const alertModalOff = () =>{
        setAlertModal(()=>initialAleretModal);
    }
    const alertModalOn = (msg:string, type : string) => {
        setAlertModal({
            alert:true,
            msg : msg,
            type : type,
        })
    }
    const alertAction = () => {
        if(alertModal.type === 'success'){
            alertModalOff();
            setShow(false);
            dispatch(setReload(true));
        }
    }

    const cancleRecHandler = async () => {
        const {result, msg } = await cancleRecMutation.mutateAsync({
            jwt_token:jwt_token,
            pt_idx : selIdx,
        })

        if(result){
            alertModalOn(msg,'success');
        }
        else{
            alertModalOn(msg,'');
        }
    }

    React.useEffect(()=>{
        if(data){
            setProDetail(data.data);
        }
    },[data])

    return (
        <Modal show={show} onHide={()=>{setShow(false);}} className='modal' centered>
            <div className="modal-header">
                <h5 className="modal-title">취소하기</h5>
                <button type="button" className="btn_close btn" onClick={()=>{setShow(false)}}>
                    <img src="/assets/img/ic_colse.png" alt="닫기 아이콘" />
                </button>
            </div>
            {proDetail ?
            <div className="modal-body">
                <div className="info_box">
                    <p className="fs_18 fw_700 mb-3 pb-3">{}</p>
                    <p className="info_p">
                        <span>지역</span>
                        <span className="fs_14">{proDetail.loc_txt}{proDetail.loc_1_txt !== '' && ' / '+proDetail.loc_1_txt}</span>
                    </p>
                    <div className="d-flex">
                        <p className="info_p mr-5">
                            <span>출발</span>
                            <span className="fs_14">{proDetail.pt_start_datetime}</span>
                        </p>
                        <p className="info_p">
                            <span>도착</span>
                            <span className="fs_14">{proDetail.pt_end_datetime}</span>
                        </p>
                    </div>
                    <div className="d-flex">
                        <p className="info_p mr-5">
                            <span>담당자</span>
                            <span className="fs_14">{proDetail.pt_mb_name}</span>
                        </p>
                        <p className="info_p">
                            <span>여행 인원수</span>
                            <span className="fs_14">성인 {proDetail.cnt_adult} / 유아 {proDetail.cnt_child} /소아 {proDetail.cnt_infant}</span>
                        </p>
                    </div>
                    <p className="info_p"><span>요청사항</span></p>
                    <div className="info_txt">
                        {proDetail.pt_memo === '' ? '요청사항이 등록되어있지 않습니다.' : proDetail.pt_memo}
                    </div>
                </div>
                <p className="mt-3 pt-3 line_h1_6 fc_red fs_14">취소하게 되면 견적서 내용을 불러올 수 없습니다. <br />그래도 취소하겠습니까?
                </p>
            </div>
            :
            <Loading />
            }
            <div className="modal-footer">
                <div className="d-flex w-100">
                    <button type="button" className="btn btn-lg btn-red btn-block mr-4 mb-0" onClick={()=>{cancleRecHandler();}}>예</button>
                    <button type="button" className="btn btn-lg btn-gray btn-block mt-0" onClick={()=>{setShow(false)}}>아니오</button>
                </div>
            </div>
            <AlertModal 
                show={alertModal.alert}
                msg={alertModal.msg}
                type={alertModal.type}
                alertAction={alertAction}
                btnLabel='확인'
                modalOff={alertModalOff}
            />
        </Modal>
    )
}

export default ProductCancleModal;
