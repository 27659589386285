import React from 'react';
import Calendar from 'react-calendar';
import { DateFormatter, DayPicker } from 'react-day-picker';
import { ko } from 'date-fns/locale';
import moment from 'moment';
import '../../assets/style/CustomCalender.css';
import CustomCaption from '../../components/form/DayPicker';
import CustomSelectBox from '../../components/form/CustomSelectBox';
import { dateConverter } from '../../inc/func';
import { MarginY } from '../../inc/FlexStyle';
import { RecProType } from './recType';

function RecStep1({recInfo, setRecInfo,recStep,setRecStep,setShow,inputCheck} : RecProType) {

    const [date, setDate] = React.useState(recInfo.startDate);

    const [hour, setHour] = React.useState('00');
    const [min , setMin] = React.useState('00');

    let hourList = [];
    let minList = []
    for(let i=0; i<=24; i++){
        const tempHour = i<10 ? '0'+i : `${i}`;
        hourList.push({
            label:tempHour,
            value:tempHour,
        })
    }
    for(let j=0; j<=59; j++){
        const tempMin = j<10 ? '0'+j : `${j}`;
        minList.push({
            label:tempMin,
            value:tempMin,
        })
    }
    React.useEffect(()=>{
        setDate(recInfo.startDate);
        if(recInfo.start_hour !== ''){
            setHour(recInfo.start_hour);
        }
        if(recInfo.start_min !== ''){
            setMin(recInfo.start_min);
        }
    },[])

    React.useEffect(()=>{ //st
        const startYear = dateConverter(date).year;
        const startMonth = dateConverter(date).month;
        const startDay = dateConverter(date).day;

        setRecInfo({
            ...recInfo,
            startDate : date,
            endDate : date > recInfo.endDate ? date : recInfo.endDate,
            start_hour : hour,
            start_min : min,
            pt_start_datetime : `${startYear}-${startMonth}-${startDay} ${hour}:${min}`,
        })
    },[date,hour,min])
    
    return (
        <div>
            <div className="modal-header justify-content-center">
                <h5 className="modal-title">행사 시작일을 선택해주세요!</h5>
            </div>
            <div className="modal-body">
                <ul className="modal_tab">
                    <li className="on"><a onClick={()=>{setRecStep(1)}}>날짜 선택<span className="fc_red"> *</span></a></li>
                    <li><a onClick={()=>{if(inputCheck?.date)setRecStep(3)}}>지역 선택</a></li>
                    <li><a onClick={()=>{if(inputCheck?.date)setRecStep(4)}}>인원수 선택<span className="fc_red"> *</span></a></li>
                    <li><a onClick={()=>{if(inputCheck?.date && inputCheck?.people)setRecStep(5)}}>완료</a></li>
                </ul>
                <div style={{width:'100%'}}>
                    <DayPicker 
                        mode="single"
                        defaultMonth={recInfo.startDate}
                        selected={recInfo.startDate}
                        fromDate={new Date()}
                        onSelect={(date)=>{
                            if(date){
                                // setReqInfo({
                                //     ...reqInfo,
                                //     startDate :date, 
                                // })
                                setDate(date);
                            }
                        }}
                        locale={ko}
                        components={{
                            Caption: CustomCaption
                        }}
                    />
                </div>
                <MarginY mb={2} />
                <div className="form-row w-100">
                    <CustomSelectBox 
                        title={'시'}
                        optionList={hourList}
                        selValue={hour}
                        setSelValue={setHour}
                        style={{flex:1}}
                    />
                    <CustomSelectBox 
                        title={'분'}
                        optionList={minList}
                        selValue={min}
                        setSelValue={setMin}
                        style={{flex:1,marginLeft:10}}
                    />
                </div>
            </div>
            <div className="modal-footer">
                <div className="d-flex w-100">
                    <button type="button" className="btn btn-lg btn-gray btn-block mb-0" onClick={()=>{if(setShow)setShow(false)}}>취소</button>
                    <button type="button" className="btn btn-lg btn-primary btn-block ml-4 mt-0" onClick={()=>{setRecStep(recStep+1)}}>다음</button>
                </div>
            </div>
        </div>
    )
}

export default RecStep1;
