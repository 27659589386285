import React from 'react';

import classNames from 'classnames';

interface CustomInputType {
    title? : string,
    input : string | number,
    setInput : (text:string,key?:string) => void;
    type? : 'password' | 'number',
    placeHolder : string,
    isValidShow : Boolean,
    isValid? : Boolean,
    validText? : string,
    keyName?:string,
    isReadOnly? : boolean,
    style? : string,
}

function CustomInput({
    title,
    input,
    setInput,
    type,
    placeHolder,
    isValidShow,
    isValid,
    validText,
    keyName,
    isReadOnly,
    style
}:CustomInputType) {

    const validTextStyle = {
        'ip_valid' : isValid,
        'ip_invalid' : !isValid,
        'mb-3' : isValidShow,
    }

    return (
        <div className={classNames(`ip_wr ${style}`,validTextStyle)}>
            {title &&
                <div className="ip_tit">
                    <h5>{title}</h5>
                </div>
            }
            <input 
                type={type ? type : 'text'} 
                value={input} 
                className="form-control form-control-lg" 
                placeholder={placeHolder} 
                readOnly={isReadOnly}
                onChange={(e)=>{
                    if(keyName){
                        setInput(e.target.value,keyName);
                    }
                    else{
                        setInput(e.target.value);
                    }
                }} 
                autoComplete="off"
            />
            <div className={classNames('form-text',validTextStyle)}>{isValidShow && `* ${validText}`}</div>
        </div>
    );
}

export default CustomInput;
