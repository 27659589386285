import React from 'react';

import classNames from 'classnames';

interface CustomButtonType {
    type? : 'sm' | 'md' | 'normal' | 'lg',
    color? : 'green' | 'white' | 'lightGreen' | 'trans',
    btnLabel : string,
    clickEvent? : ()=>void;
    labelStyle? : string,
}

function CustomButton({
    type,
    color,
    btnLabel,
    clickEvent,
    labelStyle,
}:CustomButtonType) {

    const btnClass = {
        
        //btn type
        'btn-sm' : type === 'sm',
        'btn-md' : type === 'md',
        'btn-lg btn-block' : type === 'lg',

        //btn color
        'btn-primary' : color==='green',
        'btn-outline-primary' : color==='white',
        'btn-secondary' : color==='lightGreen',
        'btn-outline-secondary' : color==='trans',
    }

    return (
        <button 
            type="button" 
            onClick={()=>{if(clickEvent)clickEvent();}}
            className={
                classNames(`btn ${labelStyle}`, btnClass)
            }>
                {btnLabel}
        </button>
    );
}


export default CustomButton;
