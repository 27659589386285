import React from 'react';


interface CustomTextareaType {
    title? : string,
    contents : string,
    setContents : (text:string,key?:string) => void,
    placeHolder : string,
    limit : number,
    keyName? : string,
    row? : number,
}

function CustomTextarea({
    title,
    contents,
    setContents,
    placeHolder,
    limit,
    keyName,
    row,
}:CustomTextareaType) {
    return (
        <div className="ip_wr">
            <div className="ip_tit d-flex align-items-center justify-content-between">
                <h5>{title}</h5>
                <p className="fc_mgr fs_14">({contents === '' ? '0' : contents.length}/{limit})</p>
            </div>
            <textarea 
                className="form-control form-control-lg" 
                placeholder={placeHolder} 
                rows={row ? row : 5}
                maxLength={limit}
                value={contents}
                onChange={(e)=>{
                    if(keyName){
                        setContents(e.target.value,keyName)
                    }
                    else{
                        setContents(e.target.value)
                    }
                }}
            ></textarea>
            <div className="invalid-feedback">{limit}자까지만 써주세요</div>
        </div>
    );
}

export default CustomTextarea;
