import React from 'react';
import classNames from 'classnames';
import { Link, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../store/config';

function SelectJoinType() {

    const {mb_type} = useAppSelector(state => state.userInfo);
    const navigator = useNavigate();


    React.useEffect(()=>{
        if(mb_type === '1'){
            navigator('/travleMain',{replace:true});
        }
        else if(mb_type === '2'){
            navigator('/landMain',{replace:true});
        }
    },[])

    return (
        <div className="container-fluid px-0">
            <div className="sub_pg">
                <section className="container-lg mx-auto">
                    <div className="lg_selctbx">
                        <div className="tit_wr">
                            <h3 className="tit_h3 tit_point">여행사&일반 회원가입</h3>
                        </div>
                        <div className="sel_wr">
                            <Link to="/travelJoin" className="join_lk ">
                                <div className="top">
                                    <h4 className="tit_h4">여행사(법인)</h4>
                                    <figure>
                                        <img src="/assets/img/ic_join03.png" alt="여행사법인 회원가입" />
                                    </figure>
                                </div>
                                <div className="bom">
                                    <p className="fs_20 fw_700">회원가입</p>
                                </div>
                            </Link>
                            <Link to='/nomalJoinSel' className="join_lk">
                                <div className="top">
                                    <h4 className="tit_h4">개인</h4>
                                    <figure>
                                        <img src="/assets/img/ic_join04.png" alt="개인 회원가입" />
                                    </figure>
                                </div>
                                <div className="bom">
                                    <p className="fs_20 fw_700">회원가입</p>
                                </div>
                            </Link>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default SelectJoinType;
