import React from 'react';
import Calendar from 'react-calendar';
import { DateFormatter, DayPicker } from 'react-day-picker';
import { ko } from 'date-fns/locale';
import ReqEstType from './reqType';
import moment from 'moment';
import '../../assets/style/CustomCalender.css';
import CustomCaption from '../../components/form/DayPicker';
import CustomSelectBox from '../../components/form/CustomSelectBox';

function ReqEstStep4({reqInfo, setReqInfo,reqStep,setReqStep,inputCheck} : ReqEstType) {    



    const countSelPeople = (type:string, count:'up' | 'down') => {
        switch(type){
            case 'cnt_adult' : 
                if(reqInfo.cnt_adult !== 0 || count !== 'down'){
                    setReqInfo({
                        ...reqInfo,
                        [type] : count === 'up' ? reqInfo.cnt_adult +1 : reqInfo.cnt_adult -1 ,
                    })
                }
            break;
            case 'cnt_child' :
                if(reqInfo.cnt_child !== 0 || count !== 'down'){
                    setReqInfo({
                        ...reqInfo,
                        [type] : count === 'up' ? reqInfo.cnt_child +1 : reqInfo.cnt_child -1 ,
                    })
                }
            break;
            case 'cnt_infant' :
                if(reqInfo.cnt_infant || count !== 'down'){
                    setReqInfo({
                        ...reqInfo,
                        [type] : count === 'up' ? reqInfo.cnt_infant +1 : reqInfo.cnt_infant -1 ,
                    })
                }
            break;
        }
    }

    const countInputHandler = (type:string, count:string) => {
        // if(count > 0){
            if(!isNaN(Number(count))){
                setReqInfo({
                    ...reqInfo,
                    [type] : Number(count),
                })
            }
        // }
    }
    
    console.log(inputCheck);

    return (
        

        <div>
            <div className="modal-header justify-content-center">
                <h5 className="modal-title">인원수를 선택해주세요!</h5>
            </div>
            <div className="modal-body">
                <ul className="modal_tab">
                    <li><a onClick={()=>{setReqStep(1)}}>날짜 선택<span className="fc_red"> *</span></a></li>
                    <li><a onClick={()=>{if(inputCheck?.date)setReqStep(3)}}>지역 선택</a></li>
                    <li className="on"><a onClick={()=>{if(inputCheck?.date)setReqStep(4)}}>인원수 선택<span className="fc_red"> *</span></a></li>
                    <li><a onClick={()=>{if(inputCheck?.date && inputCheck?.people)setReqStep(5)}}>마감일 선택<span className="fc_red"> *</span></a></li>
                    <li><a onClick={()=>{if(inputCheck?.date && inputCheck?.deadLine && inputCheck?.people)setReqStep(6)}}>완료</a></li>
                </ul>
                <p className='mb-5'><span className="fc_red">* </span>성인 / 아동 / 유아 중 최소 1명 이상 선택해주셔야합니다.</p>
                <ul>
                    <li className="d_sp_center mb-3">
                        <p>성인</p>
                        <div className="count">
                            <a type="button" onClick={()=>{countSelPeople('cnt_adult','down')}}><img src="/assets/img/ic_minus.png" alt="빼기" /></a>
                            <div style={{flex:1,textAlign:'center',alignItems:'center',justifyContent:'center'}}>
                                <input type="text" className='count-input' value={Number(reqInfo.cnt_adult)/1} onChange={(e)=>{countInputHandler('cnt_adult',e.target.value)}}/>
                            </div>
                            <a type="button" onClick={()=>{countSelPeople('cnt_adult','up')}}><img src="/assets/img/ic_plus.png" alt="더하기" /></a>
                        </div>
                    </li>
                    <li className="d_sp_center mb-3">
                        <div>
                            <p>아동</p>
                            <p className='fs_13 mt-2'>- 만 12세미만</p>
                        </div>
                        <div className="count">
                            <a type="button" onClick={()=>{countSelPeople('cnt_child','down')}}><img src="/assets/img/ic_minus.png" alt="빼기" /></a>
                            <div style={{flex:1,textAlign:'center',alignItems:'center',justifyContent:'center'}}>
                                <input type="text" className='count-input' value={Number(reqInfo.cnt_child)/1} onChange={(e)=>{countInputHandler('cnt_child',e.target.value)}}/>
                            </div>
                            <a type="button" onClick={()=>{countSelPeople('cnt_child','up')}}><img src="/assets/img/ic_plus.png" alt="더하기" /></a>
                        </div>
                    </li>
                    <li className="d_sp_center ">
                        <div>
                            <p>유아</p>
                            <p className="fs_13 mt-2">- 만 2세 미만</p>
                        </div> 
                        <div className="count">
                            <a type="button" onClick={()=>{countSelPeople('cnt_infant','down')}}><img src="/assets/img/ic_minus.png" alt="빼기" /></a>
                            <div style={{flex:1,textAlign:'center',alignItems:'center',justifyContent:'center'}}>
                                <input type="text" className='count-input' value={Number(reqInfo.cnt_infant)/1} onChange={(e)=>{countInputHandler('cnt_infant',e.target.value)}}/>
                            </div>
                            <a type="button" onClick={()=>{countSelPeople('cnt_infant','up')}}><img src="/assets/img/ic_plus.png" alt="더하기" /></a>
                        </div>
                    </li>
                </ul>
            </div>
            <div className="modal-footer">
                <div className="d-flex w-100">
                    <button type="button" className="btn btn-lg btn-gray btn-block mb-0" onClick={()=>{setReqStep(3)}}>이전</button>
                    {inputCheck?.people ?
                        <button type="button" className="btn btn-lg btn-primary btn-block ml-4 mt-0" onClick={()=>{setReqStep(5)}}>다음</button>
                    :
                        <button type="button" className="btn btn-lg btn-gray btn-block ml-4 mt-0" onClick={()=>{}}>다음</button>
                    }
                    </div>
            </div>
        </div>
    )
}

export default ReqEstStep4;
