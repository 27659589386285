import React from 'react';
import logo from './logo.svg';

import Slider from 'react-slick';
import UseCountUp from '../inc/UseCountup';
import ReqList from '../components/list/ReqList';
import Accordion from 'react-bootstrap/Accordion';
import PageNation from '../components/form/PageNation';
import { usePostQuery } from '../inc/axiosQuery';
import CountUp from 'react-countup';
import { TravleReqListType } from '../inc/type/reqType';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Loading from '../components/form/Loading';
import { useAppSelector } from '../store/config';
import LoginModal from '../modal/user/LoginModal';
import CustomButton from '../components/form/CustomButton';


function Main() {
    const [page, setPage] = React.useState(1);
    const [totalItem, setTotalItem] = React.useState(1);
    const [isLoginModal,setIsLoginModal] = React.useState(false);

    const [updateDate, setUpdateDate] = React.useState({
        year: new Date().getFullYear(),
        month : new Date().getMonth()+1,
        day : new Date().getDate(),
    })

    const {mb_type} = useAppSelector(state => state.userInfo);

    const {data:count, isLoading : countLoading, isError: countError , refetch : countRefetch} = usePostQuery('mainCount',{},'info/maininfo.php');
    const {data:list, isLoading : listLoading, isError: listError, refetch : listRefetch} = usePostQuery('mainList',{page : page,limit:10},'info/request_main_now_list.php');
    const {data:bannerList, isLoading : bannerLoding, isError : bannerError } = usePostQuery('bannerList',{},'info/coop_banner_list.php');


    const [mainCounter , setMainCounter] = React.useState({
        userValue:0,
        reqValue:0,
        tradeValue:0,
        updateDate : '',
    })

    const [mainBannerList, setMainBannerList] = React.useState<{banner_img:string,banner_text:string,idx:string,sort:string}[]>([]);

    let slideSettings = { //메인 케로셀 
        dots:false,
        slidesToShow : 2.28,
        autoplay:true,
        speed:300,
        arrows:false,
    };

    const responsive = {
        
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 10,
          partialVisibilityGutter:20,
        },
        
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 7,
          partialVisibilityGutter:20,
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 6,
          partialVisibilityGutter:20,
        },
        desktopSmall:{
            breakpoint: { max: 3000, min: 1024 },
            items: 6,
            partialVisibilityGutter:20,
        },
      };

    const bannerClickHandler = () => {
        if(mb_type === ''){
            setIsLoginModal(true);
        }
        else{

        }
    }

    const updateCount = () => {
        countRefetch();
        setUpdateDate({
            year : new Date().getFullYear(),
            month : new Date().getMonth()+1,
            day : new Date().getDate(),
        })
    }

    // let partnerSlideSettings = {
    //     slidesToShow: 9.5,
        
    //     dots: false,
    //     centerMode: true,
    //     autoplay: true,
    //     speed: 300,
    //     arrows: false,
    //     responsive: [{
    //             breakpoint: 1400,
    //             settings: {
    //                 slidesToShow: 7.5,
    //             }
    //         },
    //         {
    //             breakpoint: 1024,
    //             settings: {
    //                 slidesToShow: 6.5,
    //             }
    //         },
    //     ]
    // }
    const [selListIdx,setSelListIdx] = React.useState('');
    const [listData, setListData] = React.useState<TravleReqListType[]>([]);
    

    const selIdxHandler = async (idx:string) => {
        await setSelListIdx(idx);
    }



    React.useEffect(()=>{
        if(count){
            console.log(count);
            setMainCounter({
                userValue : count.data.user_cnt,
                reqValue : count.data.req_cnt,
                tradeValue : count.data.price_tot_kr,
                updateDate : count.data.date_txt
            })
            setMainBannerList([...count.data.banner_list]);
        }
    },[count,countLoading,countError]);

    React.useEffect(()=>{
        if(list){
            setListData([...list.data.req_list]);
            setTotalItem(list.data.tot_cnt);
            setSelListIdx('');
        }
    },[list,listLoading,listError])

    React.useEffect(()=>{
        if(bannerList){
            console.log(bannerList);
        }
    },[bannerList])
    


    return (
        <div className="container-fluid px-0">
            <div className="idx_pg">
            <section className="main_slider">
                <Slider 
                    className='carousel'
                    {...slideSettings}
                >
                    {/* <div><a onClick={()=>{setIsLoginModal(true)}}><img src="/assets/img/main_slide01.jpg" /></a></div>
                    <div><a onClick={()=>{setIsLoginModal(true)}}><img src="/assets/img/main_slide02.jpg" /></a></div>
                    <div><a onClick={()=>{setIsLoginModal(true)}}><img src="/assets/img/main_slide03.jpg" /></a></div> */}
                    {mainBannerList.map((item,index) => {
                        return(
                            <div style={{width:400,height:200}}>
                                <a onClick={()=>{setIsLoginModal(true)}} style={{position:'relative'}}>
                                    <img src={item.banner_img} style={{width:900,height:350,objectFit:'cover',borderRadius:8}}/>
                                    <div style={{position:'absolute',top:'50%',left:'2rem',transform: 'translate(0,-50%)'}}>
                                        <div dangerouslySetInnerHTML={{__html:item.banner_text.replace('\n','<br />')}} className={"fw_700 text-white fs_30"}></div>
                                        <div className="mt-4" />
                                        <CustomButton 
                                            btnLabel='견적 요청하기'
                                            color='green'
                                        />
                                    </div>
                                </a>
                            </div>
                        )
                    })}
                    
                </Slider>
            </section>
            <section className="container-lg mx-auto">
                <div className="cont_wr mx_n15 d-flex flex-wrap justify-content-spacebetween">
                    <div className="count_bx">
                        <div className="count_wr">
                            <p>현재 가입고객수</p>
                            <p className="num"><span className="number"><CountUp end={mainCounter.userValue} separator=','/></span><span>명</span></p>
                            <img src="/assets/img/ic_main01.png" alt="현재 가입고객수" />
                        </div>
                    </div>
                    <div className="count_bx">
                        <div className="count_wr">
                            <p>실시간견적 요청건수</p>
                            <p className="num"><span className="number"><CountUp end={mainCounter.reqValue} separator=','/></span><span>건</span></p>
                            <img src="/assets/img/ic_main02.png" alt="실시간견적 요청건수" />
                        </div>
                    </div>
                    <div className="count_bx">
                        <div className="count_wr">
                            <p>거래금액</p>
                            <p className="num"><span className="number"><CountUp end={mainCounter.tradeValue} separator=','/></span><span>원</span></p>
                            <img src="/assets/img/ic_main03.png" alt="거래금액" />
                        </div>
                    </div>
                    <a onClick={()=>{updateCount()}} type="button" className="count_ch w-100"><img src="/assets/img/ic_change.png" alt="새로고침" /> {updateDate.year}년 {updateDate.month}월 {updateDate.day}일 업데이트 기준</a>
                </div>
            </section>
            <section className="container-lg mx-auto">
                <div className="cont_wr">
                    <h2 className="tit_h2 tit_point gmarket">실시간 견적 요청 중</h2>
                    <Accordion defaultActiveKey={selListIdx}>
                        <div className="accordion est_acc">
                            {listLoading ?
                                <Loading />
                            :
                            listData.length > 0 ? listData.map((item:TravleReqListType,index:number) => {
                                return(
                                    <ReqList 
                                        key={index} 
                                        setSelListIdx={selIdxHandler} 
                                        selListIdx={selListIdx} 
                                        index={index} 
                                        reqItem={item}
                                        setSelTab={()=>{}}
                                        reload={()=>{listRefetch()}}
                                    />
                                )
                            })
                            :
                                <div className='mt-5 text-center w-100'>
                                    <p>현재 요청 중인 실시간 견적이 존재하지 않습니다.</p>
                                </div>
                            }
                        </div>
                    </Accordion>
                    {listData.length > 0 &&
                        <PageNation 
                            page={page}
                            setPage={setPage}
                            limitItem={10}
                            totalItem={totalItem}
                        />
                    }
                </div>
            </section>
            {bannerList && bannerList.data.coop_list.length> 0 && 
                <section className="mx-auto partner_wr cont_wr  mb-0 overflow-hidden">
                    <div className=" ">
                        <div className="container-lg">
                            <h2 className="tit_h2 tit_point">파트너스 {bannerList.data.coop_list.length} 곳</h2>
                        </div>
                        <div className="pt_slider p-4">
                            <div className="wrapper" style={{maxHeight:75}}>
                                
                                    <Carousel
                                        responsive={responsive} 
                                        autoPlay={true}
                                        infinite={true}
                                        partialVisible={true}
                                        // centerMode={true}
                                        containerClass={'banner-slide'}
                                        additionalTransfrom={(bannerList.data.coop_list.length > 10) ? -20 * 5 : 0}
                                        arrows={false}
                                        swipeable={true}
                                        draggable={false}
                                    >
                                        {bannerList.data.coop_list.map((item:any,index:number) => {
                                            return(
                                                <div key={index} className="rect rc2 mr-2"><img src={item.coop_image} style={{width:200,height:80,objectFit:'contain'}}/></div>
                                            )
                                        })}

                                        {/* <div className="rect rc2 mr-2"><img src="/assets/img/img_partners01.jpg" /></div> */}
                                        {/* <div className="rect rc2 mr-2"><img src="/assets/img/img_01.jpg" /></div> */}
                                        {/* <div className="rect rc2 mr-2"><img src="/assets/img/img_partners01.jpg" /></div> */}
                                        {/* <div className="rect rc2 mr-2"><img src="/assets/img/img_01.jpg" /></div> */}
                                        {/* <div className="rect rc2 mr-2"><img src="/assets/img/img_partners01.jpg" /></div> */}
                                        {/* <div className="rect rc2 mr-2"><img src="/assets/img/img_01.jpg" /></div> */}
                                        {/* <div className="rect rc2 mr-2"><img src="/assets/img/img_01.jpg" /></div> */}
                                        {/* <div className="rect rc2 mr-2"><img src="/assets/img/img_01.jpg" /></div> */}
                                    </Carousel>
                            </div>
                        </div>
                    </div>
                </section>
            }
            </div>

            <LoginModal 
                show={isLoginModal}
                setShow={setIsLoginModal}
            />
        </div>
    );
}

export default Main;
