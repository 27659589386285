import classNames from 'classnames';
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Loading from '../components/form/Loading';
import PageNation from '../components/form/PageNation';
import { usePostQuery } from '../inc/axiosQuery';
import { NumberComma } from '../inc/func';
import { ReviewItemType } from '../inc/type/ReviewType';
import { useAppSelector } from '../store/config';

interface ModalType {
    show : boolean;
    setShow : (hide:boolean) => void;
    mbIdx : string;
}


function EmployeeModal({ //회사정보
    show,
    setShow,
    mbIdx,
}:ModalType) {
    
    const {jwt_token} = useAppSelector(state => state.userInfo);
    const [page, setPage] = React.useState(1);
    const [totalItem, setTotalItem] = React.useState(1)

    const {data : empData, isLoading : empLoading} = usePostQuery('getEmpInfo',{mb_idx_target:mbIdx},'user/company_intro.php');
    const {data : revList, isLoading : revLoading} = usePostQuery('getRevList',{
        mb_idx_target:mbIdx,
        page : page,
        limit : 10,
    },'review/review_list_not_jwt.php')

    
    React.useEffect(()=>{
        if(revList){
            setTotalItem(revList.data.tot_cnt);
        }
    },[revList, revLoading])

    

    return (
        <Modal show={show} onHide={()=>{setShow(false);}} className='modal' centered>
            <div className="modal-header">
                <h5 className="modal-title">회사소개</h5>
                <button type="button" className="btn_close btn" onClick={()=>{setShow(false);}}>
                    <img src="/assets/img/ic_colse.png" alt="닫기 아이콘" />
                </button>
            </div>
            {empLoading ? 
                <Loading />
            :
                <>
                {empData.result ?
                    <div className="modal-body">
                        <div className="info_box">
                            <div className="com_info">
                                <p className="fs_18 fw_700 py-4">{empData.data.company_name}</p>
                                <p className="fs_35 fw_700 ff_gmarket mb-4">{empData.data.rating}</p>
                                <div className="d-flex justify-content-center com_star">
                                    {[...Array(5)].map((x,index) => {
                                        return(
                                            <img 
                                                src={index+1 <= empData.data.rating ?
                                                    '/assets/img/ic_star_on.png' 
                                                    :
                                                    '/assets/img/ic_star_off.png' 
                                                    }
                                                alt="별 아이콘" 
                                                key={index}
                                            />
                                        )
                                    })}
                                </div>
                                <img src="/assets/img/gold_leaf.png" alt="" className="com_info_bg" />
                            </div>
                            <p className={
                                classNames('mb-4 line_h1_4',{
                                    'text-center text_gray': empData.data.mb_intro === ''
                                })
                            }>
                                {empData.data.mb_intro === '' ? '입력된 회사정보가 없습니다.' : empData.data.mb_intro }
                            </p>
                            <p className="d-flex mb-3">
                                <span className="fs_13 mr-3 fc_888">수행완료 프로젝트</span>
                                <span className="fs_14">{NumberComma(Number(empData.data.req_cnt_end))}건</span>
                            </p>
                        </div>
                        <p className="fc_888 fs_13 mt-3 pt-3">클라이언트 최근 리뷰</p>
                        {revLoading ? 
                            <Loading />
                        :
                            <>
                            {(revList && revList.data.list.length > 0) ?
                                <>
                                    <ul
                                        className={'hide-scroll'}
                                        style={{maxHeight:300, overflowY:'scroll'}}
                                    >
                                        {revList.data.list.map((item:ReviewItemType,index:number) => {
                                            return(
                                                <li className="fs_13 border-bottom mt-4" key={index}>
                                                    <div className="d-flex align-items-center mb-3">
                                                        <div className="d-flex align-items-center">
                                                            <img src="/assets/img/ic_star_on.png" alt="별 아이콘" style={{width:10}} />
                                                            <span className="ml-2">{item.rating}</span>
                                                        </div>
                                                        <span className="col_bar"></span>
                                                        <p className="">{item.mb_id}</p>
                                                    </div>
                                                    <p className="mb-4">{item.cmt_content} </p>
                                                </li>
                                            )
                                        })} 
                                        
                                        
                                    </ul>
                                    <PageNation 
                                        page={page}
                                        setPage={setPage}
                                        totalItem={totalItem}
                                        limitItem={10}
                                    />
                                </>
                                :
                                    <p className="mt-4">조회된 리뷰내역이 없습니다.</p>
                                }
                            </>
                        }
                    </div>
                :
                <p>조회된 회사정보가 없습니다.</p>
                }
                </>
            }
            <div className="modal-footer">
                <button type="button" className="btn btn-lg btn-primary btn-block" onClick={()=>{setShow(false)}}>확인</button>
            </div>
        </Modal>
    )
}

export default EmployeeModal;
