import classNames from 'classnames';
import React from 'react';
import { FreeMode } from 'swiper';
import {fetchPostData, usePostQuery} from '../../inc/axiosQuery';
import TravleList from './TravleList';
import ProductList from './ProductList';
import { useAppDispatch, useAppSelector } from '../../store/config';
import { setReload } from '../../store/slices/ReloadSlice';
import { setLoading } from '../../store/slices/LoadingSlice';
import { useLocation, useNavigate } from 'react-router';
import SwiperCore from 'swiper';


function TravleMain() { 

    const dispatch = useAppDispatch();
    const navigator = useNavigate();
    const {jwt_token ,mb_id, mb_type} = useAppSelector(state => state.userInfo);
    const {reload} = useAppSelector(state => state.reload);
    const [selTab, setSelTab] = React.useState('');
    const [searchKey , setSearchKey] = React.useState('');

    const [tabList, setTabList] = React.useState([]);

    const [loc,setLoc] = React.useState('');
    const [rcmdType,setRcmdType] = React.useState('');

    //링크이동 (qs)
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);    

    //swiper 핸들러
    // const [swiper , setSwiper] = React.useState<SwiperCore>();

    const getTabList = async () => {
        dispatch(setLoading(true));
        const params = {
            sch_txt:searchKey,
            jwt_token:jwt_token,
            rcmd_type : rcmdType,
            loc : loc,
        }
        await fetchPostData(params,'doc/tvl_tab.php')
        .then(res => {
            setTabList(res.data);
        })
        .catch(err => {
            console.log(err);
        })
        dispatch(setLoading(false));
    }

    React.useEffect(()=>{
        if(mb_id === ''){
            navigator('/',{replace:true});
            return;
        }
        else if(mb_type === '2'){
            navigator('/landMain',{replace:true});
            return;
        }  
        getTabList();
    },[])
    React.useEffect(()=>{
        if(reload){
            getTabList();
            dispatch(setReload(false));
        }
    },[reload])

    React.useEffect(()=>{
        window.scrollTo(0,0);
    },[selTab])

    React.useEffect(()=>{
        const qTab = queryParams.get('seltab');
        setSelTab(()=> qTab === null ? '' : qTab);

        // tabList && tabList.forEach((item:any,index) => {
        //     if(qTab === item.req_status){
        //         swiper?.slideTo(index);
        //     }
        // })
    },[queryParams])


    return (
        <div className="container-fluid px-0">
            <div className="sub_pg main">
                <section className="container-lg mx-auto">
                    <div className="subtop_img">
                        <img src="/assets/img/img_ban.jpg" alt="tobyfrom" />
                        <div className="text">
                            <div className="lef">
                                <p className="fs_18">현재 진행중인 견적을 관리하세요.</p>
                                <p className="fs_38 gmarket mt-3 fw_700">비교견적 관리자</p>
                            </div>
                            <div className="rg">
                                <img src="/assets/img/ban_logo_wh.png" alt="tobyfrom" />
                            </div>
                        </div>
                    </div>
                    <div className="estimate_tab_wr">
                        {tabList.length > 0 &&
                            <div className="estimate_tab" >
                                {tabList.map((item:any,index:any) => {
                                    return(
                                        <li key={index} className={classNames({'active text-center' : selTab === item.req_status})}>
                                            <a onClick={
                                                ()=>{
                                                    navigator(`/travleMain?seltab=${item.req_status}`);
                                                }
                                            }>{item.req_name} <span>({item.req_cnt}건)</span></a>
                                        </li>
                                    )  
                                })} 
                            </div>
                        }
                    </div>
                    
                </section>     
                <section className="container-lg mx-auto">
                    {selTab !== '11'  ? 
                            <TravleList 
                                searchKey={searchKey}
                                setSearchKey={setSearchKey}
                                getTabList={getTabList}
                                selTab={selTab}
                                setSelTab={setSelTab}
                            />
                        :
                            <ProductList
                                loc={loc}
                                setLoc={setLoc}
                                rcmdType={rcmdType}
                                setRcmdType={setRcmdType} 
                                searchKey={searchKey}
                                setSearchKey={setSearchKey}
                                getTabList={getTabList}
                                selTab={selTab}
                            />
                    }
                     
                </section>           
            </div>
        </div>
    );
}

export default TravleMain;
