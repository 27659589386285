import React from 'react';
import SpecialProductCom from '../../components/list/SpecialProductCom';
import { useAppSelector } from '../../store/config';
import { ProductItemType } from '../../inc/type/ProductType';
import RecommandProductModal from '../../modal/recommandProduct/RecommandProductModal';
import { fetchPostData, usePostQuery } from '../../inc/axiosQuery';
import PageNation from '../../components/form/PageNation';
import ProductCancleModal from '../../modal/landReq/ProductCancleModal';
import CustomSelectBox from '../../components/form/CustomSelectBox';
import classNames from 'classnames';
import Loading from '../../components/form/Loading';

interface LandProListPropType{
    loc:string,
    setLoc:(loc:string)=>void;
    rcmdType:string;
    setRcmdType:(rcmdType:string)=>void;
    searchKey:string,
    setSearchKey:(search:string)=>void;
    getTabList:()=>void;
    selTab : string,
}

type countryType = {
    value : string,
    label : string,
}

function LandProductList({searchKey,setSearchKey,getTabList,selTab, loc,setLoc,rcmdType,setRcmdType}:LandProListPropType) { 

    const {reload} = useAppSelector(state => state.reload); 
    const {jwt_token,mb_type} = useAppSelector(state => state.userInfo);
    const [isLoading, setIsLoading] = React.useState(false);
    const {data:conList, isLoading : conLoading, isError: conError} = usePostQuery('countryList',{},'cate/loc_list.php');

    const [page, setPage] = React.useState(1);
    const [totalItem, setTotalItem] = React.useState(1);
    const [showTab, setShowTab] = React.useState(1);
    const [orderby, setOrderby] = React.useState('1');
    const [countryList, setCountryList] = React.useState<countryType[]>([]);

    const [proList, setProList] = React.useState<ProductItemType[]>([]);

    const getProductList = async () => {
        setIsLoading(true);
        const params ={
            limit:12,
            page:page,
            jwt_token:jwt_token,
            sch_txt : searchKey,
            orderby : orderby,
        }
        await fetchPostData(params,'product/product_land_list.php')
        .then(res => {
            setTotalItem(res.data.tot_cnt);
            setProList([...res.data.pro_list]);
            setIsLoading(false);
        })
    }

    const searchList = () => {
        setPage(1);
        getTabList();
        getProductList();
    }

    const [proEditModal, setProEditModal] = React.useState({
        show : false,
        selIdx : '',        
    })
    const [proCancleModal, setProCancleModal] = React.useState({
        show: false,
        selIdx : '',
    })

    const {data:proDetailData} = usePostQuery('pro_detail',{pt_idx:proEditModal.selIdx},'product/product_detail.php');

    
    const proEditModalOff = (hide:boolean) => {
        setProEditModal({
            show:hide,
            selIdx : '' ,
        })
    }
    const proCnacleModalOff = (hide:boolean) => {
        setProCancleModal({
            show:hide,
            selIdx:'',
        })
    }

    React.useEffect(()=>{
        getProductList();
    },[page,showTab,rcmdType,loc,orderby]);

    React.useEffect(()=>{
        if(reload)getProductList();
    },[reload])

    React.useEffect(()=>{ //국가 리스트 불러오기
        if(conList){
            let tempArray:countryType[] = [];
            
            conList.data.loc_list.forEach((item:{loc:string,location:string,},index:number) => {
                tempArray.push({
                    value : item.loc,
                    label : item.location,
                })
            })
            setCountryList(tempArray);
        }
    },[conList,conLoading,conError])

    return (
        <>
            <div className="ip_wr search_input">
                <div className="form-row input_row">
                    <div className="col-9 pr-0 ">
                        <input 
                            type="search" 
                            className="form-control form-control-lg" 
                            value={searchKey} 
                            onChange={(e)=>{setSearchKey(e.target.value)}} 
                            placeholder="지역 또는 제목으로 검색하기"
                            onKeyUp={(e)=>{if(e.key === 'Enter'){
                                searchList();
                            }}}
                        />
                    </div>
                    <div className="col-3 pl-0">
                        <button type="button" className="btn btn-lg btn-primary btn-block" onClick={searchList}>검색</button>
                    </div>
                </div>
            </div>
            {/* <div className="prd_filter">
                <div className="filter_nav">
                    <ul className="nav nav-pills " id="pills-tab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className={classNames('nav-link',{active:rcmdType === '1'})} onClick={()=>{setRcmdType('1')}}>추천상품</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className={classNames('nav-link',{active:rcmdType === '2'})} onClick={()=>{setRcmdType('2')}}>전체보기</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className={classNames('nav-link',{active:rcmdType === '3'})} onClick={()=>{setRcmdType('3')}}>지역보기</button>
                        </li>
                    </ul>
                </div>
                <div className="filter_option">
                    <div className="ip_wr ">
                        <select value={orderby} onChange={(e)=>{setOrderby(e.target.value)}} className="form-control  custom-select">
                            <option value="1">최신순</option>
                            <option value="2">행사마감순</option>
                            <option value="3">구매건수</option>
                        </select>
                    </div>
                </div>
                {rcmdType === '3' &&
                    <CustomSelectBox 
                        optionList={countryList}
                        selValue={loc}
                        setSelValue={setLoc}
                    />
                }
            </div> */}
            {isLoading ?
                <Loading />
            :
                <>
                    <div className="product_list prd_1">
                            {proList.length > 0 ? 
                                <div className="row row-cols-3 row-cols-xl-4">
                                    {proList.map((item,index) => {
                                        return(
                                            <div key={index}>
                                                <SpecialProductCom 
                                                    proItem={item}
                                                    detailOn={setProEditModal}
                                                    cancleOn={setProCancleModal}
                                                    type={'land'}
                                                    index={index}
                                                />
                                            </div>
                                        )})
                                    }
                                </div>
                                :
                                <div className="est_acc w-100">
                                    <div className="w-100 d-flex flex-row justify-content-center align-items-center m-5">
                                        <p>조회된 견적이 존재하지 않습니다.</p>
                                    </div>
                                </div>
                            }
                    </div>
                    {proList.length > 0 &&
                        <PageNation 
                            page={page}
                            totalItem={totalItem}
                            limitItem={12}
                            setPage={setPage}
                        />
                    }
                    {(proEditModal.selIdx !== '' && proDetailData) &&
                        <RecommandProductModal 
                            show={proEditModal.show}
                            setShow={proEditModalOff}
                            editType={'u'}
                            editInfo={proDetailData.data}
                        />
                    }
                    {(proCancleModal.selIdx !== '' && proDetailData) &&
                        <ProductCancleModal 
                            show={proCancleModal.show}
                            setShow={proCnacleModalOff}
                            selIdx={proCancleModal.selIdx}
                        />
                    }
                </>
            }
        </>
    );
}

export default LandProductList;
