import classNames from 'classnames';
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import { getDate, getTime } from '../../inc/func';
import { DetailInfoType, ProDetailModalType } from './DetailType';


function NoramalProDetail2({ //여행사 & 개인 기획상품 신청
    proStep,
    setProStep,
    proDetailInfo,
    setProDetailInfo,
    setShow
}:ProDetailModalType) {

    const countSelPeople = (type:string, count:'up' | 'down') => {
        switch(type){
            case 'cnt_adult' : 
                if(proDetailInfo.cnt_adult !== 0 || count !== 'down'){
                    const tempAudltPrice = 10000;
                    setProDetailInfo({
                        ...proDetailInfo,
                        [type] : count === 'up' ? proDetailInfo.cnt_adult +1 : proDetailInfo.cnt_adult -1,
                        price_adult : count === 'up' ? proDetailInfo.price_adult+tempAudltPrice : proDetailInfo.price_adult-tempAudltPrice
                    })
                }
            break;
            case 'cnt_child' :
                if(proDetailInfo.cnt_child !== 0 || count !== 'down'){
                    const tempChildPrice = 5000;
                    setProDetailInfo({
                        ...proDetailInfo,
                        [type] : count === 'up' ? proDetailInfo.cnt_child +1 : proDetailInfo.cnt_child -1 ,
                        price_child : count === 'up' ? proDetailInfo.price_child+tempChildPrice : proDetailInfo.price_child-tempChildPrice})
                }
            break;
            case 'cnt_infant' :
                if(proDetailInfo.cnt_infant || count !== 'down'){
                    const tempInfantPrice = 3000;
                    setProDetailInfo({
                        ...proDetailInfo,
                        [type] : count === 'up' ? proDetailInfo.cnt_infant +1 : proDetailInfo.cnt_infant -1 ,
                        price_infant : count === 'up' ? proDetailInfo.price_infant+tempInfantPrice : proDetailInfo.price_infant-tempInfantPrice})
                }
            break;
        }
    }

    return (
        <>
            <div className="modal-header">
                <h5 className="modal-title">인원수를 선택해주세요.</h5>
                <button type="button" className="btn_close btn" onClick={()=>{setShow();}}>
                    <img src="/assets/img/ic_colse.png" alt="닫기 아이콘" />
                </button>
            </div>
            <div className="modal-body">
                <ul>
                    <li className="d_sp_center mb-3">
                        <p>성인</p>
                        <div className="count">
                            <a onClick={()=>{countSelPeople('cnt_adult','down')}} type="button"><img src="/assets/img/ic_minus.png" alt="빼기" /></a>
                            <p>{proDetailInfo.cnt_adult}</p>
                            <a onClick={()=>{countSelPeople('cnt_adult','up')}} type="button"><img src="/assets/img/ic_plus.png" alt="더하기" /></a>
                        </div>
                    </li>
                    <li className="d_sp_center mb-3">
                        <p>소아</p>
                        <div className="count">
                            <a onClick={()=>{countSelPeople('cnt_child','down')}} type="button"><img src="/assets/img/ic_minus.png" alt="빼기" /></a>
                            <p>{proDetailInfo.cnt_child}</p>
                            <a onClick={()=>{countSelPeople('cnt_child','up')}} type="button"><img src="/assets/img/ic_plus.png" alt="더하기" /></a>
                        </div>
                    </li>
                    <li className="d_sp_center ">
                        <p>유아</p>
                        <div className="count">
                            <a onClick={()=>{countSelPeople('cnt_infant','down')}} type="button"><img src="/assets/img/ic_minus.png" alt="빼기" /></a>
                            <p>{proDetailInfo.cnt_infant}</p>
                            <a onClick={()=>{countSelPeople('cnt_infant','up')}} type="button"><img src="/assets/img/ic_plus.png" alt="더하기" /></a>
                        </div>
                    </li>
                </ul>
            </div>
            <div className="modal-footer">
                <div className="d-flex w-100">
                    <button type="button" className="btn btn-lg btn-gray btn-block mb-0" onClick={()=>{setProStep(proStep-1)}}>이전</button>
                    <button type="button" className="btn btn-lg btn-primary btn-block ml-4 mt-0" onClick={()=>{setProStep(proStep+1)}}>다음</button>
                </div>
            </div>
        </>
    )
}

export default NoramalProDetail2;
