import classNames from 'classnames';
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import { ProductItemType } from '../../inc/type/ProductType';
import { useAppSelector } from '../../store/config';
import RecStep1 from './RecStep1';
import RecStep2 from './RecStep2';
import RecStep3 from './RecStep3';
import RecStep4 from './RecStep4';
import RecStep5 from './RecStep5';
import { RecInfoType } from './recType';
import { dateConverter } from '../../inc/func';
import { usePostQuery } from '../../inc/axiosQuery';
import { countryType } from '../reqEstimate/reqType';

interface ModalType {
    show : boolean;
    setShow : (hide:boolean) => void;
    editType : string;
    editInfo? : RecInfoType
}

function RecommandProductModal({ //랜드사 - 상품추천하기
    show,
    setShow,
    editType,
    editInfo,
}:ModalType) {

    const {jwt_token} = useAppSelector(state => state.userInfo);

    const [recStep , setRecStep] = React.useState(0);

    const [inputInfo, setInputInfo] = React.useState<RecInfoType>({
        pt_title : '',
        price_type : '원화',
        price_adult : '',
        price_child : '',
        price_infant : '',
        cnt_adult : 0,
        cnt_child : 0,
        cnt_infant : 0,
        loc : '',
        loc_temp_name : '전체',
        loc_1 : '',
        loc1_temp_name : '전체',
        pt_file:{},
        pt_file_name : '',
        pt_image:{},
        pt_image_preview : '',
        pt_memo:'',
        pt_start_datetime : '',
        start_hour : '',
        start_min : '',
        startDate : new Date(),
        pt_end_datetime : '',
        end_hour : '',
        end_min : '',
        endDate : new Date(),
        pt_mb_name : '',
        edit_type : editType === 'u' ? 'u' : '',
        jwt_token : jwt_token,
    })

    const [inputCheck, setInputCheck] = React.useState({
        date :false,
        people : false,
    })

    const {data:conList, isLoading : conLoading, isError: conError} = usePostQuery('countryList',{},'cate/loc_list.php');
    const {data:locList, isLoading : locLoading, isError: locError} = usePostQuery('locationList',{loc:inputInfo.loc},'cate/loc_list_1.php');
    
    const [countryList, setCountryList] = React.useState<countryType[]>([]);
    const [locationList, setLocationList] = React.useState<countryType[]>([]);

    const clearInfo = () => {
        setInputInfo({
            pt_title : '',
            price_type : '원화',
            price_adult : '',
            price_child : '',
            price_infant : '',
            cnt_adult : 0,
            cnt_child : 0,
            cnt_infant : 0,
            loc : '',
            loc_temp_name : '',
            loc_1 : '',
            loc1_temp_name : '',
            pt_file:{},
            pt_file_name : '',
            pt_image:{},
            pt_image_preview : '',
            pt_memo:'',
            pt_start_datetime : '',
            startDate : new Date(),
            start_hour : '',
            start_min : '',
            pt_end_datetime : '',
            endDate : new Date(),
            end_hour : '',
            end_min : '',
            pt_mb_name : '',
            edit_type : '',
            jwt_token : jwt_token,
        })
        setInputCheck({
            date:false,
            people:false,
        })
        setRecStep(1);
    }
    React.useEffect(()=>{
        if(editType === 'u' && editInfo){
            const splitStart = editInfo.pt_start_datetime.split(' ');
            const splitEnd = editInfo.pt_end_datetime.split(' ');

            const [syear,smonth,sday] = splitStart[0].split('-');
            const [eyaer,emonth,eday] = splitEnd[0].split('-');

            const [shour , smin] = splitStart[1].split(':');
            const [ehour , emin] = splitEnd[1].split(':');
            setInputInfo({
                ...inputInfo,
                ...editInfo,
                startDate : new Date(Number(syear),Number(smonth)-1,Number(sday)),
                endDate : new Date(Number(eyaer),Number(emonth)-1,Number(eday)),
                start_hour : shour,
                start_min : smin,
                end_hour : ehour,
                end_min : emin,
                pt_file : {},
                pt_image_preview : editInfo.pt_image_url,
                edit_type : 'u',
            });
        }
        else{
            clearInfo();
        }
        setRecStep(1);
    },[])

    React.useEffect(()=>{ //표시될 이름 구하기
        // console.log(reqInfo.loc);
        const countryFilter = countryList.filter(el => el.value === inputInfo.loc);
        const locationFilter = locationList.filter(el => el.value === inputInfo.loc_1);

        setInputInfo({
            ...inputInfo,
            loc_temp_name : countryFilter.length > 0 ? countryFilter[0].label : '전체',
            loc1_temp_name : locationFilter.length > 0 ? locationFilter[0].label : '전체',
        })
        
    },[inputInfo.loc,inputInfo.loc_1]);

    React.useEffect(()=>{ //국가 리스트 불러오기
        if(conList){
            let tempArray:countryType[] = [];
            
            conList.data.loc_list.forEach((item:{loc:string,location:string,},index:number) => {
                tempArray.push({
                    value : item.loc,
                    label : item.location,
                })
            })
            setCountryList(tempArray);
        }
    },[conList,conLoading,conError])

    React.useEffect(()=>{   
        if(locList){

            let tempList:countryType[] = [];

            locList.data.loc_list.forEach((item:{loc:string,location:string} , index : number) => {
                tempList.push({
                    value:item.loc,
                    label:item.location,
                })
            })
            setLocationList([...tempList]);
            
        }
    },[locList,locLoading,locError])

    React.useEffect(()=>{ //날짜선택 출발일 < 도착일 1일 이상
        const startYear = dateConverter(inputInfo.startDate).year;
        const startMonth = dateConverter(inputInfo.startDate).month;
        const startDay = dateConverter(inputInfo.startDate).day;

        const endYear = dateConverter(inputInfo.endDate).year;
        const endMonth = dateConverter(inputInfo.endDate).month;
        const endDay = dateConverter(inputInfo.endDate).day;

        const startDate : Date = new Date(`${startYear}-${startMonth}-${startDay} ${inputInfo.start_hour === '' ? '00' : inputInfo.start_hour}:${inputInfo.start_min === '' ? '0' : inputInfo.start_min}`);
        const endDate: Date = new Date(`${endYear}-${endMonth}-${endDay} ${inputInfo.end_hour === '' ? '00' : inputInfo.end_hour}:${inputInfo.end_min === '' ? '0' : inputInfo.end_min}`);

        const diffInMs = endDate.getTime() - startDate.getTime();


        const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
        if (diffInDays >= 1) {
            setInputCheck({
                ...inputCheck,
                date : true,
            })
        } else {
            setInputCheck({
                ...inputCheck,
                date : false,
            })
        }
    },[inputInfo.startDate,inputInfo.endDate,inputInfo.start_hour,inputInfo.start_min,inputInfo.end_hour,inputInfo.end_min])  
    
    React.useEffect(()=>{ //인원수 선택 체크
        setInputCheck({
            ...inputCheck,
            people : inputInfo.cnt_adult > 0 || inputInfo.cnt_child > 0 || inputInfo.cnt_infant > 0
        })
    },[inputInfo.cnt_adult,inputInfo.cnt_child,inputInfo.cnt_infant])
    
    return (
        <Modal show={show} onHide={()=>{clearInfo(); setRecStep(1); setShow(false);}} className='modal' centered>
            {recStep === 1 &&
                <RecStep1
                    recInfo={inputInfo}
                    setRecInfo={setInputInfo}
                    recStep={recStep}
                    setRecStep={setRecStep}
                    setShow={setShow}
                    inputCheck={inputCheck}
                />
            }
            {recStep === 2 &&
                <RecStep2 
                    recInfo={inputInfo}
                    setRecInfo={setInputInfo}
                    recStep={recStep}
                    setRecStep={setRecStep}
                    setShow={setShow}
                    inputCheck={inputCheck}
                />
            }
            {recStep === 3 &&
                <RecStep3 
                    recInfo={inputInfo}
                    setRecInfo={setInputInfo}
                    recStep={recStep}
                    setRecStep={setRecStep}
                    setShow={setShow}
                    countryList={countryList}
                    locationList={locationList}
                    inputCheck={inputCheck}
                />
            }
            {recStep === 4 &&
                <RecStep4 
                    recInfo={inputInfo}
                    setRecInfo={setInputInfo}
                    recStep={recStep}
                    setRecStep={setRecStep}
                    setShow={setShow}  
                    inputCheck={inputCheck}
                />
            }
            {recStep === 5 &&
                <RecStep5 
                    recInfo={inputInfo}
                    setRecInfo={setInputInfo}
                    recStep={recStep}
                    setRecStep={setRecStep}
                    setShow={setShow}
                    clearInfo={clearInfo}
                    editType={editType}
                    inputCheck={inputCheck}
                />

            }
        </Modal>
    )
}

export default RecommandProductModal;
