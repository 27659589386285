import classNames from 'classnames';
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';

interface LoginModalType {
    show : boolean;
    modalOff : () => void;
    btnLabel : string;
    msg : string;
    alertAction : ()=>void;
    title? : string;
}

function ConfirmModal({
    show,
    title,
    modalOff,
    msg,
    btnLabel,
    alertAction
}:LoginModalType) {

    return (
        <Modal show={show} onHide={()=>{modalOff();}} className='modal' centered>
            <div className="modal-header">
            </div>
            <div className="modal-body">
                <p className="text-center fs_18 line_h1_5 px-4">{msg}</p>
            </div>
            <div className="modal-footer">
                <div className="d-flex w-100">
                    <button type="button" className="btn btn-lg btn-primary btn-block mr-4 mb-0" onClick={()=>{modalOff(); alertAction();}}>{btnLabel}</button>
                    <button type="button" className="btn btn-lg btn-gray btn-block mt-0" onClick={()=>{modalOff();}}>취소</button>
                </div>
            </div>
        </Modal>
    )
}

export default ConfirmModal;
