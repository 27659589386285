import React, { useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Link, useNavigate } from 'react-router-dom';
import CustomInput from '../../components/form/CustomInput';
import {useMutation} from 'react-query';
import { usePostMutation } from '../../inc/axiosQuery';
import { MarginY } from '../../inc/FlexStyle';
import { useAppDispatch } from '../../store/config';
import { setAutoLogin, setUserInfo } from '../../store/slices/UserInfoSlice';
import { setLoading } from '../../store/slices/LoadingSlice';
import FacebookLogin from '@greatsumini/react-facebook-login';
import { KAKAO_AUTH_URL } from '../../page/user/nomalJoin/KakaoAuth';
import { NAVER_AUTH_URL } from '../../page/user/nomalJoin/NaverAuth';

interface LoginModalType {
    show : boolean;
    setShow : (isLogin:boolean) => void;
}

function LoginModal({
    show,
    setShow,
}:LoginModalType) {
    const navigator = useNavigate();
    const facebookLoginMutation = usePostMutation('accessSnsLogin','user/sns_login.php');
    const accessLoginMutation = usePostMutation('login','user/email_login.php'); //loginMutation 생성


    const initialInputInfo = {
        mb_id : '',
        mb_pw : '',
    }
    const initialInputCheck = {
        error_check : false,
        error_msg : '',
    }

    const [inputInfo, setInputInfo] = React.useState(()=>initialInputInfo);
    const [autoLoginCheck, setAutoLoginCheck] = React.useState(false);

    const dispatch = useAppDispatch();


    const setInputId = (id:string) => {
        setInputInfo({...inputInfo , mb_id : id});
    }
    const setInputPw = (pw:string) => {
        setInputInfo({...inputInfo,mb_pw : pw});
    }

    const faceBookLoginSccuess = async (res:any) => { //페이스북 로그인
        const {data,result, msg} = await facebookLoginMutation.mutateAsync({
            mt_id:res.id,
            mb_reg_type:'F',
        })

        if(result){
            await dispatch(setAutoLogin(true));
            await dispatch(setUserInfo(data));
        }
        else{
            navigator(`/normalJoin?type=facebook&id=${res.id}&email=${res.email}&name=${res.name}`,{replace:true});
        }
        setShow(false); 
    }

    const [inputCheck, setInputCheck] = React.useState(()=>initialInputCheck)

    const handleLogin = async () => {
        dispatch(setLoading(true));
        try{
            const {result,msg,data} = await accessLoginMutation.mutateAsync(inputInfo);
            
            if(!result){
                setInputCheck({
                    error_check : true,
                    error_msg : msg,
                })
            }
            else{
                setShow(false); 
                setInputInfo(()=>initialInputInfo);
                setInputCheck(()=>initialInputCheck);
                await dispatch(setAutoLogin(autoLoginCheck));
                await dispatch(setUserInfo(data));
                await setShow(false);
            }

        }
        catch(err){
            console.log(err);
        }
        dispatch(setLoading(false));

    };

    return (
        <Modal show={show} onHide={()=>{
            setInputInfo(()=>initialInputInfo);
            setInputCheck(()=>initialInputCheck);
            setShow(false);
        }} className='modal' centered>
            <div className="modal-header">
                <h5 className="modal-title">로그인</h5>
                <button type="button" className="btn_close btn" onClick={()=>{setShow(false)}}>
                    <img src="/assets/img/ic_colse.png" alt="닫기 아이콘" />
                </button>
            </div>
            <form className="modal-body">
                <CustomInput 
                    input={inputInfo.mb_id}
                    setInput={setInputId}
                    title={'아이디'}
                    placeHolder={"입력해주세요."}
                    isValidShow={false}
                />
                <MarginY mt={1} />
                <CustomInput 
                    input={inputInfo.mb_pw}
                    setInput={setInputPw}
                    title={'비밀번호'}
                    placeHolder={"입력해주세요."}
                    type={'password'}
                    isValidShow={inputCheck.error_check}
                    isValid={!inputCheck.error_check}
                    validText={inputCheck.error_msg}
                />
                <div className="d-flex align-items-md-center justify-content-between mt-md-5 mt-3 pt-md-0 pt-3 flex-column flex-md-row">
                    <div className="checks">
                        <input id="ck1" name="ck" type="checkbox" value={'Y'} onChange={(e)=>{setAutoLoginCheck(!autoLoginCheck)}}/>
                        <label htmlFor="ck1">자동 로그인</label>
                    </div>
                    <Link to="/find" className="mt-md-0 mt-2 align-self-end" onClick={()=>{setShow(false)}}>아이디/비밀번호 찾기</Link>
                </div>
            </form>
            <div className="modal-footer">
                <button type="button" className="btn btn-primary btn-lg btn-block" onClick={handleLogin}>로그인</button>
            </div>

            <div className={'top-border mt-5 py-5'}>
                <p className={'text-center fs_18 fw_700'}>일반 회원이신가요?</p>
                <div className="join_btn_wr">
                    <div className="sns_btn_wr">
                    <button onClick={()=>{
                        setShow(false);
                        window.location.href=KAKAO_AUTH_URL;

                    }} type="button" className="btn  btn-lg btn-block kakao fw_700 mt-4"><img src="/assets/img/ic_kako.png" alt="카카오로 회원가입" /> 카카오로 바로시작</button>
                    {/* <button type="button" className="btn  btn-lg btn-block facebook fw_700"><img src="/assets/img/ic_face.png" alt="페이스북으로 회원가입" /> 카카오로 바로시작</button> */}
                    
                    <FacebookLogin
                        appId={process.env.REACT_APP_FACEBOOK_APP_ID ? process.env.REACT_APP_FACEBOOK_APP_ID : ''}
                        initParams={{
                            version:'v16.0',
                            xfbml:true,
                        }}
                        onFail={(error) => {
                            console.log('Login Failed!');
                            console.log('status: ', error.status);
                        }}
                        onProfileSuccess={faceBookLoginSccuess}
                        render={({onClick})=>{
                            return(
                                <button onClick={onClick} type="button" className="btn  btn-lg btn-block facebook fw_700 mt-4"><img src="/assets/img/ic_face.png" alt="페이스북으로 회원가입" /> 페이스북으로 바로시작</button>
                            )
                        }}
                    />
                    <button onClick={()=>{
                        setShow(false);
                        window.location.href=NAVER_AUTH_URL;
                    }} type="button" className="btn  btn-lg btn-block naver fw_700 mt-4"><img src="/assets/img/ic_naver.png" alt="네이버 회원가입" /> 네이버로 바로시작</button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default LoginModal;
