/* store/slices/counterSlice.ts */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface CommonState {
    joinType : string, 
}

const initialState: CommonState = { //normal travel land guide
    joinType : '',
};

export const joinTypeSlice = createSlice({
  name: 'joinType',
  initialState,
  reducers: {
    setJoinType(state, action: PayloadAction<string>) {
      state.joinType = action.payload;
    }
  }
});

export const { setJoinType } = joinTypeSlice.actions;

export default joinTypeSlice