import React from 'react';
import Modal from 'react-bootstrap/Modal';
import CustomSelectBox from '../../components/form/CustomSelectBox';
import { usePostMutation } from '../../inc/axiosQuery';
import { LandReqListType, TravleReqListType } from '../../inc/type/reqType';
import { useAppDispatch, useAppSelector } from '../../store/config';
import { setLoading } from '../../store/slices/LoadingSlice';
import { setReload } from '../../store/slices/ReloadSlice';
import AlertModal from '../AlertModal';

interface ModalType {
    show : boolean;
    setShow : (hide:boolean) => void;
    reqItem : TravleReqListType | LandReqListType,
    setSelTab : (index:string) => void;
}

function ReqAgainModal({ //여행사 - 견적요청 취소
    show,
    setShow,
    reqItem,
    setSelTab,
}:ModalType) {

    const dispatch = useAppDispatch();
    const {jwt_token} = useAppSelector(state => state.userInfo);
    const reqAgainMutation = usePostMutation('reqAgain','doc/request_replay.php');

    const [alertModal, setAlertModal] = React.useState({
        show:false,
        msg:'',
        type:'',
    });

    const [hour, setHour] = React.useState('00');
    const [min , setMin] = React.useState('00');

    let hourList = [];
    let minList = []
    for(let i=0; i<=48; i++){
        const tempHour = i<10 ? '0'+i : `${i}`;
        hourList.push({
            label:tempHour,
            value:tempHour,
        })
    }
    for(let j=0; j<=59; j++){
        const tempMin = j<10 ? '0'+j : `${j}`;
        minList.push({
            label:tempMin,
            value:tempMin,
        })
    }

    const alertModalOff = () => {
        setAlertModal({
            show:false,
            msg:'',
            type:'',
        })
    }

    const alertAction = () => {
        if(alertModal.type === 'success'){
            setShow(false);
            dispatch(setReload(true));
            setSelTab('0');
        }
        else{

        }
    }

    const reqAgainAccess = async () => {

        dispatch(setLoading(true));

        const {result,msg} = await reqAgainMutation.mutateAsync({req_idx : reqItem.req_idx,jwt_token:jwt_token,limit_sec:`${hour}:${min}`});

        if(result){
            setAlertModal({
                show:true,
                msg:msg,
                type:'success'
            })
        }
        else{
            setAlertModal({
                show:true,
                msg:msg,
                type:'',
            })
        }
        dispatch(setLoading(false));

    }


    return (
        <Modal show={show} onHide={()=>{setShow(false);}} className='modal' centered>
            <div className="modal-header">
                <h5 className="modal-title">견적요청 마감시간 선택</h5>
                <button type="button" className="btn_close btn" data-dismiss="modal" aria-label="Close">
                    <img src="/assets/img/ic_colse.png" alt="닫기 아이콘" onClick={()=>{setShow(false);}} />
                </button>
            </div>
            <div className="modal-body">
            <div className="form-row w-100 my-4">
                    <CustomSelectBox 
                        title={'시'}
                        optionList={hourList}
                        selValue={hour}
                        setSelValue={setHour}
                        style={{flex:1}}
                    />
                    <CustomSelectBox 
                        title={'분'}
                        optionList={minList}
                        selValue={min}
                        setSelValue={setMin}
                        style={{flex:1,marginLeft:10}}
                    />
                </div>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-lg btn-primary btn-block" onClick={reqAgainAccess}>변경하기</button>
            </div>
            <AlertModal 
                show={alertModal.show}
                msg={alertModal.msg}
                alertAction={alertAction}
                modalOff={alertModalOff}
                btnLabel={'확인'}
                type={alertModal.type}
            />
        </Modal>
    )
}

export default ReqAgainModal;
