import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import ReqDetailModal from '../../modal/trvReq/ReqDetailModal';
import ReqCancleModal from '../../modal/trvReq/ReqCancleModal';
import EmployeeModal from '../../modal/EmployeeModal';
import axios from 'axios';
import { baseUrl } from '../../inc/axiosQuery';
import { ReqListDetailPropType } from '../../inc/type/reqType';
import { useAppSelector } from '../../store/config';
import { NumberComma, priceTypeCon } from '../../inc/func';
import ReqAgainModal from '../../modal/trvReq/ReqAgainModal';



function ReqDetailType3({
    index,
    cancleReq,
    reqItem,
    selListIdx,
    setSelTab,
    setSelMbIdx,
    setEmployeeModal
}:ReqListDetailPropType) { //견적선택

    const userInfo = useAppSelector(state => state.userInfo);

    const [detailModal, setDetailModal] = React.useState(false); //여행사 - 견적선택 modal
    const [selDetailIdx, setSelDetailIdx] = React.useState(0); //여행사 - 선택한견적 idx
    const [reqAgainModal, setReqAgainModal] = React.useState(false); //여행사 - 다시요청하기
    const [cancleToggle, setCancleToggle] = React.useState(false);//여행사 - 취소하기

    const openDetailModal = (selIdx:number) => {
        setDetailModal(true);
        setSelDetailIdx(selIdx);
    }

    const downloadFile = (url:string,fileName:string) => {
        axios.get(`${baseUrl}file/file_download.php`,{params:{file:url,file_name:fileName}, responseType: 'blob',})
        .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            if (link.parentNode) {
            link.parentNode.removeChild(link);
            }
        })
        .catch(error => {
            console.log(error);
        });
    }



    return (
        <Accordion.Collapse eventKey={String(index)} in={selListIdx === index}>
            <div className="card-body">
                <ul className="info">
                    <li><span>담당자</span>
                        <p>{reqItem.req_mb_name}</p>
                    </li>
                    <li><span>여행 인원수</span>
                        <p>성인 {reqItem.cnt_adult} / 유아 {reqItem.cnt_child} /소아 {reqItem.cnt_infant}</p>
                    </li>
                    <li><span>견적코드</span>
                        <p>{reqItem.req_code}</p>
                    </li>
                    <li className="textbx w-100"><span>요청사항</span>
                        <textarea name="" id="" cols={10} rows={2} className="form-control" readOnly defaultValue={reqItem.req_memo === '' ? '요청사항이 등록되어있지 않습니다.' : reqItem.req_memo}></textarea>
                    </li>
                </ul>
                {userInfo.mb_type === '1' ?
                <div className="bom">
                    {reqItem.req_file !== '' ?
                        <div className="lef">
                            <p>파일보기</p>
                            <a className="btn btn_down on" type="button" onClick={()=>downloadFile(reqItem.req_file,reqItem.req_file_name)}>다운로드</a>
                        </div>
                        :<div className="lef">
                            <p>* 등록된 파일이 존재하지 않습니다.</p>
                        </div>

                    }
                    <div className="e_list_wr">
                        <p className="lef">견적 리스트</p>
                        <div className="rg">
                            {(reqItem.est_list && reqItem.est_list.length > 0) ? reqItem.est_list.map((item,index) => {
                                return(
                                    <div className="e_list" key={index}>
                                        <div className="txt">
                                            <a className="br_name" type="button" onClick={()=>{setSelMbIdx(item.mb_idx); setEmployeeModal(true)}}>{item.company_name}</a>
                                            {item.est_file !== '' ?
                                                <a className="underline" onClick={()=>{downloadFile(item.est_file,item.est_file_name)}}>첨부파일보기</a>
                                            :
                                                <p className="ml-2">첨부된 파일이 없습니다.</p>
                                            }
                                            {/* <p>제안금액(1인당) : 성인 {NumberComma(item.price_adult)}{item.price_type} / 소아 {NumberComma(item.price_child)}{item.price_type} / 유아 {NumberComma(item.price_infant)}{item.price_type}</p> */}
                                            <p>제안금액(1인당) : 성인 {priceTypeCon(item.price_type)} {NumberComma(item.price_adult)} / 소아 {priceTypeCon(item.price_type)} {NumberComma(item.price_child)} / 유아 {priceTypeCon(item.price_type)} {NumberComma(item.price_infant)}</p>
                                        </div>
                                        <a className="btn_selc btn-primary" onClick={()=>{openDetailModal(index)}} >선택하기</a>
                                    </div>
                                )
                                })
                            :
                                <p>조회된 견적서가 존재하지 않습니다.</p>
                            }
                        </div>
                    </div>
                    <div className="e_btn_wr">
                        <button className="btn btn_request" onClick={()=>{setReqAgainModal(true)}}>다시 요청하기</button>
                        <button className="btn btn_cancel" onClick={()=>{setCancleToggle(true)}}>취소하기</button>
                    </div>
                </div>
                :
                <div className="bom">
                    {reqItem.req_file !== '' &&
                        <div className="lef">
                            <p>파일보기</p>
                            <a className="btn btn_down on" type="button" onClick={()=>downloadFile(reqItem.req_file,reqItem.req_file_name)}>다운로드</a>
                        </div>
                    }
                    <div className="e_list_wr">
                        <p className="lef">제안 견적</p>
                        <div className="rg">
                            {/* {(reqItem.est_list && reqItem.est_list.length > 0) ? reqItem.est_list.map((item,index) => {
                                return(
                                    <div className="e_list" key={index}>
                                        <div className="txt">
                                            <a className="br_name" type="button" onClick={()=>{setSelMbIdx(item.mb_idx); setEmployeeModal(true)}}>{item.company_name}</a>
                                            {item.est_file !== '' ?
                                                <a className="underline" onClick={()=>{downloadFile(item.est_file,item.est_file_name)}}>첨부파일보기</a>
                                            :
                                                <p className="ml-2">첨부된 파일이 없습니다.</p>
                                            }
                                            <p>제안금액(1인당) : 성인 {NumberComma(item.price_adult)}{item.price_type} / 소아 {NumberComma(item.price_child)}{item.price_type} / 유아 {NumberComma(item.price_infant)}{item.price_type}</p>
                                        </div>
                                    </div>
                                )
                                })
                            :
                                <p>조회된 견적서가 존재하지 않습니다.</p>
                            } */}
                            {reqItem.est_sct_item ? 
                                <div className="e_list" key={index}>
                                    <div className="txt">
                                        <a className="br_name" type="button" onClick={()=>{setSelMbIdx(reqItem.est_sct_item.mb_idx); setEmployeeModal(true)}}>{reqItem.est_sct_item.company_name}</a>
                                        {reqItem.est_sct_item.est_file !== '' ?
                                            <a className="underline" onClick={()=>{downloadFile(reqItem.est_sct_item.est_file,reqItem.est_sct_item.est_file_name)}}>첨부파일보기</a>
                                        :
                                            <p className="ml-2">첨부된 파일이 없습니다.</p>
                                        }
                                        {/* <p>제안금액(1인당) : 성인 {NumberComma(reqItem.est_sct_item.price_adult)}{reqItem.est_sct_item.price_type} / 소아 {NumberComma(reqItem.est_sct_item.price_child)}{reqItem.est_sct_item.price_type} / 유아 {NumberComma(reqItem.est_sct_item.price_infant)}{reqItem.est_sct_item.price_type}</p> */}
                                        <p>제안금액(1인당) : 성인 {priceTypeCon(reqItem.est_sct_item.price_type)} {NumberComma(reqItem.est_sct_item.price_adult)} / 소아 {priceTypeCon(reqItem.est_sct_item.price_type)} {NumberComma(reqItem.est_sct_item.price_child)} / 유아 {priceTypeCon(reqItem.est_sct_item.price_type)} {NumberComma(reqItem.est_sct_item.price_infant)}</p>
                                    </div>
                                </div>
                            : 
                                <p>조회된 견적서가 존재하지 않습니다.</p>
                            }
                        </div>
                    </div>
                </div> 
                }
                {reqItem &&
                    <>
                        {(reqItem.est_list && reqItem.est_list.length>0)&&
                            <ReqDetailModal 
                                show={detailModal}
                                setShow={setDetailModal}
                                reqItem={reqItem}
                                selEstInfo={reqItem.est_list[selDetailIdx]}
                            />
                        }
                        <ReqCancleModal
                            show={cancleToggle}
                            setShow={setCancleToggle}
                            reqItem={reqItem}
                            setSelTab={setSelTab}
                        />
                        <ReqAgainModal 
                            show={reqAgainModal}
                            setShow={setReqAgainModal}
                            reqItem={reqItem}
                            setSelTab={setSelTab}
                        />
                    </>
                }
            </div>
        </Accordion.Collapse>
    )
}

export default ReqDetailType3;
