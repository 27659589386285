import React from 'react';
import { Accordion, Modal } from 'react-bootstrap';
import PageNation from '../components/form/PageNation';
import { usePostQuery } from '../inc/axiosQuery';
import { useAppSelector } from '../store/config';
import { TravleReqListType } from '../inc/type/reqType';
type MyEstType = {
    label : string,
    value : string,
}

interface MyEstListModalType {
    show : boolean,
    setShow : (show:boolean) => void;
    setSelEst : (est:MyEstType) => void
}

export default function MyEstListModal({show,setShow,setSelEst}:MyEstListModalType){ //나의 견적서

    const {jwt_token} = useAppSelector(state => state.userInfo);
    const [page, setPage] = React.useState(1);
    const [totalItem, setTotalItem] = React.useState(1);
    const [selEstIndex , setSelEstIndex] = React.useState('');


    const {data:estData, isLoading,isError} = usePostQuery(
        'getMyEst',
        {
            jwt_token:jwt_token,
            page : page,
            limit : 10,
        },
        'board/my_request_list.php'
    );

    const selEstIndexHanlder = (index:string) => { //견적서 펼치기
        if(selEstIndex === index){
            setSelEstIndex('');
        }
        else{
            setSelEstIndex(index);
        }
    }

    const selEstHandler = (label:string,value:string,) =>{ //견적서 선택
        setSelEst({
            label,
            value
        })
        setShow(false);
    }

    React.useEffect(()=>{
        if(estData){
            const {tot_cnt} = estData.data;
            console.log(estData.data);
            setTotalItem(tot_cnt);
        }
    },[estData,isLoading])

    React.useEffect(()=>{
        setSelEstIndex('');
    },[page])

    return(
        <Modal show={show} onHide={()=>{setShow(false);}} className='modal' centered>
            <div className='modal-header'>
                    <h5 className="modal-title">나의 견적서</h5>
                <button type="button" className="btn_close btn" onClick={()=>{setShow(false);}}>
                    <img src="/assets/img/ic_colse.png" alt="닫기 아이콘" />
                </button>
            </div>
            <div className="modal-body hide-scroll" style={{maxHeight:'50vh',overflowY:'scroll'}}>
                <Accordion defaultActiveKey={selEstIndex}>
                    <div className="accordion acc_style_2" id="accordion_estimate">
                        {estData && estData.data.req_list.map((item:TravleReqListType,index:number) => {
                            return(
                                <div className="card" key={index}>
                                    <div className="card-header" onClick={()=>{selEstIndexHanlder(String(index))}}>
                                        <button className="btn btn-block text-left accordion_tit" type="button" data-toggle="collapse" data-target="#collapse1" aria-expanded="false" aria-controls="collapse1">                                
                                            <p>
                                                <span className="text-primary mr-2">[{item.req_status_txt}]</span>
                                                <span>{item.req_name}</span>
                                            </p>  
                                            <div className="btn_accordion">
                                                <img src="/assets/img/arr_accodion.png" />
                                            </div>
                                        </button>
                                    </div>
                                    <Accordion.Collapse eventKey={String(index)} in={selEstIndex === String(index)}>
                                        <div className="card-body">                                    
                                            <p className="mb-2">
                                                <span className="fs_13 text_gray mr-2">지역</span>
                                                <span className="fs_14 mr-5">{item.loc_txt}{item.loc_1 !== '' && ' / '+item.loc_1_txt}</span>
                                            </p>
                                            <span className="fs_13 text_gray mr-2">출발</span>
                                            <span className="fs_14 mr-5">{item.start_date_txt}</span>
                                            <span className="fs_13 text_gray mr-2">도착</span>
                                            <span className="fs_14 mr-5">{item.end_date_txt}</span>
                                            <div className="d-flex justify-content-end mt-4">
                                                <button type="button" className="btn btn-dark btn-sm w_100 h_40" onClick={()=>{selEstHandler(item.req_name,item.req_idx)}}>선택하기</button>                                        
                                            </div>
                                        </div>
                                    </Accordion.Collapse>
                                </div>
                            )
                        })}
                        
                    </div>
                </Accordion>
            </div>
            <div className="modal-footer justify-content-center">
                <PageNation 
                    page={page}
                    setPage={setPage}
                    limitItem={10}
                    totalItem={totalItem}
                />
                {/* <button type="button" className="btn btn-primary btn-lg btn-block" onClick={()=>{modalOff();}}>확인</button> */}
            </div>
        
        </Modal>
    )
}