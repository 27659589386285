import classNames from 'classnames';
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import CustomTextarea from '../components/form/CustomTextarea';
import { usePostMutation } from '../inc/axiosQuery';
import { NumberComma } from '../inc/func';
import { LandReqListType, TravleReqListType } from '../inc/type/reqType';
import { useAppDispatch, useAppSelector } from '../store/config';
import { setLoading } from '../store/slices/LoadingSlice';
import { setReload } from '../store/slices/ReloadSlice';
import AlertModal from './AlertModal';
import ConfirmModal from './ConfirmModal';

interface ModalType {
    show : boolean;
    setShow : (hide:boolean) => void;
    reqItem : LandReqListType | TravleReqListType
}

function RateModal({ //평가하기
    show,
    setShow,
    reqItem,
}:ModalType) {

    const dispatch = useAppDispatch();

    const {jwt_token , mb_type} = useAppSelector(state => state.userInfo);
    const rvWriteMutation = usePostMutation('rvWrite','review/review_write.php');
    const rvHoldMutation = usePostMutation('rvHold','review/review_hold.php');

    const priceType = reqItem.est_sct_item.price_type === '달러' ? 'USD' : 'KRW';

    const [selRate, setSelRate] = React.useState(5);
    const [feedBack, setFeedBack] = React.useState('');
    const [rvFile , setRvFile] = React.useState({
        file : {},
        fileName : '',
    });

    const initailAlert = {
        show : false,
        msg : '',
        type :'',
    }
    const [alertModal,setAlertModal] = React.useState(()=>initailAlert);
    const [confirmModal, setConfirmModal] = React.useState(()=>initailAlert);

    const alertModalOff= () =>{
        setAlertModal(initailAlert)
    }
    const confirmModalOff = () => {
        setConfirmModal(initailAlert);
    }
    const alertModalOn = (msg:string,type:string) => {
        setAlertModal({
            show:true,
            msg:msg,
            type:type,
        })
    }
    const confirmModalon = (type:string) => {
        if(type === 'rate_write'){
            setConfirmModal({
                show:true,
                msg:'평가를 등록하시겠습니까? 평가는 계약 하나당 한번만 가능합니다.',
                type:type,
            });
        }
        else if(type === 'rate_postphone'){
            setConfirmModal({
                show:true,
                msg:'정말로 평가를 보류하시겠습니까?',
                type:type
            })
        }
    }

    const alertAction = () => {
        if(alertModal.type === 'success'){
            dispatch(setReload(true));
            setShow(false);
        }
    }
    const confirmAction = () => {
        if(confirmModal.type === 'rate_wirte'){
            setConfirmModal(initailAlert);
            rvWriteHandler();
        }
        else{
            setConfirmModal(initailAlert);
            rvHoldHandler();
        }
    }

    const rvWriteHandler = async () => {
        if(feedBack === ''){
            alertModalOn('남기고싶은 말을 작성해주세요.','');
        }
        else{
            dispatch(setLoading(true));
            const params = {
                jwt_token:jwt_token,
                req_idx : reqItem.req_idx,
                rating : selRate,
                cmt_content : feedBack,
                rv_file : rvFile.file,
            }
            const {result,msg} = await rvWriteMutation.mutateAsync(params);

            if(result){
                alertModalOn('평가등록이 완료되었습니다.','success');
            }
            else{
                alertModalOn(msg,'')
            }

            dispatch(setLoading(false));
        }
    }

    const rvHoldHandler = async () => {
        dispatch(setLoading(true));
        const params = {
            jwt_token : jwt_token,
            req_idx : reqItem.req_idx,
        }
        console.log(mb_type);
        const {result, msg} = await rvHoldMutation.mutateAsync(params);

        if(result){
            alertModalOn('평가 보류하기가 완료되었습니다.','success');
        }
        else{
            alertModalOn(msg,'');
        }
        dispatch(setLoading(false));
    }



    const rvFileHandler = (file : FileList | null) => {
        if(file){
            setRvFile({
                file : file[0],
                fileName : file[0].name,
            })
        }
    };


    return (
        <Modal show={show} onHide={()=>{setShow(false);}} className='modal' centered>
            <div className="modal-header">
                <h5 className="modal-title">{mb_type === '1' ? '구매확정' : '평가하기'}</h5>
                <button type="button" className="btn_close btn" onClick={()=>{setShow(false)}}>
                    <img src="/assets/img/ic_colse.png" alt="닫기 아이콘" />
                </button>
            </div>
            <div className="modal-body">
                {mb_type === '1' &&
                    <div className="info_box">
                        <p className="fs_18 fw_700 mb-3 pb-3">{reqItem.req_name}</p>
                        <p className="info_p">
                            <span>지역</span>
                            <span className="fs_14">{reqItem.loc_txt}{reqItem.loc_1_txt !== '' && ' / '+reqItem.loc_1_txt}</span>
                        </p>
                        <div className="d-flex">
                            <p className="info_p mr-5">
                                <span>출발</span>
                                <span className="fs_14">{reqItem.start_date_txt}</span>
                            </p>
                            <p className="info_p">
                                <span>도착</span>
                                <span className="fs_14">{reqItem.end_date_txt}</span>
                            </p>
                        </div>
                        <div className="d-flex">
                            <p className="info_p mr-5">
                                <span>담당자</span>
                                <span className="fs_14">{reqItem.req_mb_name}</span>
                            </p>
                            <p className="info_p">
                                <span>여행 인원수</span>
                                <span className="fs_14">성인 {reqItem.cnt_adult}명 / 유아 {reqItem.cnt_child}명 /소아 {reqItem.cnt_infant}명</span>
                            </p>
                        </div>
                        <p className="info_p"><span>요청사항</span></p>
                        <div className="info_txt">
                            {reqItem.req_memo === '' ? '요청사항이 등록되어있지 않습니다.' : reqItem.req_memo}
                        </div>
                    </div>
                }
                <p className="info_p mt-5"><span>해당업체</span></p>
                <div className="d-flex mt-3">
                    <div className="ic_dot"></div>
                    <div className="fs_14">
                        <p className="fw_600">{mb_type === '1' ? reqItem.est_sct_item.company_name : reqItem.req_mb_name}</p>
                        {/* <p>제안금액 : 성인 {priceType}{NumberComma(Number(reqItem.est_sct_item.price_adult))} / 소아 {priceType}{NumberComma(Number(reqItem.est_sct_item.price_child))} / 유아 {priceType}{NumberComma(Number(reqItem.est_sct_item.price_infant))}</p> */}

                    </div>
                </div>
                <p className="info_p mt-5"><span>별점선택</span></p>
                <div className="d-flex mb-3 pb-3">

                    {[...Array(5)].map((x,index) => {
                        return(
                            <a onClick={()=>{setSelRate(index+1)}} key={index}>
                                <img 
                                    src={index+1 <= selRate ?
                                        '/assets/img/ic_star_on.png' 
                                        :
                                        '/assets/img/ic_star_off.png' 
                                        }
                                    style={{width:30}}
                                    className={classNames({'mr-2':index !== 4})}
                                    alt="별 아이콘" 
                                />
                            </a>
                        )
                    })}
                    {/* <img src="/assets/img/ic_star_off.png" alt="별 아이콘" style={{width:24}} className="mr-2" />
                    <img src="/assets/img/ic_star_off.png" alt="별 아이콘" style={{width:24}} className="mr-2" />
                    <img src="/assets/img/ic_star_off.png" alt="별 아이콘" style={{width:24}} className="mr-2" />
                    <img src="/assets/img/ic_star_off.png" alt="별 아이콘" style={{width:24}} className="mr-2" />
                    <img src="/assets/img/ic_star_off.png" alt="별 아이콘" style={{width:24}} /> */}
                </div>
                <CustomTextarea 
                    contents={feedBack}
                    setContents={setFeedBack}
                    row={5}
                    placeHolder={'남기고 싶은 말을 적으세요! (200자 이내)'}
                    limit={200}
                />
                {/* <textarea 
                    className="form-control"
                    placeholder="남기고 싶은 말을 적으세요!(200자 이내)" 
                    rows={5}
                ></textarea> */}
                {/* <button type="button" className="btn btn-dark text-white file_btn mt-4">파일첨부</button> */}
                <input id="rvFile" type="file" className={'d-none'} onChange={(e)=>{rvFileHandler(e.target.files)}}/>
                <label htmlFor='rvFile'>
                    <div className="btn btn-dark text-white file_btn d-flex align-items-center justify-content-center mt-4"><span>파일첨부</span></div>
                </label>
                {rvFile.fileName !== '' &&
                    <p className="mt-4">* {rvFile.fileName}</p>
                }            
            </div>
            <div className="modal-footer">
                <div className="d-flex w-100">
                    <button type="button" className="btn btn-lg btn-primary btn-block mr-4 mb-0" onClick={()=>{confirmModalon('rate_write')}}>{mb_type === '1' ? '구매확정' : '평가하기'}</button>
                    {mb_type === '1' &&
                        <button type="button" className="btn btn-lg btn-gray btn-block mt-0" onClick={()=>{confirmModalon('rate_postphone')}}>보류하기</button>
                    }
                </div>
            </div>
            
            <AlertModal 
                show={alertModal.show}
                msg={alertModal.msg}
                type={alertModal.type}
                modalOff={alertModalOff}
                alertAction={alertAction}
                btnLabel={'확인'}
            />
            <ConfirmModal 
                show={confirmModal.show}
                msg={confirmModal.msg}
                modalOff={confirmModalOff}
                alertAction={confirmAction}
                btnLabel={'확인'}
            />
        </Modal>
    )
}

export default RateModal;
