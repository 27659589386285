import React from 'react';

import Accordion from 'react-bootstrap/Accordion';
import EmployeeModal from '../../modal/EmployeeModal';
import { ReqListDetailPropType } from '../../inc/type/reqType';
import { downloadFile, NumberComma, priceTypeCon } from '../../inc/func';
import { useAppSelector } from '../../store/config';


interface TravleReqListType {
    index: string,
}

function ReqDetailType5({
    index,
    reqItem,
    selListIdx,
    setSelMbIdx,
    setEmployeeModal,
    setRateToggle,
    
}:ReqListDetailPropType) { //입금대기

    const {mb_type} = useAppSelector(state => state.userInfo);



    return (
        <Accordion.Collapse eventKey={String(index)} in={selListIdx === index}>
            <div className="card-body">
                <ul className="info">
                    <li><span>담당자</span>
                    <p>{reqItem.req_mb_name}</p>
                    </li>
                    <li><span>여행 인원수</span>
                        <p>성인 {reqItem.cnt_adult} / 유아 {reqItem.cnt_child} /소아 {reqItem.cnt_infant}</p>
                    </li>
                    <li><span>견적코드</span>
                        <p>{reqItem.req_code}</p>
                    </li>
                    <li className="textbx w-100"><span>요청사항</span>
                        <textarea name="" id="" cols={10} rows={2} className="form-control" readOnly defaultValue={reqItem.req_memo === '' ? '요청사항이 등록되어있지 않습니다.' : reqItem.req_memo}></textarea>
                    </li>
                </ul>
                <div className="bom">
                    {reqItem.req_file !== '' ?
                        <div className="lef">
                            <p>파일보기</p>
                            <a className="btn btn_down on" type="button" onClick={()=>downloadFile(reqItem.req_file,reqItem.req_file_name)}>다운로드</a>
                        </div>
                        :
                        <div className="lef">
                            <p>등록된 파일이 존재하지 않습니다.</p>
                        </div>
                    }
                    <div className="e_list_wr">
                        <p className="lef">견적 리스트</p>
                        <div className="rg">
                            <div className="e_list">
                                <div className="txt">
                                    <a className="br_name" type="button" onClick={()=>{setSelMbIdx(reqItem.est_sct_item.mb_idx); setEmployeeModal(true)}}>{reqItem.est_sct_item.company_name}</a>
                                    {reqItem.est_sct_item.est_file !== '' ?
                                        <a className="underline" onClick={()=>{downloadFile(reqItem.est_sct_item.est_file,reqItem.est_sct_item.est_file_name)}}>첨부파일보기</a>
                                    :
                                        <p className="ml-2">첨부된 파일이 없습니다.</p>
                                    }
                                    {reqItem.est_sct_item &&
                                    // <p>제안금액(1인당) : 성인 {NumberComma(reqItem.est_sct_item.price_adult)}{reqItem.est_sct_item.price_type} / 소아 {NumberComma(reqItem.est_sct_item.price_child)}{reqItem.est_sct_item.price_type} / 유아 {NumberComma(reqItem.est_sct_item.price_infant)}{reqItem.est_sct_item.price_type}</p>
                                    <p>제안금액(1인당) : 성인 {priceTypeCon(reqItem.est_sct_item.price_type)} {NumberComma(reqItem.est_sct_item.price_adult)} / 소아 {priceTypeCon(reqItem.est_sct_item.price_type)} {NumberComma(reqItem.est_sct_item.price_child)} / 유아 {priceTypeCon(reqItem.est_sct_item.price_type)} {NumberComma(reqItem.est_sct_item.price_infant)}</p>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="lef w-100">
                        <p>입금계좌</p>
                        <div className="bank">
                            <p>국민은행 1234586874568</p>
                            <span className="state s01">{reqItem.pay_status_txt}</span>
                        </div>
                    </div>
                    {(reqItem.req_status === '4' && reqItem.review_chk === 'N') &&
                        <div className="e_btn_wr mt-4">
                            <button className="btn btn_cancel" onClick={()=>{if(setRateToggle)setRateToggle(true)}}>{mb_type === '1' ? '구매확정' : '평가하기'}</button>
                        </div>
                    }
                </div>
            </div>
        </Accordion.Collapse>
    )
}

export default ReqDetailType5;
