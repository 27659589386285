import styled from "styled-components";

type FlexDirection = 'row' | 'column' | 'row-reverse' | 'column-reverse';
type FlexPositon  = 'absolute' | 'relative' | 'fixed' | 'sticky';
type FlexWrap = 'wrap' | 'nowrap' | 'wrap-reverse';
type AlignItems = 
    //basic
    'normal' | 
    'stretch' |
    
    //positional 
    'center' | 
    'start' | 
    'end' | 
    'flex-start' | 
    'flex-end' |
    'baseline' |

    //baseline
    'first baseline' |
    'last baseline' |
    'safe center' |
    'unsafe center' |

    //global values
    'inherit' |
    'initial' |
    'revert' |
    'revert-layer' |
    'unset';

interface FlexType {
    type : FlexDirection;
    inline? : boolean;
    gap? : number;
    alignItem? : AlignItems;
    name? :string;
    mb? : number;
    mt? : number;
    ml? : number;
    mr? : number;
    pt? : number;
    pb? : number;
    pl? : number;
    pr? : number;
    width? : number | string;
    height? : number | string;
    position? : FlexPositon;
    flexWrap? : FlexWrap;
}

interface marginYType {
    mt? : number;
    mb? : number;    
}

export const MarginY = styled.div<marginYType>`
    margin-bottom:${({mb}) => mb && `${mb}rem`};
    margin-top:${({mt}) => mt && `${mt}rem`};
`

/********* FlexBox **********/
export const FlexBox = styled.div<FlexType>`
    display:${({inline}) => !inline ? 'flex' : 'inline-flex'};
    flex-direction: ${({type}) => type && `${type}`};
    gap:${({gap}) => gap && `${gap}rem`};
    align-items: ${({alignItem}) => alignItem && `${alignItem}`};

    margin-top:${({mt}) => mt && `${mt}rem`};
    margin-bottom: ${({mb}) => mb && `${mb}rem`};
    margin-left:${({ml}) => ml && `${ml}rem`};
    margin-right:${({mr}) => mr && `${mr}rem`};

    padding-top:${({pt}) => pt && `${pt}rem`};
    padding-bottom:${({pb}) => pb && `${pb}rem`};
    padding-left:${({pl}) => pl && `${pl}rem`};
    padding-right:${({pr}) => pr && `${pr}rem`};

    width:${({width}) => typeof width === 'string' ? `${width}` : `${width}rem`};
    height:${({height}) => typeof height === 'string' ? `${height}` : `${height}rem`};
    position:${({position}) => position && `${position}`};
    flex-wrap:${({flexWrap}) => flexWrap && `${flexWrap}`};
`
export const FlexStart  = styled(FlexBox)`
    justify-content: flex-start;
`
export const FlexEnd = styled(FlexBox)`
    justify-content:flex-end;
`
export const FlexSpaceB = styled(FlexBox)`
    justify-content: space-between;
`
export const FlexSpaceA = styled(FlexBox)`
    justify-content: space-around;
`
export const FlexSpaceE = styled(FlexBox)`
    justify-content: space-evenly;
`
export const FlexCenter = styled(FlexBox)`
    justify-content: center;
`
/******** flex Box *******/