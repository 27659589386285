import React from 'react';
import CustomInputBtn from '../../../components/form/CustomBtnInput';
import CustomButton from '../../../components/form/CustomButton';
import CustomInput from '../../../components/form/CustomInput';
import { usePostMutation } from '../../../inc/axiosQuery';
import { MarginY } from '../../../inc/FlexStyle';
import { phoneCheck } from '../../../inc/func';
import AlertModal from '../../../modal/AlertModal';
import ChangePwdModal from '../../../modal/user/ChangePwdModal';
import { useAppDispatch, useAppSelector } from '../../../store/config';
import { setLoading } from '../../../store/slices/LoadingSlice';
import { useNavigate } from 'react-router';


function FindPw() {

    const {mb_type} = useAppSelector(state => state.userInfo);
    const navigator = useNavigate();
    const dispatch = useAppDispatch();
    const sendPhoneCodeMutation = usePostMutation('sendPhoneCode','user/auth_msg.php');
    const authPhoneCodeMutation = usePostMutation('authPhoneCode','user/auth_msg_chk.php');

    const [id , setId] = React.useState('');
    const [phone, setPhone] = React.useState('');
    const [auth, setAuth] = React.useState('');

    const [isCodeSend, setIsCodeSend] = React.useState(false);

    const [alertModal, setAlertModal] = React.useState({
        show:false,
        msg:'',
        type:'',
    })
    const [changePwdModal, setChangePwdModal] = React.useState(false);

    const alertModalOn = (msg:string, type:string) => {
        setAlertModal({
            show:true,
            msg:msg,
            type:type,
        })
    }

    const alertModalOff = () => {
        setAlertModal({
            show:false,
            msg:'',
            type:'',
        })
    }

    const alertAction = () => {

    }

    const [inputCheck, setInputCheck] = React.useState(
        {
            id : false,
            idMsg : '',
            phone : false, 
            phoneMsg : '',
            auth : false,
            authMsg : '',
        }
    )

    const sendPhoneCodeHandler = async () => {
        if(!phoneCheck(phone).result){
            setInputCheck({
                ...inputCheck,
                phone : false,
                phoneMsg : phoneCheck(phone).msg,
            })
            alertModalOn(phoneCheck(phone).msg,'');
        }
        else{
            dispatch(setLoading(true));
            const {result,msg} = await sendPhoneCodeMutation.mutateAsync({mb_phone:phone});

            setIsCodeSend(result);
            
            if(result){
                alertModalOn('인증번호가 발송되었습니다.','');
                setInputCheck({
                    ...inputCheck,
                    phone : true,
                    phoneMsg : '인증번호가 발송되었습니다.',
                })
            }
            else{
                setInputCheck({
                    ...inputCheck,
                    phone:false,
                    phoneMsg : msg,
                })
                alertModalOn(msg,'');
            }
            dispatch(setLoading(false));
        }
    }

    const authPhoneCodeHandler = async () => {

        if(!phoneCheck(phone).result){
            setInputCheck({
                ...inputCheck,
                phone : false,
                phoneMsg : phoneCheck(phone).msg,
            })
            alertModalOn(phoneCheck(phone).msg,'');
            return false;
        }
        if(auth === ''){
            setInputCheck({
                ...inputCheck,
                auth : false,
                authMsg : '인증번호를 입력해주세요.',
            })
            alertModalOn('인증번호를 입력해주세요.','');
        }
        else{
            dispatch(setLoading(true));
            const {result,msg} = await authPhoneCodeMutation.mutateAsync({
                mb_phone:phone,
                auth_number : auth,
            })
            if(result){
                
                setIsCodeSend(false);
                alertModalOn(msg,'auth_success');
                setInputCheck({
                    ...inputCheck,
                    phone:true,
                    auth:true,
                    phoneMsg : msg,
                })
                
            }
            else{
                setInputCheck({
                    ...inputCheck,
                    auth:false,
                    authMsg : msg,
                })
                alertModalOn(msg,'');
            }
            dispatch(setLoading(false));
        }
    }

    const pwdModalHandler = () =>{
        if(id === ''){
            alertModalOn('아이디를 입력해주세요.','');
        }
        else if(!inputCheck.phone || !inputCheck.auth){
            alertModalOn('휴대폰인증을 실시해주세요.','');
        }   
        else{
            setChangePwdModal(true);
        }
    }

    React.useEffect(()=>{
        if(mb_type === '1'){
            navigator('/travleMain',{replace:true});
        }
        else if(mb_type === '2'){
            navigator('/landMain',{replace:true});
        }
    },[])
    return (
        <>  
            <CustomInput 
                title="아이디"
                placeHolder='아이디를 입력해주세요'
                input={id}
                setInput={setId}
                isValidShow={inputCheck.id}
                isValid={!inputCheck.id}
                validText={inputCheck.idMsg}
            />
            <MarginY mt={2} />
            <CustomInputBtn 
                title="휴대폰번호"
                input={phone}
                setInput={setPhone}
                isValidShow={inputCheck.phone || inputCheck.phoneMsg !== ''}
                isValid={inputCheck.phone}
                validText={inputCheck.phoneMsg}
                placeHolder={'(-)을 제외하고 입력해주세요.'}
                btnLabel={'인증번호 받기'}
                btnAction={sendPhoneCodeHandler}
                inputStyle={'col-9 pr-0'}
                btnStyle={'col-3 pl-0'}
                btnLabelStyle={'fs_16'}
                isReadOnly={inputCheck.phone && inputCheck.auth}
            />
            <MarginY mt={1} />
            {isCodeSend &&
                <CustomInputBtn 
                    input={auth}
                    setInput={setAuth}
                    isValidShow={inputCheck.auth || inputCheck.authMsg !== ''}
                    isValid={inputCheck.auth}
                    validText={inputCheck.authMsg}
                    placeHolder={'인증번호를 입력해주세요.'}
                    btnLabel={'확인'}
                    btnAction={authPhoneCodeHandler}
                    inputStyle={'col-9 pr-0'}
                    btnStyle={'col-3 pl-0'}
                    btnLabelStyle={'fs_16'}
                />
            }
            <MarginY mt={4} />
            <CustomButton 
                btnLabel='비밀번호 변경하기'
                color='green'
                type='lg'
                clickEvent={pwdModalHandler}
                labelStyle={'fw_700'}
            />
            <AlertModal 
                show={alertModal.show}
                modalOff={alertModalOff}
                alertAction={alertAction}
                msg={alertModal.msg}
                type={alertModal.type}
                btnLabel={'확인'}

            />

            <ChangePwdModal
                show={changePwdModal}
                setShow={setChangePwdModal}
                mb_id={id}
                type={'find'}
            />
        </>
    );
}

export default FindPw;
