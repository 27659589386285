import React from 'react';

import LoadingImg from '../../assets/Loading.svg';



export default function Loading(){

    const spinerStyle = {
        width:120,
        height:120,
    }

    return(
        <div className={'d-flex flex-row justify-content-center align-items-center'} style={{height:300}}>
            <img src={LoadingImg} style={spinerStyle}/>
        </div>
    )
}