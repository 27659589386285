import React from 'react';
import Calendar from 'react-calendar';
import { DateFormatter, DayPicker } from 'react-day-picker';
import { ko } from 'date-fns/locale';
import moment from 'moment';
import '../../assets/style/CustomCalender.css';
import CustomCaption from '../../components/form/DayPicker';
import CustomSelectBox from '../../components/form/CustomSelectBox';
import { RecProType } from './recType';
import CustomInput from '../../components/form/CustomInput';
import classNames from 'classnames';
import { dateConverter } from '../../inc/func';
import { usePostMutation } from '../../inc/axiosQuery';
import AlertModal from '../AlertModal';
import { useAppDispatch } from '../../store/config';
import { setReload } from '../../store/slices/ReloadSlice';
import { setLoading } from '../../store/slices/LoadingSlice';

function RecStep5({recInfo, setRecInfo,recStep,setRecStep,setShow,clearInfo,editType} : RecProType) {    

    const dispatch = useAppDispatch();
    const recProductMutation = usePostMutation('recPro','product/product_write.php');;
    const recProductEditMutation = usePostMutation('recEditPro','product/product_edit.php');

    const [alertModal, setAlertModal] = React.useState({
        show : false,
        msg : '',
        type : '',
    })

    const alertModalOn = (msg:string,type:string) => {
        setAlertModal({
            show : true,
            msg : msg,
            type : type,
        })
    }
    const alertModalOff = () => {
        setAlertModal({
            show : false,
            msg : '',
            type : '',
        })
    }
    const alertAction = () => {
        if(alertModal.type === 'step2'){
            setRecStep(2);
            alertModalOff()
        }
        else if(alertModal.type === 'step3'){
            setRecStep(3);
            alertModalOff()
        }
        else if(alertModal.type === 'step4'){
            setRecStep(4);
            alertModalOff()
        }
        else if(alertModal.type === 'write_fail'){
            alertModalOff();
            // setShow(false);
        }
        else if(alertModal.type === 'write_success'){
            if(clearInfo)clearInfo();
            setRecStep(1);
            alertModalOff();
            setShow(false);
            dispatch(setReload(true));
        }
    }


    const inputHandler = (text:string,key?:string) => {
        if(key){
            setRecInfo({
                ...recInfo,
                [key]:text
            });
        }
    }

    const reqFileHandler = (file : FileList | null) => {
        if(file){
            setRecInfo({
                ...recInfo,
                pt_file : file[0],
                pt_file_name : file[0].name,
            })
        }
    }

    const onUpload = (e: any) => {
        const file = e[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);

        return new Promise<void>((resolve) => { 
            reader.onload = () => {	
                setRecInfo({
                    ...recInfo,
                    pt_image : e[0],
                    pt_image_preview : reader.result || null,
                }); // 파일의 컨텐츠
                resolve();
            };
        });
    }

    const accessRecPro = async () => {
        if(recInfo.endDate === recInfo.startDate){
            alertModalOn('출발일과 도착일이 동일합니다.','step2');
        }
        // else if(recInfo.loc === ''){
        //     alertModalOn('행선지를 선택해주세요','step3');
        // }
        else if(recInfo.cnt_adult === 0 && recInfo.cnt_child === 0 && recInfo.cnt_infant === 0){
            alertModalOn('최소 인원수를 입력해주세요.','step4');
        }
        else if(recInfo.pt_title === ''){
            alertModalOn('제목을 입력해주세요.' , '');
        }
        else if(recInfo.pt_mb_name === ''){
            alertModalOn('담당자를 입력해주세요.' , '');
        }
        else if(recInfo.price_adult === ''){
            alertModalOn('성인 1인 기준 금액을 입력해주세요.','');
        }
        // else if(recInfo.price_child === ''){
        //     alertModalOn('유아 1인 기준 금액을 입력해주세요.','');
        // }
        // else if(recInfo.price_infant === ''){
        //     alertModalOn('소아 1인 기준 금액을 입력해주세요.','');
        // }
        else{
            dispatch(setLoading(true));

            if(editType === 'u'){
                const params = {
                    ...recInfo,
                    price_child : recInfo.price_child === '' ? 0 : recInfo.price_child,
                    price_infant : recInfo.price_infant === '' ? 0 : recInfo.price_infant,
                }

                const {data,result,msg} = await recProductEditMutation.mutateAsync(params);

                if(result){
                    alertModalOn('상품 수정이 완료되었습니다.','write_success');
                }
                else{
                    alertModalOn(msg,'write_fail');
                }

                dispatch(setLoading(false));
            }
            else{

                const params = {
                    ...recInfo,
                    price_child : recInfo.price_child === '' ? 0 : recInfo.price_child,
                    price_infant : recInfo.price_infant === '' ? 0 : recInfo.price_infant,
                }

                const {data,result,msg} = await recProductMutation.mutateAsync(params);

                if(result){
                    alertModalOn('상품 추천이 완료되었습니다.','write_success');
                }
                else{
                    alertModalOn(msg,'write_fail');
                }
                dispatch(setLoading(false));
            }
            
            
        }

    }

    return (
        <div>
            <div className="modal-header">
                <h5 className="modal-title">나의 추천 상품</h5>
                <button type="button" className="btn_close btn" data-dismiss="modal" aria-label="Close">
                    <img src="/assets/img/ic_colse.png" alt="닫기 아이콘" onClick={()=>{if(clearInfo)clearInfo(); setShow(false);}} />
                </button>
            </div>
            <div className="big-modal-wrapper">
            <div className="modal-body">
                <div className="info_box">
                    <p className="info_p">
                        <span>지역</span>
                        <span className="fs_14">{recInfo.loc_temp_name} {recInfo.loc_temp_name !== '전체' && `/ ${recInfo.loc1_temp_name}` }</span>

                    </p>
                    <div className="d-flex">
                        <p className="info_p mr-5">
                            <span>출발</span>
                            <span className="fs_14">{`${dateConverter(recInfo.startDate).year}.${dateConverter(recInfo.startDate).month}.${dateConverter(recInfo.startDate).day}`}</span>
                        </p>
                        <p className="info_p">
                            <span>도착</span>
                            <span className="fs_14">{`${dateConverter(recInfo.endDate).year}.${dateConverter(recInfo.endDate).month}.${dateConverter(recInfo.endDate).day}`}</span>
                        </p>
                    </div>
                    <div className="d-flex">
                        {/* <p className="info_p mr-5">
                            <span>담당자</span>
                            <span className="fs_14">김경종</span>
                        </p> */}
                        <p className="info_p">
                            <span>여행 인원수</span>
                            <span className="fs_14">성인 {recInfo.cnt_adult} / 유아 {recInfo.cnt_child} /소아 {recInfo.cnt_infant}</span>
                        </p>
                    </div>
                    <p className="info_p row mx-0 align-items-center mt-3">
                        <span className="col-2 pl-0 mr-0">제목 <em className="essential">*</em></span>
                        <input type="text" className="form-control form-control-sm col-10 border-0" placeholder="입력해주세요." value={recInfo.pt_title} onChange={(e)=>{setRecInfo({...recInfo, pt_title : e.target.value})}}/>
                    </p>
                    <p className="info_p row mx-0 align-items-center">
                        <span className="col-2 pl-0 mr-0">담당자<em className="essential">*</em></span>
                        <input type="text" className="form-control form-control-sm col-10 border-0" placeholder="입력해주세요." value={recInfo.pt_mb_name} onChange={(e)=>{setRecInfo({...recInfo, pt_mb_name : e.target.value})}} />
                    </p>
                    <p className="info_p"><span>비고</span></p>
                    <textarea className="form-control form-control-lg border-0" placeholder="남기고 싶은 말을 적어세요!(200자 이내)" defaultValue={recInfo.pt_memo} rows={5} onChange={(e)=>{setRecInfo({...recInfo, pt_memo : e.target.value})}}></textarea>
                </div>
                <div className="d-flex justify-content-between align-items-center mt-4 pt-2">
                    <p className="text_gray">통화 선택</p>
                    <div className="d-flex justify-content-end">
                        {recInfo.price_type === '원화' ?
                            <button type="button" className="btn btn-dark btn-sm w_100 h_40 mr-3" onClick={()=>{setRecInfo({...recInfo,price_type : '원화'})}}>원화</button>
                        :
                            <button type="button" className="btn btn-outline-dark btn-sm w_100 h_40 mr-3" onClick={()=>{setRecInfo({...recInfo,price_type : '원화'})}}>원화</button>
                        }
                        {recInfo.price_type === '달러' ? 
                            <button type="button" className="btn btn-dark btn-sm w_100 h_40" onClick={()=>{setRecInfo({...recInfo, price_type : '달러'})}}>달러</button>
                        :
                            <button type="button" className="btn btn-outline-dark btn-sm w_100 h_40" onClick={()=>{setRecInfo({...recInfo, price_type : '달러'})}}>달러</button>
                        }
                        
                    </div>
                </div>
                <div className="ip_wr mt-4 pt-2">
                    <CustomInput 
                        title="성인 1인 기준"
                        placeHolder='금액을 입력해주세요.'
                        input={recInfo.price_adult}
                        setInput={inputHandler}
                        keyName={'price_adult'}
                        isValidShow={false}
                        type={'number'}
                    />
                </div>
                <div className="ip_wr mt-4 pt-2">
                    <CustomInput 
                        title="유아 1인 기준"
                        placeHolder='금액을 입력해주세요.'
                        input={recInfo.price_child}
                        setInput={inputHandler}
                        keyName={'price_child'}
                        isValidShow={false}
                        type={'number'}
                    />
                </div>
                <div className="ip_wr mt-4 pt-2">
                    <CustomInput 
                        title="소아 1인 기준"
                        placeHolder='금액을 입력해주세요.'
                        input={recInfo.price_infant}
                        setInput={inputHandler}
                        keyName={'price_infant'}
                        isValidShow={false}
                        type={'number'}
                    />
                </div>
                <input id="reqFile" type="file" className="d-none" onChange={(e)=>{reqFileHandler(e.target.files)}}/>
                <label htmlFor="reqFile">    
                    <div className="btn btn-dark text-white file_btn d-flex align-items-center justify-content-center mt-4"><span>파일첨부</span></div>
                </label>
                {recInfo.pt_file_name !== '' &&
                    <p className="mt-4">* {recInfo.pt_file_name}</p>
                } 
                <div className="ip_wr mt-4 pt-2">
                    <div className="ip_tit">
                        <h5>대표이미지</h5>
                    </div>
                    <div className="img_attachment_wrap">
                        <input id="recImage" type="file" className="d-none" onChange={(e)=>{onUpload(e.target.files)}} accept="image/*"/>
                        <label htmlFor='recImage' style={{cursor:'pointer'}}>
                            <div className={classNames('rect btn_img_attach img_attachment',{active : recInfo.pt_image_preview !== ''})}>
                                {/* <img src="/assets/img/ic_plus_gr.png" alt="이미지 첨부" /> */}
                                <img src={recInfo.pt_image_preview === '' ? '/assets/img/ic_plus_gr.png' : recInfo.pt_image_preview} alt="이미지 첨부" />
                            </div>
                            <div className="rec">
                            </div>
                        </label>
                        {/* <div className="rect img_attachment">
                            <img src="./img/ic_close2.png" alt="닫기" className="btn_close">
                            <img src="./img/sample4.jpg" alt="샘플이미지">
                        </div> */}
                    </div>
                </div>                      
            </div>
            </div>
            <div className="modal-footer">
                <div className="d-flex w-100">
                    <button type="button" className="btn btn-lg btn-gray btn-block mb-0" onClick={()=>{setRecStep(recStep-1)}}>이전</button>
                    <button type="button" className="btn btn-lg btn-primary btn-block ml-4 mt-0" onClick={()=>{accessRecPro();}}>완료</button>
                </div>
            </div>
            <AlertModal 
                show={alertModal.show}
                modalOff={()=>{alertModalOff()}}
                msg={alertModal.msg}
                alertAction={()=>{alertAction()}}
                btnLabel={'확인'}
                type={alertModal.type}
            />
        </div>
    )
}

export default RecStep5;
