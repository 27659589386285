import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { usePostMutation } from '../../inc/axiosQuery';
import { NumberComma } from '../../inc/func';
import { EstListType, LandReqListType, TravleReqListType } from '../../inc/type/reqType';
import { useAppDispatch, useAppSelector } from '../../store/config';
import { setLoading } from '../../store/slices/LoadingSlice';
import { setReload } from '../../store/slices/ReloadSlice';
import AlertModal from '../AlertModal';

interface ModalType {
    show : boolean;
    setShow : (hide:boolean) => void;
    reqItem:TravleReqListType | LandReqListType,
    selEstInfo : EstListType,
}

function ReqDetailModal({ //여행사 - 견적상세
    show,
    setShow,
    reqItem,
    selEstInfo,
}:ModalType) {

    const dispatch = useAppDispatch();
    const {jwt_token} = useAppSelector(state => state.userInfo);

    const estSelMutation = usePostMutation('estSel','doc/est_select.php');

    const [alertModal, setAlertModal] = React.useState({
        show:false,
        msg:'',
        type:'',
    })

    const alertModalOn = (msg:string,type:string) => {
        setAlertModal({
            show:true,
            msg:msg,
            type:type,
        })
    }
    const alertModalOff= () => {
        setAlertModal({
            show:false,
            msg:'',
            type:'',
        })
    }

    const alertAction = () => {
        // if(alertModal.type === 'write_success'){
        dispatch(setReload(true));
        setShow(false);
        // }
    }


    const estSelAccess = async () => {
        dispatch(setLoading(true));
        const params = {
            jwt_token:jwt_token,
            est_idx:selEstInfo.est_idx,
            req_idx:reqItem.req_idx,
        }
        const {result,msg} = await estSelMutation.mutateAsync(params);
        
        if(result){
            alertModalOn(msg,'write_success');
        }
        else{
            alertModalOn(msg,'')
        }
        dispatch(setLoading(false));
    }

    const priceType = selEstInfo.price_type === '달러' ? 'USD ' : 'KRW '

    const totalPrice = Number(reqItem.cnt_adult)*Number(selEstInfo.price_adult) +
                       Number(reqItem.cnt_child)*Number(selEstInfo.price_child) +
                       Number(reqItem.cnt_infant)*Number(selEstInfo.price_infant);

    return (
        <Modal show={show} onHide={()=>{setShow(false);}} className='modal' centered>
            <div className="modal-header">
                <h5 className="modal-title">견적상세</h5>
                <button type="button" className="btn_close btn" onClick={()=>{setShow(false);}}>
                    <img src="/assets/img/ic_colse.png" alt="닫기 아이콘" />
                </button>
            </div>
            <div className="modal-body">
                <div className="info_box">
                    <p className="fs_18 fw_700 mb-3 pb-3">{reqItem.req_name}</p>
                    <p className="info_p">
                        <span>지역</span>
                        <span className="fs_14">{reqItem.loc_txt}{reqItem.loc_1_txt !== '' && ' / '+reqItem.loc_1_txt}</span>
                    </p>
                    <div className="d-flex">
                        <p className="info_p mr-5">
                            <span>출발</span>
                            <span className="fs_14">{reqItem.start_date_txt}</span>
                        </p>
                        <p className="info_p">
                            <span>도착</span>
                            <span className="fs_14">{reqItem.end_date_txt}</span>
                        </p>
                    </div>
                    <div className="d-flex">
                        <p className="info_p mr-5">
                            <span>담당자</span>
                            <span className="fs_14">{reqItem.req_mb_name}</span>
                        </p>
                        <p className="info_p">
                            <span>여행 인원수</span>
                            <span className="fs_14">성인 {NumberComma(Number(reqItem.cnt_adult))} / 유아 {NumberComma(Number(reqItem.cnt_child))} /소아 {NumberComma(Number(reqItem.cnt_infant))}</span>
                        </p>
                    </div>
                    <p className="info_p"><span>요청사항</span></p>
                    <div className="info_txt">
                        {reqItem.req_memo === '' ? '요청사항이 등록되어있지 않습니다.' : reqItem.req_memo }
                    </div>
                </div>
                <p className="info_p mt-5"><span>해당업체</span></p>
                <div className="d-flex mt-3">
                    <div className="ic_dot"></div>
                    <div className="fs_14">
                        <p className="fw_600 mb-3">{selEstInfo.company_name}</p>
                        <p>제안금액 : 성인 {priceType}{NumberComma(selEstInfo.price_adult)} / 소아 {priceType}{NumberComma(selEstInfo.price_child)} / 유아 {priceType}{NumberComma(selEstInfo.price_infant)}</p>
                    </div>
                </div>

                <p className="info_p mt-3 pt-3"><span>합계</span></p>
                <div className="info_box py-4">
                    <p className="d-flex align-items-center justify-content-between fs_14 py-2">
                        <span className="d-flex justify-content-between">
                            <span className="span_cate">성인</span>
                            <span>x {reqItem.cnt_adult}</span>
                        </span>
                        <span>{priceType}{NumberComma(Number(selEstInfo.price_adult) * Number(reqItem.cnt_adult))}</span>
                    </p>
                    <p className="d-flex align-items-center justify-content-between fs_14 py-2">
                        <span className="d-flex justify-content-between">
                            <span className="span_cate">유아</span>
                            <span>x {reqItem.cnt_child}</span>
                        </span>
                        <span>{priceType}{NumberComma(Number(selEstInfo.price_child) * Number(reqItem.cnt_child))}</span>
                    </p>
                    <p className="d-flex align-items-center justify-content-between fs_14 py-2">
                        <span className="d-flex justify-content-between">
                            <span className="span_cate">소아</span>
                            <span>x {reqItem.cnt_infant}</span>
                        </span>
                        <span>{priceType}{NumberComma(Number(selEstInfo.price_infant) * Number(reqItem.cnt_infant))}</span>
                    </p>
                    {/* +<p className="d-flex align-items-center justify-content-between fs_14 py-2">
                        <span>할인 금액</span>
                        <span>₩0</span>
                    </p> */}
                    <div className="border-top my-4"></div>
                    <p className="d-flex align-items-center justify-content-between fs_14">
                        <span>합계</span>
                        <span className="fs_18 fw_600">{priceType}{NumberComma(totalPrice)}</span>
                    </p>
                </div>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-lg btn-primary btn-block" onClick={estSelAccess}>선택하기</button>
            </div>
            <AlertModal
                show={alertModal.show}
                modalOff={alertModalOff}
                msg={alertModal.msg}
                alertAction={alertAction}
                btnLabel={'확인'}
                type={alertModal.type}
            />
        </Modal>
    )
}

export default ReqDetailModal;
