import React from 'react';
import classNames from 'classnames';
import CustomInput from '../../../components/form/CustomInput';
import { MarginY } from '../../../inc/FlexStyle';
import CustomInputBtn from '../../../components/form/CustomBtnInput';
import CustomButton from '../../../components/form/CustomButton';
import CustomFile from '../../../components/form/CustomFile';
import CustomSelectBox from '../../../components/form/CustomSelectBox';
import AlertModal from '../../../modal/AlertModal';
import AgreeModal from '../../../modal/AgreeModal';
import { usePostMutation, usePostQuery } from '../../../inc/axiosQuery';
import { emailCheck, idCheck, phoneCheck, pwCheck, pwCheckRe } from '../../../inc/func';
import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../../store/config';
import { setLoading } from '../../../store/slices/LoadingSlice';
import ChangePwdModal from '../../../modal/user/ChangePwdModal';
import ChangePhoneModal from '../../../modal/user/ChangePhoneModal';
import { setUserInfo } from '../../../store/slices/UserInfoSlice';

type BankType = {
    value:string,
    label:string,
}

function TravleEditMyInfo() {

    const dispatch = useAppDispatch();
    const userInfo = useAppSelector(state => state.userInfo);
    const editNormalInfoMutation = usePostMutation('editNormalInfo','user/user_edit_1.php');
    
    const sendEmailAuthMutation = usePostMutation('sendEmailAuth','user/email_auth_send.php'); //이메일 인증 전송
    const emailAuthMutation = usePostMutation('emailAuth','user/email_auth_chk.php'); //이메일인증

    const corpNumCheckMutation = usePostMutation('corpNumCheck', 'user/corp_number_chk.php'); //사업자등록번호 인증



    const {data:bankList, isLoading : bankLoading, isError: bankError} = usePostQuery('test',{},'bank/bank_list.php');
    const navigator = useNavigate();
    
    const [joinInfo , setJoinInfo] = React.useState({
        jwt_token : userInfo.jwt_token,
        mb_id : userInfo.mb_id,
        mb_name : userInfo.mb_name,
        mb_email : userInfo.mb_email,
        mb_email_auth : '',
        mb_name_ : userInfo.mb_name,
        company_cate_1 : userInfo.company_cate_1,
        company_cate_2 : userInfo.company_cate_2,
        company_number : userInfo.company_number,
        company_name : userInfo.company_name,
        company_reg_file_name : userInfo.company_reg_file_name,
        company_reg_file : {},
        bank_name : userInfo.bank_name,
        bank_number : userInfo.bank_number,
        bank_reg_file_name : userInfo.bank_reg_file_name,
        bank_reg_file : {},
        mb_reg_type : 'E',
    })

    const [inputCheck, setInputCheck] = React.useState({
        mb_id : true,
        mb_name : true,
        mb_email : true,
        mb_email_auth : true,
        company_name : true,
        company_cate_1 : false,
        company_cate_2 : false,
        company_number : true,

        mb_id_msg : '',
        mb_name_msg : '',
        mb_email_msg : '',
        mb_email_auth_msg : '',
        company_name_msg : '',
        company_cate_1_msg : '',
        company_cate_2_msg : '',
        company_number_msg : '',
    })

    const [isSendEmailAuth, setIsSendEmailAuth] = React.useState(false);

    const [selBankList, setSelBankList] = React.useState<BankType[]>([]);

    const [chgPwdModal, setChgPwdModal] = React.useState(false);
    const [chgPhoneModal, setChgPhoneModal] = React.useState(false);


    const [alertModal, setAlertModal] = React.useState({
        show : false,
        msg : '',
        type : '',
    });

    const modalOff = () => {
        setAlertModal({
            show : false,
            msg : '',
            type : '',
        })
    }
    const alertModalOn = (msg:string) => {
        setAlertModal({
            show:true,
            msg,
            type:'',
        })
        return false;
    }
    const alertModalAction = () => {
        if(alertModal.type === 'success'){
            navigator('/');
        }
    }
    

    const inputController = (text:string , type?:string) => { //입력 컨트롤러
        if(type){
            setJoinInfo({
                ...joinInfo,
                [type] : text,
            })
        }
    }

    const busiNumberCheck = async () => {
        if(joinInfo.company_number === ''){
            alertModalOn('사업자등록번호를 입력해주세요.');
            setInputCheck({
                ...inputCheck,
                company_number : false,
                company_number_msg : '사업자등록번호를 입력해주세요.',
            })
        }
        else{
            dispatch(setLoading(true));

            const {result, msg} = await corpNumCheckMutation.mutateAsync({
                company_number : joinInfo.company_number
            })

            if(result){
                alertModalOn(msg);
                setInputCheck({
                    ...inputCheck,
                    company_number : true,
                    company_number_msg : msg,
                })
            }
            else{
                alertModalOn(msg);
                setInputCheck({
                    ...inputCheck,
                    company_number : false,
                    company_number_msg : msg,
                })
            }
            dispatch(setLoading(false));
            
        }
    }

    const busiFileChange = (file : FileList | null) => { //사업자등록증 사본
        if(file){
            setJoinInfo({
                ...joinInfo,
                company_reg_file_name : file[0].name,
                company_reg_file : file[0],
            })
        }
    }
    const bankFileChange = (file: FileList | null) => { //통장사본 업로드
        if(file){
            setJoinInfo({
                ...joinInfo,
                bank_reg_file_name : file[0].name,
                bank_reg_file : file[0],
            })
        }
    }

    const sendEmailAuth = async () => { //이메일 인증 전송
        if(!inputCheck.mb_email){
            alertModalOn(inputCheck.mb_email_msg);
        }
        else{

            dispatch(setLoading(true));
            const {result,msg} = await sendEmailAuthMutation.mutateAsync({mb_email:joinInfo.mb_email});

            if(result){
                setAlertModal({
                    show : true,
                    msg : `인증번호가 \n 이메일로 발송되었습니다.`,
                    type : 'send-email',
                })
                setIsSendEmailAuth(true);
            }
            else{
                setInputCheck({
                    ...inputCheck,
                    mb_email:false,
                    mb_email_msg:msg,
                })
                setAlertModal({
                    show : true,
                    msg : msg,
                    type : '',
                })
            }
            dispatch(setLoading(false));
        }
    }

    const authEmailHandler = async () => { //이메일 인증
        if(joinInfo.mb_email_auth === ''){
            setInputCheck({
                ...inputCheck,
                mb_email_auth : false,
                mb_email_msg : '인증번호를 입력해주세요.',
            });
        }
        else{
            dispatch(setLoading(true));
            const authParams = {
                mb_email : joinInfo.mb_email,
                auth_no : joinInfo.mb_email_auth
            }
            const {result,msg} = await emailAuthMutation.mutateAsync(authParams);

            if(result){
                setIsSendEmailAuth(false);
                setInputCheck({
                    ...inputCheck,
                    mb_email_auth:true,
                    mb_email_msg:msg,      
                })
            }
            else{
                setInputCheck({
                    ...inputCheck,
                    mb_email_auth : false,
                    mb_email_auth_msg: msg,
                })
                setAlertModal({
                    show:true,
                    msg:msg,
                    type:'',
                })
            }
            dispatch(setLoading(false));
        }
    }

    

    const editTravleHandler = async () => { //회원가입

        if(joinInfo.mb_id === ''){
            alertModalOn(inputCheck.mb_id_msg === '' ? '아이디를 입력해주세요.' : inputCheck.mb_id_msg);
        }
        else if(!inputCheck.mb_id){
            alertModalOn(inputCheck.mb_id_msg);
        }
        else if(joinInfo.mb_name === ''){
            alertModalOn('담당자 이름을 입력해주세요.');
        }
        else if(!inputCheck.mb_email){
            alertModalOn(inputCheck.mb_email_msg === '' ? '유효한 이메일을 입력해주세요.' : inputCheck.mb_email_msg);
        }
        else if(!inputCheck.mb_email_auth){
            alertModalOn('이메일 인증을 진행해주세요.');
        }
        else if(joinInfo.company_name === ''){
            alertModalOn('회사명을 입력해주세요.');
        }
        else if(joinInfo.company_number === ''){
            alertModalOn('사업자 등록번호를 입력해주세요.');
        }
        else if(!inputCheck.company_number){
            alertModalOn(inputCheck.company_number_msg === '' ? '사업자등록번호 인증을 진행해주세요.' : inputCheck.company_number_msg);
        }
        else if(joinInfo.company_cate_1 === ''){
            alertModalOn('업종을 입력해주세요.')
        }
        else if(joinInfo.company_cate_2 === ''){
            alertModalOn('업태를 입력해주세요.');
        }
        else if(joinInfo.company_reg_file_name === ''){
            alertModalOn('사업자등록증 사본을 등록해주세요.');
        }
        else if(joinInfo.bank_name === ''){
            alertModalOn('은행을 선택해주세요.');
        }
        else if(joinInfo.bank_number === ''){
            alertModalOn('계좌번호를 입력해주세요.(-포함)');
        }
        else if(joinInfo.bank_reg_file_name === ''){
            alertModalOn('통장사본을 등록해주세요.');
        }
        
        else{
            dispatch(setLoading(true));
            const {result,msg} = await editNormalInfoMutation.mutateAsync(joinInfo);

            if(!result){
                setAlertModal({
                    show:true,
                    msg : msg,
                    type : '',
                })
            }
            else{

                dispatch(setUserInfo({
                    ...userInfo,
                    ...joinInfo,
                }))
                setAlertModal({
                    show:true,
                    msg:msg,
                    type : 'success',
                })
            }

            dispatch(setLoading(false));
        }

        
    }

    React.useEffect(()=>{
        if(bankList){
            const {data} = bankList;

            let tempList:BankType[] = [];

            data.bank_list.forEach((item:string,index:number) => {
                tempList.push({
                    label:item,
                    value:item,
                })
            })
            setSelBankList([...tempList]);
        }
    },[bankList,bankLoading,bankError])

    React.useEffect(()=>{
        setInputCheck({
            ...inputCheck,
            mb_id : idCheck(joinInfo.mb_id).result,
            mb_id_msg : idCheck(joinInfo.mb_id).msg,
        })
    },[joinInfo.mb_id])

    React.useEffect(()=>{
        if(joinInfo.mb_email === userInfo.mb_email){
            setInputCheck({
                ...inputCheck,
                mb_email:true,
                mb_email_auth : true,
                mb_email_auth_msg : '',
            })
        }
        else{
            setInputCheck({
                ...inputCheck,
                mb_email:emailCheck(joinInfo.mb_email).result,
                mb_email_msg : emailCheck(joinInfo.mb_email).msg,
                mb_email_auth : false,
                mb_email_auth_msg : '',
            })
        }
    },[joinInfo.mb_email])

    React.useEffect(()=>{
        if(joinInfo.company_number === userInfo.company_number){
            setInputCheck({
                ...inputCheck,
                company_number:true,
            })
        }
        else{
            setInputCheck({
                ...inputCheck,
                company_number:false,
            })
        }
    },[joinInfo.company_number])

    return (
        <div className="container-fluid px-0">
            <AlertModal 
                show={alertModal.show}
                modalOff={modalOff}
                btnLabel={'확인'}
                msg={alertModal.msg}
                type={alertModal.type}
                alertAction={alertModalAction}  
            />
            <div className="sub_pg">
                <section className="container-lg mx-auto">
                    <div className="lg_bx">
                        <div className="tit_wr">
                            <h3 className="tit_h3 tit_point gmarket">로그인 정보</h3>
                        </div>
                        <form>
                        <CustomInput 
                                title={'아이디'}
                                placeHolder={'아이디를 입력해주세요.'}
                                input={joinInfo.mb_id}
                                setInput={inputController}
                                isValidShow={userInfo.mb_id !== joinInfo.mb_id}
                                isValid={inputCheck.mb_id}
                                validText={inputCheck.mb_id_msg}
                                keyName={'mb_id'}
                            />
                            <MarginY mt={2} />
                            <CustomButton 
                                btnLabel='비밀번호 변경하기'
                                color='green'
                                type='lg'
                                clickEvent={()=>{setChgPwdModal(true)}}
                            />
                            <h4 className="tit_h4 mt-5 mb-4">회원정보</h4>

                            <CustomInput 
                                title={'담당자 이름'}
                                placeHolder={'이름을 입력해주세요.'}
                                input={joinInfo.mb_name}
                                setInput={inputController}
                                isValidShow={false}
                                isValid={inputCheck.mb_name}
                                validText={inputCheck.mb_name_msg}
                                keyName={'mb_name'}
                            />

                            <MarginY mt={1} />

                            <CustomInputBtn 
                                title='이메일'
                                input={joinInfo.mb_email}
                                setInput={inputController}
                                placeHolder={'이메일을 입력해주세요.'}
                                isValidShow={joinInfo.mb_email !== '' && userInfo.mb_email !== joinInfo.mb_email}
                                isValid={inputCheck.mb_email}
                                validText={inputCheck.mb_email_msg}
                                btnLabel={'인증하기'}
                                inputStyle={userInfo.mb_email !== joinInfo.mb_email ? 'col-9 pr-0' : 'w-100'}
                                btnStyle={'col-3 pl-0'}
                                keyName={'mb_email'}
                                isBtnHide={userInfo.mb_email === joinInfo.mb_email}
                                btnAction={sendEmailAuth}
                            />
                            {isSendEmailAuth &&
                                <>
                                    <MarginY mt={1} />
                                    <CustomInputBtn 
                                        title={'인증번호'}
                                        placeHolder={'입력해주세요.'}
                                        input={joinInfo.mb_email_auth}
                                        setInput={inputController}
                                        isValidShow={joinInfo.mb_email_auth !== '' && inputCheck.mb_email_auth_msg !== ''}
                                        isValid={inputCheck.mb_email_auth}
                                        validText={inputCheck.mb_email_auth_msg}
                                        btnLabel={'확인'}
                                        inputStyle={'col-9 pr-0'}
                                        btnStyle={'col-3 pl-0'}
                                        btnLabelStyle={'fs_16'}
                                        keyName={'mb_email_auth'}
                                        btnAction={authEmailHandler}
                                    />
                                </>
                            }
                            <MarginY mt={2} />
                            <CustomInput 
                                title={'회사명'}
                                placeHolder={'소속된 회사 이름을 입력해주세요.'}
                                input={joinInfo.company_name}
                                setInput={inputController}
                                isValidShow={false}
                                isValid={inputCheck.company_name}
                                validText={inputCheck.company_name_msg}
                                keyName={'company_name'}
                            />
                            <MarginY mt={1} />
                            <CustomInputBtn 
                                title={'사업자등록번호'}
                                placeHolder={'사업자등록번호를 적어 주세요.'}
                                input={joinInfo.company_number}
                                setInput={inputController}
                                isValidShow={inputCheck.company_number_msg !== ''}
                                isValid={inputCheck.company_number}
                                validText={inputCheck.company_number_msg}
                                btnLabel={'인증하기'}
                                inputStyle={inputCheck.company_number ||joinInfo.company_number === userInfo.company_number ? 'w-100' : 'col-9 pr-0'}
                                btnStyle={'col-3 pl-0'}
                                btnLabelStyle={'fs_16'}
                                keyName={'company_number'}
                                btnAction={busiNumberCheck}
                                isBtnHide={inputCheck.company_number || joinInfo.company_number === userInfo.company_number}
                                isReadOnly={inputCheck.company_number && joinInfo.company_number !== userInfo.company_number}
                                // isReadOnly={joinInfo.company_number !== userInfo.company_number}
                            />
                            <MarginY mt={2} />
                            <CustomInput 
                                title={'업종'}
                                placeHolder={'사업자등록증에 등록된 업종을 기재해주세요.'}
                                input={joinInfo.company_cate_1}
                                setInput={inputController}
                                isValidShow={inputCheck.company_cate_1}
                                isValid={!inputCheck.company_cate_1}
                                validText={inputCheck.company_cate_1_msg}
                                keyName={'company_cate_1'}
                            />
                            <MarginY mt={2} />
                            <CustomInput 
                                title={'업태'}
                                placeHolder={'사업자등록증에 등록된 업태를 기재해주세요.'}
                                input={joinInfo.company_cate_2}
                                setInput={inputController}
                                isValidShow={inputCheck.company_cate_2}
                                isValid={!inputCheck.company_cate_2}
                                validText={inputCheck.company_cate_2_msg}
                                keyName={'company_cate_2'}
                            />
                            <MarginY mt={2} />
                            <CustomFile 
                                title={'사업자등록증 사본'}
                                placeHolder={'사업자등록번호사본을 업로드해주세요'}
                                input={joinInfo.company_reg_file_name}
                                setInput={busiFileChange}
                                btnLabel={'업로드'}
                                inputStyle={'col-9 pr-0'}
                                btnStyle={'col-3 pl-0'}
                                btnLabelStyle={'fs_16'}
                                fileId={'company_name'}
                            />
                            <MarginY mt={2} />
                            <CustomSelectBox 
                                title={'계좌등록'}
                                optionList={selBankList}
                                selValue={joinInfo.bank_name}
                                setSelValue={inputController}
                                keyName={'bank_name'}
                            />
                            <MarginY mt={1} />
                            <CustomInput
                                placeHolder={'계좌번호를 입력해주세요.'}
                                input={joinInfo.bank_number}
                                setInput={inputController}
                                isValidShow={false}
                                keyName={'bank_number'}
                            />

                            <CustomFile 
                                title={'통장 사본'}
                                placeHolder={'통장 사본을 업로드해주세요.'}
                                input={joinInfo.bank_reg_file_name}
                                setInput={bankFileChange}
                                btnLabel={'업로드'}
                                inputStyle={'col-9 pr-0'}
                                btnStyle={'col-3 pl-0'}
                                btnLabelStyle={'fs_16'}
                                fileId={'bank'}
                            />

                            {/* <div className="d_sp_center mt-5 mb-4">
                                <h4 className="tit_h4 ">당신이 담당하고 있는 지역을 선택해주세요!</h4>
                                <button type="button" className="btn xs_btn bg_bk" onClick={()=>{addCountry();}}>추가</button>
                            </div>
                            {selPlaceList.map((item,index) => {
                                return(
                                    <div key={index}>
                                        <div className="d-flex flex-row w-100" style={{gap:10}}>
                                            <div style={{flex:1}}>
                                                <CustomSelectBox 
                                                    title={'국가'}
                                                    optionList={selBankList}
                                                    selValue={selPlaceList[index].country}
                                                    setSelValue={countryChange}
                                                    keyName={String(index)}
                                                />
                                            </div>
                                            <div style={{flex:1}}>
                                                <CustomSelectBox 
                                                    title={'지역'}
                                                    optionList={selBankList}
                                                    selValue={selPlaceList[index].location}
                                                    setSelValue={locationChange}
                                                    keyName={String(index)}
                                                    isDelBtn={true}
                                                    delAction={delCountry}
                                                />
                                            </div>
                                        </div>
                                        <MarginY mb={1} />
                                    </div>
                                )
                            })} */}
                            
                            

                            <MarginY mt={3} />
                            <CustomInput 
                                title={'핸드폰 번호'}
                                placeHolder={'ex) 19970804'}
                                input={userInfo.mb_phone}
                                setInput={()=>{}}
                                isValidShow={false}
                                isReadOnly={true}
                            />
                            <MarginY mt={2} />
                            <CustomButton 
                                btnLabel='핸드폰번호 변경하기'
                                color='green'
                                type='lg'
                                clickEvent={()=>{setChgPhoneModal(true)}}
                            />
                            <MarginY mt={5} />
                            <CustomButton 
                                btnLabel='수정하기'
                                color='green'
                                type='lg'
                                labelStyle='fw_700'
                                clickEvent={()=>{editTravleHandler();}}
                            />
                        </form>
                    </div>
                    <ChangePwdModal 
                        show={chgPwdModal}
                        setShow={setChgPwdModal}
                        mb_id={userInfo.mb_id}
                        type={'edit'}
                    />
                    <ChangePhoneModal 
                        show={chgPhoneModal}
                        setShow={setChgPhoneModal}
                    />
                </section>
            </div>
        </div>
    );
}

export default TravleEditMyInfo;
