import classNames from 'classnames';
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import { downloadFile, getDate, getTime, NumberComma } from '../../inc/func';
import { DetailInfoType, ProDetailModalType } from './DetailType';


function NoramalProDetail1({
    proStep,
    setProStep,    
    setShow,
    detailInfo,
}:ProDetailModalType) { //여행사 & 개인 기획상품 신청

    const priceType = detailInfo.price_type === '달러' ? 'USD ' : 'KRW ';


    return (
        <>
            <div className="modal-header">
                <h5 className="modal-title">{detailInfo.pt_title}</h5>
                <button type="button" className="btn_close btn" onClick={()=>{setShow();}}>
                    <img src="/assets/img/ic_colse.png" alt="닫기 아이콘" />
                </button>
            </div>
            <div className="modal-body">
                <div className="info_box">
                    <p className="info_p">
                        <span>지역</span>
                        <span className="fs_14">{detailInfo.loc_txt}{detailInfo.loc_1_txt !== '' && ' / '+detailInfo.loc_1_txt }</span>
                    </p>
                    <div className="d-flex">
                        <p className="info_p mr-5">
                            <span>행사시작일</span>
                            <span className="fs_14">{detailInfo.pt_start_datetime}</span>
                        </p>
                        <p className="info_p">
                            <span>행사도착일</span>
                            <span className="fs_14">{detailInfo.pt_end_datetime}</span>
                        </p>
                    </div>
                    <div className="d-flex flex-wrap">
                        <p className="info_p mr-5">
                            <span>담당자</span>
                            <span className="fs_14">{detailInfo.pt_mb_name}</span>
                        </p>
                        <p className="info_p">
                            <span>여행 인원수</span>
                            <span className="fs_14">성인 {detailInfo.cnt_adult}명 / 유아 {detailInfo.cnt_child}명 /소아 {detailInfo.cnt_infant}명</span>
                        </p>
                        <p className="info_p">
                            <span>제안금액(1인당)</span>
                            <span className="fs_14">성인 {priceType}{NumberComma(Number(detailInfo.price_adult))} / 소아 {priceType}{NumberComma(Number(detailInfo.price_child))} / 유아 {priceType}{NumberComma(Number(detailInfo.price_infant))}</span>
                        </p>
                    </div>
                    <p className="info_p"><span>한줄요약</span></p>
                    <div className="info_txt">
                        {detailInfo.pt_memo === '' ? '한줄요약이 등록되어있지 않습니다.' : detailInfo.pt_memo}
                    </div>
                </div>
                <div className="d-flex align-items-center mt-4">
                    <p className="info_p mr-4"><span>첨부파일</span></p>
                    <div className="d-flex">
                        {/* <button type="button" className="btn btn-gray file_btn mr-3">보기</button> */}
                        <a className="btn btn-dark text-white file_btn d-flex justify-content-center align-items-center" onClick={()=>downloadFile(detailInfo.pt_file,detailInfo.pt_file_name)}>다운로드</a>
                    </div>
                </div>
            </div>
            <div className="modal-footer">
                <div className="d-flex w-100">
                    <button type="button" className="btn btn-lg btn-primary btn-block  mb-0" onClick={()=>{setProStep(proStep+1)}}>다음</button>
                </div>
            </div>
        </>
    )
}

export default NoramalProDetail1;
