import React from 'react';

import Accordion from 'react-bootstrap/Accordion';
import ReqDeadlineModal from '../../modal/trvReq/ReqDeadlineModal';
import ReqCancleModal from '../../modal/trvReq/ReqCancleModal';
import {ReqListDetailPropType} from '../../inc/type/reqType';
import { useAppSelector } from '../../store/config';
import { downloadFile, NumberComma, priceTypeCon } from '../../inc/func';
import LandReqEstEditModal from '../../modal/landReq/LandReqEstEditModal';

// interface ReqListPropType {
//     index: string,
//     cancleReq : () => void,
//     reqItem:TravleReqListType | LandReqListType
//     selListIdx? : string;
// }

function ReqDetailType2({index,
    cancleReq,
    reqItem,
    selListIdx,
    setSelTab,
    setConfirmModal,
    setSelMbIdx,
    setEmployeeModal
}:ReqListDetailPropType) { //계약전

    const userInfo = useAppSelector(state => state.userInfo);

    
    const [deadlineToggle, setDeadlineToggle] = React.useState(false); //여행사 - 견적마감
    const [cancleToggle, setCancleToggle] = React.useState(false); //여행사 - 견적요청 취소

    const [landEditModal, setLandEditModal] = React.useState(false); //랜드사 - 견적수정



    const confirmCancleReq = () => {
        if(setConfirmModal){
            setConfirmModal({
                confirm:true,
                msg:'정말로 신청한 견적을 취소하시겠습니까?',
                type:'land_req_cancle',
            })
        }
    }


    return (
        <Accordion.Collapse eventKey={String(index)} in={selListIdx===index}>
            <div className="card-body">
                <ul className="info">
                    <li><span>담당자</span>
                        <p>{reqItem.req_mb_name}</p>
                    </li>
                    <li><span>여행 인원수</span>
                        <p>성인 {reqItem.cnt_adult} / 유아 {reqItem.cnt_child} /소아 {reqItem.cnt_infant}</p>
                    </li>
                    <li><span>견적코드</span>
                        <p>{reqItem.req_code}</p>
                    </li>
                    <li className="textbx w-100"><span>요청사항</span>
                        <textarea name="" id="" cols={10} rows={2} className="form-control" readOnly defaultValue={reqItem.req_memo === '' ? '요청사항이 등록되어있지 않습니다.' : reqItem.req_memo}></textarea>
                    </li>
                </ul>
                <div className="bom">
                    {reqItem.req_file !== '' &&
                        <div className="lef">
                            <p>파일보기</p>
                            <a className="btn btn_down on" type="button" onClick={()=>downloadFile(reqItem.req_file,reqItem.req_file_name)}>다운로드</a>
                        </div>
                    }
                    {userInfo.mb_type === '1' &&
                        <div className="e_list_wr">
                            <p className="lef">견적 리스트</p>
                            <div className="rg">
                                {(reqItem.est_list && reqItem.est_list.length > 0) ? reqItem.est_list.map((item,index) => {
                                    return(
                                        <div className="e_list" key={index}>
                                            <div className="txt">
                                                <a className="br_name" type="button" onClick={()=>{console.log(item); setSelMbIdx(item.mb_idx);setEmployeeModal(true)}}>{item.company_name}</a>
                                                {item.est_file !== '' ?
                                                    <a className="underline" onClick={()=>{downloadFile(item.est_file,item.est_file_name)}}>첨부파일보기</a>
                                                    :
                                                    <p className="ml-3">첨부파일이 존재하지 않습니다.</p>
                                                }
                                                <p>제안금액(1인당) : 성인 {priceTypeCon(item.price_type)} {NumberComma(item.price_adult)} / 소아 {priceTypeCon(item.price_type)} {NumberComma(item.price_child)} / 유아 {priceTypeCon(item.price_type)} {NumberComma(item.price_infant)}</p>
                                            </div>
                                            {/* <a className="btn_selc btn-primary" onClick={()=>{openDetailModal(index)}} >선택하기</a> */}
                                        </div>
                                    )
                                    })
                                :
                                    <p>조회된 견적서가 존재하지 않습니다.</p>
                                }
                            </div>
                        </div>
                    }
                    {(userInfo.mb_type === '2' && reqItem.est_sct_item) &&
                        <div className="e_list_wr">
                            <p className="lef">견적 리스트</p>
                            <div className="rg">
                                <div className="e_list">
                                    <div className="txt">
                                        <a className="br_name" type="button" onClick={()=>{setSelMbIdx(reqItem.est_sct_item.mb_idx); setEmployeeModal(true)}}>{reqItem.est_sct_item.company_name}</a>
                                        {reqItem.est_sct_item.est_file !== '' ?
                                            <a className="underline" onClick={()=>{downloadFile(reqItem.est_sct_item.est_file,reqItem.est_sct_item.est_file_name)}}>첨부파일보기</a>
                                            :
                                            <p className="ml-3">첨부파일이 존재하지 않습니다.</p>
                                        }
                                        <p>제안금액(1인당) : 성인 {priceTypeCon(reqItem.est_sct_item.price_type)} {NumberComma(reqItem.est_sct_item.price_adult)} / 소아 {priceTypeCon(reqItem.est_sct_item.price_type)} {NumberComma(reqItem.est_sct_item.price_child)} / 유아 {priceTypeCon(reqItem.est_sct_item.price_type)} {NumberComma(reqItem.est_sct_item.price_infant)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {userInfo.mb_type === '1' &&
                        <div className="e_btn_wr">
                            <button className="btn btn_cancel" onClick={()=>{setDeadlineToggle(true)}}>마감하기</button>
                            <button className="btn btn_cancel" onClick={()=>{setCancleToggle(true)}} >취소하기</button>
                        </div>
                    }
                    {(userInfo.mb_type === '2' && reqItem.req_me_chk == 'Y') &&
                        <div className="e_btn_wr">
                            <button className="btn btn_cancel" onClick={()=>{setLandEditModal(true)}}>수정하기</button>
                            <button className="btn btn_cancel" onClick={()=>{confirmCancleReq()}} >취소하기</button>
                        </div>
                    }
                </div>
                {reqItem &&
                    <>
                        <ReqDeadlineModal 
                            show={deadlineToggle}
                            setShow={setDeadlineToggle}
                            reqItem={reqItem} 
                            setSelTab={setSelTab}
                        />
                        <ReqCancleModal 
                            show={cancleToggle}
                            setShow={setCancleToggle}
                            reqItem={reqItem}
                            setSelTab={setSelTab}
                        />
                        <LandReqEstEditModal 
                            show={landEditModal}
                            setShow={setLandEditModal}
                            estItem={reqItem}
                        />
                        
                    </>
                }
                
            </div>
        </Accordion.Collapse>
    )
}

export default ReqDetailType2;
